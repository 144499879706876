import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  Th,
  Link,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import {
  useBreakDownActivityListMutation,
  useDayBreakActivityListMutation,
} from 'store/dashboard.slice';
import { boolean } from 'yup';
import TrLoading from '../TrLoading';
import { Link as RouterLink } from 'react-router-dom';

interface DrawerProps {
  onClose: () => void;
  isOpen: boolean;
  type: string;
  selectByUser: any;
  week: number | null;
  date: any | null;
}

interface listInt {
  created_at: string;
  first_name: string;
  id: number;
  last_name: string;
  ref_id: number;
  status: string;
  type: string;
  parent_id: string;
  title: string;
}

const ActivityDrawer = ({
  onClose,
  isOpen,
  type,
  selectByUser,
  week,
  date,
}: DrawerProps) => {
  const toTitleCase = (text: string) => {
    return text.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };
  const [list, setList] = useState<listInt[]>([]);
  const [reqList, resList] = useBreakDownActivityListMutation();
  const [dayReqList, resDayList] = useDayBreakActivityListMutation();

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchWeekly = async () => {
    const param = {
      week,
      type,
      recruiters: selectByUser,
    };
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    reqList({ data: param, signal: abortControllerRef.current.signal })
      .then((res: any) => {
        setList(res?.data?.data || []);
      })
      .catch((e: any) => {});
  };

  const fetchDaily = async () => {
    const param = {
      date,
      type,
      recruiters: selectByUser,
    };
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    dayReqList({ data: param, signal: abortControllerRef.current.signal })
      .then((res: any) => {
        setList(res?.data?.data || []);
      })
      .catch((e: any) => {});
  };

  useEffect(() => {
    if (isOpen) {
      if (week) {
        fetchWeekly();
      }
      if (date) {
        fetchDaily();
      }
    }
  }, [isOpen]);

  const thSx = {
    // position: 'sticky',
    left: 0,
    bg: 'default.white.600',
    color: 'default.gray.600',
  };

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="md">
      <DrawerOverlay />
      <DrawerContent
        maxW="50vw"
        fontFamily="NunitoSans Regular"
        position="relative"
      >
        <DrawerCloseButton
          position="absolute"
          top="0px"
          left="-40px"
          borderRadius="unset"
          p="20px"
          bg="#E6E9EE"
          color="default.secondarytext"
          _hover={{
            backgroundColor: 'none',
          }}
        />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap="18px"
          pl={4}
          pr={0}
          borderBottom="1px solid #E6E9EE"
          fontSize="14px"
        >
          <Box
            id={`tagTooltip`}
            color="primary.600"
            fontSize="12px"
            lineHeight="18px"
            fontWeight={500}
            height="fit-content"
          >
            <Flex gap="5px" sx={{ paddingTop: '11px', paddingBottom: '11px' }}>
              <Link rel="noreferrer">{toTitleCase(type)}</Link>
            </Flex>
          </Box>
        </Flex>
        <DrawerBody p="0" overflow="hidden">
          <TableContainer
            boxSizing="border-box"
            border="1px solid #EEEEEE"
            borderRadius="4px"
            height="85vh"
            sx={{ overflowY: 'auto', padding: '1em 2em' }}
          >
            <Table>
              <Thead
                // sx={{ position: 'sticky', top: 0, zIndex: 1 }}
                key={`thead-candidate-0`}
              >
                <Tr bg="default.white.600">
                  {type === 'new client w/ interview' ||
                  type === 'new leads repeat' ? (
                    <>
                      <Th sx={thSx}>Lead Name</Th>
                      <Th sx={thSx}>Company Name</Th>
                    </>
                  ) : (
                    <>
                      <Th sx={thSx}>Candidate Name</Th>
                      <Th sx={thSx}>Job Name</Th>
                    </>
                  )}

                  <Th sx={thSx}>Current Status</Th>
                  <Th sx={thSx}>Last Activity</Th>
                </Tr>
              </Thead>
              <Tbody>
                {resList.isLoading ? (
                  <TrLoading columns={4} rows={10} />
                ) : (
                  list.map((item: listInt) => {
                    return (
                      <Tr>
                        <Td>
                          {type === 'new client w/ interview' ||
                          type === 'new leads repeat' ? (
                            <Link
                              as={RouterLink}
                              to={`/clients/contacts/${item.ref_id}/overview`}
                            >
                              {[item.first_name, item.last_name]
                                .filter(boolean)
                                .join(' ')}
                            </Link>
                          ) : (
                            <Link
                              as={RouterLink}
                              to={`/candidates/views/1/${item.ref_id}/about`}
                            >
                              {[item.first_name, item.last_name]
                                .filter(boolean)
                                .join(' ')}
                            </Link>
                          )}
                        </Td>
                        <Td>
                          {type === 'new client w/ interview' ||
                          type === 'new leads repeat' ? (
                            <Link
                              as={RouterLink}
                              to={`/clients/all-clients/${item.ref_id}/overview`}
                            >
                              {item.title}
                            </Link>
                          ) : (
                            <Link
                              as={RouterLink}
                              to={`/jobs/${item.parent_id}/details`}
                            >
                              {item.title}
                            </Link>
                          )}
                        </Td>
                        <Td>{item.status}</Td>
                        <Td>{moment(item.created_at).format('MMM D, YYYY')}</Td>
                      </Tr>
                    );
                  })
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ActivityDrawer;
