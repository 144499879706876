// React hooks
import { useRef, useState, useEffect, useMemo } from 'react';

// Redux hooks
import { useDispatch, useSelector } from 'react-redux';

// React Router hooks
import { useParams } from 'react-router';

// Redux slices
import { updateApp } from 'store/app.slice';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import { useListCandidatesMutation } from 'store/candidates.slice';
import { useViewClientMutation } from 'store/client.slice';
import { useGetCandidateSourceMutation } from 'store/constant.slice';
import { useCreateJobPostPlacementMutation } from 'store/jobs.slice';
import { useUserListMutation } from 'store/user.slice';

// Validation library
import * as Yup from 'yup';

interface useHiredModalServices {
  jobId: number;
  candidate: any;
  onCloseStatus: () => void;
  onClose: () => void;
  onCallback: () => void;
}

export const useHiredModalServices = ({
  jobId,
  candidate,
  onCloseStatus,
  onClose,
  onCallback,
}: useHiredModalServices) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { userList } = useSelector((state: any) => state.user);
  const { email } = useSelector((state: any) => state.auth);
  const { clientAgreements } = useSelector(
    (state: any) => state.clientAgreements
  );
  const { jobData } = useSelector((state: any) => state.jobs);
  const { candidateData, candidatePgBtn } = useSelector(
    (state: any) => state.candidates
  );
  const { lead_source } = useSelector((state: any) => state.constants);
  const [reqClient, resClient] = useViewClientMutation();
  const [reqCandSource, resCandSource] = useGetCandidateSourceMutation();
  const [reqUsers, resUsers] = useUserListMutation();
  const [reqCreateForm, resCreateForm] = useCreateJobPostPlacementMutation();
  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();
  const [reqCandidates] = useListCandidatesMutation();

  const abortFilterControllerRef = useRef<AbortController | null>(null);
  const fetchPin = async () => {
    if (abortFilterControllerRef.current) {
      abortFilterControllerRef.current.abort();
    }

    abortFilterControllerRef.current = new AbortController();
    await reqPinList({
      signal: abortFilterControllerRef.current.signal,
    });
  };

  const [recOptions, setRecruiters] = useState([]);
  const initCcOption = Object.values(userList).map((item: any) => {
    return {
      label: [item.first_name, item.last_name].filter(Boolean).join(' '),
      value: item.email,
    };
  });
  const [ccOption, setCcOption] = useState<any>(initCcOption);

  const [leadSourceList, setLeadSourceList] = useState([]);
  const [candSourceList, setCandSourceList] = useState([]);

  const [isSplit, setIsSplit] = useState(true);
  const [jobDetails, setJobDetails] = useState<any>(null);

  useEffect(() => {
    reqUsers({});
    const job_id = jobId || Number(params.jobsId) || jobData?.id;
    const candidateJob =
      candidate?.jobs?.filter((job: any) => job.job_id === job_id)?.[0]?.job
        ?.client_id || jobData?.client_id;
    setJobDetails(
      candidate?.jobs?.[0]?.job
        ? candidate?.jobs?.filter((job: any) => job.job_id === job_id)?.[0]
        : jobData
    );
    if (candidateJob) {
      reqClient({ id: candidateJob });
    }
  }, [jobId, jobData?.id]);

  useMemo(() => {
    let options: any = [];
    if (lead_source?.length > 0) {
      lead_source.map((item: any) => {
        options.push({
          label: item.source,
          value: item.id,
        });
      });
    }
    setLeadSourceList(options);
  }, [lead_source]);

  useMemo(() => {
    reqCandSource({}).then((res: any) => {
      let option: any = [];
      if (res?.data?.data?.length > 0) {
        res?.data?.data.map((item: any) => {
          option.push({
            label: String(item.source),
            value: item.id,
            sub: item.sub,
          });
        });
      }

      setCandSourceList(option);
    });
  }, []);

  useEffect(() => {
    let users: any = [];
    if (resUsers.isSuccess) {
      if (resUsers.data?.data.length > 0) {
        Promise.all(
          resUsers.data?.data.map((item: any) => {
            users.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
          })
        );
      }
    }
    setRecruiters(users);
  }, [resUsers.data?.data, resUsers.isSuccess]);

  const primaryEmails = candidate?.emails?.filter((item: any) => item.primary);
  const clientAgreementData = [...clientAgreements]?.sort((a: any, b: any) => {
    return (
      new Date(a?.date_signed).getTime() - new Date(b?.date_signed).getTime()
    );
  })?.[0];
  const initPlacementForm = {
    candidate_name: candidate
      ? [candidate?.first_name, candidate?.last_name].join(' ')
      : [candidateData?.first_name, candidateData?.last_name].join(' '),
    candidate_email: primaryEmails?.length > 0 ? primaryEmails?.[0]?.email : '',
    job_title: jobDetails?.job?.title || jobData?.title || '',
    start_date: '',
    base_salary: '',
    fee: Number(clientAgreementData?.fee_pct) || (null as any),
    payable_days: Number(clientAgreementData?.payable) || (null as any),
    guarantee_days: Number(clientAgreementData?.guarantee) || (null as any),
    guarantee_type:
      Number(clientAgreementData?.guarantee_type) || (null as any),
    sign_on_bonus: '0',
    fee_discount: '',
    total_fee_invoice: '',
    reporting_name: '',
    reporting_title: '',
    reporting_email: '',
    reporting_phone: '',
    invoice_name: '',
    invoice_title: '',
    invoice_email: '',
    invoice_phone: '',
    invoice_cc: ccOption?.filter(
      (entry: any) =>
        entry.value === 'roy@talently.com' || entry.value === email
    ),
    isSplit: 'Y',
    recruiters: [
      {
        id: null as any,
        split: null as any,
      },
    ],
    client_source_id: jobData?.lead?.lead_source?.source,
    candidate_source_id: candidateData?.source?.source,
    lifetime_value_client: '',
    notes: '',
  };
  const [initialValues, setInitialValues] = useState<any>(initPlacementForm);
  const formRef = useRef(null);
  const [company, setCompany] = useState<any>(null);

  useEffect(() => {
    let params = {} as any;
    if (jobData) {
      params = {
        ...params,
        job_title: jobData?.title || '',
      };
    }
    if (candidateData) {
      params = {
        ...params,
        candidate_name: [
          candidateData?.first_name,
          candidateData?.last_name,
        ].join(' '),
        candidate_email:
          candidateData?.emails?.filter((item: any) => item.primary)[0]
            ?.email || '',
      };
    }
    console.log('params ', params);
    setInitialValues({
      ...initialValues,
      ...params,
    });
  }, [jobData, candidateData]);

  useEffect(() => {
    if (resClient.isSuccess) {
      const clientData = resClient.data.data;
      setCompany(clientData);
      setInitialValues({
        ...initialValues,
        fee: clientData?.agreements?.[0]?.fee_pct,
        payable_days: clientData?.agreements?.[0]?.payable,
        guarantee_days: clientData?.agreements?.[0]?.guarantee,
        guarantee_type: clientData?.agreements?.[0]?.guarantee_type,
      });
      if (clientData) {
        let options: any = [];
        let optionsEmail: any = [];
        // await Promise.all(
        clientData.leads.map(async (item: any) => {
          options.push({
            label: [item.first_name, item.last_name].filter(Boolean).join(' '),
            value: String(item.id),
          });
          optionsEmail.push({
            label: [item.first_name, item.last_name].filter(Boolean).join(' '),
            value: item.primary_email,
          });
        });

        setInvoiceNameOption(options);
        setCcOption([...ccOption, ...optionsEmail]);
        setReportingNameOption(options);
      }
    }
  }, [resClient.isSuccess]);

  useEffect(() => {
    if (jobDetails?.job?.title) {
      setInitialValues({
        ...initialValues,
        job_title: jobDetails?.job?.title,
      });
    }
  }, [jobDetails?.job?.title]);

  useEffect(() => {
    if (jobData?.lead?.lead_source?.source) {
      setInitialValues({
        ...initialValues,
        client_source_id: jobData?.lead?.lead_source?.source,
      });
    }
  }, [jobData?.lead?.lead_source?.source]);

  useEffect(() => {
    if (candidateData?.source?.source) {
      setInitialValues({
        ...initialValues,
        candidate_source_id: candidateData?.source?.source,
      });
    }
  }, [candidateData?.source?.source]);

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      candidate_name: Yup.string().required('Candidate Name is required.'),
      candidate_email: Yup.string()
        .email('Invalid email.')
        .required('Candidate Email is required.'),
      job_title: Yup.string().required('Job Title is required.'),
      start_date: Yup.string().required('Start Date is required.'),
      base_salary: Yup.string().required('Base Salary is required.'),
      fee: Yup.number().required('Fee is required.').nullable(),
      payable_days: Yup.number()
        .required('Payable Days is required.')
        .nullable(),
      guarantee_days: Yup.number()
        .required('Guarantee Days is required.')
        .nullable(),
      guarantee_type: Yup.string()
        .required('Guarantee Type is required.')
        .nullable(),
      sign_on_bonus: Yup.string(),
      fee_discount: Yup.string(),
      total_fee_invoice: Yup.string(),
      reporting_name: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
      reporting_title: Yup.string(),
      reporting_email: Yup.string().email('Invalid email.'),
      reporting_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Personal phone is invalid'
      ),
      invoice_name: Yup.object().shape({
        label: Yup.string().required('Name is required.'),
        value: Yup.string(),
      }),
      invoice_title: Yup.string().required('Title is required.'),
      invoice_email: Yup.string()
        .email('Invalid email.')
        .required('Email is required.'),
      invoice_phone: Yup.string()
        .matches(/^[\d ()+-.]+$/gi, 'Personal phone is invalid')
        .required('Phone is required.'),
      invoice_cc: Yup.array(),
      isSplit: Yup.string(),
      ...(isSplit && {
        recruiters: Yup.array().of(
          Yup.object().shape({
            recruiter_id: Yup.number().required('Recruiter is required'),
            split: Yup.number()
              .required('Split percentage is required')
              .nullable(),
          })
        ),
      }),
      client_source_id: Yup.string().required('Source Client is required.'),
      candidate_source_id: Yup.string().required(
        'Source Candidate is required.'
      ),
      lifetime_value_client: Yup.string(),
      notes: Yup.string(),
    })
  );

  useEffect(() => {
    if (resCreateForm.isSuccess) {
      onCloseStatus();
      onClose();
      onCallback();
      dispatch(updateApp({ isCongrats: true }));
      setTimeout(() => {
        reqCandidates({ data: candidatePgBtn });
        reqFilterList({});
        fetchPin();
      }, 500);
    }
  }, [resCreateForm.isSuccess]);
  const [invoiceNameOption, setInvoiceNameOption] = useState([]);
  const [reportingNameOption, setReportingNameOption] = useState([]);

  const handleSubmit = async (data: any) => {
    data['job_id'] = params.jobsTab
      ? Number(params.jobsId)
      : jobDetails?.job_id || jobDetails?.id || jobData.id;
    data['candidate_id'] = candidate?.id;
    data['total_fee_invoice'] = Number(
      data['total_fee_invoice']?.replace(/,/g, '')
    );
    data['sign_on_bonus'] = Number(data['sign_on_bonus']?.replace(/,/g, ''));
    data['fee_discount'] = Number(data['fee_discount']?.replace(/,/g, ''));
    data['base_salary'] =
      typeof data?.base_salary === 'string'
        ? Number(data?.base_salary.replace(/,/g, ''))
        : data?.base_salary;
    data['split_recruiter'] = isSplit;
    data['recruiters'] = isSplit ? data['recruiters'] : [];
    data['lifetime_value_client'] = Number(
      data['lifetime_value_client'].replace(/,/g, '')
    );
    data['invoice_cc'] = data['invoice_cc'].map((val: any) => val.value);
    data['candidate_source_id'] = candSourceList.filter(
      (candSource: any) => candSource?.label === data['candidate_source_id']
    )?.[0]?.value;
    data['client_source_id'] = leadSourceList.filter(
      (leadSource: any) => leadSource?.label === data['client_source_id']
    )?.[0]?.value;
    data['invoice_name'] = data['invoice_name']?.label || '';
    data['reporting_name'] = data['reporting_name']?.label || '';

    delete data['isSplit'];
    // console.log(data);
    await reqCreateForm(data);
  };
  const handleInvoiceCallback = async (lead_id: number) => {
    await reqClient({
      id: jobDetails?.client_id || jobDetails?.job?.client_id,
    }).then((res: any) => {
      const companyData = res.data.data;
      const leadData =
        companyData.leads?.filter((lead: any) => {
          return lead.id == lead_id;
        })?.[0] || null;
      if (leadData) {
        const invoiceForm = {
          invoice_name: {
            label: [leadData?.first_name, leadData?.last_name]
              .filter(Boolean)
              .join(' '),
            value: leadData?.id,
          },
          invoice_title: leadData?.title || '',
          invoice_email: leadData?.primary_email || '',
          invoice_phone: leadData?.work_phone || '',
        };
        setInitialValues({
          ...initialValues,
          ...invoiceForm,
        });
      }
    });
  };

  const handleReportingCallback = async (lead_id: number) => {
    await reqClient({
      id: jobDetails?.client_id || jobDetails?.job?.client_id,
    }).then((res: any) => {
      const companyData = res.data.data;
      const leadData =
        companyData.leads?.filter((lead: any) => {
          return lead.id == lead_id;
        })?.[0] || null;
      if (leadData) {
        const reportingForm = {
          reporting_name: {
            label: [leadData?.first_name, leadData?.last_name]
              .filter(Boolean)
              .join(' '),
            value: leadData?.id,
          },
          reporting_title: leadData?.title || '',
          reporting_email: leadData?.primary_email || '',
          reporting_phone: leadData?.work_phone || '',
        };
        setInitialValues({
          ...initialValues,
          ...reportingForm,
        });
      }
    });
  };
  return {
    formRef,
    initialValues,
    handleSubmit,
    validationSchema,
    company,
    handleReportingCallback,
    reportingNameOption,
    handleInvoiceCallback,
    invoiceNameOption,
    ccOption,
    setIsSplit,
    recOptions,
    leadSourceList,
    candSourceList,
    isLoading: resCandSource.isLoading,
  };
};
