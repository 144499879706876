/* eslint-disable react-hooks/exhaustive-deps */

// Formik components for handling forms
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';

// Chakra UI components for layout and UI elements
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  Divider,
  Image,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';

// Components from the application
import ScrollToFieldError from 'components/app/ScrollError';
import AtsSelect from 'components/app/AtsSelect';
import AtsCraetableSelect from 'components/app/AtsCreatabeSelect';
import AtsEmailBody from 'components/app/EmailBody';
import AtsSelectContact from 'components/app/AtsSelectContact';
import { useHiredModalServices } from './useHiredModalServices';

// Assets
import MdConfetti from 'assets/images/MdConfetti.svg';

// Utilities
import moment from 'moment';
import { truncateToTwoDecimalPlaces } from 'utils/utilFormat';

// Custom Library Components
import Button from 'Library/Button';

interface HiredModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseStatus?: () => void;
  candidate?: any;
  onCallback: () => void;
  jobId?: number;
}

export default function HiredModal({
  isOpen,
  onClose,
  onCloseStatus = () => {},
  candidate,
  onCallback,
  jobId,
}: HiredModalProps) {
  const {
    formRef,
    initialValues,
    handleSubmit,
    validationSchema,
    company,
    handleReportingCallback,
    reportingNameOption,
    handleInvoiceCallback,
    invoiceNameOption,
    ccOption,
    setIsSplit,
    recOptions,
    leadSourceList,
    candSourceList,
    isLoading,
  } = useHiredModalServices({
    jobId,
    candidate,
    onCloseStatus,
    onClose,
    onCallback,
  });

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick
      lockFocusAcrossFrames
      blockScrollOnMount
      onClose={() => {
        onClose();
        onCloseStatus();
      }}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent
        fontFamily="NunitoSans Regular"
        overflowY="hidden"
        borderRadius="8px"
      >
        <ModalHeader
          background="primary.800"
          p="10px 32px"
          borderRadius="8px 8px 0 0"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Box
              fontSize="24px"
              lineHeight="18px"
              textAlign="left"
              color="white"
            >
              Placement Form
            </Box>
            <Image height="70px" mr="25px" src={MdConfetti} alt="logo" />
          </Flex>
        </ModalHeader>
        <ModalCloseButton top="13px" right="20px" color="white" />

        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              setFieldValue,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mb="32px"
                    >
                      Candidate Information
                    </Box>
                    <Flex gap="32px" mb="24px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.candidate_name && touched.candidate_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Candidate Name{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="candidate_name"
                          name="candidate_name"
                          type="text"
                          variant="outline"
                          value={values.candidate_name}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.candidate_name)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.candidate_email && touched.candidate_email
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Candidate Email{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="candidate_email"
                          name="candidate_email"
                          type="email"
                          variant="outline"
                          value={values.candidate_email}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.candidate_email)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Job Information
                    </Box>
                    <Flex gap="32px" mb="24px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.job_title && touched.job_title
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Job Title{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="job_title"
                          name="job_title"
                          type="text"
                          variant="outline"
                          value={values.job_title}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.job_title)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.start_date && touched.start_date
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Start Date{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="start_date"
                          name="start_date"
                          placeholder="Select Date"
                          type="date"
                          value={values.start_date}
                          onChange={handleChange}
                          max={moment().format('MM/DD/YYYY')}
                        />

                        <FormErrorMessage>
                          {String(errors.start_date)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Position Reporting To
                    </Box>
                    <Flex gap="32px" mb="24px">
                      <FormControl
                        isInvalid={Boolean(
                          !!(errors.reporting_name as any)?.label &&
                            touched.reporting_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Name
                        </FormLabel>

                        <Field
                          id="reporting_name"
                          name="reporting_name"
                          component={AtsSelectContact}
                          value={values.reporting_name}
                          client={{ label: company?.name, value: company?.id }}
                          onChange={(event: any) => {
                            const leadData =
                              company.leads?.filter(
                                (lead: any) => lead.id === Number(event.value)
                              )?.[0] || null;
                            if (leadData) {
                              setFieldValue('reporting_name', {
                                label: [
                                  leadData?.first_name,
                                  leadData?.last_name,
                                ]
                                  .filter(Boolean)
                                  .join(' '),
                                value: leadData?.id,
                              });
                              setFieldValue(
                                'reporting_title',
                                leadData?.title || ''
                              );
                              setFieldValue(
                                'reporting_email',
                                leadData?.primary_email || ''
                              );
                              setFieldValue(
                                'reporting_phone',
                                leadData?.work_phone || ''
                              );
                            }
                          }}
                          onCallback={handleReportingCallback}
                          options={reportingNameOption}
                          defaultValue={values.reporting_name}
                          isSaveName={true}
                          isSaveInput={true}
                        />
                        <FormErrorMessage>
                          {String((errors.reporting_name as any)?.label)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.reporting_title && touched.reporting_title
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Title
                        </FormLabel>
                        <Input
                          id="reporting_title"
                          name="reporting_title"
                          type="text"
                          variant="outline"
                          value={values.reporting_title}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.reporting_title)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex gap="32px" mb="24px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.reporting_email && touched.reporting_email
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Email
                        </FormLabel>
                        <Input
                          id="reporting_email"
                          name="reporting_email"
                          type="email"
                          variant="outline"
                          value={values.reporting_email}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.reporting_email)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.reporting_phone && touched.reporting_phone
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Phone
                        </FormLabel>
                        <Input
                          id="reporting_phone"
                          name="reporting_phone"
                          type="text"
                          variant="outline"
                          value={values.reporting_phone}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.reporting_phone)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Compensation Details
                    </Box>
                    <Flex gap="32px" mb="24px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.base_salary && touched.base_salary
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Base Salary{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="base_salary"
                          name="base_salary"
                          placeholder="$"
                          type="text"
                          variant="outline"
                          value={values.base_salary}
                          onChange={(event: any) => {
                            let { value } = event.target;

                            // Allow only numeric characters and a dot
                            value = value.replace(/[^0-9.]/g, '');

                            // Remove leading zeros
                            value = value.replace(/^0+/g, '');

                            // Format the number with commas
                            const formattedValue =
                              Number(value)?.toLocaleString();
                            // console.log('went to format base salary');
                            setFieldValue('base_salary', formattedValue);
                            const totalFee =
                              Number(
                                typeof value === 'string'
                                  ? Number(value.replace(/,/g, ''))
                                  : value
                              ) *
                              (Number(values.fee) / 100);

                            setFieldValue(
                              'total_fee_invoice',
                              truncateToTwoDecimalPlaces(
                                totalFee
                              ).toLocaleString()
                            );
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.base_salary)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(!!errors.fee && touched.fee)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Fee %{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="fee"
                          name="fee"
                          placeholder="%"
                          type="number"
                          min={0}
                          max={100}
                          variant="outline"
                          value={values.fee}
                          onChange={(e) => {
                            let { value } = e.target;

                            handleChange(e);

                            const totalFee =
                              Number(
                                typeof values?.base_salary === 'string'
                                  ? Number(
                                      values?.base_salary.replace(/,/g, '')
                                    )
                                  : values?.base_salary
                              ) *
                              (Number(value) / 100);

                            setFieldValue(
                              'total_fee_invoice',
                              truncateToTwoDecimalPlaces(
                                totalFee
                              ).toLocaleString()
                            );
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.fee)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex gap="32px" mb="16px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.payable_days && touched.payable_days
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Payable Days{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="payable_days"
                          name="payable_days"
                          type="number"
                          variant="outline"
                          disabled={true}
                          min={0}
                          value={values.payable_days}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.payable_days)}
                        </FormErrorMessage>
                      </FormControl>
                      {/* <FormControl
                      isInvalid={Boolean(
                        !!errors.guarantee_days && touched.guarantee_days
                      )}
                    > */}
                      <FormControl>
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Guarantee Days - Type{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Flex>
                          <Input
                            id="guarantee_days"
                            name="guarantee_days"
                            type="number"
                            min={0}
                            borderRightRadius="none"
                            variant="outline"
                            value={values.guarantee_days}
                            disabled={true}
                            onChange={handleChange}
                          />
                          <Input
                            id="guarantee_type"
                            name="guarantee_type"
                            type="string"
                            min={0}
                            borderLeftRadius="none"
                            variant="outline"
                            value={values.guarantee_type}
                            disabled={true}
                            onChange={handleChange}
                          />
                        </Flex>
                      </FormControl>

                      {/* <FormErrorMessage>
                        {String(errors.guarantee_days)}
                      </FormErrorMessage>
                    </FormControl> */}
                    </Flex>
                    <Box
                      fontSize="16px"
                      lineHeight="18px"
                      color="default.primarytext"
                      mb="16px"
                    >
                      Discount Given
                    </Box>
                    <Flex gap="32px" mb="24px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.sign_on_bonus && touched.sign_on_bonus
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Sign On Bonus to Candidate from Talently{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="sign_on_bonus"
                          name="sign_on_bonus"
                          placeholder="$"
                          type="text"
                          variant="outline"
                          value={values.sign_on_bonus}
                          onChange={(event: any) => {
                            let { value } = event.target;

                            // Allow only numeric characters and a dot
                            value = value.replace(/[^0-9.]/g, '');

                            // Remove leading zeros
                            value = value.replace(/^0+/g, '');

                            // Format the number with commas
                            const formattedValue =
                              Number(value)?.toLocaleString();

                            setFieldValue('sign_on_bonus', formattedValue);
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.sign_on_bonus)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.fee_discount && touched.fee_discount
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Fee Discount to the Client{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="fee_discount"
                          name="fee_discount"
                          placeholder="$"
                          type="text"
                          variant="outline"
                          value={values.fee_discount}
                          onChange={(event: any) => {
                            let { value } = event.target;

                            // Allow only numeric characters and a dot
                            value = value.replace(/[^0-9.]/g, '');

                            // Remove leading zeros
                            value = value.replace(/^0+/g, '');

                            // Format the number with commas
                            const formattedValue =
                              Number(value)?.toLocaleString();

                            setFieldValue('fee_discount', formattedValue);
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.fee_discount)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Box
                      sx={{
                        bg: '#FAF5FF',
                        borderRadius: '6px',
                        mb: '16px',
                        p: '16px',
                      }}
                    >
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.total_fee_invoice &&
                            touched.total_fee_invoice
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Total Fee to Invoice{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="total_fee_invoice"
                          name="total_fee_invoice"
                          placeholder="$"
                          type="text"
                          variant="outline"
                          value={values.total_fee_invoice}
                          onChange={handleChange}
                          bg="#fff"
                        />
                        <FormErrorMessage>
                          {String(errors.total_fee_invoice)}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Invoice Information
                    </Box>
                    <Box
                      fontSize="16px"
                      lineHeight="18px"
                      color="default.primarytext"
                      mb="16px"
                    >
                      Who do we Invoice?
                    </Box>
                    <Flex gap="32px" mb="24px">
                      <FormControl
                        isInvalid={Boolean(
                          !!(errors.invoice_name as any)?.label &&
                            touched.invoice_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Name{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          id="invoice_name"
                          name="invoice_name"
                          component={AtsSelectContact}
                          value={values.invoice_name}
                          onChange={(event: any) => {
                            const leadData =
                              company.leads?.filter(
                                (lead: any) => lead.id === Number(event.value)
                              )?.[0] || null;
                            if (leadData) {
                              setFieldValue('invoice_name', {
                                label: [
                                  leadData?.first_name,
                                  leadData?.last_name,
                                ]
                                  .filter(Boolean)
                                  .join(' '),
                                value: leadData?.id,
                              });
                              setFieldValue(
                                'invoice_title',
                                leadData?.title || ''
                              );
                              setFieldValue(
                                'invoice_email',
                                leadData?.primary_email || ''
                              );
                              setFieldValue(
                                'invoice_phone',
                                leadData?.work_phone || ''
                              );
                            }
                          }}
                          client={{ label: company?.name, value: company?.id }}
                          onCallback={handleInvoiceCallback}
                          options={invoiceNameOption}
                          defaultValue={values.invoice_name}
                          isSaveName={true}
                          isSaveInput={true}
                        />
                        <FormErrorMessage>
                          {String((errors.invoice_name as any)?.label)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.invoice_title && touched.invoice_title
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Title{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="invoice_title"
                          name="invoice_title"
                          type="text"
                          variant="outline"
                          value={values.invoice_title}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.invoice_title)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex gap="32px" mb="24px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.invoice_email && touched.invoice_email
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Email{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="invoice_email"
                          name="invoice_email"
                          type="email"
                          variant="outline"
                          value={values.invoice_email}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.invoice_email)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.invoice_phone && touched.invoice_phone
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Phone{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="invoice_phone"
                          name="invoice_phone"
                          type="text"
                          variant="outline"
                          value={values.invoice_phone}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.invoice_phone)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Box mb="32px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.invoice_cc && touched.invoice_cc
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          cc
                        </FormLabel>
                        <AtsCraetableSelect
                          id="invoice_cc"
                          name="invoice_cc"
                          onChange={(event: any) => {
                            setFieldValue('invoice_cc', event);
                          }}
                          options={ccOption}
                          isMulti={true}
                          placeholder="cc"
                          defaultValue={values.invoice_cc}
                        />

                        <FormErrorMessage>
                          {String(errors.invoice_cc)}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Recruitment Collaboration Details
                    </Box>
                    <Box
                      fontSize="16px"
                      lineHeight="18px"
                      color="default.primarytext"
                      mb="16px"
                    >
                      Split with another Recruiter?
                    </Box>
                    <RadioGroup
                      defaultValue={values.isSplit}
                      onChange={handleChange}
                    >
                      <Stack spacing={2} direction="row" mb="16px">
                        <Radio
                          colorScheme="purple"
                          name="isSplit"
                          onChange={(e) => {
                            handleChange(e);
                            setIsSplit(true);
                          }}
                          value="Y"
                        >
                          Yes
                        </Radio>
                        <Radio
                          colorScheme="purple"
                          name="isSplit"
                          onChange={(e: any) => {
                            handleChange(e);
                            setIsSplit(false);
                          }}
                          value="N"
                        >
                          No
                        </Radio>
                      </Stack>
                    </RadioGroup>

                    {values.isSplit === 'Y' && (
                      <FieldArray name="recruiters">
                        {({ remove, push }) => (
                          <div>
                            {values.recruiters.length > 0 &&
                              values.recruiters.map(
                                (recruiter: any, index: number) => {
                                  const recruiter_id = `recruiters[${index}].recruiter_id`;
                                  const split = `recruiters[${index}].split`;
                                  return (
                                    <>
                                      <Box
                                        className="row"
                                        key={`recr-${index}`}
                                      >
                                        {values.recruiters.length > 0 && (
                                          <Flex justifyContent="flex-end">
                                            <Button
                                              leftIcon="close"
                                              onClick={() => remove(index)}
                                              size="sm"
                                            >
                                              Remove
                                            </Button>
                                          </Flex>
                                        )}
                                        <Flex mb="20px" gap="32px">
                                          <FormControl
                                            isInvalid={Boolean(
                                              !!(
                                                (errors.recruiters as any)?.[
                                                  index
                                                ]?.recruiter_id &&
                                                (touched.recruiters as any)?.[
                                                  index
                                                ]?.id
                                              )
                                            )}
                                          >
                                            <FormLabel
                                              fontSize="14px"
                                              lineHeight="18px"
                                              color="default.primarytext"
                                            >
                                              Recruiter{' '}
                                              <Box
                                                as="span"
                                                color="primary.800"
                                              >
                                                *
                                              </Box>
                                            </FormLabel>
                                            <AtsSelect
                                              name={recruiter_id}
                                              id={recruiter_id}
                                              placeholder="Select"
                                              variant="outline"
                                              onChange={(e: any) =>
                                                setFieldValue(
                                                  recruiter_id,
                                                  Number(e.value)
                                                )
                                              }
                                              defaultValue={
                                                recruiter.recruiter_id
                                              }
                                              options={
                                                recOptions?.length > 0 &&
                                                recOptions.map((item: any) => ({
                                                  label: item.label,
                                                  value: item.value,
                                                }))
                                              }
                                            />
                                            <FormErrorMessage>
                                              {String(
                                                (errors.recruiters as any)?.[
                                                  index
                                                ]?.recruiter_id
                                              )}
                                            </FormErrorMessage>
                                          </FormControl>

                                          <FormControl
                                            isInvalid={Boolean(
                                              !!(
                                                (errors.recruiters as any)?.[
                                                  index
                                                ]?.split &&
                                                (touched.recruiters as any)?.[
                                                  index
                                                ]?.split
                                              )
                                            )}
                                          >
                                            <FormLabel
                                              fontSize="14px"
                                              lineHeight="18px"
                                              color="default.primarytext"
                                            >
                                              What % Split{' '}
                                              <Box
                                                as="span"
                                                color="primary.800"
                                              >
                                                *
                                              </Box>
                                            </FormLabel>
                                            <Field
                                              as={Input}
                                              id={split}
                                              name={split}
                                              placeholder="Split Percentage"
                                              type="number"
                                              variant="outline"
                                              max={100}
                                              min={0}
                                              value={recruiter.split}
                                              onChange={(e: any) => {
                                                setFieldValue(
                                                  split,
                                                  e.target.value
                                                );
                                              }}
                                            />

                                            <FormErrorMessage>
                                              {String(
                                                (errors.recruiters as any)?.[
                                                  index
                                                ]?.split
                                              )}
                                            </FormErrorMessage>
                                          </FormControl>
                                        </Flex>
                                      </Box>
                                      <Divider mb="20px" />
                                    </>
                                  );
                                }
                              )}
                            <Button
                              leftIcon="plus"
                              onClick={() =>
                                push({
                                  name: '',
                                  split_percentage: null as any,
                                })
                              }
                            >
                              {values.recruiters.length > 0
                                ? 'Add Another Recruiter'
                                : 'Add Recruiter'}
                            </Button>
                          </div>
                        )}
                      </FieldArray>
                    )}

                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Client and Candidate Source
                    </Box>
                    <Flex gap="24px" mb="24px" flexDir="column">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.client_source_id && touched.client_source_id
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Source of the Client{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <AtsSelect
                          options={leadSourceList}
                          name="client_source_id"
                          id="client_source_id"
                          value={
                            leadSourceList?.filter(
                              (leadSource: any) =>
                                leadSource?.label === values.client_source_id
                            )?.[0] || ''
                          }
                          onChange={(e: any) => {
                            setFieldValue('client_source_id', e.label);
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.client_source_id)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.candidate_source_id &&
                            touched.candidate_source_id
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Source of the Candidate{' '}
                          <Box as="span" color="primary.800">
                            *
                          </Box>
                        </FormLabel>
                        <AtsSelect
                          options={candSourceList}
                          name="candidate_source_id"
                          id="candidate_source_id"
                          isLoading={isLoading}
                          value={
                            candSourceList?.filter(
                              (candSource: any) =>
                                candSource?.label === values.candidate_source_id
                            )?.[0] || ''
                          }
                          onChange={(e: any) => {
                            setFieldValue('candidate_source_id', e.label);
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.candidate_source_id)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.lifetime_value_client &&
                            touched.lifetime_value_client
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Lifetime Value of the Client
                        </FormLabel>
                        <Input
                          id="lifetime_value_client"
                          name="lifetime_value_client"
                          placeholder="$"
                          type="text"
                          variant="outline"
                          value={values.lifetime_value_client}
                          onChange={(event: any) => {
                            let { value } = event.target;

                            // Allow only numeric characters and a dot
                            value = value.replace(/[^0-9.]/g, '');

                            // Remove leading zeros
                            value = value.replace(/^0+/g, '');

                            // Format the number with commas
                            const formattedValue =
                              Number(value)?.toLocaleString();

                            setFieldValue(
                              'lifetime_value_client',
                              formattedValue
                            );
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.lifetime_value_client)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Divider borderColor="#CEB8FF" py="10px" />
                    <Box
                      fontSize="20px"
                      lineHeight="18px"
                      color="primary.800"
                      fontWeight={700}
                      mt="40px"
                      mb="32px"
                    >
                      Additional Notes
                    </Box>
                    <Box mb="24px">
                      <FormControl
                        isInvalid={Boolean(!!errors.notes && touched.notes)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.primarytext"
                        >
                          Notes
                        </FormLabel>
                        <AtsEmailBody
                          id="notes"
                          value={values.notes}
                          height={200}
                          onChange={(e: any) => {
                            setFieldValue('notes', e);
                          }}
                          refocusTo="candidate_name"
                        />
                        <Box color="red.700">
                          <ErrorMessage
                            name="notes"
                            className="alert alert-danger"
                          />
                        </Box>
                      </FormControl>
                    </Box>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      variant="solid"
                      onClick={(e: any) => {
                        // console.log(values, 'val');
                        // console.log(errors, 'errors');
                        handleSubmit(e);
                      }}
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
