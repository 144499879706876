import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  FormErrorMessage,
  Flex,
  Text,
  Textarea,
  Wrap,
  WrapItem,
  useToast,
  Grid,
  RadioGroup,
  Stack,
  Radio,
  GridItem,
} from '@chakra-ui/react';
import * as Yup from 'yup';

import ScrollToFieldError from 'components/app/ScrollError';
import ReactQuill from 'react-quill';
import BoxLoading from 'components/app/Loading/box';
import 'react-quill/dist/quill.snow.css';
import 'assets/css/minHquill.css';
import AtsDropZone from 'components/app/Dropzone';
import AtsCraetableSelDyn from 'components/app/AtsCreatabeSelect/Dyanmic';
import {
  useDefaultTemplateListMutation,
  useListTemplateMutation,
  usePlaceHoldersMutation,
  useUpdateTemplateMutation,
} from 'store/template.slice';
import { FaRegCopy } from 'react-icons/fa';
import { useGroupsMutation } from 'store/email_group.slice';
import TemplateAttachments from './attachment';
import { cleanUpload } from 'store/uploads.slice';
import AtsEmailBody from 'components/app/EmailBody';
import AtsSelect from 'components/app/AtsSelect';
import { useUserListMutation } from 'store/user.slice';
import '../../../../../../assets/css/custom-modal.css';
import { meetingModeInt, meetingTypeInt } from 'types';

interface TemplateAdd {
  isOpen: any;
  onClose: any;
  template: any;
}

interface typeInt {
  value: number;
  label: string;
  mode: meetingModeInt[] | null;
}

interface modeInt {
  value: number;
  label: string;
}

export default function TemplateEditModal({
  isOpen,
  onClose,
  template,
}: TemplateAdd) {
  const [reqPlaceHolder, resPlaceholder] = usePlaceHoldersMutation();
  const [reqGroups, resGroups] = useGroupsMutation();
  const [reqUpdateTemplate, resUpdateTemplate] = useUpdateTemplateMutation();
  const [reqListTemplate] = useListTemplateMutation();
  const [reqUser, resUser] = useUserListMutation();
  const [reqDefTempList, resDefTempList] = useDefaultTemplateListMutation();

  const [groupList, setGroupList] = useState([]);
  const [quilValue, setquilValue] = useState(template?.body || '');
  const [users, setUsers] = useState();

  const { uploaded, uploading } = useSelector((state: any) => state.uploads);
  const { placehodlers } = useSelector((state: any) => state.template);
  const { templatePageBtn } = useSelector((state: any) => state.template);
  const { meeting_types } = useSelector((state: any) => state.constants);

  const [typeList, setTypeList] = useState<typeInt[]>([]);
  const [modeList, setModeList] = useState<modeInt[]>([]);
  const [modeValue, setModeValue] = useState<any>(null);
  const [typeValue, setTypeValue] = useState<any>(null);
  const [modeType, setModeType] = useState<boolean>(false);
  const [modeVisible, setModeVisible] = useState<boolean>(true);

  const dispatch = useDispatch();
  const onSubmit = async (data: any) => {
    // data['attachments'] = uploaded;
    let form = {
      ...data,
      attachments: uploaded,
      type: data?.type?.value || null,
      mode: data?.mode?.value || null,
    };

    reqUpdateTemplate({ data: form, id: template?.id });
  };
  const toast = useToast();

  const usersDefault = () => {
    let users = [] as any;
    if (template?.users?.length > 0) {
      template?.users?.map((item: any) => {
        users.push({
          label: [item?.user?.first_name, item?.user?.last_name].join(' '),
          value: item?.user?.id,
        });
      });
    }
    return users;
  };

  const [initialValues, setInitialValues] = useState({
    title: template?.title || '',
    remarks: template?.remarks || '',
    group: {
      value: template?.group?.id,
      label: template?.group?.name,
    },
    subject: template?.subject || '',
    body: template?.body || '',
    share: template?.shareable || 'only me',
    users: [] as any,
    type: {} as any,
    mode: {} as any,
  });

  useEffect(() => {
    let type = {} as any;
    let mode = {} as any;
    let initial: any = { ...initialValues };
    if (template?.group_id === 5 || template?.group_id === 4) {
      // console.log({ template });
      setModeType(true);
      const filtered = meeting_types.find(
        (item: meetingTypeInt) => item.id == template?.type_id
      );
      if (filtered) {
        type = {
          value: filtered.id,
          label: filtered.type,
          moe: filtered.mode,
        };
        setTypeValue(type);
        if (filtered.id === 3) {
          setModeVisible(false);
        } else {
          const modes: meetingModeInt = filtered.mode.find(
            (item: meetingModeInt) => item.id === template?.mode_id
          );
          if (modes) {
            mode = {
              value: modes.id,
              label: modes.mode,
            };
            setModeValue(mode);
          }

          setModeVisible(true);
        }
      }
    }
    initial = {
      ...initial,
      type,
      mode,
      users: usersDefault(),
    };
    setInitialValues(initial);
  }, []);

  useEffect(() => {
    let types = [] as typeInt[];
    meeting_types.map((item: meetingTypeInt) => {
      types.push({
        value: item.id,
        label: item.type,
        mode: item.mode,
      });
    });
    setTypeList(types);
  }, [meeting_types]);

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().required('Subject is required.'),
      subject: Yup.string().required('Subject is required.'),
      body: Yup.string().required('Body is required.'),
      group: Yup.array().min(1, 'Pick at least 1 company').nullable(),
      type: Yup.array().nullable(),
      mode: Yup.array().nullable(),
    })
  );

  const typeChange = (event: typeInt) => {
    const modes = [] as modeInt[];
    event.mode.map((item: meetingModeInt) => {
      modes.push({
        value: item.id,
        label: item.mode,
      });
    });
    setTypeValue(event);
    setModeList(modes);
    setModeValue(null);
    setModeVisible(event.mode.length > 0 ? true : false);
  };

  useEffect(() => {
    if (resUpdateTemplate.isSuccess) {
      onClose();
      reqListTemplate(templatePageBtn);
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
    }
  }, [resUpdateTemplate.isSuccess]);

  useEffect(() => {
    let users: any = [];
    if (resUser.isSuccess) {
      if (resUser.data?.data.length > 0) {
        Promise.all(
          resUser.data?.data.map((item: any) => {
            users.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
          })
        );
      }
    }
    setUsers(users);
  }, [resUser.data?.data, resUser.isSuccess]);

  useEffect(() => {
    if (isOpen) {
      const load = async () => {
        await reqPlaceHolder({});
        await reqGroups({});
        await dispatch(cleanUpload({ attachments: [] }));
        await dispatch(cleanUpload({ uploaded: [] }));
        await reqUser({});
      };

      load();
    }
  }, [isOpen]);

  useEffect(() => {
    if (resGroups.isSuccess) {
      const data: any = resGroups.data.data;
      let option: any = [];
      data.map((item: any) => {
        option.push({
          label: item.name,
          value: item.id,
        });
      });
      setGroupList(option);
      reqDefTempList({});
    }
  }, [resGroups.isSuccess]);

  const placeHolderClick = (holder: string) => {
    navigator.clipboard.writeText(`{{${holder}}}`);
    toast({
      title: 'Placeholder copied',
      duration: 1000,
      position: 'top',
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => onClose()}
        size="6xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Edit Template
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box mb="34px">
                      <Flex gap="32px" mb="34px">
                        <Box w="40%">
                          <Text>Placeholders</Text>
                          <Box
                            width="100%"
                            borderWidth="1px"
                            borderRadius="lg"
                            padding={5}
                          >
                            {resPlaceholder.isLoading ? (
                              <BoxLoading box={20} width="70px" />
                            ) : (
                              <Grid templateColumns="repeat(1, 1fr)" gap={2}>
                                {Object.values(placehodlers).length > 0 &&
                                  Object.values(placehodlers).map(
                                    (category: any, key: any) => {
                                      let allowedCategory = [
                                        'Candidates',
                                        'Jobs',
                                        'Interview',
                                        'Follow-up',
                                        'Signatures',
                                      ] as any;

                                      if (
                                        Object.keys(values.group).length > 0
                                      ) {
                                        const label = String(
                                          values?.group?.label
                                        )
                                          .replace(' Emails', '')
                                          .toLowerCase();

                                        switch (label) {
                                          case 'candidate':
                                            allowedCategory = [
                                              'Candidates',
                                              'Jobs',
                                              'Signatures',
                                            ];
                                            break;
                                          case 'submittal':
                                            allowedCategory = [
                                              'Candidates',
                                              'Jobs',
                                              'Signatures',
                                            ];
                                            break;
                                          case 'client':
                                            allowedCategory = [
                                              'Candidates',
                                              'Jobs',
                                              'Signatures',
                                            ];
                                            break;
                                          case 'interview':
                                            allowedCategory = [
                                              'Candidates',
                                              'Jobs',
                                              'Interview',
                                              'Signatures',
                                            ];
                                            break;
                                          case 'interview invite':
                                            allowedCategory = [
                                              'Candidates',
                                              'Jobs',
                                              'Interview',
                                              'Signatures',
                                            ];
                                            break;
                                          case 'follow-up':
                                            allowedCategory = [
                                              'Candidates',
                                              'Jobs',
                                              'Follow-up',
                                              'Signatures',
                                            ];
                                            break;
                                          default:
                                            allowedCategory = [
                                              'Candidates',
                                              'Jobs',
                                              'Interview',
                                              'Follow-up',
                                              'Signatures',
                                            ];
                                            break;
                                        }
                                      }

                                      if (
                                        allowedCategory.includes(
                                          category.category
                                        )
                                      ) {
                                        return (
                                          <Box mb="20px">
                                            <Text
                                              color="gray.700"
                                              mb="5px"
                                              fontSize="12px"
                                            >
                                              {category.category}
                                            </Text>
                                            <Wrap spacing={1}>
                                              {category.subs.map(
                                                (item: any, i: number) => {
                                                  return (
                                                    <WrapItem>
                                                      <Button
                                                        leftIcon={<FaRegCopy />}
                                                        onClick={() =>
                                                          placeHolderClick(item)
                                                        }
                                                        sx={{
                                                          height: 'fit-content',
                                                          p: '5px 10px',
                                                          fontSize: '10px',
                                                        }}
                                                      >
                                                        {item}
                                                      </Button>
                                                    </WrapItem>
                                                  );
                                                }
                                              )}
                                            </Wrap>
                                          </Box>
                                        );
                                      }
                                    }
                                  )}
                              </Grid>
                            )}
                          </Box>
                        </Box>
                        <Box w="60%">
                          <Grid
                            templateColumns="repeat(2, 1fr)"
                            gap="32px"
                            mb="20px"
                          >
                            <GridItem w="100%">
                              <FormControl
                                isInvalid={Boolean(
                                  !!errors.group && touched.group
                                )}
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  Template Group
                                  <Box as="span" color="caution.800">
                                    *
                                  </Box>
                                </FormLabel>
                                <AtsSelect
                                  id="group"
                                  name="group"
                                  defaultValue={values.group}
                                  placeholder="Template Group"
                                  onChange={(event: any) => {
                                    setFieldValue('group', event);
                                    setModeType(
                                      event.value === 5 || event.value === 4
                                        ? true
                                        : false
                                    );
                                    if (event.value !== 5) {
                                      setFieldValue('type', {});
                                      setFieldValue('mode', {});
                                    }
                                  }}
                                  // onInputChange={() => {}}
                                  isLoading={resGroups.isLoading}
                                  options={groupList}
                                />
                                <FormErrorMessage>
                                  {String(errors.group)}
                                </FormErrorMessage>
                              </FormControl>
                            </GridItem>
                            {modeType && (
                              <>
                                <GridItem w="100%">
                                  <FormControl
                                    isInvalid={Boolean(
                                      !!errors.type && touched.type
                                    )}
                                  >
                                    <FormLabel
                                      fontSize="14px"
                                      lineHeight="18px"
                                      color="default.secondarytext"
                                    >
                                      Meeting Type
                                      <Box as="span" color="caution.800">
                                        *
                                      </Box>
                                    </FormLabel>
                                    <AtsSelect
                                      id="type"
                                      name="type"
                                      defaultValue={values.type}
                                      placeholder="Meeting Type"
                                      onChange={(event: any) => {
                                        setFieldValue('type', event);
                                        setFieldValue('mode', {});

                                        typeChange(event);
                                      }}
                                      options={typeList}
                                      value={typeValue}
                                    />
                                    <FormErrorMessage>
                                      {String(errors.type)}
                                    </FormErrorMessage>
                                  </FormControl>
                                </GridItem>
                                {modeVisible && (
                                  <GridItem w="100%">
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.title && touched.title
                                      )}
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        Meeting Mode
                                      </FormLabel>
                                      <AtsSelect
                                        id="mode"
                                        name="mode"
                                        defaultValue={values.mode}
                                        placeholder="Meeting Mode"
                                        onChange={(event: any) => {
                                          setFieldValue('mode', event);
                                          setModeValue(event);
                                        }}
                                        options={modeList}
                                        isClearable={true}
                                        value={modeValue}
                                      />
                                      <FormErrorMessage>
                                        {String(errors.mode)}
                                      </FormErrorMessage>
                                    </FormControl>
                                  </GridItem>
                                )}
                              </>
                            )}
                            <GridItem w="100%">
                              <FormControl
                                isInvalid={Boolean(
                                  !!errors.title && touched.title
                                )}
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  Template Name
                                  <Box as="span" color="caution.800">
                                    *
                                  </Box>
                                </FormLabel>
                                <Input
                                  name="title"
                                  type="text"
                                  placeholder="Template Name"
                                  variant="outline"
                                  value={values.title}
                                  onChange={handleChange}
                                />
                                <FormErrorMessage>
                                  {String(errors.title)}
                                </FormErrorMessage>
                              </FormControl>
                            </GridItem>
                          </Grid>

                          <Box mb="20px">
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.subject && touched.subject
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Subject
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <Input
                                name="subject"
                                type="text"
                                placeholder="Subject"
                                variant="outline"
                                value={values.subject}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {String(errors.subject)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                          <Box mb="20px">
                            <FormControl
                              isInvalid={Boolean(!!errors.body && touched.body)}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Body
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <AtsEmailBody
                                value={quilValue}
                                onChange={(e: any) => {
                                  setquilValue(e);
                                  setFieldValue('body', e);
                                }}
                                height={200}
                              />
                              <FormErrorMessage>
                                {String(errors.body)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                          <Box mb="20px">
                            <TemplateAttachments template_id={template?.id} />
                          </Box>
                          <Box mb="20px">
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Attachment(s)
                            </FormLabel>
                            <AtsDropZone
                              multipleFile={true}
                              uploadedEnd={(e: any) => {}}
                              deletedFile={(key: string) => {}}
                            />
                          </Box>

                          <Box
                            pt="32px"
                            pb="15px"
                            color="default.primarytext"
                            fontSize="16px"
                            fontWeight="600"
                            lineHeight="18px"
                          >
                            Share this with:
                          </Box>

                          <RadioGroup defaultValue={values.share}>
                            <Stack spacing={2} direction="column">
                              <Radio
                                colorScheme="purple"
                                name="share"
                                onChange={handleChange}
                                value="only me"
                              >
                                Only me
                              </Radio>
                              <Radio
                                colorScheme="purple"
                                name="share"
                                onChange={handleChange}
                                value="everyone"
                              >
                                Organization
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      variant="solid"
                      type="submit"
                      disabled={uploading}
                      isLoading={resUpdateTemplate.isLoading}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
