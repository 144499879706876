import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

import { useToast, useDisclosure } from '@chakra-ui/react';

import { useClientSearchMutation } from 'store/client.slice';
import {
  useGetLeadSourceMutation,
  useGetCountriesMutation,
} from 'store/constant.slice';
import {
  useListLeadsMutation,
  useQualifiedLeadsMutation,
  useSendForApprovalMutation,
  useByClientMutation,
  useGetLeadsMutation,
  getLead,
} from 'store/leads.slice';
import { cleanUpload, uploadList } from 'store/uploads.slice';
import { useUserListMutation } from 'store/user.slice';

import { changeUploadName } from 'utils/newFilename';
import { addressFormat, leadSourceFormat } from 'utils/utilFormat';
import { LeadsModalProps } from './types';

export function useSendForApprovalServices({
  isOpen,
  onClose,
  isSendApproval,
  setIsSendApproval,
}: LeadsModalProps) {
  const dispatch = useDispatch();
  const toast = useToast();

  const { leadData, leadPgBtn, leadClient } = useSelector(
    (state: any) => state.leads
  );
  const { userList } = useSelector((state: any) => state.user);
  const { uploaded, prefix, uploading } = useSelector(
    (state: any) => state.uploads
  );

  const [reqLeads] = useListLeadsMutation();
  const [reqQualified, resQualified] = useQualifiedLeadsMutation();
  const [reqSendApproval, resSendApproval] = useSendForApprovalMutation();
  const [reqUser] = useUserListMutation();
  const [reqLeadClient] = useByClientMutation();
  const [reqGetLeads] = useGetLeadsMutation();

  const [reqLeadSource] = useGetLeadSourceMutation();
  const [reqCountry] = useGetCountriesMutation();

  const [errorMessage, setErrorMessage] = useState('');
  const [companyList, setCompanyList] = useState([]);
  const [leadSrouceList, setLeadSrouceList] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const { countries, lead_source } = useSelector(
    (state: any) => state.constants
  );

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const leadClientData: any = Object.values(leadClient).filter(
    (lead: any) => lead.id === leadData.id
  );

  useEffect(() => {
    if (isOpen) {
      reqLeadSource({});
      reqCountry({});
    }
  }, [isOpen]);

  useEffect(() => {
    reqGetLeads({ id: leadData?.id });
  }, []);

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });
      });
    }
    setCountryOption(options);
  }, [countries]);

  useEffect(() => {
    let options: any = [];
    if (lead_source?.length > 0) {
      lead_source.map((item: any) => {
        options.push({
          label: item.source,
          value: item.id,
        });
      });
    }
    setLeadSrouceList(options);
  }, [lead_source]);

  const initialValues = leadData &&
    Object.keys(leadData).length !== 0 && {
      first_name: leadData.first_name,
      last_name: leadData.last_name,
      title: leadData.title,
      company: {
        value: leadData?.client?.id,
        label: leadData?.client?.name,
      },
      email: leadData.primary_email,
      personal_phone: leadData.personal_phone,
      work_phone: leadData.work_phone,
      extension: leadData.extension,
      address: addressFormat(leadClientData[0]?.client.address, countries),
      linkedIn_url: leadData.linked_in_url,
      website: leadData.website,
      lead_owner: leadData.owner_id,
      lead_source: leadSourceFormat(leadData?.lead_source),
      created_by: String(leadData?.user_id),
      notes: '',
      status: leadData.leadStatus?.id,
    };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      first_name: Yup.string()
        .required('First name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'First name is invalid.'),
      last_name: Yup.string()
        .required('Last name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'Last name is invalid.'),
      title: Yup.string().required('Title is required.'),
      company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
      email: Yup.string()
        .email('Invalid email.')
        .required('Email is required.'),
      personal_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Personal phone is invalid'
      ),
      work_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Work phone is invalid'
      ),
      extension: Yup.string().matches(/^[\d ()+-]+$/gi, 'Extension is invalid'),
      address: Yup.array()
        .of(
          Yup.object().shape({
            location: Yup.string().required('Location is required.'),
            address: Yup.string(),
            city: Yup.string().required('City is required.'),
            state: Yup.string().required('State is required.'),
            country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
            zip_code: Yup.string(),
            primary: Yup.boolean(),
          })
        )
        .min(1, 'Address is required.'),
      linkedIn_url: Yup.string(),
      website: Yup.string().required('Website is required.'),
      lead_owner: Yup.string().required('Lead owner is required.'),
      lead_source: Yup.object().shape({
        label: Yup.string().required('Pick at least 1 source'),
        value: Yup.number(),
      }),
      created_by: Yup.string().required('Created by is required.'),
      notes: Yup.string(),
      status: Yup.number(),
    })
  );

  const onSubmit = async (data: any) => {
    data['attachments'] = await changeUploadName(uploaded, prefix);

    if (isSendApproval) {
      reqSendApproval({ data, id: leadData.id });
    } else {
      reqQualified({ data, id: leadData.id });

      if (resQualified.isSuccess) {
        dispatch(getLead({ leadData: {} }));
        dispatch(cleanUpload({ attachments: [] }));
        dispatch(cleanUpload({ uploaded: [] }));
      }
    }
  };

  useEffect(() => {
    reqUser({});
    dispatch(uploadList({ prefix: 'Attachment' }));
    dispatch(cleanUpload({ withPrefix: false }));
    reqLeadClient({ id: leadData?.client_id });

    if (resQualified.isSuccess) {
      reqLeads({ data: leadPgBtn });
      dispatch(getLead({ leadData: {} }));
      dispatch(getLead({ leadDetails: [] }));
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
      onClose();

      try {
        const description = `Lead was successfully converted to qualified lead`;

        toast({
          title: 'Send for Approval',
          description: description,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }

    if (resQualified.isError) {
      let errors: any = resQualified.error;
      setErrorMessage(errors['data']['error']['message']);
      onOpen();
    }

    if (resSendApproval.isSuccess) {
      reqLeads(leadPgBtn);
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
      setIsSendApproval(false);
      onClose();

      try {
        let data;
        let title = '';
        let description = '';

        if (resSendApproval.isSuccess) {
          data = resSendApproval.data.data;
          title = 'Approval Sent';
          description = `Lead ${data.first_name} ${data.last_name} has been send to approval`;
        }

        toast({
          title: title,
          description: description,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [
    leadPgBtn,
    resQualified.isSuccess,
    resQualified.isError,
    resQualified.error,
    resSendApproval.isSuccess,
    resSendApproval?.data?.data,
  ]);

  const [isDisabledWebsite, setIsDisabledWebsite] = useState(false);
  const [reqCompnay, resCompany] = useClientSearchMutation();

  useEffect(() => {
    if (resCompany.isSuccess) {
      const data: any = resCompany.data.data;
      let option: any = [];
      data.map((item: any) => {
        option.push({
          label: item.name,
          value: item.id,
          // location: item.location,
          website: item.website,
          address: item.address,
        });
      });
      setCompanyList(option);
    }
  }, [resCompany.isSuccess]);

  const SearchCompany = (e: any) => {
    reqCompnay({ search: e });
  };

  const handleAddressSelection = (
    index: number,
    setFieldValue: any,
    values: any
  ) => {
    setFieldValue(
      'address',
      values.address.map((address: any, idx: any) => ({
        ...address,
        primary: idx === index,
      }))
    );
  };

  return {
    isOpenAlert,
    errorMessage,
    onCloseAlert,
    initialValues,
    validationSchema,
    onSubmit,
    setIsDisabledWebsite,
    SearchCompany,
    resCompany,
    companyList,
    handleAddressSelection,
    countryOption,
    leadData,
    leadClientData,
    isDisabledWebsite,
    userList,
    leadSrouceList,
    uploading,
  };
}
