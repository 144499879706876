/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  Divider,
  Image,
  RadioGroup,
  Stack,
  Radio,
  Textarea,
  others,
} from '@chakra-ui/react';

import * as Yup from 'yup';
import ScrollToFieldError from 'components/app/ScrollError';

import MdConfetti from 'assets/images/MdConfetti.svg';
import moment from 'moment';
import Button from 'Library/Button';
import AtsSelect from 'components/app/AtsSelect';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useUserListMutation } from 'store/user.slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCreateJobPostPlacementMutation,
  useGetJobsMutation,
} from 'store/jobs.slice';
import { useGetCandidateSourceMutation } from 'store/constant.slice';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import {
  useGetCandidatesMutation,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import { useParams } from 'react-router';
import { truncateToTwoDecimalPlaces } from 'utils/utilFormat';
import { useViewClientMutation } from 'store/client.slice';
import { useGetClientAgreementMutation } from 'store/clientagreement.slice';
import AtsSelectContact from 'components/app/AtsSelectContact';
import { LogPlacement } from '../Candidates/Drawer/Activity/types';

interface PlacementModalProps {
  isOpen: any;
  onClose: any;
  logs: LogPlacement;
}

export default function PlacementModal({
  isOpen,
  onClose,
  logs,
}: PlacementModalProps) {
  const dispatch = useDispatch();
  const params = useParams();

  const { userList } = useSelector((state: any) => state.user);
  const { email } = useSelector((state: any) => state.auth);
  const { clientAgreements } = useSelector(
    (state: any) => state.clientAgreements
  );
  const { jobData } = useSelector((state: any) => state.jobs);
  const { candidateData, candidatePgBtn } = useSelector(
    (state: any) => state.candidates
  );
  const { lead_source } = useSelector((state: any) => state.constants);
  const [reqClient, resClient] = useViewClientMutation();
  const [reqGetJob, resGetJob] = useGetJobsMutation();
  const [reqGetAgreement, resGetAgreement] = useGetClientAgreementMutation();
  const [reqCandSource, resCandSource] = useGetCandidateSourceMutation();
  const [reqUsers, resUsers] = useUserListMutation();
  const [reqCreateForm, resCreateForm] = useCreateJobPostPlacementMutation();
  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();
  const [reqCandidates] = useListCandidatesMutation();

  const [recOptions, setRecruiters] = useState([]);
  const initCcOption = Object.values(userList).map((item: any) => {
    return {
      label: [item.first_name, item.last_name].filter(Boolean).join(' '),
      value: item.email,
    };
  });
  const [ccOption, setCcOption] = useState<any>(initCcOption);

  const [leadSourceList, setLeadSourceList] = useState([]);
  const [candSourceList, setCandSourceList] = useState([]);

  const [isSplit, setIsSplit] = useState(true);
  const [jobDetails, setJobDetails] = useState<any>(null);

  useEffect(() => {
    const loadConstant = async () => {
      reqUsers({});
      await reqGetJob({ id: logs?.job_id });
    };
    loadConstant();
    return () => {};
  }, []);

  useEffect(() => {
    const loadClient = async () => {
      if (resGetJob.isSuccess) {
        const job = resGetJob.data.data;
        console.log('job', job);
        reqClient({ id: job.client_id });
      }
    };
    loadClient();
  }, [resGetJob.isSuccess]);

  useMemo(() => {
    let options: any = [];
    if (lead_source?.length > 0) {
      lead_source.map((item: any) => {
        options.push({
          label: item.source,
          value: item.id,
        });
      });
    }
    setLeadSourceList(options);
  }, [lead_source]);
  useMemo(() => {
    reqCandSource({}).then((res: any) => {
      let option: any = [];
      if (res?.data?.data?.length > 0) {
        res?.data?.data.map((item: any) => {
          option.push({
            label: String(item.source),
            value: item.id,
            sub: item.sub,
          });
        });
      }
      setCandSourceList(option);
    });
  }, []);

  useEffect(() => {
    let users: any = [];
    if (resUsers.isSuccess) {
      if (resUsers.data?.data.length > 0) {
        Promise.all(
          resUsers.data?.data.map((item: any) => {
            users.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
          })
        );
      }
    }
    setRecruiters(users);
  }, [resUsers.data?.data, resUsers.isSuccess]);

  const [company, setCompany] = useState<any>(null);

  useEffect(() => {
    if (resClient.isSuccess) {
      const clientData = resClient.data.data;
      setCompany(clientData);

      if (clientData) {
        let options: any = [];
        let optionsEmail: any = [];
        // await Promise.all(
        clientData.leads.map(async (item: any) => {
          options.push({
            label: [item.first_name, item.last_name].filter(Boolean).join(' '),
            value: String(item.id),
          });
          optionsEmail.push({
            label: [item.first_name, item.last_name].filter(Boolean).join(' '),
            value: item.primary_email,
          });
        });

        setInvoiceNameOption(options);
        setCcOption([...ccOption, ...optionsEmail]);
        setReportingNameOption(options);
      }
    }
  }, [resClient.isSuccess]);
  const [invoiceNameOption, setInvoiceNameOption] = useState([]);
  const [reportingNameOption, setReportingNameOption] = useState([]);

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick
      lockFocusAcrossFrames
      blockScrollOnMount
      onClose={() => {
        onClose();
      }}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent
        fontFamily="NunitoSans Regular"
        overflowY="hidden"
        borderRadius="8px"
      >
        <ModalHeader
          background="primary.800"
          p="10px 32px"
          borderRadius="8px 8px 0 0"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Box
              fontSize="24px"
              lineHeight="18px"
              textAlign="left"
              color="white"
            >
              Placement Form
            </Box>
            <Image height="70px" mr="25px" src={MdConfetti} alt="logo" />
          </Flex>
        </ModalHeader>
        <ModalCloseButton top="13px" right="20px" color="white" />

        <ModalBody borderRadius="0 0 4px 4px" p={0} w="100%">
          <Flex w="100%">
            <Box p="32px" w="100%">
              <Box
                fontSize="20px"
                lineHeight="18px"
                color="primary.800"
                fontWeight={700}
                mb="32px"
              >
                Candidate Information
              </Box>
              <Flex gap="32px" mb="24px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Candidate Name{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="candidate_name"
                    name="candidate_name"
                    type="text"
                    variant="outline"
                    value={logs.candidate_name}
                    disabled={true}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Candidate Email{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="candidate_email"
                    name="candidate_email"
                    type="email"
                    variant="outline"
                    value={logs.candidate_email}
                    disabled={true}
                  />
                </FormControl>
              </Flex>
              <Divider borderColor="#CEB8FF" py="10px" />
              <Box
                fontSize="20px"
                lineHeight="18px"
                color="primary.800"
                fontWeight={700}
                mt="40px"
                mb="32px"
              >
                Job Information
              </Box>
              <Flex gap="32px" mb="24px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Job Title{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="job_title"
                    name="job_title"
                    type="text"
                    variant="outline"
                    value={logs.job_title}
                    disabled={true}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Start Date{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="start_date"
                    name="start_date"
                    placeholder="Select Date"
                    type="date"
                    value={logs.start_date}
                    disabled={true}
                  />
                </FormControl>
              </Flex>
              <Divider borderColor="#CEB8FF" py="10px" />
              <Box
                fontSize="20px"
                lineHeight="18px"
                color="primary.800"
                fontWeight={700}
                mt="40px"
                mb="32px"
              >
                Position Reporting To
              </Box>
              <Flex gap="32px" mb="24px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Name
                  </FormLabel>
                  {/* <Input
                          id="reporting_name"
                          name="reporting_name"
                          type="text"
                          variant="outline"
                          value={values.reporting_name}
                          onChange={handleChange}
                        /> */}
                  <Input
                    fontSize="14px"
                    lineHeight="18px"
                    value={logs.reporting_name}
                    disabled={true}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Title
                  </FormLabel>
                  <Input
                    id="reporting_title"
                    name="reporting_title"
                    type="text"
                    variant="outline"
                    value={logs.reporting_title}
                    disabled={true}
                  />
                </FormControl>
              </Flex>
              <Flex gap="32px" mb="24px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Email
                  </FormLabel>
                  <Input
                    id="reporting_email"
                    name="reporting_email"
                    type="email"
                    variant="outline"
                    value={logs.reporting_email}
                    disabled={true}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Phone
                  </FormLabel>
                  <Input
                    id="reporting_phone"
                    name="reporting_phone"
                    type="text"
                    variant="outline"
                    value={logs.reporting_phone}
                    disabled={true}
                  />
                </FormControl>
              </Flex>
              <Divider borderColor="#CEB8FF" py="10px" />
              <Box
                fontSize="20px"
                lineHeight="18px"
                color="primary.800"
                fontWeight={700}
                mt="40px"
                mb="32px"
              >
                Compensation Details
              </Box>
              <Flex gap="32px" mb="24px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Base Salary{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="base_salary"
                    name="base_salary"
                    placeholder="$"
                    type="text"
                    variant="outline"
                    value={logs.base_salary}
                    disabled={true}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Fee %{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="fee"
                    name="fee"
                    placeholder="%"
                    type="number"
                    min={0}
                    max={100}
                    variant="outline"
                    value={logs.fee}
                    disabled={true}
                  />
                </FormControl>
              </Flex>
              <Flex gap="32px" mb="16px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Payable Days{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="payable_days"
                    name="payable_days"
                    type="number"
                    variant="outline"
                    disabled={true}
                    min={0}
                    value={logs.payable_days}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Guarantee Days - Type{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Flex>
                    <Input
                      id="guarantee_days"
                      name="guarantee_days"
                      type="number"
                      min={0}
                      borderRightRadius="none"
                      variant="outline"
                      value={logs.guarantee_days}
                      disabled={true}
                    />
                    <Input
                      id="guarantee_type"
                      name="guarantee_type"
                      type="string"
                      min={0}
                      borderLeftRadius="none"
                      variant="outline"
                      value={logs.guarantee_type}
                      disabled={true}
                    />
                  </Flex>
                </FormControl>

                {/* <FormErrorMessage>
                        {String(errors.guarantee_days)}
                      </FormErrorMessage>
                    </FormControl> */}
              </Flex>
              <Box
                fontSize="16px"
                lineHeight="18px"
                color="default.primarytext"
                mb="16px"
              >
                Discount Given
              </Box>
              <Flex gap="32px" mb="24px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Sign On Bonus to Candidate from Talently{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="sign_on_bonus"
                    name="sign_on_bonus"
                    placeholder="$"
                    type="text"
                    variant="outline"
                    value={logs.sign_on_bonus}
                    disabled={true}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Fee Discount to the Client{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="fee_discount"
                    name="fee_discount"
                    placeholder="$"
                    type="text"
                    variant="outline"
                    value={logs.fee_discount}
                    disabled={true}
                  />
                </FormControl>
              </Flex>
              <Box
                sx={{
                  bg: '#FAF5FF',
                  borderRadius: '6px',
                  mb: '16px',
                  p: '16px',
                }}
              >
                <FormLabel
                  fontSize="14px"
                  lineHeight="18px"
                  color="default.primarytext"
                >
                  Total Fee to Invoice{' '}
                  <Box as="span" color="primary.800">
                    *
                  </Box>
                </FormLabel>
                <Input
                  id="total_fee_invoice"
                  name="total_fee_invoice"
                  placeholder="$"
                  type="text"
                  variant="outline"
                  value={logs.total_fee_invoice}
                  bg="#fff"
                  disabled={true}
                />
              </Box>
              <Divider borderColor="#CEB8FF" py="10px" />
              <Box
                fontSize="20px"
                lineHeight="18px"
                color="primary.800"
                fontWeight={700}
                mt="40px"
                mb="32px"
              >
                Invoice Information
              </Box>
              <Box
                fontSize="16px"
                lineHeight="18px"
                color="default.primarytext"
                mb="16px"
              >
                Who do we Invoice?
              </Box>
              <Flex gap="32px" mb="24px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Name{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input value={logs.invoice_name} disabled={true} />
                </FormControl>

                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Title{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="invoice_title"
                    name="invoice_title"
                    type="text"
                    variant="outline"
                    value={logs.invoice_title}
                    disabled={true}
                  />
                </FormControl>
              </Flex>
              <Flex gap="32px" mb="24px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Email{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="invoice_email"
                    name="invoice_email"
                    type="email"
                    variant="outline"
                    value={logs.invoice_email}
                    disabled={true}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.primarytext"
                  >
                    Phone{' '}
                    <Box as="span" color="primary.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="invoice_phone"
                    name="invoice_phone"
                    type="text"
                    variant="outline"
                    value={logs.invoice_phone}
                    disabled={true}
                  />
                </FormControl>
              </Flex>
              <Box mb="32px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    cc
                  </FormLabel>
                  <Input value={logs.invoice_cc} disabled={true} />
                </FormControl>

                {/* <AtsCraetableSelect
                            id="invoice_cc"
                            name="invoice_cc"
                            options={ccOption}
                            isMulti={true}
                            placeholder="cc"
                            defaultValue={values.invoice_cc}
                        /> */}
              </Box>
              <Divider borderColor="#CEB8FF" py="10px" />
              <Box
                fontSize="20px"
                lineHeight="18px"
                color="primary.800"
                fontWeight={700}
                mt="40px"
                mb="32px"
              >
                Recruitment Collaboration Details
              </Box>
              <Box
                fontSize="16px"
                lineHeight="18px"
                color="default.primarytext"
                mb="16px"
              >
                Split with another Recruiter?
              </Box>
              <RadioGroup
                defaultValue={logs?.split_recruiter?.toString()}
                isDisabled={true}
              >
                <Stack spacing={2} direction="row" mb="16px">
                  <Radio
                    colorScheme="purple"
                    name="isSplit"
                    value="Y"
                    disabled={true}
                  >
                    Yes
                  </Radio>
                  <Radio
                    colorScheme="purple"
                    name="isSplit"
                    value="N"
                    disabled={true}
                  >
                    No
                  </Radio>
                </Stack>
              </RadioGroup>

              {logs.split_recruiter && logs.recruiters && (
                <div>
                  {logs.recruiters?.length > 0 &&
                    logs.recruiters.map((recruiter: any, index: number) => {
                      const recruiter_id = `recruiters[${index}].recruiter_id`;
                      const split = `recruiters[${index}].split`;
                      return (
                        <>
                          <Box className="row" key={`recr-${index}`}>
                            <Flex mb="20px" gap="32px">
                              <FormControl>
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.primarytext"
                                >
                                  Recruiter{' '}
                                  <Box as="span" color="primary.800">
                                    *
                                  </Box>
                                </FormLabel>
                                <AtsSelect
                                  name={recruiter_id}
                                  id={recruiter_id}
                                  placeholder="Select"
                                  variant="outline"
                                  disabled={true}
                                  value={
                                    recOptions.filter(
                                      (item: any) => item.value == recruiter.id
                                    )[0]
                                  }
                                  options={
                                    recOptions?.length > 0 &&
                                    recOptions.map((item: any) => ({
                                      label: item.label,
                                      value: item.value,
                                    }))
                                  }
                                />
                              </FormControl>

                              <FormControl>
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.primarytext"
                                >
                                  What % Split{' '}
                                  <Box as="span" color="primary.800">
                                    *
                                  </Box>
                                </FormLabel>
                                <Input
                                  value={recruiter.split}
                                  disabled={true}
                                />
                              </FormControl>
                            </Flex>
                          </Box>
                          <Divider mb="20px" />
                        </>
                      );
                    })}
                </div>
              )}
              <Divider borderColor="#CEB8FF" py="10px" />
              <Box
                fontSize="20px"
                lineHeight="18px"
                color="primary.800"
                fontWeight={700}
                mt="40px"
                mb="32px"
              >
                Client and Candidate Source
              </Box>
              <Flex gap="24px" mb="24px" flexDir="column">
                <FormLabel
                  fontSize="14px"
                  lineHeight="18px"
                  color="default.primarytext"
                >
                  Source of the Client{' '}
                  <Box as="span" color="primary.800">
                    *
                  </Box>
                </FormLabel>
                <AtsSelect
                  options={leadSourceList}
                  name="client_source_id"
                  id="client_source_id"
                  disabled={true}
                  value={
                    leadSourceList?.filter(
                      (leadSource: any) =>
                        leadSource?.value === logs.client_source_id
                    )?.[0] || ''
                  }
                />

                <FormLabel
                  fontSize="14px"
                  lineHeight="18px"
                  color="default.primarytext"
                >
                  Source of the Candidate{' '}
                  <Box as="span" color="primary.800">
                    *
                  </Box>
                </FormLabel>
                <AtsSelect
                  options={candSourceList}
                  name="candidate_source_id"
                  id="candidate_source_id"
                  isLoading={resCandSource.isLoading}
                  value={
                    candSourceList?.filter(
                      (candSource: any) =>
                        candSource?.value === logs.candidate_source_id
                    )?.[0] || ''
                  }
                  disabled={true}
                />

                <FormLabel
                  fontSize="14px"
                  lineHeight="18px"
                  color="default.primarytext"
                >
                  Lifetime Value of the Client
                </FormLabel>
                <Input
                  id="lifetime_value_client"
                  name="lifetime_value_client"
                  placeholder="$"
                  type="text"
                  variant="outline"
                  value={logs.lifetime_value_client}
                  disabled={true}
                />
              </Flex>
              <Divider borderColor="#CEB8FF" py="10px" />
              <Box
                fontSize="20px"
                lineHeight="18px"
                color="primary.800"
                fontWeight={700}
                mt="40px"
                mb="32px"
              >
                Additional Notes
              </Box>
              <Box mb="24px">
                <FormLabel
                  fontSize="14px"
                  lineHeight="18px"
                  color="default.primarytext"
                >
                  Notes
                </FormLabel>
                <Box
                  // 3 lines with line-height of 1.6em each
                  overflow="hidden"
                  textOverflow="ellipsis"
                  dangerouslySetInnerHTML={{ __html: logs.notes }}
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 'unset',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                  p="16px"
                  border="1px solid #eaeef3"
                  color="#aaacb0"
                />
              </Box>
            </Box>
          </Flex>

          <ModalFooter
            position="sticky"
            bottom="0"
            background="default.white.100"
            boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
          ></ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
