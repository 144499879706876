import { Icon, Link, Td } from '@chakra-ui/react';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';

import { RecruiterMenu } from 'components/app/Engage/RecruiterMenu';

interface TableDataProps {
  openAccordionItems: any;
  handleOpenAccordion: (e: any) => void;
  content: any;
}

const tdStyle = {
  fontWeight: 700,
  color: '#2B2D42',
  height: '68px',
};

export const TableData = ({
  openAccordionItems,
  handleOpenAccordion,
  content,
}: TableDataProps) => {
  const getSumOfSequenceCandidateCount = (data: any) => {
    return data.reduce(
      (sum: number, item: any) => sum + item.sequence_candidate_count,
      0
    );
  };

  const totalSequenceCandidateCount = getSumOfSequenceCandidateCount(
    content.outreachsequence
  );

  const sequenceTableData = (item: any) => [
    {
      content: openAccordionItems.includes(item.id) ? (
        <Icon as={BsChevronDown} />
      ) : (
        <Icon as={BsChevronRight} />
      ),
      sx: {
        width: '50px',
        svg: {
          color: '#2B2D42',
        },
      },
    },
    {
      content: item.title,
      sx: tdStyle,
    },
    {
      content: (
        <Link
          href={`/clients/${item.client.id}/overview`}
          target="_blank"
          sx={{ fontSize: '16px' }}
        >
          {item.client.name}
        </Link>
      ),
      sx: tdStyle,
    },
    {
      content: item?.recruiters?.length > 0 && (
        <RecruiterMenu recruiters={item?.recruiters} />
      ),
      sx: { tdStyle },
      hasNoClick: true,
    },
    {
      content: item.outreachsequence?.length,
      sx: tdStyle,
    },
    {
      content: totalSequenceCandidateCount || item.job_outreach_candidate_count,
      sx: tdStyle,
    },
    {
      content: '',
      sx: {},
    },
  ];
  return (
    <>
      {sequenceTableData(content).map((val, index) => (
        <Td
          key={index}
          sx={val.sx}
          onClick={() => !val?.hasNoClick && handleOpenAccordion(content.id)}
        >
          {val.content}
        </Td>
      ))}
    </>
  );
};
