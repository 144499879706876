import { Flex, Stack, Text } from '@chakra-ui/react';
import { CheckboxLayout } from 'components/app/Global/Checkbox';
import { SearchBar } from 'components/app/Global/SearchBar';
import { Dispatch, SetStateAction } from 'react';
import { JOBS_STATUS, JOBS_TYPE } from './constant';
import { useSideBarServices } from './useSideBarServices';

interface JobsSideBarProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const JobsSideBar = ({ setIsLoading }: JobsSideBarProps) => {
  const {
    searchJobs,
    setSearchJobs,
    handleSearchJobs,
    setStatusJobTypePayload,
    setStatusJobStatusPayload,
  } = useSideBarServices({ setIsLoading });
  return (
    <Stack
      spacing="9px"
      sx={{
        borderRadius: '10px',
        width: '300px',
        bgColor: '#F9F9FB',
        fontFamily: 'NunitoSans Regular',
      }}
    >
      <Stack
        spacing="16px"
        sx={{
          bgColor: '#fff',
          height: '100%',
          borderRadius: '10px',
          p: '16px',
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            h: "40px",
            w: '100%',
            borderBottom: '1px solid #E7EDF4',
          }}
        >
          <Text
            sx={{
              color: '#718096',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '21.82px',
            }}
          >
            Filters
          </Text>
        </Flex>

        <SearchBar
          search={searchJobs}
          setSearch={setSearchJobs}
          placeholder="Search..."
          handleOnChange={handleSearchJobs}
        />

        <Stack spacing="8px">
          <Text
            color="#718096"
            fontSize="14px"
            fontWeight="600"
            lineHeight="19.1px"
          >
            Job Type
          </Text>

          <CheckboxLayout
            data={JOBS_TYPE}
            setStatusPayload={setStatusJobTypePayload}
          />
        </Stack>

        <Stack spacing="8px">
          <Text
            color="#718096"
            fontSize="14px"
            fontWeight="600"
            lineHeight="19.1px"
          >
            Job Status
          </Text>

          <CheckboxLayout
            data={JOBS_STATUS}
            setStatusPayload={setStatusJobStatusPayload}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
