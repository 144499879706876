import { Formik } from 'formik';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ModalFooter,
  Button,
} from '@chakra-ui/react';

import AtsDropZone from 'components/app/Dropzone';
import ScrollToFieldError from 'components/app/ScrollError';

import { LeadAgreementAttachments } from '../Attachment';

import { LeadsAgrrmentModalProps } from './types';
import { useEditAgreementServices } from './useEditAgreementServices';

export const LeadsEditAgreementModal = ({
  isOpen,
  onClose,
}: LeadsAgrrmentModalProps) => {
  const {
    agreementData,
    initialValues,
    validationSchema,
    onSubmit,
    uploading,
    attachments,
  } = useEditAgreementServices({
    onClose,
  });
  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Edit Agreement
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          {agreementData && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.title && touched.title)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Agreement Name{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          placeholder="Agreement Name"
                          variant="outline"
                          value={values.title}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.title)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Box mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.content && touched.content)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Description
                        </FormLabel>
                        <Input
                          id="content"
                          name="content"
                          type="textarea"
                          placeholder="description"
                          variant="outline"
                          value={values.content}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.content)}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box mb="34px">
                      <LeadAgreementAttachments attachments={attachments} />
                    </Box>
                    <Box mb="34px">
                      <FormControl>
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Attachments{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                      </FormControl>
                    </Box>
                    <AtsDropZone
                      multipleFile={false}
                      isAgreement
                      uploadedEnd={(e: any) => {}}
                      deletedFile={(key: string) => {}}
                    />

                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.date_signed && touched.date_signed
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Signed On{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="date_signed"
                          name="date_signed"
                          placeholder="Select Date"
                          size="md"
                          type="date"
                          value={values.date_signed}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.date_signed)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.valid_until && touched.valid_until
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Valid Until{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="valid_until"
                          name="valid_until"
                          placeholder="Select Date"
                          size="md"
                          type="date"
                          value={values.valid_until}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.valid_until)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button variant="solid" type="submit" disabled={uploading}>
                      Update
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
