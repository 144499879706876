export default function replacePlaceholders(text: any, placeholders: any) {
  try {
    for (const placeholder in placeholders) {
      const regex = new RegExp(`{{${placeholder}}}`, 'g');
      text = text.replace(regex, placeholders[placeholder]);
      if (placeholder === 'email_signature') {
        console.log({ placeholder, text });
      }
    }
    return text;
  } catch (e) {
    return text;
  }
}
