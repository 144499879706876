import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import * as Yup from 'yup';

import {
  useGetAgreementMutation,
  useUpdateAgreementMutation,
  useListLeadAgreementMutation,
} from 'store/leadagreement.sclice';
import { useGetLeadsMutation } from 'store/leads.slice';
import { cleanUpload } from 'store/uploads.slice';
import { LeadsAgrrmentModalProps } from './types';

export function useEditAgreementServices({ onClose }: LeadsAgrrmentModalProps) {
  const dispatch = useDispatch();

  const { leadData } = useSelector((state: any) => state.leads);
  const { uploaded, uploading } = useSelector((state: any) => state.uploads);
  const { leadAgreementPgBtn } = useSelector(
    (state: any) => state.leadagreement
  );
  const { agreementData, attachments } = useSelector(
    (state: any) => state.leadagreement
  );
  const [reqget, resget] = useGetAgreementMutation();

  const params = useParams();
  const leadID = params.leadsId;

  const [reqleads] = useGetLeadsMutation();
  const [requpdate, resupdate] = useUpdateAgreementMutation();
  const [reqAgreement] = useListLeadAgreementMutation();

  const initialValues = {
    title: agreementData?.title,
    content: agreementData?.content,
    date_signed: moment(agreementData?.date_signed).format('YYYY-MM-DD'),
    valid_until: moment(agreementData?.valid_until).format('YYYY-MM-DD'),
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().required('Agreement name is required.'),
      content: Yup.string(),
      date_signed: Yup.string().required('Date signed is required.'),
      valid_until: Yup.string().required('Valid until is required.'),
    })
  );

  const onSubmit = async (data: any) => {
    data['attachments'] = uploaded;
    await requpdate({ data, id: agreementData?.id });
    await reqget({ id: agreementData?.id });
  };

  useEffect(() => {
    if (resupdate.isSuccess) {
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
      reqAgreement({ data: leadAgreementPgBtn, id: leadID });
      onClose();
    }
  }, [
    dispatch,
    leadAgreementPgBtn,
    leadData.id,
    leadID,
    onClose,
    reqAgreement,
    reqleads,
    resupdate.isSuccess,
  ]);

  return {
    agreementData,
    initialValues,
    validationSchema,
    onSubmit,
    uploading,
    attachments,
  };
}
