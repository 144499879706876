import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import * as Yup from 'yup';

import {
  useCreateAgreementMutation,
  useListLeadAgreementMutation,
} from 'store/leadagreement.sclice';
import { useGetLeadsMutation } from 'store/leads.slice';
import { cleanUpload, uploadList } from 'store/uploads.slice';
import { LeadsAgrrmentModalProps } from './types';

export function useAddAgreementServices({ onClose }: LeadsAgrrmentModalProps) {
  const dispatch = useDispatch();
  const { leadData } = useSelector((state: any) => state.leads);
  const { uploaded, uploading } = useSelector((state: any) => state.uploads);
  const { leadAgreementPgBtn } = useSelector(
    (state: any) => state.leadagreement
  );

  const [reqcreate, rescreate] = useCreateAgreementMutation();
  const [reqleads] = useGetLeadsMutation();
  const [reqAgreement] = useListLeadAgreementMutation();
  
  const initialValues = {
    title: '',
    content: '',
    date_signed: '',
    valid_until: '',
  };

  const params = useParams();
  const leadID = params.leadsId;

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().required('Agreement name is required.'),
      content: Yup.string(),
      date_signed: Yup.string().required('Date signed is required.'),
      valid_until: Yup.string().required('Valid until is required.'),
    })
  );

  const onSubmit = async (data: any) => {
    data['attachments'] = uploaded;
    await reqcreate({ data, id: leadData.id });
  };

  useEffect(() => {
    dispatch(cleanUpload({ withPrefix: false }));
    dispatch(uploadList({ prefix: 'Attachment' }));
    if (rescreate.isSuccess) {
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
      reqAgreement({ data: leadAgreementPgBtn, id: leadID });
      onClose();
    }
  }, [
    dispatch,
    leadAgreementPgBtn,
    leadData.id,
    leadID,
    onClose,
    reqAgreement,
    reqleads,
    rescreate.isSuccess,
  ]);

  return { initialValues, validationSchema, onSubmit, uploading };
}
