/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import '../../../../assets/css/autocomplete.css';

import { useGetJobsMutation } from 'store/jobs.slice';
import {
  useGetCandidatetatusMutation,
  useGetSubStatusMutation,
} from 'store/constant.slice';
import {
  getCandidate,
  setloadingCandidate,
  useChangeSubStatusMutation,
  useGetCandidatesMutation,
  useListCandidatesMutation,
  useRejectCandidateMutation,
  useUnRejectCandidateMutation,
} from 'store/candidates.slice';

import Button from 'Library/Button';
import CandidateJobList from './CandidateJobList';
import CandidatesInterviewModal from '../Drawer/Interviews/modal';
import RejectModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/Reject';
import SubmitToClientModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/SubmitToClient';
import { CandidatesTypes, swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';
import LoadingPage from 'components/app/Loading';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import HiredModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/HiredModal';
import {
  setClientAgreement,
  useListClientAgreementMutation,
} from 'store/clientagreement.slice';
import { useTRservices } from '../Table/useTRservices';
import delay from 'utils/delay';

interface jobselectInt {
  id: number;
  type: string;
  assoc_id?: number;
  reject?: string;
}

const ActionButton = ({
  rowId,
  setRowId,
  totalCandidates,
  onClose,
  isLoadingDrawer,
  candidates,
  handleNextData,
  candidateId,
}: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { reloadGroups, setJob_Id, fetchDandidate } = useTRservices();

  const {
    isOpen: isOpenJobList,
    onClose: onCloseJobList,
    onOpen: onOpenJobList,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenSubmit,
    onClose: onCloseSubmit,
    onOpen: onOpenSubmit,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenScheduleInterview,
    onOpen: onOpenScheduleInterview,
    onClose: onCloseScheduleInterview,
  } = useDisclosure();

  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();

  const param = useParams();
  const stateTab: any = param.candidatesTab;

  const {
    candidateData,
    candidatePgBtn,
    candidatePage,
    candidateDataJobId,
    candidateOthers,
    reloadByJob,
  } = useSelector((state: any) => state.candidates);
  const { clientAgreementPgBtn } = useSelector(
    (state: any) => state.clientAgreements
  );
  const { candidate_status } = useSelector((state: any) => state.constants);

  const [isGroupByJob, setIsGroupByJob] = useState(false);

  const [reqPair] = usePlaceHolderPairMutation();
  const [candidateStatus] = useGetCandidatetatusMutation();

  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();

  const abortFilterControllerRef = useRef<AbortController | null>(null);

  const initJobDetails =
    candidateDataJobId &&
    candidateData?.jobs?.filter(
      (job: any) =>
        job.job_id === candidateDataJobId || job.id === candidateDataJobId
    )[0];

  const [jobDetails, setJobDetails] = useState<any>(initJobDetails);
  const { filter } = useSelector((state: any) => state.candidateFilters);
  const {
    isOpen: isOpenHired,
    onOpen: onOpenHired,
    onClose: onCloseHired,
  } = useDisclosure();
  useEffect(() => {
    setIsGroupByJob(
      candidateOthers &&
      candidateOthers.length > 0 &&
      candidateOthers[0].group_by_job
    );
  }, [candidateOthers, candidateData]);

  useEffect(() => {
    const data =
      candidateDataJobId &&
      candidateData?.jobs?.filter(
        (job: any) =>
          job.job_id === candidateDataJobId || job.id === candidateDataJobId
      )[0];
    if (data !== null) {
      setJobDetails(data);
    }
  }, [candidateData?.id]);

  // useEffect(() => {
  //   console.log({ candidateData, candidateDataJobId });
  // }, [candidateData, candidateDataJobId]);

  // useEffect(() => {
  //   if (filter) {
  //     setIsGroupByJob(true);
  //   }
  // }, [filter, candidateData]);

  const [jobList, setJobList] = useState([]);
  const [hasSubmit, setHasSubmit] = useState(false);
  const [hasInterview, setHasInterview] = useState(false);
  const [hasReject, setHasReject] = useState(false);
  const [hasUnReject, setHasUnReject] = useState(false);
  const [actionType, steActionType] = useState('');
  const [jobId, setJobId] = useState(null);
  const [assocId, setAssocId] = useState(0);
  const [intSubj, setSubject] = useState('');
  const [rejectType, setRejectType] = useState('other');
  const [contactOptionValue, setContactOptionValue] = useState([]);
  const [statOption, setStatOption] = useState([]);

  const [reqjob] = useGetJobsMutation();
  const [reqAgreement, resAgreement] = useListClientAgreementMutation();
  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqStatusChange, resStatusChange] = useChangeSubStatusMutation();
  const [reSub] = useGetSubStatusMutation();
  const [reqReject, resReject] = useRejectCandidateMutation();
  const [reqUnReject, resUnReject] = useUnRejectCandidateMutation();
  // const [reqCandidates] = useListCandidatesMutation();
  const [reqGetJob] = useGetJobsMutation();

  const [hasStatus, setHasStatus] = useState(false);
  const [hasRejectButton, setHasRejectButton] = useState(false);
  const [isShow, setIsShow] = useState(isLoadingDrawer);
  const [submitData, setSubmitData] = useState({});

  const isLoading =
    resReject.isLoading ||
    resUnReject.isLoading ||
    resStatusChange.isLoading ||
    resGetCandidate.isLoading;

  const fetchNext = async () => {
    let candidateID: any = null;
    let nextData: any = null;

    // INITIALIZE CANDIDATEID AND NEXTDATA FOR JOBS MODULE
    if (param.jobsId) {
      // reqCandidates(candidatePgBtn);
      const nextrow = rowId + 1;

      candidateID = candidates[rowId]?.id;
      nextData = candidates.filter(
        (data: CandidatesTypes) => data.id === candidateID
      );
      setRowId(nextrow);

      reqGetJob({ id: param.jobsId || candidateDataJobId || jobId });
    }
    // INITIALIZE CANDIDATEID AND NEXTDATA FOR CANDIDATES MODULE
    else {
      candidateID = candidates.filter(
        (item: any) => item.rowId === rowId + 1
      )[0]?.id;
      nextData = candidates.filter((item: any) => item.rowId === rowId + 1)[0];
    }

    // CLOSE DRAWER WHEN ROWID IS AT THE END
    if (rowId === totalCandidates || !candidateID) {
      // console.log({ nextData });
      if (isGroupByJob) {
        handleNextData();
        await reqGetCandidate({ id: candidateData.id });
      } else {
        onClose();
        if (param.candidatesTab) {
          navigate(`/candidates/${param?.candidatesTabState || 'search'}`);
        }
        if (param.jobsId) {
          // setTimeout(() => {
          // navigate(-1);
          // }, 500)
        }
      }
    }

    // FOR CANDIDATES MODULE
    else if (stateTab) {
      handleNextData();
      dispatch(
        getCandidate({
          candidateDataJobId: nextData?.jobs?.[0]?.job_id || nextData?.job_id,
        })
      );
    }
    // FOR JOBS MODULE
    else if (param.jobsId) {
      reqGetCandidate({ id: candidateID }).then((res: any) => {
        handleNextData();
        dispatch(
          getCandidate({
            candidateData: res?.data?.data?.data,
            candidateDataJobId: nextData?.jobs?.[0]?.job_id,
          })
        );
      });
    } else if (rowId !== totalCandidates) {
      reqGetCandidate({ id: candidateID });
    }
  };

  const submitToClient = async () => {
    let jobs = [] as any;
    let job_id = null as any;
    let assoc = null as any;
    const data = {
      candidate_id: candidateData.id,
      job_id: param?.jobsId || null,
    } as any;
    await reqPair(data);
    steActionType('submit');

    if (isGroupByJob || filter || param?.jobsTab === 'view-candidates') {
      onJobSelect({
        id: Number(jobDetails?.job_id || jobDetails?.job?.id),
        type: 'submit',
        assoc_id: jobDetails?.assoc_id || jobDetails?.id,
        reject: jobDetails?.sub?.target,
      });
    } else {
      candidateData?.jobs?.map(async (item: any) => {
        if (param?.jobsId) {
          if (
            (item.candidate_status_id === 2 ||
              item?.status?.id === 2 ||
              item.candidate_status_id === 8 ||
              item.status?.id === 8) &&
            item?.job_id == param?.jobsId
          ) {
            if (jobs.length === 0) {
              jobs.push(item);
              job_id = item?.job_id;
              assoc = item?.assoc_id || item;
            } else {
              if (jobs.some((job: any) => job?.job_id != item?.job_id)) {
                jobs.push(item);
                job_id = item?.job_id;
                assoc = item?.assoc_id || item;
              }
            }
          }
        } else {
          if (
            item.candidate_status_id === 2 ||
            item.candidate_status_id === 8 ||
            ((item?.status?.id === 2 || item.status?.id === 8) &&
              item?.job_id == param?.jobsId)
          ) {
            if (jobs.length === 0) {
              jobs.push(item);
              job_id = item?.job_id;
              assoc = item?.assoc_id || item;
            } else {
              if (jobs.some((job: any) => job?.job_id != item?.job_id)) {
                jobs.push(item);
                job_id = item?.job_id;
                assoc = item?.assoc_id || item;
              }
            }
          }
        }
      });
      setJobList(jobs);

      if (jobs.length === 1) {
        onJobSelect({
          id: Number(job_id),
          type: 'submit',
          assoc_id: typeof assoc === 'number' ? assoc : assoc?.id,
          reject: assoc?.sub?.target,
        });
      } else {
        onOpenJobList();
      }
    }
  };

  const scheduleInterview = async () => {
    let jobs = [] as any;
    let job_id = null as any;
    let assoc = null as any;
    steActionType('interview');
    const data = {
      candidate_id: candidateData.id,
      job_id: param?.jobsId || null,
    } as any;
    await reqPair(data);
    if (isGroupByJob || filter || param?.jobsTab === 'view-candidates') {
      onJobSelect({
        id: Number(jobDetails?.job_id || jobDetails?.job?.id),
        type: 'interview',
        assoc_id: jobDetails?.assoc_id || jobDetails?.id,
        reject: jobDetails?.sub?.target,
      });
    } else {
      await Promise.all(
        candidateData?.jobs?.map(async (item: any) => {
          if (param?.jobsId) {
            if (
              (item?.candidate_status_id === 3 ||
                item?.status?.id === 3 ||
                item?.candidate_status_id === 2 ||
                item?.status?.id === 2 ||
                item.candidate_status_id === 8 ||
                item.status?.id === 8 ||
                item?.candidate_status_id === 1 ||
                item?.status?.id === 1) &&
              (item?.job?.id == param?.jobsId || item?.job_id == param?.jobsId)
            ) {
              if (item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              } else if (item?.job_id) {
                jobs.push(item);
                job_id = item?.job_id;
                assoc = item.assoc_id;
              }
            }
          } else {
            if (
              item.candidate_status_id === 3 ||
              item?.status?.id === 3 ||
              item.candidate_status_id === 2 ||
              item?.status?.id === 2 ||
              item.candidate_status_id === 8 ||
              item.status?.id === 8 ||
              item.candidate_status_id === 1 ||
              item?.status?.id === 1
            ) {
              if (item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              } else if (item?.job_id) {
                jobs.push(item);
                job_id = item?.job_id;
                assoc = item.assoc_id;
              }
            }
          }
        })
      );
      setJobList(jobs);
      if (jobs.length === 1) {
        onJobSelect({
          id: Number(job_id),
          type: 'interview',
          assoc_id: typeof assoc === 'number' ? assoc : assoc?.id,
          reject: assoc?.sub?.target,
        });
      } else {
        onOpenJobList();
      }
    }
  };

  const rejectClick = async (data: any) => {
    let jobs = [] as any;
    let job_id = null as any;
    let assoc = null as any;
    steActionType('reject');
    if (isGroupByJob || filter || param?.jobsTab === 'view-candidates') {
      const assoc_id = jobDetails?.assoc_id || jobDetails?.id;
      handleSubmit(data, assoc_id);
    } else {
      await Promise.all(
        candidateData?.jobs?.map(async (item: any) => {
          if (param?.jobsId) {
            if (
              item.candidate_status_id !== 6 &&
              item?.job?.id == param?.jobsId
            ) {
              if (item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              }
            }
          } else {
            if (item.candidate_status_id !== 6 && item?.job) {
              jobs.push(item);
              job_id = item?.job?.id;
              assoc = item;
            }
          }
        })
      );
      setJobList(jobs);

      if (jobDetails?.assoc_id || jobDetails?.id) {
        const assoc = jobDetails?.assoc_id || jobDetails?.id;
        handleSubmit(data, assoc);
      } else {
        if (jobs.length === 1) {
          onJobSelect({
            id: Number(job_id),
            type: 'reject',
            assoc_id: assoc?.id,
            reject: assoc?.sub?.target,
          });
          handleSubmit(data, assoc?.id);
        } else {
          onOpenJobList();
        }
      }
    }
  };

  const callBackActionHired = () => {
    const form = {
      sub_id: submitData,
    };
    const assoc_id = jobDetails?.assoc_id || jobDetails?.id;
    reqStatusChange({ id: assoc_id, data: form }).then(() => {
      setJob_Id(jobDetails?.job_id);
      reloadGroups();
      dispatch(
        setloadingCandidate({
          reloadByJob: { reload: true, job_id: Number(jobDetails?.job_id) },
        })
      );
      fetchNext();
    });
  };

  const callBackAction = () => {
    const candidateID = candidates.filter(
      (item: any) => item.rowId === rowId + 1
    )[0]?.id;
    const nextData = candidates.filter(
      (item: any) => item.rowId === rowId + 1
    )[0];

    // console.log('candidateId', candidateID);
    // console.log('nextData', nextData);

    // console.log({ candidateID, candidates, nextData, rowId, jobDetails });

    if (!param?.jobsTabNav) {
      reloadGroups();
      dispatch(
        setloadingCandidate({
          reloadByJob: { ...reloadByJob, reload: true },
        })
      );
    }

    setJob_Id(jobDetails?.id);

    if (rowId === totalCandidates || !candidateID) {
      if (isGroupByJob) {
        handleNextData();
        reqGetCandidate({ id: candidateData.id });
      } else {
        onClose();
        if (param.candidatesTab) {
          navigate(`/candidates/${param?.candidatesTabState || 'search'}`);
        }
      }
    } else if (stateTab) {
      // console.log('went to set row id in action button ', rowId + 1);
      setRowId(rowId + 1);
      if (isGroupByJob && (nextData?.job_id || nextData?.jobs?.[0])) {
        navigate(
          `/candidates/${param?.candidatesTabState || 'search'}/${candidatePage.currentPage
          }/${candidateID}/${stateTab}?jobId=${nextData?.job_id || nextData?.jobs?.[0]?.id
          }`
        );
      } else {
        navigate(
          `/candidates/${param?.candidatesTabState || 'search'}/${candidatePage.currentPage
          }/${candidateID}/${stateTab}`
        );
      }
      reqGetCandidate({ id: candidateID });
      if (nextData?.job_id || nextData?.jobs) {
        dispatch(
          getCandidate({
            // candidateData: nextData,
            candidateDataJobId: nextData?.job_id || nextData?.jobs[0]?.job_id,
          })
        );
      }
    } else if (rowId !== totalCandidates) {
      reqGetCandidate({ id: candidateID });
    }
    // for actions within view candidates
    if (param['*'].includes('view-candidates')) {
      onClose();
    }
  };

  const onJobSelect = (data: jobselectInt) => {
    setAssocId(data.assoc_id);
    setRejectType(data.reject);
    if (data.type === 'submit' || data.type === 'interview') {
      reqjob({ id: data.id }).then((res: any) => {
        setJobId(data.id);
        const job = res?.data?.data;
        if (data.type === 'submit') {
          onOpenSubmit();
        }
        if (data.type === 'interview') {
          const client_name = job?.data?.client ? job?.data?.client?.name : '';
          const subject =
            'Video Interview' +
            ' | ' +
            job?.data?.title +
            ' | ' +
            candidateData?.first_name +
            ' ' +
            candidateData?.last_name +
            '/' +
            client_name;

          setSubject(subject);
          let contactOption: any = [];
          // if (job?.data?.lead?.leadStatus?.id !== 5) {
          contactOption = [
            {
              label: [
                job?.data?.lead?.first_name,
                job?.data?.lead?.last_name,
              ].join(' '),
              value: job?.data?.lead?.primary_email,
            },
          ];

          // }
          setContactOptionValue(contactOption);
          onOpenScheduleInterview();
        }
      });
    }

    if (data.type === 'reject' && param.candidatesTab && !isGroupByJob) {
      onOpenReject();
    }

    if (data.type === 'unreject' && param.candidatesTab && !isGroupByJob) {
      reqUnReject({ data: [], id: data.assoc_id }).then(() => {
        setJob_Id(jobDetails?.id);
        reloadGroups();
        dispatch(
          setloadingCandidate({
            reloadByJob: { ...reloadByJob, reload: true },
          })
        );
        fetchNext();
      });
    }
  };

  const handleStatus = async (data: any) => {
    const form = {
      sub_id: (data?.sub_id && data?.sub_id?.value) || data,
    };
    if (form.sub_id === 8) {
      setSubmitData(data);

      let clientID = null as any;
      candidateData?.jobs.map(async (job: any, index: number) => {
        if (job?.job_id == param.jobsId || job?.job_id == jobDetails?.job_id) {
          if (job?.job?.client?.id) {
            clientID = job?.job?.client?.id;
          }

          if (!candidateData?.source?.source) {
            await reqGetCandidate({ id: candidateData?.id });
          }
          if (
            jobDetails?.job_id !== candidateData?.jobs?.[index]?.job_id ||
            !jobDetails?.lead?.lead_source?.source
          ) {
            await reqGetJob({ id: candidateData?.jobs?.[index]?.job_id });
          }
          if (clientID) {
            let newParam = {
              page: 1,
              take: clientAgreementPgBtn.take,
              query: '',
              status: clientAgreementPgBtn.status,
              types: clientAgreementPgBtn.types,
            };
            dispatch(
              setClientAgreement({
                leadAgreementPgBtn: newParam,
              })
            );
            await reqAgreement({ data: newParam, id: clientID });
          }

          onOpenHired();
        }
      });
    } else if (isGroupByJob) {
      const assoc_id = jobDetails?.assoc_id || jobDetails?.id;
      await reqStatusChange({ id: Number(assoc_id), data: form }).then(
        (res) => {
          setJob_Id(jobDetails?.id);
          reloadGroups();
          dispatch(
            setloadingCandidate({
              reloadByJob: { ...reloadByJob, reload: true },
            })
          );
          fetchNext();
        }
      );
    } else if (filter) {
      const assoc_id = jobDetails?.assoc_id || jobDetails?.id;
      await reqStatusChange({ id: Number(assoc_id), data: form }).then(
        (res) => {
          setJob_Id(jobDetails?.id);
          fetchNext();
        }
      );
    } else if (candidateData?.jobs?.length > 0) {
      candidateData?.jobs.map(async (job: any) => {
        const assoc_id = job?.assoc_id || job?.id;
        if (job?.job_id == param.jobsId) {
          await reqStatusChange({ id: Number(assoc_id), data: form }).then(
            () => {
              setJob_Id(jobDetails?.job_id); //jobDetails.id is wrong id
              // fetchDandidate();
              fetchNext();
            }
          );
        }
      });
    }
  };

  const handleSubmit = async (data: any, id: any) => {
    await reqReject({
      id: id,
      data: {
        reason: data,
      },
    });
  };

  useEffect(() => {
    if (resReject.isSuccess || resUnReject.isSuccess) {
      if (!param?.jobsTabNav) {
        reloadGroups();
        dispatch(
          setloadingCandidate({
            reloadByJob: { ...reloadByJob, reload: true },
          })
        );
        setJob_Id(jobDetails?.id);
      }

      fetchNext();
    }
  }, [resReject.isSuccess, resUnReject.isSuccess]);

  useEffect(() => {
    if (candidate_status?.length > 0) {
      let groups: any = [];
      candidate_status.map((item: any) => {
        if (item?.sub?.length > 0) {
          let option: any = [];
          item?.sub?.map((sub: any) => {
            option.push({
              label: sub.sub_status,
              value: sub.id,
            });
          });

          groups.push({
            label: item.status,
            options: option,
          });
        }
      });
      setStatOption(groups);
    } else {
      //ensuring that candidate status has value
      candidateStatus({});
    }
  }, [candidate_status]);

  useEffect(() => {
    if (!isOpenScheduleInterview) {
      setContactOptionValue([]);
    }
  }, [isOpenScheduleInterview]);

  useEffect(() => {
    const interviewCondition = [1, 2, 3, 8];
    const rejectContidion = [3, 4, 5];
    setHasSubmit(false);
    if (isGroupByJob && jobDetails) {
      if (
        jobDetails.candidate_status_id === 2 ||
        jobDetails.status?.id === 2 ||
        jobDetails.candidate_status_id === 8 ||
        jobDetails?.status?.id === 8
      ) {
        setHasSubmit(true);
      }
      // if (
      //   jobDetails.candidate_status_id === 3 ||
      //   jobDetails.candidate_status_id === 2 ||
      //   jobDetails.candidate_status_id === 1
      // )
      if (
        interviewCondition.includes(jobDetails.candidate_status_id) ||
        interviewCondition.includes(jobDetails.status?.id)
      ) {
        setHasInterview(true);
        setHasReject(true);
        setHasUnReject(false);
      }
      // if (
      //   jobDetails.candidate_status_id === 3 ||
      //   jobDetails.candidate_status_id === 4 ||
      //   jobDetails.candidate_status_id === 5
      // )
      if (
        rejectContidion.includes(jobDetails.candidate_status_id) ||
        rejectContidion.includes(jobDetails.status?.id)
      ) {
        setHasReject(true);
        setHasUnReject(false);
      }
      if (jobDetails.candidate_status_id === 6 || jobDetails.status?.id === 6) {
        setHasUnReject(true);
        setHasRejectButton(false);
        setHasReject(false);
        setHasInterview(false);
      }
    } else {
      // console.log('candidateData', candidateData);
      candidateData?.jobs?.map(async (item: any) => {
        if (param?.jobsId || candidateDataJobId || jobId) {
          const job_id = param?.jobsId || candidateDataJobId || jobId;
          if (item.job_id == job_id) {
            const hasSubmit =
              item.candidate_status_id === 2 ||
                item.status?.id === 2 ||
                item.candidate_status_id === 8 ||
                item.status?.id === 8
                ? true
                : false;
            setHasSubmit(hasSubmit);
            setHasInterview(false);

            if (
              interviewCondition.includes(item.candidate_status_id) ||
              interviewCondition.includes(item.status?.id)
            ) {
              setHasInterview(true);
              setHasReject(true);
              if (filter) {
                setHasUnReject(false);
              }
            }

            if (
              rejectContidion.includes(item.candidate_status_id) ||
              rejectContidion.includes(item.status?.id)
            ) {
              setHasReject(true);
              if (filter) {
                setHasUnReject(false);
              }
            }
            if (item.candidate_status_id === 6 || item.status?.id === 6) {
              setHasUnReject(true);
              setHasInterview(false);
              setHasRejectButton(false);
            }
          }
        } else {
          if (
            item.candidate_status_id === 2 ||
            item.status?.id === 2 ||
            item.candidate_status_id === 8 ||
            item.status?.id === 8
          ) {
            setHasSubmit(true);
          }

          if (
            interviewCondition.includes(item.candidate_status_id) ||
            interviewCondition.includes(item.status?.id)
          ) {
            setHasInterview(true);
            setHasReject(true);
            if (filter) {
              setHasUnReject(false);
            }
          }

          if (
            rejectContidion.includes(item.candidate_status_id) ||
            rejectContidion.includes(item.status?.id)
          ) {
            setHasReject(true);
            if (filter) {
              setHasUnReject(false);
            }
          }
          if (item.candidate_status_id === 6 || item.status?.id === 6) {
            setHasUnReject(true);
            setHasInterview(false);
            setHasRejectButton(false);
          }
        }
      });
    }
  }, [candidateData?.id, isGroupByJob, jobDetails?.id, param?.jobsId]);

  // useEffect(() => {
  //   const load = async () => {
  //     await delay(200);
  //     await reSub({ id: 6 }).then(() => {
  // if (!param?.jobsTabNav) {
  //   reloadGroups();
  //   setJob_Id(jobDetails?.id);
  // }
  // fetchNext();
  //     });
  //   };
  //   load();
  // }, [assocId]);

  const jobStatus = (candidate: any) => {
    try {
      if (isGroupByJob) {
        return jobDetails?.sub?.sub_status;
      }
      if (candidate?.jobs?.length > 0) {
        const data: any = candidate?.jobs?.filter(
          (job: any) =>
            job.job_id === Number(param.jobsId || jobId || candidateDataJobId)
        )[0];
        return data?.sub?.sub_status;
      } else {
        return '--';
      }
    } catch (e) {
      console.warn(e);
      return 'Error';
    }
  };

  const unRejectClick = async (candidate: any) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'You are about to unreject this candidate.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      let id = null;
      if (isGroupByJob) {
        id = jobDetails?.assoc_id || jobDetails?.id;
      } else {
        let jobs = [] as any;
        let job_id = null as any;
        let assoc = null as any;

        steActionType('unreject');

        await Promise.all(
          candidateData?.jobs?.map(async (item: any) => {
            if (param.jobsId) {
              if (
                item.candidate_status_id === 6 &&
                item?.job?.id == param?.jobsId
              ) {
                if (item?.job) {
                  jobs.push(item);
                  job_id = item?.job?.id;
                  assoc = item;
                }
              }
            } else {
              if (item.candidate_status_id === 6 && item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              }
            }
          })
        );

        setJobList(jobs);

        if (jobDetails?.assoc_id) {
          id = jobDetails?.assoc_id;
        } else {
          if (jobs.length === 1) {
            onJobSelect({
              id: Number(job_id),
              type: 'unreject',
              assoc_id: assoc?.id,
              reject: assoc?.sub?.target,
            });
            id = assoc?.id;
          } else {
            onOpenJobList();
          }
        }
      }

      //this is needed for jobs un reject
      id &&
        (await reqUnReject({ data: [], id }).then(() => {
          // console.log('hindi sa on job select', param);
          setJob_Id(jobDetails?.id);
          if (!param?.jobsId) {
            reloadGroups();

            dispatch(
              setloadingCandidate({
                reloadByJob: { ...reloadByJob, reload: true },
              })
            );
          }

          fetchNext();
        }));
    }
  };

  useEffect(() => {
    if (param.jobsTabId && param.jobsTabId !== 'search') {
      setHasStatus(true);
    } else {
      setHasStatus(false);
    }
    if (param.candidatesTab && (isGroupByJob || filter) && candidateDataJobId) {
      setHasStatus(true);
    }
  }, [
    candidateData,
    filter,
    candidateDataJobId,
    param.candidatesTab,
    param.jobsTabId,
  ]);

  useEffect(() => {
    if (hasReject && !hasUnReject) {
      if (param.candidatesTab && (isGroupByJob || filter)) {
        setHasRejectButton(true);
      }

      if (param.jobsTab && param.jobsTabId !== 'candidate-pool') {
        setHasRejectButton(true);
      }
    }
  }, [
    filter,
    hasReject,
    hasUnReject,
    isGroupByJob,
    param.candidatesTab,
    param.jobsTab,
    param.jobsTabId,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (isLoadingDrawer) {
        setIsShow(isLoadingDrawer);
      }
    }, 800);
  }, [isLoadingDrawer]);

  const rejectItems = () => {
    let jobs = [] as any;

    candidateData?.jobs?.map(async (item: any) => {
      if (param?.jobsId) {
        if (item.candidate_status_id !== 6 && item?.job?.id == param?.jobsId) {
          if (item?.job) {
            jobs.push(item);
          }
        }
      } else {
        if (item.candidate_status_id !== 6 && item?.job) {
          jobs.push(item);
        }
      }
    });

    return jobs.length;
  };

  const unRejectItems = () => {
    let jobs = [] as any;

    candidateData?.jobs?.map(async (item: any) => {
      if (param?.jobsId) {
        if (item.candidate_status_id === 6 && item?.job?.id == param?.jobsId) {
          if (item?.job) {
            jobs.push(item);
          }
        }
      } else {
        if (item.candidate_status_id === 6 && item?.job) {
          jobs.push(item);
        }
      }
    });

    return jobs.length;
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        isShow && (
          <>
            {hasStatus && (
              <Menu closeOnSelect={true}>
                <Flex>
                  <Box
                    sx={{
                      button: {
                        // p: '4px',
                        p: '4px',
                        pl: '8px',
                        height: '35px',
                        fontSize: '12px',
                        borderRight: 'none',
                        borderRadius: '6px 0 0 6px',
                      },
                    }}
                  >
                    <Button>{jobStatus(candidateData)}</Button>
                  </Box>
                  <MenuButton>
                    <Box
                      sx={{
                        button: {
                          p: '4px 8px',
                          height: '35px',
                          fontSize: '12px',
                          borderLeft: 'none',
                          borderRadius: '0 6px 6px 0',
                        },
                      }}
                    >
                      <Button rightIcon="chevron-down">| </Button>
                    </Box>
                  </MenuButton>
                </Flex>
                {
                  <MenuList
                    fontSize="sm"
                    maxHeight="120px"
                    overflowY="auto"
                    sx={{
                      '.chakra-menu__group__title': {
                        margin: '0.4rem 0.8rem',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '10px',
                        color: 'default.gray.400',
                        textTransform: 'uppercase',
                      },
                    }}
                  >
                    {statOption.map((val, key: number) => {
                      return (
                        <MenuGroup title={val.label} key={`stat-option-${key}`}>
                          {val.options.map((op: any, index: number) => {
                            return (
                              <MenuItem
                                onClick={() => handleStatus(op.value)}
                                key={`stat-option-${key}-${index}`}
                              >
                                {op.label}
                              </MenuItem>
                            );
                          })}
                        </MenuGroup>
                      );
                    })}
                  </MenuList>
                }
              </Menu>
            )}
            {param.jobsTabId !== 'search' && hasSubmit && (
              <Flex alignItems="center" justifyContent="center">
                <Box
                  sx={{
                    button: {
                      height: '35px',
                      fontSize: '12px',
                      pl: '8px',
                    },
                  }}
                >
                  <Button
                    leftIcon="arrow-right"
                    size="sm"
                    onClick={() => submitToClient()}
                  >
                    Submit to Client
                  </Button>
                </Box>
              </Flex>
            )}
            {param.jobsTabId !== 'search' && hasInterview && (
              <Flex>
                <Box
                  sx={{
                    button: {
                      height: '35px',
                      fontSize: '12px',
                      pl: '8px',
                    },
                  }}
                >
                  <Button
                    leftIcon="video"
                    size="sm"
                    onClick={() => scheduleInterview()}
                  >
                    Schedule Interview
                  </Button>
                </Box>
              </Flex>
            )}
            {hasReject &&
              param.candidatesTab &&
              !isGroupByJob &&
              !filter &&
              rejectItems() > 0 && (
                <Box
                  sx={{
                    button: {
                      position: 'relative',
                      p: '4px 8px',
                      pl: '8px',
                      height: '35px',
                      fontSize: '12px',
                      borderRadius: '6px',
                    },
                  }}
                >
                  <Button
                    leftIcon="circle-xmark"
                    size="sm"
                    onClick={() => rejectClick(null)}
                  >
                    <Box>Reject</Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '-10px',
                        right: '-8px',
                        float: 'right',
                        background: '#7e5ec2',
                        borderRadius: '4px',
                        padding: '3px 5px',
                        display: 'flex',
                        fontSize: '10px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                      }}
                    >
                      {rejectItems()}
                    </Box>
                  </Button>
                </Box>
              )}
            {hasRejectButton && (
              <Menu closeOnSelect={true}>
                <Flex>
                  <Box
                    sx={{
                      button: {
                        p: '4px',
                        pl: '8px',
                        height: '35px',
                        fontSize: '12px',
                        borderRight: 'none',
                        borderRadius: '6px 0 0 6px',
                      },
                    }}
                  >
                    <Button onClick={() => rejectClick(14)}>
                      Recruiter Reject
                    </Button>
                  </Box>
                  <MenuButton>
                    <Box
                      sx={{
                        button: {
                          p: '4px 8px',
                          height: '35px',
                          fontSize: '12px',
                          borderLeft: 'none',
                          borderRadius: '0 6px 6px 0',
                        },
                      }}
                    >
                      <Button rightIcon="chevron-down">|</Button>
                    </Box>
                  </MenuButton>
                </Flex>
                {
                  <MenuList fontSize="sm" maxHeight="120px" overflowY="auto">
                    <MenuItem onClick={() => rejectClick(15)}>
                      Client Reject
                    </MenuItem>
                    <MenuItem onClick={() => rejectClick(16)}>
                      Candidate Not Interested
                    </MenuItem>
                  </MenuList>
                }
              </Menu>
            )}
            {param.jobsTabId !== 'candidate-pool' &&
              hasUnReject &&
              unRejectItems() > 0 && (
                <Box
                  sx={{
                    button: {
                      position: 'relative',
                      p: '4px 8px',
                      pl: '8px',
                      height: '35px',
                      fontSize: '12px',
                      borderRadius: '6px',
                    },
                  }}
                >
                  <Button
                    leftIcon="circle-xmark"
                    size="sm"
                    onClick={() => unRejectClick(candidateData)}
                  >
                    <Box>Un - Reject</Box>
                    {param.candidatesTab && !isGroupByJob && !filter && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '-10px',
                          right: '-8px',
                          float: 'right',
                          background: '#7e5ec2',
                          borderRadius: '4px',
                          padding: '3px 5px',
                          display: 'flex',
                          fontSize: '10px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'white',
                        }}
                      >
                        {unRejectItems()}
                      </Box>
                    )}
                  </Button>
                </Box>
              )}
            {isOpenJobList && (
              <CandidateJobList
                jobs={jobList}
                isOpen={isOpenJobList}
                onClose={onCloseJobList}
                onSelect={(e: any) => onJobSelect(e)}
                type={actionType}
              />
            )}
            {isOpenSubmit && (
              <SubmitToClientModal
                isOpen={isOpenSubmit}
                onClose={onCloseSubmit}
                candidate={candidateData}
                job_id={jobId}
                callback={() => {
                  callBackAction();
                }}
              />
            )}
            {isOpenScheduleInterview && (
              <CandidatesInterviewModal
                isOpen={isOpenScheduleInterview}
                onClose={onCloseScheduleInterview}
                associate={assocId}
                subject={intSubj}
                isJobView={true}
                jobsId={jobId}
                contactOptionValue={contactOptionValue}
                callback={() => {
                  callBackAction();
                }}
                isEdit={false}
              />
            )}
            {isOpenReject && (
              <RejectModal
                isOpen={isOpenReject}
                onClose={onCloseReject}
                jobsId={jobId}
                id={assocId}
                rejectType={rejectType}
                callback={() => {
                  callBackAction();
                }}
              />
            )}
            {isOpenHired && (
              <HiredModal
                isOpen={isOpenHired}
                // onCloseStatus={onClose}
                jobId={Number(param.jobsId) || jobDetails?.job_id}
                onClose={onCloseHired}
                candidate={candidateData}
                onCallback={callBackActionHired}
              />
            )}
          </>
        )
      )}
    </>
  );
};

export default ActionButton;
