import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { listCandidates } from 'store/candidates.slice';
import { navClient } from 'store/client.slice';
import {
  useListJobsMutation,
  useGetJobsMutation,
  useToggleStatusMutation,
  useDeleteJobsMutation,
  useListByContactCandidatesMutation,
  listJobs,
  getJob,
} from 'store/jobs.slice';
import { JobsTypes, swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';

interface followUpClickProps {
  open: boolean;
  all: boolean;
  job_id: number;
  lead_id: number | null;
}

interface followUpModalProps {
  lead_id: number | null;
  job_id: number | null;
}

interface useJobsTableServicesProps {
  onOpen: () => void;
  onFollowUpOpen: () => void;
  onManualResumeOpen: () => void;
  onEditJobOpen: () => void;
  onOpenPaid: () => void;
}

// Custom hook for managing job table services
export const useJobsTableServices = ({
  onOpen,
  onFollowUpOpen,
  onManualResumeOpen,
  onEditJobOpen,
  onOpenPaid,
}: useJobsTableServicesProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  // Extracting state values from the Redux store
  const { jobs, jobPage, jobPgBtn } = useSelector((state: any) => state.jobs);
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  // Defining mutation hooks for various API calls
  const [reqJobs, resJobs] = useListJobsMutation();
  const [reqGetJob, resGetJob] = useGetJobsMutation();
  const [reqToggleStatus] = useToggleStatusMutation();
  const [reqDelJob] = useDeleteJobsMutation();
  const [reqListByContactCandidates] = useListByContactCandidatesMutation();

  // State variables
  const [rowId, setRowId] = useState(1);
  const [modalTitle, setModalTitle] = useState('Edit Job');
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [jobDataState, setJobData] = useState([]);
  const [jobId, setJobId] = useState(null);

  const [isSorted, setIsSorted] = useState(false);
  const [isFollowUp, setIsFollowUp] = useState(false);
  const [isFollowUpAll, setIsFollowUpAll] = useState(false);
  const [orderBy] = useState(null);
  const [followUpParam, setFollowUpParam] = useState<followUpModalProps>({
    lead_id: null,
    job_id: null,
  });

  // Ensures the document text direction is left-to-right
  document.documentElement.dir = 'ltr';

  // Update rowId when jobs or params.jobsId change
  useEffect(() => {
    const jobID =
      jobs.findIndex((x: JobsTypes) => x.id === Number(params.jobsId)) + 1;
    setRowId(jobID);
  }, [jobs, params.jobsId]);

  // Open modal when params.jobsId changes
  useEffect(() => {
    if (params.jobsId) {
      onOpen();
    }
  }, [params.jobsId]);

  // Abort controller for managing fetch requests
  const abortControllerJobRef = useRef<AbortController | null>(null);

  // Fetch jobs with the given parameters
  const fetchJobs = async (params: object) => {
    if (abortControllerJobRef.current) {
      abortControllerJobRef.current.abort();
    }

    abortControllerJobRef.current = new AbortController();
    reqJobs({ data: params, signal: abortControllerJobRef.current.signal });
  };

  // Handle follow-up operations when isFollowUp and resGetJob.isSuccess change
  useEffect(() => {
    if (isFollowUp && resGetJob.isSuccess) {
      onFollowUpOpen();
    }
  }, [isFollowUp, resGetJob.isSuccess]);

  // Navigate to job details and update rowId when a job is clicked
  const handleClick = (job: JobsTypes) => {
    onOpen();
    navigate(`/jobs/${job.id}/details`);

    const key = jobs.findIndex((x: JobsTypes) => x.id === job.id) + 1;
    setRowId(key);
  };

  // Confirmation content for job status toggle
  let content: swalContent = {
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this job data.',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  // Handle toggling job status with confirmation
  const handleToggleStatus = async (job: JobsTypes) => {
    const status = job.job_status_id === 1 ? 'closed' : 'open';
    content.text = `You are about to mark this job as ${status}, continue?`;
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqToggleStatus({ id: job.id });
      fetchJobs(jobPgBtn);
    }
  };

  const stateTab: any = params.jobsTab;

  // Navigate to previous job if available
  const handlePrevData = () => {
    if (rowId !== 0) {
      const jobID = jobs[rowId - 1 - 1].id;
      setRowId((prevKey: number) => prevKey - 1);
      navigate(`/jobs/${jobID}/${stateTab}`);
    }
  };

  // Navigate to next job if available
  const handleNextData = () => {
    const jobID = jobs[rowId - 1 + 1].id;
    setRowId((prevKey: number) => prevKey + 1);
    navigate(`/jobs/${jobID}/${stateTab}`);
  };

  // Handle page changes for job listing
  const pageChange = async (page: number) => {
    let newParam = {
      ...jobPgBtn,
      page: page,
    };
    dispatch(
      listJobs({
        jobPgBtn: newParam,
      })
    );
    fetchJobs(newParam);
  };

  let dynamicJobs: any = [];

  // Update job data state when jobs change
  useEffect(() => {
    dynamicJobs = [];
    if (jobs.length > 0) {
      jobs.map((job: any) => {
        dynamicJobs.push({
          job: job,
          id: job.id,
          title: job.title,
          open: false,
          job_status_id: job.job_status_id,
          client: job.client,
          jobads: job.jobads,
          screen: job.screen,
          submits: job.submits,
          interview: job.interview,
        });
      });
    }
    setJobData(dynamicJobs);
  }, [jobs]);

  // Refetch sorted jobs when isSorted changes
  useEffect(() => {
    if (isSorted) {
      const params = {
        page: jobPgBtn.page,
        take: jobPgBtn.take,
        query: jobPgBtn.query,
        closed: jobPgBtn.closed,
        open: jobPgBtn.open,
        quickJobs: jobPgBtn.quickJobs,
        job_assigned: jobPgBtn.job_assigned,
        job_ads: jobPgBtn.job_ads,
        draft: jobPgBtn.draft,
        boolean: jobPgBtn.boolean,
        ...(orderBy !== null && {
          orderBy: {
            column_name: orderBy?.column_name,
            sort_order: orderBy?.sort_order,
          },
        }),
      };
      dispatch(
        listJobs({
          jobPgBtn: params,
        })
      );
      fetchJobs(params);
      setIsSorted(false);
    }
  }, [isSorted]);

  // Handle changes in number of entries per page
  const entryChange = (entries: number) => {
    let newParam = {
      ...jobPgBtn,
      page: 1,
      take: entries,
    };
    dispatch(
      listJobs({
        jobPgBtn: newParam,
      })
    );
    fetchJobs(newParam);
  };

  // Format location from city and state
  const locationParse = (location: any) => {
    try {
      const loc = [location?.city, location?.state].join(', ');
      if (loc !== ', ') {
        return loc;
      }
    } catch (e) {
      console.log(e);
      return '';
    }
  };

  // Handle deletion of a job with confirmation
  const DelJobs = async (job: any) => {
    let content: swalContent = {
      title: 'Are you sure?',
      text: `You are about to delete ${job?.title} (${job?.job?.serial_number}).`,
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDelJob({ id: job.id });
      fetchJobs(jobPgBtn);
    }
  };

  // Handle follow-up operations with job and lead information
  const followUpClick = (params: followUpClickProps) => {
    const { open, all, job_id, lead_id } = params;
    setFollowUpParam({
      job_id,
      lead_id,
    });
    setIsFollowUp(open);
    setIsFollowUpAll(all);
    reqGetJob({ id: job_id });
    if (all) {
      reqListByContactCandidates({
        id: lead_id,
      });
    }
  };

  // Handle manual resume parsing
  const handleManualParse = async (job_id: number) => {
    console.log('clicked handle manualparse');
    setJobId(job_id);
    onManualResumeOpen();
  };

  // Handle view candidate click with exceptions
  const viewCandidateClick = async (e: any, job: any) => {
    const isExceptionClick =
      e.target.classList.contains('checkboxClass') ||
      e.target.closest('.checkboxClass') ||
      e.target.classList.contains('dropdownClass') ||
      e.target.closest('.dropdownClass') ||
      e.target.classList.contains('menuClass') ||
      e.target.closest('.menuClass');
    await dispatch(getJob({ jobData: job?.job }));
    if (!isExceptionClick) {
      trViewCandidate(job.id);
    }
  };

  // View candidate related to a job
  const trViewCandidate = async (job_id: any) => {
    const param = {
      ...candidatePgBtn,
      job_status: 1,
      status: [2],
      filter: null,
    };

    await dispatch(listCandidates({ candidatePgBtn: param }));
    navigate(`/jobs/${job_id}/view-candidates/in-progress/screen`);

    localStorage.setItem('candidatesFilter', JSON.stringify(param));
  };

  // Handle job edit action
  const handleEdit = (id: number) => {
    if (!isNaN(id) && id !== null) {
      reqGetJob({ id: id });
    }
    onEditJobOpen();
    setModalTitle('Edit Job');
    setIsDuplicate(false);
    setJobId(Number(id));
  };

  // Handle viewing of candidates for a job
  const handleViewCandidate = (job: { job: any; id: any }) => {
    dispatch(getJob({ jobData: job?.job }));
    navigate(`/jobs/${job.id}/view-candidates/in-progress/screen`);
    const param = {
      ...candidatePgBtn,
      job_status: 1,
      status: [2],
    };
    localStorage.setItem('candidatesFilter', JSON.stringify(param));
  };

  // Handle job duplication action
  const handleDuplicate = (id: number) => {
    if (!isNaN(id) && id !== null) {
      reqGetJob({ id: id });
    }
    onEditJobOpen();
    setModalTitle('Duplicate Job');
    setIsDuplicate(true);
    setJobId(Number(id));
  };

  // Handle client table cell click
  const handleClickClientTd = (e: any, job: JobsTypes) => {
    const isClientHyperlinkClick =
      e.target.classList.contains('clientClass') ||
      e.target.closest('.clientClass');
    if (!isClientHyperlinkClick) {
      handleClick(job);
    }
  };

  // Navigate to client overview
  const handleClickClientLink = () => {
    dispatch(navClient({ clientNav: 'all-clients' }));
  };

  // Handle paid job related actions
  const handleOpenPaid = (job: any) => {
    dispatch(getJob({ hasPaidJob: true }));
    dispatch(listJobs({ jobData: job }));
    onOpenPaid();
  };

  return {
    isLoading: resJobs.isLoading,
    jobPgBtn,
    jobDataState,
    viewCandidateClick,
    trViewCandidate,
    handleClick,
    locationParse,
    handleEdit,
    handleViewCandidate,
    followUpClick,
    handleDuplicate,
    handleToggleStatus,
    DelJobs,
    handleClickClientTd,
    handleClickClientLink,
    handleOpenPaid,
    handleManualParse,
    rowId,
    jobs,
    handlePrevData,
    handleNextData,
    jobPage,
    pageChange,
    entryChange,
    modalTitle,
    isDuplicate,
    jobId,
    isFollowUpAll,
    followUpParam,
    setIsFollowUp,
  };
};
