export const JOBS_STATUS = [
  {
    id: 1,
    stat_id: { open: true },
    status: 1,
    name: 'Open',
  },
  {
    id: 2,
    stat_id: { closed: true },
    status: 0,
    name: 'Close',
  },
  {
    id: 3,
    stat_id: { draft: true },
    status: 1,
    name: 'Draft',
  },
];

export const JOBS_TYPE = [
  {
    id: 1,
    stat_id: { quickJobs: true },
    status: 1,
    name: 'Quick Jobs',
  },
  {
    id: 2,
    stat_id: { full: true },
    status: 1,
    name: 'Full Jobs',
  },
  {
    id: 3,
    stat_id: { job_assigned: true },
    status: 1,
    name: 'Jobs Assigned to me',
  },
];
