import {
  useEffect,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  useDisclosure,
  Alert,
  AlertIcon,
  Text,
} from '@chakra-ui/react';

import LoadingPage from 'components/app/Loading';
import mapPin from '../../../../assets/images/mapPin.svg';

import { candidateDataInt, CandidatesTypes, swalContent } from 'types';

import Button from '../../../../Library/Button';
import CandidatesTabs from './Tabs';
import {
  getCandidate,
  setloadingCandidate,
  useCompanyUpdateCandidatesMutation,
  useDeleteCandidatesMutation,
  useFindDistanceFromJobMutation,
  useFirstNameUpdateCandidatesMutation,
  useGetCandidatesMutation,
  useJobTitleUpdateCandidatesMutation,
  useLastNameUpdateCandidatesMutation,
  useListCandidatesMutation,
  useToggleHideCandidatesMutation,
} from 'store/candidates.slice';
import { getResume } from 'store/candidateresume.slice';
import { AtsConfirm } from 'utils/swal';
import { useListResumeCandidateMutation } from 'store/candidateresume.slice';
import ActionButton from '../components/ActionButton';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import { useGetJobsMutation } from 'store/jobs.slice';

import FAIcon from 'components/lib/FAIcon';
import DynamicInputWidthForm from './DynamicInputWidthForm';
import { useCandidateActivityLogsMutation } from 'store/candidateActivity';
import CandidatesTagModal from '../Modals/Tag';
import CandidatesAssociateJobModal from '../Modals/AssociateJobs';
// import CandidatesModal from './Modals';
import SubmitToClientModal from '../../../../modules/Jobs/Layout/ViewCandidatesModule/Modal/SubmitToClient';
import CandidatesInterviewModal from './Interviews/modal';
import CandidatesModal from '../Modals/modal';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import { getJob } from 'store/jobs.slice';
import delay from 'utils/delay';

interface CandidateProps {
  isOpen: boolean;
  onClose: () => void;
  rowId: number;
  setRowId: any;
  totalCandidates: number;
  handlePrevData: (candidate: CandidatesTypes) => void;
  handleNextData: () => void;
  isLoading?: boolean;
  candidates: any;
  isRefresh?: boolean;
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CandidatesDrawer({
  isOpen,
  onClose,
  rowId,
  setRowId,
  totalCandidates,
  handlePrevData,
  handleNextData,
  isLoading,
  candidates,
  isRefresh,
}: CandidateProps) {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const toast = useToast();
  const urlQuery = useQuery();

  const [reqjob] = useGetJobsMutation();

  const [reqCandidatesList] = useListCandidatesMutation();
  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqAttachment, resAttachment] = useListResumeCandidateMutation();
  const [reqLogs, resLogs] = useCandidateActivityLogsMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqGetJob, resGetJob] = useGetJobsMutation();
  const [reqDeleteCandidate, resDeleteCandidate] =
    useDeleteCandidatesMutation();

  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();

  const [reqToggleHide, resToggleHide] = useToggleHideCandidatesMutation();

  const [reqFirstNameUpdate, resFirstNameUpdate] =
    useFirstNameUpdateCandidatesMutation();
  const [reqLastNameUpdate, resLastNameUpdate] =
    useLastNameUpdateCandidatesMutation();
  const [reqJobTitleUpdate, resJobTitleUpdate] =
    useJobTitleUpdateCandidatesMutation();
  const [reqCompanyUpdate, resCompanyUpdate] =
    useCompanyUpdateCandidatesMutation();
  // const [reqDistance, resDistance] = useFindDistanceFromJobMutation();

  const abortFilterControllerRef = useRef<AbortController | null>(null);
  const {
    candidateData,
    candidatePgBtn,
    candidateOthers,
    candidateDataJobId,
    reloadByJob,
  } = useSelector((state: any) => state.candidates);
  const { candidateLogsPgBtn } = useSelector(
    (state: any) => state.candidateActivity
  );

  const [candidateInfo, setCandidateInfo] = useState<candidateDataInt | null>(
    candidateData
  );

  const { jobData } = useSelector((state: any) => state.jobs);

  let emails = candidateInfo?.emails?.filter((email: any) => email.primary);
  emails = emails?.length === 0 ? candidateInfo?.emails : emails;

  const candidateID = Number(params.candidatesId || params?.candidateId);
  const [assocJobs, setAssocJobs] = useState([]);
  const [hasFilter, setHasFilter] = useState(true);
  const [contactOptionValue, setContactOptionValue] = useState([]);
  const [candidateEmail, setCandidateEmail] = useState<string | null>(null);
  const [distanceDetails, setDistanceDetails] = useState<any>(null);
  const [interviewStatus, setInterviewStatus] = useState(null);

  const [isGroupByJob, setIsGroupByJob] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const loadInterviewStatus = async (interviews: any) => {
    if (interviews.length > 0) {
      let status = '';
      await Promise.all(
        interviews?.map((interview: any) => {
          if (Number(jobId) === interview?.job_id) {
            if (interview.status == 1) {
              status = `Interview #${interview.counter} - Scheduled`;
            } else if (interview.status == 2) {
              status = `Interview #${interview.counter} - Completed`;
            }
          }
        })
      );
      setInterviewStatus(status);
    }
  };
  const loadDistanceDetails = async (jobs: any) => {
    if (jobs.length > 0) {
      let distance = {} as any;
      jobs?.map((job: any, index: number) => {
        if (Number(jobId) === job?.job_id) {
          const ad = job?.job?.jobads?.[index];
          distance = {
            distance: job?.distance_from_job,
            location: [ad?.city, ad?.state].filter(Boolean).join(' '),
          };
        }
      });
      if (
        Object.keys(distance).length > 0 &&
        distance?.distance &&
        distance?.location
      ) {
        setDistanceDetails(distance);
      } else {
        setDistanceDetails(null);
      }
    }
  };
  const fetchCandidate = async () => {
    await reqGetCandidate({ id: candidateID }).then(async (res: any) => {
      // console.log({ data: res?.data?.data.data });
      const data = res?.data?.data;
      setCandidateInfo(data?.data);
      loadInterviewStatus(data?.interviews);
      loadDistanceDetails(data?.data?.jobs);
    });
  };

  const associateJobSuccess = async () => {
    await fetchCandidate();
    // await reqGetJob({ id: params.jobsId });
  };

  useEffect(() => {
    setIsGroupByJob(
      candidateOthers &&
        candidateOthers.length > 0 &&
        candidateOthers?.[0].group_by_job
    );
  }, [candidateOthers]);

  const queryParam = new URLSearchParams(search);
  const jobId =
    queryParam.get('jobId') && !isNaN(Number(queryParam.get('jobId')))
      ? Number(queryParam.get('jobId'))
      : Number(params?.jobsId) || null;

  const abortJobControllerRef = useRef<AbortController | null>(null);
  useEffect(() => {
    const load = async () => {
      // await delay(200);
      if (abortJobControllerRef.current) {
        abortJobControllerRef.current.abort();
      }

      abortJobControllerRef.current = new AbortController();
      reqGetJob({
        id: jobId,
        // signal: abortJobControllerRef.current.signal,
      }).then((res: any) => {
        // console.log({ res: res?.data?.data?.data });
        dispatch(getJob({ jobData: res?.data?.data?.data }));
      });
    };
    if (jobId) {
      load();
    } else {
      dispatch(getJob({ jobData: null }));
    }
    dispatch(
      setloadingCandidate({
        reloadByJob: { reload: false, job_id: Number(jobId) },
      })
    );
  }, [jobId]);

  useEffect(() => {
    if (
      params?.jobsId !== undefined &&
      params?.jobsId !== 'undefined' &&
      params?.jobsId !== null &&
      resGetJob.isSuccess
    ) {
      const contactOption = [
        {
          label: [jobData?.lead?.first_name, jobData?.lead?.last_name].join(
            ' '
          ),
          value: jobData?.lead?.primary_email,
        },
      ];

      setContactOptionValue(contactOption);
    }
  }, [resGetJob.isSuccess]);

  const {
    isOpen: isOpenCandidate,
    onOpen: onOpenCandiate,
    onClose: onCloseCandidate,
  } = useDisclosure();

  const {
    isOpen: isOpenTag,
    onOpen: onOpenTag,
    onClose: onCloseTag,
  } = useDisclosure();

  const {
    isOpen: isOpenAssociate,
    onOpen: onOpenAssociate,
    onClose: onCloseAssociate,
  } = useDisclosure();

  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure();

  const {
    isOpen: isOpenSubmitToClient,
    onOpen: onOpenSubmitToClient,
    onClose: onCloseSubmitToClient,
  } = useDisclosure();

  const {
    isOpen: isOpenScheduleInterview,
    onOpen: onOpenScheduleInterview,
    onClose: onCloseScheduleInterview,
  } = useDisclosure();

  const abortAttachmentControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    const api = async () => {
      // await delay(200);
      if (abortAttachmentControllerRef.current) {
        abortAttachmentControllerRef.current.abort();
      }

      abortAttachmentControllerRef.current = new AbortController();
      await reqAttachment({
        id: candidateID,
        signal: abortAttachmentControllerRef.current.signal,
      });
    };
    api();
  }, [candidateID]);

  useEffect(() => {
    const api = async () => {
      await delay(200);
      await fetchCandidate();
    };

    if (resAttachment.isSuccess) {
      api();
    }
  }, [resAttachment.isSuccess]);

  useEffect(() => {
    if (resGetCandidate.isSuccess) {
      const candidateData = resGetCandidate?.data?.data;
      if (
        (candidateData === null || candidateData === undefined) &&
        !isOpenEmail
      ) {
        const title = 'Candidate Details';
        const description = 'Candidate Not Found';
        toast({
          title: title,
          description: description,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      }
    }
  }, [resGetCandidate.isSuccess]);

  useEffect(() => {
    if (!isOpenScheduleInterview) {
      setContactOptionValue([]);
    }
  }, [isOpenScheduleInterview]);

  useEffect(() => {
    if (resDeleteCandidate.isSuccess || resToggleHide.isSuccess) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      reqCandidatesList({
        data: candidatePgBtn,
        signal: abortControllerRef.current.signal,
      });
      handleCloseDrawer();
    }
  }, [resDeleteCandidate.isSuccess, resToggleHide.isSuccess]);

  useEffect(() => {
    if (params.jobsTabId) {
      setHasFilter(false);
    } else {
      setHasFilter(true);
    }
  }, [params.jobsTabId]);

  const handleOpenDrawer = () => {
    if (candidateID) {
      return true;
    }
    return isOpen;
  };

  const associateToJob = async () => {
    let list: any = [];
    if (candidateInfo.jobs?.length > 0) {
      await Promise.all(
        candidateInfo?.jobs?.map((job: any) => {
          list.push(job.job_id);
        })
      );
    }

    setAssocJobs(list);
    onOpenAssociate();
  };

  const addTagClick = () => {
    onOpenTag();
  };

  const editCandidate = () => {
    onOpenCandiate();
  };

  const loadPintList = async () => {
    if (abortFilterControllerRef.current) {
      abortFilterControllerRef.current.abort();
    }

    abortFilterControllerRef.current = new AbortController();
    await reqPinList({
      signal: abortFilterControllerRef.current.signal,
    });
  };

  const handleDeleteCandidate = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will delete the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDeleteCandidate({ id: candidateID });
      await reqFilterList({});

      dispatch(getCandidate({ candidateData: {} }));
      dispatch(
        setloadingCandidate({
          reloadByJob: { ...reloadByJob, reload: true },
        })
      );
    }
  };

  const handleHideCandidate = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will hide the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqToggleHide({ id: candidateID });
      dispatch(getCandidate({ candidateData: {} }));
      dispatch(
        setloadingCandidate({
          reloadByJob: { ...reloadByJob, reload: true },
        })
      );
    }
  };

  const callBackAction = async () => {
    await fetchCandidate();
  };

  const spanRef = useRef<HTMLSpanElement | null>(null);
  const [inputWidth, setInputWidth] = useState<number | 'auto'>(
    spanRef.current?.offsetWidth
  );
  const newInputWidth = spanRef.current?.offsetWidth;

  useEffect(() => {
    if (inputWidth !== newInputWidth) {
      setInputWidth(newInputWidth);
    }
  }, [inputWidth, newInputWidth]);

  useEffect(() => {
    if (!inputWidth) {
      setInputWidth(newInputWidth);
    }
  }, [inputWidth, newInputWidth]);

  useEffect(() => {
    if (jobId) {
      dispatch(getCandidate({ candidateDataJobId: jobId }));
    }
  }, [jobId]);

  const handleCloseDrawer = () => {
    onClose();
    let nav = [''];
    if (params['*'].includes('in-progress')) {
      nav = [
        params.jobsId,
        params.jobsTab,
        params.jobsTabId,
        params.jobsTabNav,
      ];
    }
    if (!params?.candidatesTabState && params['*'].includes('search')) {
      nav = [params.jobsId, params.jobsTab, params.jobsTabId];
    }

    if (params?.candidatesTabState) {
      nav = [params.candidatesTabState];
      if (params.candidatesTabState === 'views') {
        loadPintList();
      }
    }

    navigate(`${nav.filter(Boolean).join('/')}`);

    dispatch(getCandidate({ candidateData: {}, candidateDataJobId: null }));
    dispatch(getResume({ latest: {} }));
    dispatch(getResume({ resumes: [] }));
  };

  const [drawerJobDetails, setDrawerJobDetails] = useState({
    jobTitle: undefined,
    jobLink: '',
    clientTitle: undefined,
    clientLink: '',
  });

  useEffect(() => {
    if (jobData) {
      setDrawerJobDetails({
        jobTitle: jobData?.title || '',
        jobLink: `/jobs/${jobData?.id}/details`,
        clientTitle: jobData?.client?.name || '',
        clientLink: `/clients/all-clients/${jobData?.client?.id}/overview`,
      });
    }
  }, [jobData, candidateData]);

  const [prevJobDetails, setPrevJobDetails] = useState(drawerJobDetails);

  const [isMoved, setIsMoved] = useState(false);

  const [prevCandidatesTab, setPrevCandidatesTab] = useState(
    params.candidatesTab
  );

  useEffect(() => {
    if (
      isGroupByJob &&
      prevJobDetails.jobTitle !== undefined &&
      drawerJobDetails.jobTitle !== undefined &&
      resGetCandidate.isSuccess &&
      prevJobDetails.jobTitle !== drawerJobDetails.jobTitle &&
      prevCandidatesTab === params.candidatesTab
    )
      setIsMoved(!(prevJobDetails.jobTitle === drawerJobDetails.jobTitle));

    const timeoutId = setTimeout(() => {
      setIsMoved(false);
    }, 3000);

    // Clean up the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [
    drawerJobDetails,
    prevJobDetails,
    isGroupByJob,
    resGetCandidate.isSuccess,
  ]);
  // }, []);

  useEffect(() => {
    const jobDetails =
      (candidateDataJobId || jobId) &&
      candidateInfo?.jobs?.filter(
        (job: any) =>
          job.job_id === (candidateDataJobId || jobId || jobData?.id)
      )[0];

    if (params.jobsTab) {
      setDrawerJobDetails({
        jobTitle: jobData.title,
        jobLink: `/jobs/${jobData.id}/details`,
        clientTitle: jobData.client?.name,
        clientLink: `/clients/all-clients/${jobData.client?.id}/overview`,
      });
    } else if ((params.candidatesTab && isGroupByJob) || jobId) {
      setDrawerJobDetails({
        jobTitle: jobDetails && jobDetails?.job?.title,
        jobLink: `/jobs/${jobDetails?.job?.id}/details`,
        clientTitle: jobDetails && jobDetails?.job?.client?.name,
        clientLink: `/clients/all-clients/${jobDetails?.job?.client?.id}/overview`,
      });
    }
  }, [
    params.jobsTab,
    params.candidatesTab,
    isGroupByJob,
    jobId,
    candidateDataJobId,
    jobData,
    candidateInfo,
  ]);

  const handleNextDataAction = async () => {
    // console.log('next');
    setDistanceDetails(null);
    handleNextData();
    setPrevJobDetails(drawerJobDetails);
    setPrevCandidatesTab(params.candidatesTab);
  };

  return (
    <>
      {candidateInfo && (
        <Drawer
          onClose={handleCloseDrawer}
          isOpen={handleOpenDrawer()}
          size="xl"
        >
          <DrawerOverlay top="60px" />
          <DrawerContent
            maxW="95vw"
            top="62px! important"
            fontFamily="NunitoSans Regular"
            position="relative"
            onClick={() => setIsMoved(false)}
          >
            <DrawerCloseButton
              position="absolute"
              top="0px"
              left="-40px"
              borderRadius="unset"
              p="20px"
              bg="#E6E9EE"
              color="default.secondarytext"
              _hover={{
                backgroundColor: 'none',
              }}
            />
            {isMoved && (
              <Flex
                sx={{
                  zIndex: 999999999999,
                  position: 'absolute',
                  top: '15vh',
                  left: 0,
                  right: 0,
                  width: 'fit-content',
                  margin: '0 auto',
                  fontSize: '20px',
                }}
              >
                <Alert
                  status="success"
                  colorScheme="purple"
                  sx={{
                    borderRadius: '10px',
                    p: '20px',
                  }}
                >
                  <AlertIcon />
                  <Box>
                    Done{' '}
                    <Box as="span">
                      <Link
                        href={prevJobDetails.jobLink}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ fontSize: '20px' }}
                      >
                        {prevJobDetails.jobTitle}
                      </Link>{' '}
                      -{' '}
                      <Link
                        href={prevJobDetails.clientLink}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ fontSize: '20px' }}
                      >
                        {prevJobDetails.clientTitle}
                      </Link>
                    </Box>
                    . Moving to{' '}
                    <Box as="span">
                      <Link
                        href={drawerJobDetails.jobLink}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ fontSize: '20px' }}
                      >
                        {drawerJobDetails.jobTitle}
                      </Link>{' '}
                      -{' '}
                      <Link
                        href={drawerJobDetails.clientLink}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ fontSize: '20px' }}
                      >
                        {drawerJobDetails.clientTitle}
                      </Link>
                    </Box>
                  </Box>
                </Alert>
              </Flex>
            )}
            <Flex
              justifyContent="space-between"
              alignItems="center"
              gap="18px"
              pl={4}
              pr={0}
              borderBottom="1px solid #E6E9EE"
              fontSize="14px"
              sx={{ filter: isMoved && 'blur(2px)' }}
            >
              <Box
                id={`tagTooltip`}
                color="primary.600"
                fontSize="12px"
                lineHeight="18px"
                fontWeight={500}
                height="fit-content"
              >
                {drawerJobDetails?.jobTitle && (
                  <Flex gap="5px">
                    <Link
                      href={drawerJobDetails.jobLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {drawerJobDetails.jobTitle}
                    </Link>
                    -
                    <Link
                      href={drawerJobDetails.clientLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {drawerJobDetails.clientTitle}
                    </Link>
                  </Flex>
                )}
              </Box>
              <Flex alignItems="center">
                {rowId > 0 ? (
                  <>
                    <Flex gap="8px" mr="12px">
                      Showing{' '}
                      <b>
                        {params.candidatesId
                          ? Number(urlQuery?.get('rowId'))
                          : rowId}
                      </b>{' '}
                      of <b>{totalCandidates}</b>
                    </Flex>
                    <Flex>
                      <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                        <Button
                          iconName="chevron-left"
                          variant="ghost"
                          onClick={() =>
                            // rowId !== 1 &&  //temporary commenting for the candidate groups
                            {
                              handlePrevData(candidateInfo);
                              setPrevJobDetails(drawerJobDetails);
                              setPrevCandidatesTab(params.candidatesTab);
                            }
                          }
                          disabled={rowId === 1}
                        />
                      </Box>

                      <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                        <Button
                          iconName="chevron-right"
                          variant="ghost"
                          onClick={() =>
                            // rowId !== totalCandidates && //temporary commenting for the candidate groups
                            {
                              handleNextDataAction();
                            }
                          }
                          disabled={rowId === totalCandidates}
                          borderLeft="1px solid #6B6C7B"
                          borderRadius="unset"
                        />
                      </Box>
                    </Flex>
                  </>
                ) : (
                  <Flex height="41.11px"></Flex>
                )}
              </Flex>
            </Flex>
            <DrawerBody
              p="0"
              overflow="hidden"
              sx={{ filter: isMoved && 'blur(2px)' }}
            >
              {(isLoading || resGetCandidate.isLoading) && <LoadingPage />}
              <Box
                bg="default.white.800"
                // borderBottom="1px solid"
                // borderColor="default.white.400"
                height="auto"
                pt="19px"
                px="20px"
                pb="30px"
              >
                <Flex justifyContent="space-between" mb="20px">
                  <Flex
                    key={candidateInfo.id}
                    gap="6px"
                    flexDir="column"
                    fontSize="18px"
                    lineHeight="21.92px"
                    fontWeight="700"
                    color="default.primarytext"
                  >
                    <Flex mb="2px" gap="5px">
                      <DynamicInputWidthForm
                        valueKey="first_name"
                        candidateData={candidateData}
                        reqUpdate={reqFirstNameUpdate}
                        resUpdate={resFirstNameUpdate}
                        reqCandidatesList={reqCandidatesList}
                        candidatePgBtn={candidatePgBtn}
                        placeholder="First Name"
                        isMain
                      />
                      <DynamicInputWidthForm
                        valueKey="last_name"
                        candidateData={candidateData}
                        reqUpdate={reqLastNameUpdate}
                        resUpdate={resLastNameUpdate}
                        reqCandidatesList={reqCandidatesList}
                        candidatePgBtn={candidatePgBtn}
                        placeholder="Last Name"
                        isMain
                      />
                      {interviewStatus && (
                        <Flex
                          borderRadius="2px"
                          bg="#2B2D421A"
                          px="2"
                          fontWeight="normal"
                          fontSize="14px"
                          color="#2B2D42"
                          h="fit-content"
                          // p="1"
                        >
                          {interviewStatus}
                        </Flex>
                      )}
                    </Flex>
                    <Flex
                      sx={{
                        gap: '8px',
                        alignItems: 'center',
                        fontSize: '14px',
                        lineHeight: '17.05px',
                      }}
                    >
                      <DynamicInputWidthForm
                        valueKey="job_title"
                        candidateData={{
                          ...candidateData,
                          job_title: candidateData?.latest_job_title,
                        }}
                        reqUpdate={reqJobTitleUpdate}
                        resUpdate={resJobTitleUpdate}
                        reqCandidatesList={reqCandidatesList}
                        candidatePgBtn={candidatePgBtn}
                        placeholder="Job Title"
                      />
                      <Box fontSize="18px" color="default.gray.600">
                        |
                      </Box>
                      <DynamicInputWidthForm
                        valueKey="company"
                        candidateData={{
                          ...candidateData,
                          company: candidateData?.latest_employer,
                        }}
                        reqUpdate={reqCompanyUpdate}
                        resUpdate={resCompanyUpdate}
                        reqCandidatesList={reqCandidatesList}
                        candidatePgBtn={candidatePgBtn}
                        placeholder="Company"
                      />
                      {distanceDetails &&
                        distanceDetails?.distance &&
                        distanceDetails?.location && (
                          // Object.keys(distanceDetails).length > 0 && (
                          <Flex color="default.gray.1000" gap="5px">
                            <Box fontSize="18px" color="default.gray.600">
                              |
                            </Box>
                            <img
                              src={mapPin}
                              alt="Closemark Circle"
                              style={{
                                width: '14px',
                                height: '14px',
                                objectFit: 'contain',
                              }}
                            />
                            <Text>
                              {/* Round up distance */}
                              {Math.ceil(distanceDetails?.distance) || ''} miles
                              from {distanceDetails?.location || ''}
                            </Text>
                          </Flex>
                        )}
                    </Flex>
                  </Flex>
                  <Flex alignItems="center" gap={'15px'}>
                    {/* <Flex gap={15}> */}
                    <ActionButton
                      rowId={rowId}
                      setRowId={setRowId}
                      totalCandidates={totalCandidates}
                      handleNextData={handleNextDataAction}
                      onClose={handleCloseDrawer}
                      isLoadingDrawer={isLoading || resGetCandidate.isLoading}
                      candidates={candidates}
                      candidateId={candidateID}
                    />
                    {/* </Flex> */}
                    {/* <Box cursor="pointer">
                      <Link
                        href={`tel:${primaryContact?.number}`}
                        color="rgba(132, 87, 229, 0.8);"
                        sx={{
                          '.fa-sm': {
                            fontSize: '18px',
                          },
                        }}
                      >
                        <FAIcon iconName="phone" />
                      </Link>
                    </Box>
                    <Box
                      color="rgba(132, 87, 229, 0.8);"
                      cursor="pointer"
                      onClick={openSendEmailClick}
                      sx={{
                        '.fa-sm': {
                          fontSize: '18px',
                        },
                      }}
                    >
                      <FAIcon iconName="envelope" />
                    </Box>
                    <Box
                      cursor="pointer"
                      color="rgba(132, 87, 229, 0.8);"
                      sx={{
                        '.fa-sm': {
                          fontSize: '18px',
                        },
                      }}
                    >
                      <FAIcon iconName="comment" />
                    </Box> */}
                    <Menu closeOnSelect={false}>
                      <MenuButton>
                        <Box
                          sx={{
                            button: {
                              background: 'none',
                              radius: '6px',
                              fontSize: '18px',
                              letterSpacing: '18px',
                              fontWeight: 600,
                              lineHeight: '16px',
                              color: 'primary.600',
                            },
                          }}
                        >
                          {/* <Button rightIcon="chevron-down">Actions</Button> */}
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </Box>
                      </MenuButton>
                      <MenuList fontSize="sm">
                        <MenuItem onClick={() => associateToJob()}>
                          Associate to Job
                        </MenuItem>
                        <MenuItem onClick={() => addTagClick()}>
                          Add Tags
                        </MenuItem>
                        <MenuItem onClick={() => editCandidate()}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleHideCandidate()}>
                          Hide
                        </MenuItem>
                        <MenuItem onClick={() => handleDeleteCandidate()}>
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                </Flex>
              </Box>
              <Box
                mt="-39px"
                flex="1"
                display="flex"
                flexDirection="column"
                height="100%"
              >
                <CandidatesTabs
                  candidateData={candidateInfo}
                  isRefresh={isRefresh}
                />
              </Box>
              {isOpenTag && (
                <CandidatesTagModal
                  isOpen={isOpenTag}
                  onClose={onCloseTag}
                  id={candidateID}
                />
              )}
              {isOpenAssociate && (
                <CandidatesAssociateJobModal
                  isOpen={isOpenAssociate}
                  onClose={onCloseAssociate}
                  id={candidateID}
                  assoc_jobs={assocJobs}
                  onSuccess={() => associateJobSuccess()}
                />
              )}
              {isOpenCandidate && (
                <CandidatesModal
                  isOpen={isOpenCandidate}
                  onClose={onCloseCandidate}
                  edit={true}
                  id={Number(candidateID)}
                  fromDrawer={true}
                />
              )}
              {isOpenSubmitToClient && (
                <SubmitToClientModal
                  isOpen={isOpenSubmitToClient}
                  onClose={onCloseSubmitToClient}
                  candidate={candidateInfo}
                  callback={callBackAction}
                />
              )}
              {isOpenScheduleInterview && (
                <CandidatesInterviewModal
                  isOpen={isOpenScheduleInterview}
                  onClose={onCloseScheduleInterview}
                  callback={callBackAction}
                  contactOptionValue={contactOptionValue}
                  isEdit={false}
                />
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default CandidatesDrawer;
