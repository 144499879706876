import { useDisclosure } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useListLeadJobsMutation, listJobs } from 'store/jobs.slice';

export const useJobsServices = () => {
  const dispatch = useDispatch();
  const UrlParams = useParams();

  const { leadDetails } = useSelector((state: any) => state.leads);
  const { jobPgBtn, jobs, jobPage } = useSelector((state: any) => state.jobs);

  const [reqList, resList] = useListLeadJobsMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isOpenCheck, setOpenCheck] = useState(false);
  const [isCloseCheck, setCloseCheck] = useState(false);
  const [isQuickJobCheck, setQuickJobCheck] = useState(false);
  const [isAssignCheck, setAssignCheck] = useState(false);
  const [defCompany, setDefCompany] = useState({});
  const [defContact, setDefContact] = useState({});

  useEffect(() => {
    reqList({ data: jobPgBtn, id: UrlParams.leadsId });
  }, [UrlParams.leadsId]);

  useEffect(() => {
    setDefCompany({
      label: leadDetails?.lead?.client?.name,
      value: leadDetails?.client?.client_id,
    });
    setDefContact({
      label: [leadDetails?.lead?.first_name, leadDetails?.lead?.last_name].join(
        ' '
      ),
      value: leadDetails?.lead?.id,
    });
  }, [leadDetails]);

  const submitForm = (params: any) => {
    dispatch(
      listJobs({
        jobPgBtn: params,
      })
    );
    reqList({ data: params, id: UrlParams.leadsId });
  };

  const filterSubmit = (params: any) => {
    submitForm(params);
  };

  const initialValues = {
    search: '',
  };

  const pageChange = async (page: number) => {
    let param = {
      page: page,
      take: jobPgBtn.take,
      query: jobPgBtn.query,
      closed: jobPgBtn.closed,
      open: jobPgBtn.open,
      quickJobs: jobPgBtn.quickJobs,
      job_assigned: jobPgBtn.job_assigned,
      job_ads: jobPgBtn.job_ads,
    };
    submitForm(param);
  };

  const onSubmit = (data: any) => {
    let param = {
      query: data.search,
      page: jobPgBtn.page,
      take: jobPgBtn.take,
      closed: jobPgBtn.closed,
      open: jobPgBtn.open,
      quickJobs: jobPgBtn.quickJobs,
      job_assigned: jobPgBtn.job_assigned,
      job_ads: jobPgBtn.job_ads,
    };
    submitForm(param);
  };

  const entryChange = (entries: number) => {
    let param = {
      page: 1,
      take: entries,
      query: jobPgBtn.query,
      closed: jobPgBtn.closed,
      open: jobPgBtn.open,
      quickJobs: jobPgBtn.quickJobs,
      job_assigned: jobPgBtn.job_assigned,
      job_ads: jobPgBtn.job_ads,
    };
    submitForm(param);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let param = {
        query: '',
        page: jobPgBtn.page,
        take: jobPgBtn.take,
        closed: jobPgBtn.closed,
        open: jobPgBtn.open,
        quickJobs: jobPgBtn.quickJobs,
        job_assigned: jobPgBtn.job_assigned,
        job_ads: jobPgBtn.job_ads,
      };
      submitForm(param);
    }
  };

  return {
    initialValues,
    onSubmit,
    clearSearch,
    isOpenCheck,
    setOpenCheck,
    jobPgBtn,
    filterSubmit,
    isCloseCheck,
    isQuickJobCheck,
    isAssignCheck,
    setCloseCheck,
    setQuickJobCheck,
    setAssignCheck,
    onOpen,
    isOpen,
    onClose,
    defCompany,
    defContact,
    resList,
    jobs,
    jobPage,
    pageChange,
    entryChange,
  };
};
