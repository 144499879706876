import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Switch,
  Checkbox,
  Image,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  getClient,
  listClients,
  useAllListClientsMutation,
  useToggleActiveClientMutation,
  useToggleMVPClientMutation,
  useViewClientMutation,
} from 'store/client.slice';
import FAIcon from '../../../lib/FAIcon';
import { Formik } from 'formik';
import Pagination from 'Library/Pagination';
import { BsStar, BsStarFill } from 'react-icons/bs';
import AllClientsDrawer from '.';
import { filterVariables } from 'constants/fileVariables';
import ProfileCard from 'components/profile-card.component';
import SearchClient from '../../../../assets/images/search-client.svg';
import TrLoading from 'components/app/TrLoading';

export default function AllClientsTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const stateTab: any = params.clientsTab;

  const { clientNav, allClient, allClientPage, allClientPageBtn } = useSelector(
    (state: any) => state.clients
  );

  const [reqAllList, resAllList] = useAllListClientsMutation();
  const [reqMvp] = useToggleMVPClientMutation();
  const [reqActive] = useToggleActiveClientMutation();
  const [filterCheck] = useState(filterVariables);
  const [typeIsChecked, setTypeChecked] = useState([]);

  let [mvpList, setMvpList] = useState([]);

  const [rowId, setRowId] = useState(1);

  document.documentElement.dir = 'ltr';

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    try {
      let list: any = [];

      allClient.map(async (item: any) => {
        list.push({
          id: item.id,
          name: item.name,
          created_at: item.created_at,
          totalOpening: item.totalOpening,
          manager: [
            item.manager[0]?.user?.first_name,
            item.manager[0]?.user?.last_name,
          ].join(' '),
          manager_id: item.manager[0]?.user?.id,
          totalPlaced: item.totalPlaced,
          is_mvp: item.is_mvp,
          is_active: item.is_active === 1 ? true : false,
          data: item,
        });
      });
      setMvpList(list);
    } catch (e) {
      console.log('e', e);
    }
  }, [resAllList.isSuccess, allClient]);

  useEffect(() => {
    const clientID =
      allClient.findIndex((x: any) => x.id === Number(params.clientsId)) + 1;
    setRowId(clientID);
  }, [allClient, params.clientsId]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchAll = async (param: any) => {
    if (param?.query) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      reqAllList({ data: param, signal: abortControllerRef.current.signal });
    } else {
      let newParam = {
        ...allClientPageBtn,
        query: '',
        page: allClientPageBtn.page,
        take: allClientPageBtn.take,
        filters: allClientPageBtn.filters,
      };

      dispatch(
        listClients({
          allClientPageBtn: newParam,
        })
      );
    }
  };

  // INITIALIZE ON OPEN CANDIDATES TABLE
  // useEffect(() => {
  //   if (params.tab === 'all-clients') {
  //     fetchAll(allClientPageBtn);
  //   }
  // }, [params.tab]);

  useEffect(() => {
    if (params.tab === 'all-clients' && allClientPageBtn.filters) {
      setTypeChecked(allClientPageBtn.filters);
    }
  }, [clientNav, allClientPageBtn.filters]);

  useEffect(() => {
    if (
      (params.tab === 'all-clients' && stateTab && params.clientsId) ||
      params.clientsTabId === 'all-clients'
    ) {
      onOpen();

      const allClientsFilter: string | null =
        localStorage.getItem('AllClientsFilter');
      const initFilter =
        allClientsFilter == null
          ? allClientPageBtn
          : JSON.parse(allClientsFilter);

      dispatch(
        listClients({
          allClientPageBtn: initFilter,
        })
      );
      setTypeChecked(initFilter.filters);
    }
  }, [params.clientsId, clientNav, stateTab]);

  const handleClick = (client: any) => {
    dispatch(getClient({ clientData: client }));

    onOpen();
    navigate(`/clients/${params.tab}/${client.id}/overview`);

    const key = allClient.findIndex((x: any) => x.id === client.id) + 1;
    setRowId(key);
  };

  const handlePrevData = () => {
    const clientID = allClient[rowId - 1 - 1].id;
    setRowId((prevKey: number) => prevKey - 1);
    navigate(`/clients/${params.tab}/${clientID}/${stateTab}`);
    const prevData = allClient.filter((data: any) => data.id === clientID);
    dispatch(getClient({ clientData: prevData[0] }));
  };

  const handleNextData = () => {
    const row_id = rowId === 0 ? 1 : rowId;
    const clientID = allClient[row_id - 1 + 1].id;
    setRowId((prevKey: number) => prevKey + 1);
    navigate(`/clients/${params.tab}/${clientID}/${stateTab}`);
    const nextData = allClient.filter((data: any) => data.id === clientID);
    dispatch(getClient({ clientData: nextData[0] }));
  };

  const tableHeader: { label: string }[] = [
    { label: 'Client' },
    { label: 'Added On' },
    { label: 'Account Manager' },
    { label: 'Active Jobs' },
    { label: 'Placements' },
    { label: 'Last Activity' },
    { label: '' },
    { label: '' },
  ];

  const pageChange = async (page: number) => {
    let newParam = {
      ...allClientPageBtn,
      page: page,
    };

    dispatch(
      listClients({
        allClientPageBtn: newParam,
      })
    );
    fetchAll(newParam);
  };

  const initialValues = {
    search: allClientPageBtn?.query || '',
  };

  const [hasSearch, setHasSearch] = useState('');

  const onSubmit = (data: any) => {
    setHasSearch(data.search);
    let newParam = {
      ...allClientPageBtn,
      query: data.search,
    };

    dispatch(
      listClients({
        allClientPageBtn: newParam,
      })
    );
    fetchAll(newParam);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      setHasSearch('');
      setMvpList([]);

      let newParam = {
        ...allClientPageBtn,
        query: '',
        page: allClientPageBtn.page,
        take: allClientPageBtn.take,
        filters: allClientPageBtn.filters,
      };
      dispatch(
        listClients({
          allClient: [],
          allClientPageBtn: newParam,
        })
      );
    }
  };

  const mvpToggle = async (id: number, is_mvp: number, key: number) => {
    let mvp = is_mvp === 0 ? 1 : 0;
    mvpList[key].is_mvp = mvp;
    await setMvpList(mvpList);
    reqMvp({ data: { is_mvp: mvp }, id: id });
  };

  const activeToggle = async (id: number, key: number, e: any) => {
    const checked = e.target.checked;
    let active = checked ? 1 : 0;
    mvpList[key].is_active = active;
    await setMvpList(mvpList);
    reqActive({ data: { is_active: active }, id: id });
  };

  const handleCheckTypes = (e: any) => {
    let newParam = {
      ...allClientPageBtn,
      query: allClientPageBtn.query || '',
      filters: typeIsChecked,
    };
    const { id, checked } = e.target;
    setTypeChecked([...typeIsChecked, id]);
    newParam.filters = [...typeIsChecked, id];

    if (!checked) {
      const isChecked = typeIsChecked.filter((item: number) => item !== id);
      setTypeChecked(isChecked);
      newParam.filters = isChecked;
    }
    dispatch(
      listClients({
        clientPageBtn: newParam,
      })
    );
    fetchAll(newParam);
    localStorage.setItem('AllClientsFilter', JSON.stringify(newParam));
  };

  const entryChange = (entries: number) => {
    let newParam = {
      ...allClientPageBtn,
      page: 1,
      take: entries,
      query: allClientPageBtn.query,
    };

    dispatch(
      listClients({
        allClientPageBtn: newParam,
      })
    );
    fetchAll(newParam);
  };

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="flex-start" py={2} px={6}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Box pr={2}>
                <InputGroup background="#fff">
                  <InputRightElement
                    cursor="pointer"
                    children={<FAIcon iconName="search" />}
                  />
                  <Input
                    type="search"
                    placeholder="Search a client"
                    id="search"
                    value={values.search}
                    onChange={(e) => {
                      handleChange(e);
                      clearSearch(e);
                    }}
                  />
                </InputGroup>
              </Box>
            </form>
          )}
        </Formik>

        <Flex px={2} justifyContent="flex-start">
          {filterCheck.map((item: any, key: number) => {
            return (
              <Checkbox
                key={key}
                colorScheme="purple"
                color="default.secondarytext"
                mx={1}
                onChange={handleCheckTypes}
                id={item.id}
                marginRight={5}
                isChecked={typeIsChecked.includes(item.id)}
              >
                {item.label}
              </Checkbox>
            );
          })}
        </Flex>
      </Flex>

      <Box px={6} py={4} justifyContent="space-between">
        {resAllList.isLoading ? (
          <Box px={6} py={4} justifyContent="space-between">
            <TableContainer
              boxSizing="border-box"
              border="1px solid"
              borderColor="default.white.400"
              borderRadius="md"
              height="65vh"
              sx={{ overflowY: 'scroll' }}
            >
              <Table>
                <Thead height="40.5px">
                  <Tr bg="default.white.600">
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody
                  boxSizing="border-box"
                  background="default.white.100"
                  borderBottom="1px solid"
                  borderColor="default.white.400"
                >
                  <TrLoading rows={7} columns={5} />
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <TableContainer
            boxSizing="border-box"
            border="1px solid"
            borderColor="default.white.400"
            borderRadius="md"
            height="65vh"
            sx={{ overflowY: 'scroll' }}
          >
            <Table>
              <Thead>
                <Tr bg="default.white.600">
                  {tableHeader.map((title, i) => (
                    <Th key={title.label + i}>
                      <Box color="default.gray.600">{title.label}</Box>
                    </Th>
                  ))}
                </Tr>
              </Thead>

              <Tbody
                boxSizing="border-box"
                background="default.white.100"
                borderBottom="1px solid"
                borderColor="default.white.400"
              >
                {mvpList.length > 0 ? (
                  mvpList?.map((client: any, key: number) => {
                    return (
                      <Tr
                        key={client.id}
                        _hover={{
                          bg: '#f8f9fa',
                        }}
                        cursor="pointer"
                      >
                        <Td onClick={() => handleClick(client.data)}>
                          <Box fontWeight="bold"> {client.name}</Box>
                        </Td>
                        <Td onClick={() => handleClick(client.data)}>
                          <Box>
                            {moment.utc(client.created_at).format('MM/DD/YYYY')}
                          </Box>
                        </Td>
                        <Td>
                          <ProfileCard
                            id={client.id}
                            user_id={client.data?.manager[0]?.user?.id}
                            name={client.manager}
                            email={client.data?.manager[0]?.user?.email}
                            thumbnail={
                              client.data?.manager[0]?.user?.thumbnailSignedUrl
                            }
                            profile={client.data?.manager[0]?.signedUrl}
                          />
                        </Td>
                        <Td
                          textAlign="center"
                          onClick={() => handleClick(client.data)}
                        >
                          {client.totalOpening}
                        </Td>
                        <Td
                          textAlign="center"
                          onClick={() => handleClick(client.data)}
                        >
                          {client.totalPlaced}
                        </Td>
                        <Td onClick={() => handleClick(client.data)}>
                          acitivity here
                        </Td>
                        <Td>
                          <Box
                            onClick={() =>
                              mvpToggle(client.id, client.is_mvp, key)
                            }
                          >
                            {client.is_mvp === 1 ? (
                              <Icon as={BsStarFill} color="yellow.400" />
                            ) : (
                              <Icon as={BsStar} />
                            )}
                          </Box>
                        </Td>
                        <Td>
                          <Box className={client.is_active.toString()}>
                            <Switch
                              colorScheme="purple"
                              defaultChecked={client.is_active}
                              onChange={(e) => activeToggle(client.id, key, e)}
                            />
                          </Box>
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Flex
                    position="absolute"
                    mt="100px"
                    height="223px"
                    width="90%"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Image src={SearchClient} />
                    <Box textAlign="center">
                      {hasSearch === '' ? (
                        <>
                          <Text
                            fontSize="20px"
                            fontWeight="500"
                            color="default.primarytext"
                            mt="17px"
                          >
                            Search To See Client Here
                          </Text>
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="default.secondarytext"
                            mt="14px"
                          >
                            Use the search to find client by their name
                          </Text>
                        </>
                      ) : (
                        <Text
                          fontSize="20px"
                          fontWeight="500"
                          color="default.primarytext"
                          mt="17px"
                        >
                          No results found
                        </Text>
                      )}
                    </Box>
                  </Flex>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Pagination
          totalPages={allClientPage.lastPage}
          currentPage={allClientPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          totalEntries={allClientPage.count}
          currentCount={allClient?.length}
          onEntryChange={(entries) => entryChange(entries)}
          targetCount={allClientPageBtn.take}
        />
      </Box>
      {isOpen && (
        <AllClientsDrawer
          isOpen={isOpen}
          onClose={onClose}
          rowId={rowId}
          totalClients={allClient?.length}
          handlePrevData={handlePrevData}
          handleNextData={handleNextData}
        />
      )}
    </Box>
  );
}
