import { Box, Text, Flex } from "@chakra-ui/react";

interface teamMemberInt {
    first_name: string;
    last_name: string;
    user_id: number;
    signedUrl: string;
}
interface teamMember {
    first_name: string;
    last_name: string;
    user_id: number;
    signedUrl: string;
    user: any;
    thumbnailSignedUrl: string;
    role: string;
}
interface SwitchAccountProps {
    member: teamMemberInt;
    handleSwitchAccount: (member: teamMember) => void;
}

export default function SwitchAccount({ member, handleSwitchAccount }: SwitchAccountProps) {
    return (
        <Flex position="absolute" w="100%" h="100%" justifyContent="center" alignItems="center" top="13px" zIndex="99999999">
            <Flex h="fit-content" zIndex="99999999">
                <Flex px="3" py="1" bg="#AE8FE2" h="fit-content">
                    <Text fontSize="sm" h="fit-content" color="primarytext" fontWeight="bold">You are viewing {[member.first_name, member.last_name].filter(Boolean).join(" ")}'s account</Text>
                </Flex>
                <Flex px="3" py="1" bg="primary.800" cursor="pointer" h="fit-content" onClick={() => handleSwitchAccount(null)}>
                    <Text fontSize="sm" h="fit-content" color="white" fontWeight="bold">Switch to your account</Text>
                </Flex>
            </Flex >
        </Flex >
    )
}