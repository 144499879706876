import { Formik, Field, FieldArray } from 'formik';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Switch,
  CloseButton,
  ModalBody,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  MenuDivider,
  Textarea,
  ModalFooter,
  Text,
} from '@chakra-ui/react';

import AtsSelect from 'components/app/AtsSelect';
import AtsSelect2 from 'components/app/AtsSelect2';
import AtsDropZone from 'components/app/Dropzone';
import ScrollToFieldError from 'components/app/ScrollError';
import AddressComponent from 'components/app/Leads/components/AddressComponent';

import { LeadsConvertClientModalProps } from './types';
import { useConvertClientServices } from './useConvertClientServices';
import Button from 'Library/Button';

export const LeadsConvertClientModal = ({
  isOpen,
  onClose,
}: LeadsConvertClientModalProps) => {
  const {
    isOpenAlert,
    errorMessage,
    onCloseAlert,
    formValues,
    validationSchema,
    onSubmit,
    jobSpecsOption,
    jobSpecsDefOption,
    setJobSpecsDefOption,
    handleAddressSelection,
    countryOption,
    leadData,
    userList,
    leadSrouceList,
    selectOption,
    uploading,
  } = useConvertClientServices({ isOpen, onClose });
  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => onClose()}
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Request to Convert
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        {isOpenAlert && (
          <Box>
            <Alert status="error" justifyContent="space-around">
              <AlertIcon />
              <Box>
                <AlertTitle>Oh snap!</AlertTitle>
                <AlertDescription>{errorMessage}</AlertDescription>
              </Box>
              <CloseButton
                position="relative"
                right={-1}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
            }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box p="32px">
                  <Flex gap="32px" mb="34px">
                    <FormControl
                      isInvalid={Boolean(
                        !!errors.client_name && touched.client_name
                      )}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Client Name{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </FormLabel>
                      <Field
                        as={Input}
                        id="client_name"
                        name="client_name"
                        type="text"
                        placeholder="Client Name"
                        variant="outline"
                        value={values.client_name}
                      />
                      <FormErrorMessage>
                        {String(errors.client_name)}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={Boolean(!!errors.website && touched.website)}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Website{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </FormLabel>
                      <Field
                        as={Input}
                        id="website"
                        name="website"
                        type="text"
                        placeholder="Website"
                        variant="outline"
                        value={values.website}
                      />
                      <FormErrorMessage>
                        {String(errors.website)}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Flex gap="32px" mb="34px">
                    <FormControl
                      isInvalid={Boolean(
                        !!errors.specification_id && touched.specification_id
                      )}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Industry{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </FormLabel>

                      <Field
                        name="specification_id"
                        options={jobSpecsOption}
                        component={AtsSelect}
                        placeholder="Select"
                        value={jobSpecsDefOption}
                        onChange={(e: any) => {
                          setJobSpecsDefOption(e);
                          setFieldValue('specification_id', String(e.value));
                        }}
                      />
                      {/* <AtsSelect
                    id="specification_id"
                    name="specification_id"
                    placeholder="Select"
                    variant="outline"
                    onChange={(e: any) =>
                      setFieldValue('specification_id', String(e.value))
                    }
                    value={ 
                      (job_specs?.length > 0 &&
                        job_specs
                          .map((item: any) => ({
                            label: item.specification,
                            value: item.id,
                          }))
                          .filter(
                            (val: any) =>
                              val.value === values.specification_id
                          )[0]) ||
                      values.specification_id
                    }
                    options={
                      job_specs?.length > 0 &&
                      job_specs.map((item: any) => ({
                        label: item.specification,
                        value: item.id,
                      }))
                    }
                  /> */}
                      <FormErrorMessage>
                        {String(errors.specification_id)}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>

                  <Box mb="34px">
                    <Text fontWeight={600} mb="34px">
                      Address Information{' '}
                      <Box as="span" color="caution.800">
                        *
                      </Box>
                    </Text>
                    <FormControl
                      isInvalid={Boolean(!!errors.address && touched.address)}
                    >
                      <FieldArray name="address">
                        {({ remove, push }) => (
                          <div>
                            {values.address &&
                              values.address.length > 0 &&
                              values.address.map((job: any, index: number) => (
                                <Accordion
                                  defaultIndex={[index]}
                                  allowMultiple
                                  key={`jobAdd-${index}`}
                                >
                                  <AccordionItem>
                                    <Flex position="relative">
                                      <AccordionButton>
                                        <Box
                                          as="span"
                                          flex="1"
                                          textAlign="left"
                                        >
                                          <Box
                                            as="span"
                                            fontSize="14px"
                                            lineHeight="18px"
                                            fontWeight={600}
                                            color="default.primarytext"
                                            mb="8px"
                                          >
                                            {job.location
                                              ? `${job.location}${
                                                  job.primary
                                                    ? ` (Primary Address)`
                                                    : ''
                                                }`
                                              : `Address ${index + 1}`}
                                          </Box>
                                        </Box>

                                        <Box>
                                          <AccordionIcon color="#6930CA" />
                                        </Box>
                                      </AccordionButton>
                                      <Flex
                                        gap="15px"
                                        alignItems="center"
                                        position="absolute"
                                        right="55px"
                                        top="0"
                                        bottom="0"
                                      >
                                        <Field
                                          as={Switch}
                                          colorScheme="purple"
                                          name={`address.${index}.primary`}
                                          isChecked={job.primary}
                                          onChange={() =>
                                            handleAddressSelection(
                                              index,
                                              setFieldValue,
                                              values
                                            )
                                          }
                                        />
                                        <Box>
                                          <Button
                                            leftIcon="close"
                                            onClick={() => remove(index)}
                                            size="sm"
                                          >
                                            Remove
                                          </Button>
                                        </Box>
                                      </Flex>
                                    </Flex>
                                    <AccordionPanel pb={4}>
                                      <AddressComponent
                                        setFieldValue={setFieldValue}
                                        index={index}
                                        handleChange={handleChange}
                                        countryOption={countryOption}
                                        countryField={{
                                          name: `address.${index}.country`,
                                          value: job.country,
                                          errors: (errors.address as any[])?.[
                                            index
                                          ]?.country,
                                          touched: (touched.address as any[])?.[
                                            index
                                          ]?.country,
                                        }}
                                        locationField={{
                                          name: `address.${index}.location`,
                                          value: job.location,
                                          errors: (errors.address as any[])?.[
                                            index
                                          ]?.location,
                                          touched: (touched.address as any[])?.[
                                            index
                                          ]?.location,
                                        }}
                                        addressField={{
                                          name: `address.${index}.address`,
                                          value: job.address,
                                          errors: (errors.address as any[])?.[
                                            index
                                          ]?.address,
                                          touched: (touched.address as any[])?.[
                                            index
                                          ]?.address,
                                        }}
                                        cityField={{
                                          name: `address.${index}.city`,
                                          value: job.city,
                                          errors: (errors.address as any[])?.[
                                            index
                                          ]?.city,
                                          touched: (touched.address as any[])?.[
                                            index
                                          ]?.city,
                                        }}
                                        stateField={{
                                          name: `address.${index}.state`,
                                          value: job.state,
                                          errors: (errors.address as any[])?.[
                                            index
                                          ]?.state,
                                          touched: (touched.address as any[])?.[
                                            index
                                          ]?.state,
                                        }}
                                        zipField={{
                                          name: `address.${index}.zip_code`,
                                          value: job.zip_code,
                                          errors: (errors.address as any[])?.[
                                            index
                                          ]?.zip_code,
                                          touched: (touched.address as any[])?.[
                                            index
                                          ]?.zip_code,
                                        }}
                                      />
                                    </AccordionPanel>
                                  </AccordionItem>
                                </Accordion>
                              ))}
                            {!leadData.address && (
                              <Box mt="50px">
                                <Button
                                  leftIcon="plus"
                                  onClick={() => {
                                    push({
                                      location: '',
                                      address: '',
                                      city: '',
                                      state: '',
                                      country: '',
                                      zip_code: '',
                                      primary:
                                        leadData.address?.length === 0 ||
                                        values.address?.length === 0
                                          ? true
                                          : false,
                                    });
                                  }}
                                >
                                  {values.address && values.address.length > 0
                                    ? 'Add Another Address'
                                    : 'Add Address'}
                                </Button>
                              </Box>
                            )}
                          </div>
                        )}
                      </FieldArray>
                      {values.address.length === 0 && (
                        <FormErrorMessage mt="20px">
                          {String(errors.address)}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>

                  <Box>
                    <Text fontWeight={600} mb="34px">
                      Contact
                    </Text>
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.first_name && touched.first_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Contact First Name{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          as={Input}
                          id="first_name"
                          name="first_name"
                          type="text"
                          placeholder="First Name"
                          variant="outline"
                          value={values.first_name}
                        />
                        <FormErrorMessage>
                          {String(errors.first_name)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.last_name && touched.last_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Contact Last Name{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          as={Input}
                          id="last_name"
                          name="last_name"
                          type="text"
                          placeholder="Contact Last Name"
                          variant="outline"
                          value={values.last_name}
                        />
                        <FormErrorMessage>
                          {String(errors.last_name)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.personal_phone && touched.personal_phone
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Personal Phone
                        </FormLabel>
                        <Field
                          as={Input}
                          id="personal_phone"
                          name="personal_phone"
                          type="text"
                          placeholder="Phone Number"
                          variant="outline"
                          value={values.personal_phone}
                        />
                        <FormErrorMessage>
                          {String(errors.personal_phone)}
                        </FormErrorMessage>
                      </FormControl>
                      <Flex width="100%" gap="32px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.work_phone && touched.work_phone
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Work Phone
                          </FormLabel>
                          <Input
                            id="work_phone"
                            name="work_phone"
                            type="text"
                            placeholder="Work Phone"
                            variant="outline"
                            value={values.work_phone}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.work_phone)}
                          </FormErrorMessage>
                        </FormControl>
                        <Flex width="30%">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.work_phone && touched.work_phone
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Extension
                            </FormLabel>
                            <Input
                              id="extension"
                              name="extension"
                              type="number"
                              placeholder="Ext"
                              variant="outline"
                              value={values.extension}
                              onChange={(e) => {
                                setFieldValue(
                                  'extension',
                                  String(e.target.value)
                                );
                              }}
                            />
                            <FormErrorMessage>
                              {String(errors.extension)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.email && touched.email)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Email{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          as={Input}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Email"
                          variant="outline"
                          value={values.email}
                        />
                        <FormErrorMessage>
                          {String(errors.email)}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={Boolean(
                          !!errors.linkedIn_url && touched.linkedIn_url
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          LinkedIn Link
                        </FormLabel>
                        <Field
                          as={Input}
                          id="linkedIn_url"
                          name="linkedIn_url"
                          type="text"
                          placeholder="LinkedIn Link"
                          variant="outline"
                          value={values.linkedIn_url}
                        />
                        <FormErrorMessage>
                          {String(errors.linkedIn_url)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.title && touched.title)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Title{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          as={Input}
                          id="title"
                          name="title"
                          type="text"
                          placeholder="Title"
                          variant="outline"
                          value={values.title}
                        />
                        <FormErrorMessage>
                          {String(errors.title)}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={Boolean(
                          !!errors.owner_id && touched.owner_id
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Contact Owner{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <AtsSelect
                          id="owner_id"
                          name="owner_id"
                          placeholder="Select"
                          variant="outline"
                          value={
                            (Object.values(userList).length > 0 &&
                              Object.values(userList)
                                .map((item: any) => ({
                                  label: [item.first_name, item.last_name].join(
                                    ' '
                                  ),
                                  value: item.id,
                                }))
                                .filter(
                                  (val: any) =>
                                    val.value === Number(values.owner_id)
                                )[0]) ||
                            values.owner_id
                          }
                          onChange={(e: any) =>
                            setFieldValue('owner_id', String(e.value))
                          }
                          options={Object.values(userList).map((item: any) => ({
                            label: [item.first_name, item.last_name].join(' '),
                            value: item.id,
                          }))}
                        />
                        <FormErrorMessage>
                          {String(errors.owner_id)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>

                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.lead_source && touched.lead_source
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Source{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          id="lead_source"
                          name="lead_source"
                          options={leadSrouceList}
                          component={AtsSelect}
                          value={values.lead_source}
                          onChange={(e: any) => {
                            setFieldValue(`lead_source`, e);
                          }}
                        />
                        <FormErrorMessage>
                          {errors.lead_source &&
                            touched.lead_source &&
                            String((errors.lead_source as any).label)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>

                    <MenuDivider />

                    <Box my="34px">
                      <FormControl>
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Multiple account managers?
                        </FormLabel>
                      </FormControl>

                      <Flex mb="20px">
                        <FormLabel
                          display="flex"
                          gap="10px"
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          <Field
                            id="isMultipleAccountManager"
                            name="isMultipleAccountManager"
                            type="radio"
                            variant="outline"
                            value={'true'}
                          />
                          Yes
                        </FormLabel>
                        <FormLabel
                          display="flex"
                          gap="10px"
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          <Field
                            id="isMultipleAccountManager"
                            name="isMultipleAccountManager"
                            type="radio"
                            variant="outline"
                            value={'false'}
                          />
                          No
                        </FormLabel>
                      </Flex>

                      <FormControl
                        isInvalid={Boolean(!!errors.manager && touched.manager)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Account Manager{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          name="manager"
                          isMulti={
                            values.isMultipleAccountManager === 'true'
                              ? true
                              : false
                          }
                          options={selectOption}
                          component={AtsSelect2}
                          value={values.manager}
                          variant="outline"
                        />

                        <FormErrorMessage>
                          {String(errors.manager)}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box mb="34px">
                    <FormControl mb="3">
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Attachments
                      </FormLabel>
                    </FormControl>
                    <AtsDropZone
                      multipleFile={true}
                      uploadedEnd={(e: any) => {}}
                      deletedFile={(key: string) => {}}
                    />
                  </Box>
                  <Box>
                    <FormLabel
                      mb="8px"
                      fontSize="14px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      Notes
                    </FormLabel>
                    <Field
                      as={Textarea}
                      id="notes"
                      name="notes"
                      type="text"
                      placeholder="Here is a sample placeholder"
                      variant="outline"
                      value={values.notes}
                    />
                    <Text
                      mt="8px"
                      fontSize="12px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      Maximum 200 characters allowed
                    </Text>
                  </Box>
                </Box>
                <ModalFooter
                  position="sticky"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                >
                  <Button
                    variant="solid"
                    disabled={isOpenAlert || uploading}
                    type="submit"
                    onClick={() => {
                      console.log(errors);
                      handleSubmit();
                    }}
                  >
                    Convert
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
