import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  MenuItem,
  MenuList,
  Menu,
  MenuButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Link,
  Box,
  DrawerBody,
  ButtonGroup,
  Icon,
} from '@chakra-ui/react';
import { BsLinkedin, BsEnvelope, BsTelephone } from 'react-icons/bs';

import Button from 'Library/Button';

import FAIcon from 'components/lib/FAIcon';
import LeadType from 'components/app/LeadTypes';
import ContactEmail from 'components/app/Clients/Contacts/Modal/Email';
import AtsEmailBody from 'components/app/EmailBody';
import ProfileCard from 'components/profile-card.component';

import { navClient } from 'store/client.slice';
import { getLead } from 'store/leads.slice';

import { LeadsModal } from '../Modal';
import { LeadsTabList } from '../TabList';

import { LeadsConvertClientModal } from '../Modal/ConvertContact';
import { LeadsSendForApprovalModal } from '../Modal/SendForApproval';

import { useDrawerServices } from './useDrawerServices';
import toUrl from 'utils/toUrl';
import { LeadProps } from './types';
import LoadingPage from 'components/app/Loading';
import { useEffect } from 'react';
import { useLeadsPageServices } from '../Page/usePageServices';

export const LeadsDrawer = ({
  isOpen,
  onClose,
  rowId,
  totalLeads,
  handlePrevData,
  handleNextData,
}: LeadProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    type,
    leadData,
    onEditLeadOpen,
    handleInactiveLead,
    converClientClick,
    sendForApproval,
    cancelApprovalClick,
    onConvertLeadOpen,
    marAsActiveClick,
    showLeadDrawer,
    clientNav,
    clientTab,
    handleOpenDrawer,
    setHover,
    hover,
    onOpenEmail,
    isNewButton,
    isEditLeadOpen,
    onEditLeadClose,
    isConvertClientOpen,
    onConvertClientClose,
    isConvertLeadOpen,
    onConvertLeadClose,
    isSendApproval,
    setIsSendApproval,
    stateTab,
    quilValue,
    status,
    setquilValue,
    createNotes,
    isOpenEmail,
    onCloseEmail,
    resleads,
    ClaimLead,
    resClaim,
  } = useDrawerServices({
    isOpen,
    onClose,
  });

  const { reloadLeadList } = useLeadsPageServices();

  const handleEditList = () => {
    if (type === 'Contact' && status === 'Rejected') {
      return (
        <>
          {leadData?.owner_id && (
            <MenuItem
              icon={<FAIcon iconName="pencil" />}
              onClick={() => {
                dispatch(getLead({ leadData: leadData }));
                onEditLeadOpen();
              }}
            >
              Edit
            </MenuItem>
          )}
          <MenuItem
            icon={<FAIcon iconName="ban" />}
            color="caution.800"
            onClick={() => {
              handleInactiveLead(leadData);
            }}
          >
            Mark as Inactive
          </MenuItem>
        </>
      );
    } else if (
      leadData?.owner_id &&
      type === 'Contact' &&
      status === 'Approval Pending'
    ) {
      return (
        <MenuItem
          icon={<FAIcon iconName="pencil" />}
          onClick={() => {
            dispatch(getLead({ leadData: leadData }));
            onEditLeadOpen();
          }}
        >
          Edit
        </MenuItem>
      );
    } else if (
      type === 'Lead' &&
      (status === 'Draft' || status === 'Rejected')
    ) {
      return (
        <>
          {leadData?.owner_id && (
            <MenuItem
              icon={<FAIcon iconName="pencil" />}
              onClick={() => {
                dispatch(getLead({ leadData: leadData }));
                onEditLeadOpen();
              }}
            >
              Edit
            </MenuItem>
          )}
          <MenuItem
            icon={<FAIcon iconName="ban" />}
            color="caution.800"
            onClick={() => {
              handleInactiveLead(leadData);
            }}
          >
            Mark as Inactive
          </MenuItem>
        </>
      );
    } else if (type === 'Lead' && status === 'Active') {
      return (
        <>
          {leadData?.owner_id && (
            <MenuItem
              icon={<FAIcon iconName="pencil" />}
              onClick={() => {
                dispatch(getLead({ leadData: leadData }));
                onEditLeadOpen();
              }}
            >
              Edit
            </MenuItem>
          )}
          <MenuItem
            icon={<FAIcon iconName="ban" />}
            color="caution.800"
            onClick={() => {
              handleInactiveLead(leadData);
            }}
          >
            Mark as Inactive
          </MenuItem>
        </>
      );
    } else if (type === 'Lead' && status === 'Approval Pending') {
      return (
        <>
          {leadData?.owner_id && (
            <MenuItem
              icon={<FAIcon iconName="pencil" />}
              onClick={() => {
                dispatch(getLead({ leadData: leadData }));
                onEditLeadOpen();
              }}
            >
              Edit
            </MenuItem>
          )}
        </>
      );
    } else if (type === 'Qualified' && status === 'Active') {
      return (
        <>
          {leadData?.owner_id && (
            <MenuItem
              icon={<FAIcon iconName="pencil" />}
              onClick={() => {
                dispatch(getLead({ leadData: leadData }));
                onEditLeadOpen();
              }}
            >
              Edit
            </MenuItem>
          )}
          <MenuItem
            icon={<FAIcon iconName="ban" />}
            color="caution.800"
            onClick={() => {
              handleInactiveLead(leadData);
            }}
          >
            Mark as Inactive
          </MenuItem>
        </>
      );
    } else if (type === 'Qualified' && status === 'Approval Pending') {
      return (
        <>
          {leadData?.owner_id && (
            <MenuItem
              icon={<FAIcon iconName="pencil" />}
              onClick={() => {
                dispatch(getLead({ leadData: leadData }));
                onEditLeadOpen();
              }}
            >
              Edit
            </MenuItem>
          )}
        </>
      );
    } else if (type === 'Qualified' && status === 'Rejected') {
      return (
        <>
          {leadData?.owner_id && (
            <MenuItem
              icon={<FAIcon iconName="pencil" />}
              onClick={() => {
                dispatch(getLead({ leadData: leadData }));
                onEditLeadOpen();
              }}
            >
              Edit
            </MenuItem>
          )}
          <MenuItem
            icon={<FAIcon iconName="ban" />}
            color="caution.800"
            onClick={() => {
              handleInactiveLead(leadData);
            }}
          >
            Mark as Inactive
          </MenuItem>
        </>
      );
    }
  };

  const handleConvertType = () => {
    if (type === 'Contact' && status === 'Rejected') {
      return (
        <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
          <MenuItem
            icon={<FAIcon iconName="briefcase" />}
            onClick={() => converClientClick(leadData)}
          >
            Request to Convert
          </MenuItem>
        </MenuList>
      );
    } else if (
      type === 'Lead' &&
      (status === 'Draft' || status === 'Rejected')
    ) {
      return (
        <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
          <MenuItem
            icon={<FAIcon iconName="check-circle" />}
            onClick={() => sendForApproval(leadData)}
          >
            Send for Approval
          </MenuItem>
          <MenuItem
            icon={<FAIcon iconName="briefcase" />}
            onClick={() => converClientClick(leadData)}
          >
            Request to Convert
          </MenuItem>
        </MenuList>
      );
    } else if (type === 'Lead' && status === 'Active') {
      return (
        <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
          <MenuItem
            icon={<FAIcon iconName="briefcase" />}
            onClick={() => converClientClick(leadData)}
          >
            Request to Convert
          </MenuItem>
        </MenuList>
      );
    } else if (type === 'Qualified' && status === 'Active') {
      return (
        <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
          <MenuItem
            icon={<FAIcon iconName="briefcase" />}
            onClick={() => converClientClick(leadData)}
          >
            Request to Convert
          </MenuItem>
        </MenuList>
      );
    } else if (type === 'Qualified' && status === 'Rejected') {
      return (
        <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
          <MenuItem
            icon={<FAIcon iconName="check-circle" />}
            onClick={() => sendForApproval(leadData)}
          >
            Send for Approval
          </MenuItem>
          <MenuItem
            icon={<FAIcon iconName="briefcase" />}
            onClick={() => converClientClick(leadData)}
          >
            Request to Convert
          </MenuItem>
        </MenuList>
      );
    }
  };

  const handleNewButton = () => {
    if (
      (type === 'Contact' && status === 'Approval Pending') ||
      (type === 'Lead' && status === 'Approval Pending') ||
      (type === 'Qualified' && status === 'Approval Pending')
    ) {
      return (
        <Button onClick={() => cancelApprovalClick(leadData)}>
          Cancel Approval
        </Button>
      );
    } else if (type === 'Contact' && status === 'Active') {
      return (
        <>
          <Menu closeOnSelect={false}>
            <MenuButton>
              <Button iconName="ellipsis" />
            </MenuButton>

            <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
              <MenuItem
                icon={<FAIcon iconName="square-check" />}
                onClick={onConvertLeadOpen}
              >
                View Contact
              </MenuItem>
              <MenuItem
                icon={<FAIcon iconName="briefcase" />}
                onClick={() => converClientClick(leadData)}
              >
                Request to Convert
              </MenuItem>
              <MenuItem
                icon={<FAIcon iconName="ban" />}
                color="caution.800"
                onClick={() => {
                  handleInactiveLead(leadData);
                }}
              >
                Mark as Inactive
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      );
    } else if (status === 'Inactive') {
      return (
        <Button onClick={() => marAsActiveClick(leadData)}>
          Mark as Active
        </Button>
      );
    }
  };

  useEffect(() => {
    if (resClaim.isSuccess) {
      reloadLeadList();
      onClose();
    }
  }, [resClaim.isSuccess]);

  return (
    <>
      {showLeadDrawer() && (
        <Drawer
          onClose={() => {
            onClose();
            if (clientNav === 'leads') {
              dispatch(navClient({ clientNav: clientTab }));
              navigate(-1);
            } else {
              navigate('/leads');
            }
            dispatch(getLead({ leadData: {} }));
          }}
          isOpen={handleOpenDrawer()}
          size="xl"
        >
          <DrawerOverlay top="60px" />
          <DrawerContent
            maxW="80vw"
            top="62px! important"
            fontFamily="NunitoSans Regular"
          >
            <DrawerCloseButton
              position="absolute"
              top="0px"
              left="-40px"
              borderRadius="unset"
              p="20px"
              bg="#E6E9EE"
              color="default.secondarytext"
              _hover={{
                backgroundColor: 'none',
              }}
            />
            <Flex
              justifyContent="space-between"
              alignItems="center"
              pl={8}
              pr={0}
              borderBottom="1px solid #E6E9EE"
              fontSize="sm"
              fontWeight="normal"
              color="default.secondarytext"
            >
              <Box>
                Showing <b>{rowId}</b> of {totalLeads}
              </Box>
              <Flex>
                <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                  <Button
                    iconName="chevron-left"
                    variant="ghost"
                    onClick={() => rowId !== 1 && handlePrevData(leadData)}
                    disabled={rowId === 1}
                  />
                </Box>

                <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                  <Button
                    iconName="chevron-right"
                    variant="ghost"
                    onClick={() =>
                      rowId !== totalLeads && handleNextData(leadData)
                    }
                    disabled={rowId === totalLeads}
                  />
                </Box>
              </Flex>
            </Flex>
            <DrawerBody p="0">
              {resleads.isLoading ? (
                <LoadingPage />
              ) : (
                <>
                  <Box
                    bg="default.white.800"
                    borderBottom="1px solid"
                    borderColor="default.white.400"
                    p="32px"
                    pb="60px"
                  >
                    <Flex justifyContent="space-between" mb="10px">
                      <Box
                        fontSize="20px"
                        fontWeight="600"
                        color="default.primarytext"
                        whiteSpace="pre-line"
                        maxWidth="300px"
                        textTransform="capitalize"
                      >
                        {[leadData?.first_name, leadData?.last_name].join(' ')}
                      </Box>
                      {!leadData.is_prospect ? (
                        <Flex gap={2}>
                          <Box fontSize="sm" color="default.secondarytext">
                            Current Lead Type:
                          </Box>
                          <Box fontSize="sm" color="default.primarytext">
                            <LeadType type={leadData.leadType?.type} />
                          </Box>
                        </Flex>
                      ) : null}
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Box>
                        <Flex
                          gap={2}
                          fontSize="md"
                          color="default.gray.600"
                          mb="10px"
                        >
                          <Box fontSize="md" color="default.gray.800">
                            {leadData.title}
                          </Box>
                          |
                          <Box
                            fontSize="md"
                            color="default.gray.800"
                            onClick={() => {
                              dispatch(navClient({ clientNav: 'all-clients' }));
                              navigate(
                                `/clients/${leadData.client_id}/overview`
                              );
                            }}
                          >
                            <Link>{leadData?.client?.name}</Link>
                          </Box>
                          |
                          <Box fontSize="md" color="default.gray.800">
                            <Link
                              href={toUrl(leadData.website)}
                              target="_blank"
                              style={{ display: 'flex', gap: '7px' }}
                              rel="noreferrer"
                            >
                              {toUrl(leadData.website)}
                            </Link>
                          </Box>
                        </Flex>
                        <Flex
                          alignItems="center"
                          gap={2}
                          fontSize="md"
                          color="default.gray.600"
                        >
                          <Box
                            fontSize="sm"
                            color="default.secondarytext"
                            onMouseEnter={() => {
                              setHover(true);
                            }}
                            onMouseLeave={() => {
                              setHover(false);
                            }}
                          >
                            Created on:{' '}
                            {hover
                              ? moment
                                  .utc(leadData.created_at)
                                  .format('MM/DD/YYYY hh:mm A')
                              : moment
                                  .utc(leadData.created_at)
                                  .format('MM/DD/YYYY')}
                          </Box>
                          {!leadData.is_prospect ? (
                            <>
                              |
                              <Flex
                                fontSize="sm"
                                color="default.secondarytext"
                                // textTransform="capitalize"
                                gap="5px"
                              >
                                Lead Owner:{' '}
                                <ProfileCard
                                  // id={leadData?.leadOwner?.id}
                                  id="1"
                                  user_id={leadData?.leadOwner?.id}
                                  name={[
                                    leadData?.leadOwner?.first_name,
                                    leadData?.leadOwner?.last_name,
                                  ].join(' ')}
                                  email={leadData?.leadOwner?.email || ''}
                                  thumbnail={
                                    leadData?.leadOwner?.thumbnailSignedUrl
                                  }
                                  profile={leadData?.leadOwner?.signedUrl}
                                />
                              </Flex>
                            </>
                          ) : null}
                        </Flex>
                      </Box>

                      <ButtonGroup alignItems="center">
                        <Flex gap="30px" alignItems="center" mr="20px">
                          <Link
                            href={toUrl(leadData?.linked_in_url)}
                            target="_blank"
                            style={{ display: 'flex', gap: '7px' }}
                            rel="noreferrer"
                            fontSize="20px"
                          >
                            <Icon as={BsLinkedin} />
                          </Link>
                          <Link fontSize="20px" onClick={() => onOpenEmail()}>
                            <Icon as={BsEnvelope} />
                          </Link>
                          <Link
                            fontSize="20px"
                            href={`tel:${leadData?.personal_phone}`}
                          >
                            <Icon as={BsTelephone} />
                          </Link>
                        </Flex>
                        {!leadData.is_prospect ? (
                          <>
                            {(leadData?.leadType?.type === 'Contact' &&
                              leadData?.leadStatus?.lead_status === 'Active') ||
                              (leadData?.leadStatus?.lead_status !==
                                'Inactive' && (
                                <Menu closeOnSelect={false}>
                                  <MenuButton>
                                    <Button iconName="ellipsis" />
                                  </MenuButton>
                                  <MenuList
                                    fontSize="12px"
                                    lineHeight="16px"
                                    fontWeight="600"
                                  >
                                    {handleEditList()}
                                  </MenuList>
                                </Menu>
                              ))}
                            {isNewButton ? (
                              handleNewButton()
                            ) : leadData?.owner_id ? (
                              <Menu closeOnSelect={false}>
                                <MenuButton>
                                  <Button rightIcon="chevron-down">
                                    Convert Lead Type
                                  </Button>
                                </MenuButton>

                                {handleConvertType()}
                              </Menu>
                            ) : (
                              <Button
                                isLoading={resClaim.isLoading}
                                isDisabled={resClaim.isLoading}
                                onClick={() => ClaimLead(Number(leadData.id))}
                              >
                                Claim Lead
                              </Button>
                            )}
                          </>
                        ) : null}
                      </ButtonGroup>
                    </Flex>
                  </Box>
                  <Box mt="-39px" p="0 32px">
                    <LeadsTabList />
                  </Box>
                </>
              )}

              {isEditLeadOpen && (
                <LeadsModal
                  isOpen={isEditLeadOpen}
                  onClose={onEditLeadClose}
                  modalTitle="Edit Lead"
                />
              )}
              {isConvertClientOpen && (
                <LeadsConvertClientModal
                  isOpen={isConvertClientOpen}
                  onClose={onConvertClientClose}
                />
              )}
              {isConvertLeadOpen && (
                <LeadsSendForApprovalModal
                  isOpen={isConvertLeadOpen}
                  onClose={onConvertLeadClose}
                  isSendApproval={isSendApproval}
                  setIsSendApproval={setIsSendApproval}
                />
              )}
              {stateTab === 'notes' && (
                <>
                  <Box
                    position="absolute"
                    width="100%"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                    p="16px 32px"
                  >
                    <AtsEmailBody
                      value={quilValue}
                      menubar={false}
                      onChange={setquilValue}
                      height={150}
                    />
                    <Box mt="3" textAlign={'right'}>
                      <Button variant="solid" onClick={() => createNotes()}>
                        Add Note
                      </Button>
                    </Box>
                  </Box>
                </>
              )}
              {isOpenEmail && (
                <ContactEmail
                  isOpen={isOpenEmail}
                  onClose={onCloseEmail}
                  id={leadData.id}
                  email={leadData?.primary_email || ''}
                />
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};
