import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { m } from 'framer-motion';
import Button from 'Library/Button';
import { useState } from 'react';
import { formatDigits } from 'utils/utilFormat';
import { tableHeader } from './constants';
import ActivityDrawer from './Drawer';
import TrActivities from './Table/Tr';

interface props {
  byActivity: any;
  selectByActivity: any;
  setByActivity: (e: string) => void;
  byUser: any;
  setSelectByUser: (e: any) => void;
  selectByUser: any;
  activities: any;
  isrecruiters?: boolean;
}

interface headerInt {
  label: any;
  col: number;
  row: number;
  sub: any;
}

const DahsboardActivity = ({
  byActivity,
  selectByActivity,
  setByActivity,
  byUser,
  setSelectByUser,
  selectByUser,
  activities,
  isrecruiters = false,
}: props) => {
  const populateTotal = () => {
    let countObj = {
      new_leads_talently: 0,
      new_leads_repeat: 0,
      new_client_interview: 0,
      job_posted: 0,
      // for job total
      submits: 0,
      first_interview: 0,
      interviews: 0,
      offers: 0,
      placements: 0,
      revenue: 0,
      calls: 0,
      emails: 0,
      sms: 0,
      inmail: 0,
    };
    activities.map((activity: any) => {
      countObj.new_leads_talently += parseInt(activity.new_leads_talently);
      countObj.new_leads_repeat += parseInt(activity.new_leads_repeat);
      countObj.new_client_interview += parseInt(activity.new_client_interview);
      countObj.job_posted += parseInt(activity.job_posted);
      countObj.submits += parseInt(activity.submits);
      countObj.first_interview += parseInt(activity.first_interview);
      countObj.interviews += parseInt(activity.interviews);
      countObj.offers += parseInt(activity.offers);
      countObj.placements += parseInt(activity.placements);
      countObj.revenue += parseInt(activity.revenue);
      countObj.calls += parseInt(activity.calls);
      countObj.emails += parseInt(activity.emails);
      countObj.sms += parseInt(activity.sms);
      countObj.inmail += parseInt(activity.inmail);
    });
    return { ...countObj };
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Type, setType] = useState<string | null>(null);
  const [Week, setWeek] = useState<number | null>(null);
  const [date, setDate] = useState(null);

  const BreakDownclick = (type: string, week: number) => {
    setType(type);
    setWeek(week);
    setDate(null);
    onOpen();
  };

  const dayBreakclick = (type: string, date: any) => {
    // console.log({ date });
    setType(type);
    setWeek(null);
    setDate(date);
    onOpen();
  };

  return (
    <>
      <Flex sx={{ gap: '10px', alignItems: 'center' }}>
        <Box
          sx={{
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 700,
            color: '#718096',
          }}
        >
          Activities
        </Box>
        <Flex width="fit-content">
          <Menu closeOnSelect={true}>
            <MenuButton type="button">
              <Box
                sx={{
                  button: {
                    p: 0,
                    fontSize: '14px',
                    border: 'none',
                    color: 'primary.500',
                  },

                  span: {
                    mb: '2px',
                  },
                }}
              >
                <Button rightIcon="chevron-down" type="button">
                  {
                    byActivity.filter(
                      (val: any) => val.label === selectByActivity
                    )[0].label
                  }
                </Button>
              </Box>
            </MenuButton>
            {
              <MenuList fontSize="sm" maxHeight="200px" overflowY="auto">
                {byActivity.map((val: any, key: number) => {
                  return (
                    <MenuItem
                      type="button"
                      onClick={() => {
                        setByActivity(val.label);
                      }}
                      key={`byActivity-${key}`}
                    >
                      {val.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            }
          </Menu>
        </Flex>
        {!isrecruiters && (
          <>
            {' '}
            <Box
              sx={{
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: 700,
                color: '#718096',
              }}
            >
              of
            </Box>
            <Flex width="fit-content">
              <Menu closeOnSelect={true}>
                <MenuButton type="button">
                  <Box
                    sx={{
                      button: {
                        p: 0,
                        fontSize: '14px',
                        border: 'none',
                        color: 'primary.500',
                      },

                      span: {
                        mb: '2px',
                      },
                    }}
                  >
                    <Button rightIcon="chevron-down" type="button">
                      {
                        byUser?.filter(
                          (val: any) => val.value === selectByUser
                        )[0]?.label
                      }
                    </Button>
                  </Box>
                </MenuButton>
                {
                  <MenuList fontSize="sm" maxHeight="200px" overflowY="auto">
                    {byUser?.map((val: any, key: number) => {
                      return (
                        <MenuItem
                          type="button"
                          onClick={() => setSelectByUser(val.value)}
                          key={`byUser-activity-${key}`}
                        >
                          {val.label}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                }
              </Menu>
            </Flex>
          </>
        )}
      </Flex>

      <Box sx={{ my: '24px' }}>
        <TableContainer
          boxSizing="border-box"
          border="1px solid #EEEEEE"
          borderRadius="4px"
        >
          <Table colorScheme="gray">
            <Thead>
              <Tr bg="common.white">
                {tableHeader.map((title: headerInt, index: number) => (
                  <Th
                    key={`header-${index}`}
                    colSpan={title.col}
                    rowSpan={title.row}
                    style={{
                      border: '1px solid #bababa',
                      borderBottomColor: '#e1e1e1',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '20px',
                        fontWeight: 400,
                        color: '#322659',
                      }}
                    >
                      {title.label}
                    </Box>
                  </Th>
                ))}
              </Tr>
              <Tr bg="common.white">
                {tableHeader
                  .filter((title) => title.sub.length > 0)
                  .flatMap((title) =>
                    title.sub.map((subTitle: any, key: number) => (
                      <Th
                        key={`tableHeader-sub-${key}-${Math.random()}`}
                        style={{ border: '1px solid #e1e1e1' }}
                      >
                        <Box
                          sx={{
                            fontSize: '10px',
                            lineHeight: '20px',
                            fontWeight: 400,
                            color: '#322659',
                          }}
                        >
                          {subTitle.label}
                        </Box>
                      </Th>
                    ))
                  )}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {activities?.map((item: any, index: number) => {
                return (
                  <TrActivities
                    index={index}
                    item={item}
                    activities={activities}
                    selectByUser={selectByUser}
                    key={`${index}-${item.label}-activity`}
                    BreakDownclick={(type, week) => BreakDownclick(type, week)}
                    dayBreakclick={(type, date) => dayBreakclick(type, date)}
                  />
                );
              })}
              <Tr
                cursor="pointer"
                key={`activity-logs-total`}
                sx={{
                  fontSize: '16px',
                  lineHeight: '16px',
                  fontWeight: 700,
                  zIndex: 10000000,
                }}
              >
                <Td
                  sx={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA !important',
                    // borderTopColor: '#6930CA',
                    // borderBottomColor: '#6930CA',
                    // borderRightColor: '#6930CA',
                    zIndex: 100000000,
                  }}
                >
                  Total
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    zIndex: 100000000,
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.new_leads_talently) !== 0
                    ? populateTotal()?.new_leads_talently
                    : '-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderRightColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.new_leads_repeat) !== 0
                    ? populateTotal()?.new_leads_repeat
                    : '-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderTopColor: '#6930CA',
                    borderBottomColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.new_client_interview) !== 0
                    ? populateTotal()?.new_client_interview
                    : '-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.job_posted) !== 0
                    ? populateTotal()?.job_posted
                    : '-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderRightColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {/* {Number(activities[0]?.total_job) !== 0 ? activities[0]?.total_job : '-'}
                   */}
                  {'-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderTopColor: '#6930CA',
                    borderBottomColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.submits) !== 0
                    ? populateTotal()?.submits
                    : '-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderRightColor: '#6930CA',
                    borderTopColor: '#6930CA',
                    borderBottomColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.first_interview) !== 0
                    ? populateTotal()?.first_interview
                    : '-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderLeftColor: '#6930CA',
                    borderTopColor: '#6930CA',
                    borderBottomColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.interviews) !== 0
                    ? populateTotal()?.interviews
                    : '-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderTopColor: '#6930CA',
                    borderBottomColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.offers) !== 0
                    ? populateTotal()?.offers
                    : '-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderTopColor: '#6930CA',
                    borderBottomColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.placements) !== 0
                    ? populateTotal()?.placements
                    : '-'}
                </Td>

                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderTopColor: '#6930CA',
                    borderBottomColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.revenue) !== 0
                    ? formatDigits(populateTotal()?.revenue)
                    : '-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderTopColor: '#6930CA',
                    borderBottomColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.calls) !== 0
                    ? populateTotal()?.calls
                    : '-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderTopColor: '#6930CA',
                    borderBottomColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.emails) !== 0
                    ? populateTotal()?.emails
                    : '-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderTopColor: '#6930CA',
                    borderBottomColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.sms) !== 0
                    ? populateTotal()?.sms
                    : '-'}
                </Td>
                <Td
                  style={{
                    color: '#6930CA',
                    background: '#FAF5FF',
                    border: '1px solid #6930CA',
                    borderTopColor: '#6930CA',
                    borderBottomColor: '#6930CA',
                  }}
                  textAlign="center"
                >
                  {Number(populateTotal()?.inmail) !== 0
                    ? populateTotal()?.inmail
                    : '-'}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {isOpen && (
        <ActivityDrawer
          isOpen={isOpen}
          onClose={onClose}
          type={Type}
          selectByUser={selectByUser}
          week={Week}
          date={date}
        />
      )}
    </>
  );
};

export default DahsboardActivity;
