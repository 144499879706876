// Chakra UI components
import {
  Box,
  Flex,
  Button as ButtonChakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalFooter,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';

// Formik for form handling
import { Formik, Field } from 'formik';

// Custom components and utilities
import ScrollToFieldError from 'components/app/ScrollError';
import Button from 'Library/Button';
import AtsSelect2 from 'components/app/AtsSelect2';
import AtsSelectContact from 'components/app/AtsSelectContact';
import jobAdDescTemplate from 'constants/jobAdDescTemplate';
import AtsSelectAddress from 'components/app/AtsSelectAddress';
import { useClientServices } from './useClientServices';
import FAIcon from 'components/lib/FAIcon';
import AtsSelect from 'components/app/AtsSelect';

// Define the props for the JobClientForm component
interface JobClientFormProps {
  initialClientValues: any;
  jobFormData: any;
  setJobStatus: (data: any) => void;
  onSubmit: (data: any) => void;
  isDraft: boolean;
  setJobFormData: (data: any) => void;
  initialValues: object;
  changeToDefault: (data: any) => object;
  tabList: any;
  tabIndex: any;
  setTabIndex: (data: any) => void;
  isOpenAlert: boolean;
  setIsDraft: (e: boolean) => void;
  hasJobPostType: any;
  aMOption: any;
  isEditModal: boolean;
  jobData: any;
  auth: any;
  setHasJobPostType: (data: any) => void;
  defaultPitch: any;
  searchableLeads: any;
  searchableClient: any;
  CompanyOption: any;
  CompanyChange: (data: any) => void;
  companyLoading: boolean;
  contactOption: any;
  locationOption: any;
  ccOption: any;
  onCcInputChange: (e: any) => void;
  bccOption: any;
  onBccInputchange: (e: any) => void;
  onClose: () => void;
  setSelectedCompanyName: any;
  jobSpecsOption: any;
  handleJobSpec: any;
}

// ClientForm component
const ClientForm = ({
  setJobStatus,
  hasJobPostType,
  initialClientValues,
  setJobFormData,
  isDraft,
  jobFormData,
  aMOption,
  jobData,
  auth,
  onSubmit,
  initialValues,
  changeToDefault,
  tabList,
  tabIndex,
  setTabIndex,
  setHasJobPostType,
  defaultPitch,
  isEditModal,
  searchableLeads,
  searchableClient,
  CompanyOption,
  CompanyChange,
  companyLoading,
  contactOption,
  locationOption,
  ccOption,
  onCcInputChange,
  bccOption,
  onBccInputchange,
  isOpenAlert,
  setIsDraft,
  onClose,
  setSelectedCompanyName,
  jobSpecsOption,
  handleJobSpec,
}: JobClientFormProps) => {
  // Use custom hook for client services
  const {
    validationClientSchema,
    clientSubmit,
    handleContact,
    handleBlur,
    handleLocation,
    handleEditModal,
  } = useClientServices({
    setJobStatus,
    hasJobPostType,
    isDraft,
    setJobFormData,
    jobFormData,
    jobData,
    aMOption,
    auth,
    onSubmit,
    initialValues,
    tabList,
    tabIndex,
    setTabIndex,
    jobAdDescTemplate,
  });

  return (
    <Formik
      initialValues={initialClientValues}
      validationSchema={validationClientSchema}
      onSubmit={(data, { setSubmitting, validateForm }) =>
        clientSubmit(data, { setSubmitting, validateForm })
      }
      enableReinitialize={true}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        setFieldError,
      }) => (
        <form onSubmit={handleSubmit} style={{ height: 'inherit' }}>
          {/* Component to scroll to the field with an error */}
          <ScrollToFieldError />

          <Stack justifyContent="space-between" h="inherit">
            <Box
              h="inherit"
              sx={{
                p: '16px',
                background: '#fff',
                borderRadius: '8px',
              }}
            >
              <>
                {/* Job Post Type Radio Group */}
                <FormControl
                  isInvalid={Boolean(
                    !!errors.job_type_id && touched.job_type_id
                  )}
                >
                  <RadioGroup value={String(values.job_type_id)} mb="24px">
                    <Stack spacing={5} direction="row">
                      <Flex alignItems="center" gap="16px">
                        <Radio
                          colorScheme="purple"
                          name="job_type_id"
                          value="1"
                          fontSize="14px"
                          lineHeight="16px"
                          color="#2B2D42"
                          onChange={(e: any) => {
                            handleChange(e);
                            localStorage.setItem(
                              'jobFormData',
                              JSON.stringify({
                                ...jobFormData,
                                ...values,
                              })
                            );
                            setHasJobPostType(e.target.value);
                            setJobFormData((prev: any) => ({
                              ...prev,
                              job_type_id: e.target.value,
                              email_subject: defaultPitch[0]?.subject || '',
                              email_content: defaultPitch[0]?.content || '',
                            }));
                            setFieldValue('job_type_id', e.target.value);
                            setFieldValue(
                              'email_subject',
                              defaultPitch[0]?.subject || ''
                            );
                            setFieldValue(
                              'email_content',
                              defaultPitch[0]?.content || ''
                            );
                          }}
                        >
                          Full Job Post
                        </Radio>
                      </Flex>

                      {/* Conditional rendering based on modal edit mode */}
                      {isEditModal && String(jobData.job_type_id) === '1' ? (
                        ''
                      ) : (
                        <Flex alignItems="center" gap="10px">
                          <Radio
                            colorScheme="purple"
                            name="job_type_id"
                            value="2"
                            fontSize="14px"
                            lineHeight="16px"
                            color="#2B2D42"
                            onChange={(e: any) => {
                              handleChange(e);
                              localStorage.setItem(
                                'jobFormData',
                                JSON.stringify({
                                  ...jobFormData,
                                  ...values,
                                })
                              );
                              setHasJobPostType(e.target.value);
                              setJobFormData((prev: any) => ({
                                ...prev,
                                job_type_id: e.target.value,
                                company: values.company,
                                contact: values.contact,
                                location: values.location,
                                email_subject: defaultPitch[1]?.subject || '',
                                email_content: defaultPitch[1]?.content || '',
                              }));
                              setFieldValue('job_type_id', e.target.value);
                              setFieldValue(
                                'email_subject',
                                defaultPitch[1]?.subject || ''
                              );
                              setFieldValue(
                                'email_content',
                                defaultPitch[1]?.content || ''
                              );
                            }}
                          >
                            Quick Job Posts
                          </Radio>
                        </Flex>
                      )}
                    </Stack>
                  </RadioGroup>

                  <FormErrorMessage>
                    {String(errors.job_type_id)}
                  </FormErrorMessage>
                </FormControl>

                {/* Company and Contact Fields */}
                <Flex gap="16px" mb="24px">
                  <FormControl
                    isInvalid={Boolean(!!errors.company && touched.company)}
                  >
                    <FormLabel
                      fontSize="14px"
                      lineHeight="19.1px"
                      color="#2B2D42"
                      fontWeight={700}
                    >
                      Client{' '}
                      <Box as="span" color="#C53030">
                        *
                      </Box>
                    </FormLabel>

                    <Box
                      border={
                        !!errors.company &&
                        touched.company &&
                        '1px solid #E53E3E'
                      }
                      borderRadius={
                        !!errors.company && touched.company && '4px'
                      }
                      boxShadow={
                        !!errors.company &&
                        touched.company &&
                        '0 0 0 1px #E53E3E'
                      }
                      onClick={() => {
                        setFieldValue('contact', []);
                        setFieldValue('location', []);
                      }}
                      onBlur={() => handleBlur(values)}
                    >
                      {searchableLeads != null || searchableClient != null ? (
                        <Field
                          name="company"
                          options={CompanyOption}
                          component={AtsSelect2}
                          value={values.company}
                          variant="outline"
                          isLoading={companyLoading}
                          onChange={(e: any) => {
                            CompanyChange(e);
                            if (e?.industry) {
                              setFieldValue('industry', {
                                value: e.industry.id,
                                label: e.industry.specification,
                              });
                            }
                          }}
                          placeholder="Select Client"
                          menuIsOpen={searchableLeads}
                          onInputChange={(e: any) => setSelectedCompanyName(e)}
                          styles={{
                            control: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              border: '1px solid #E7E9ED',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              boxShadow: 'none',
                              '&:hover': {},
                              '& ::-webkit-scrollbar': {
                                display: 'none',
                              },
                            }),
                            valueContainer: (styles: any) => ({
                              ...styles,
                              maxHeight: '33px',
                              overflowY: 'scroll',
                            }),
                            dropdownIndicator: (styles: any) => ({
                              ...styles,
                              color: '#676767',
                            }),
                            indicatorSeparator: () => ({
                              display: 'none',
                            }),
                            placeholder: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              color: '#9B9B9B',
                            }),
                          }}
                        />
                      ) : (
                        <Field
                          name="company"
                          options={CompanyOption}
                          component={AtsSelect2}
                          value={values.company}
                          variant="outline"
                          isLoading={companyLoading}
                          onChange={(e: any) => {
                            CompanyChange(e);
                            if (e?.industry) {
                              setFieldValue('industry', {
                                value: e.industry.id,
                                label: e.industry.specification,
                              });
                            }
                          }}
                          placeholder="Select Client"
                          onInputChange={(e: any) => setSelectedCompanyName(e)}
                          styles={{
                            control: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              border: '1px solid #E7E9ED',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              boxShadow: 'none',
                              '&:hover': {},
                              '& ::-webkit-scrollbar': {
                                display: 'none',
                              },
                            }),
                            valueContainer: (styles: any) => ({
                              ...styles,
                              maxHeight: '33px',
                              overflowY: 'scroll',
                            }),
                            dropdownIndicator: (styles: any) => ({
                              ...styles,
                              color: '#676767',
                            }),
                            indicatorSeparator: () => ({
                              display: 'none',
                            }),
                            placeholder: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              color: '#9B9B9B',
                            }),
                          }}
                        />
                      )}
                    </Box>
                    <FormErrorMessage>
                      {String(errors.company)}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={Boolean(!!errors.contact && touched.contact)}
                  >
                    <FormLabel
                      fontSize="14px"
                      lineHeight="19.1px"
                      color="#2B2D42"
                      fontWeight={700}
                    >
                      Contact{' '}
                      <Box as="span" color="#C53030">
                        *
                      </Box>
                    </FormLabel>
                    <Box
                      border={
                        !!errors.contact &&
                        touched.contact &&
                        '1px solid #E53E3E'
                      }
                      borderRadius={
                        !!errors.contact && touched.contact && '4px'
                      }
                      boxShadow={
                        !!errors.contact &&
                        touched.contact &&
                        '0 0 0 1px #E53E3E'
                      }
                      onClick={handleContact}
                      onBlur={() => handleBlur(values)}
                    >
                      {searchableLeads != null ? (
                        <Field
                          name="contact"
                          options={contactOption}
                          component={AtsSelect2}
                          defaultValue={values.contact}
                          placeholder="Select Contact"
                          menuIsOpen={searchableLeads}
                          styles={{
                            control: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              border: '1px solid #E7E9ED',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              boxShadow: 'none',
                              '&:hover': {},
                              '& ::-webkit-scrollbar': {
                                display: 'none',
                              },
                            }),
                            valueContainer: (styles: any) => ({
                              ...styles,
                              maxHeight: '33px',
                              overflowY: 'scroll',
                            }),
                            dropdownIndicator: (styles: any) => ({
                              ...styles,
                              color: '#676767',
                            }),
                            indicatorSeparator: () => ({
                              display: 'none',
                            }),
                            placeholder: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              color: '#9B9B9B',
                            }),
                          }}
                        />
                      ) : (
                        <Field
                          name="contact"
                          options={contactOption}
                          component={AtsSelectContact}
                          value={values.contact}
                          client={values.company}
                          placeholder="Select Contact"
                          styles={{
                            control: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              border: '1px solid #E7E9ED',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              boxShadow: 'none',
                              '&:hover': {},
                              '& ::-webkit-scrollbar': {
                                display: 'none',
                              },
                            }),
                            valueContainer: (styles: any) => ({
                              ...styles,
                              maxHeight: '33px',
                              overflowY: 'scroll',
                            }),
                            dropdownIndicator: (styles: any) => ({
                              ...styles,
                              color: '#676767',
                            }),
                            indicatorSeparator: () => ({
                              display: 'none',
                            }),
                            placeholder: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              color: '#9B9B9B',
                            }),
                          }}
                        />
                      )}
                    </Box>
                    <FormErrorMessage>
                      {String(errors.contact)}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>

                {/* Location Field */}
                <FormControl
                  isInvalid={Boolean(!!errors.location && touched.location)}
                  mb="24px"
                >
                  <FormLabel
                    fontSize="14px"
                    lineHeight="19.1px"
                    color="#2B2D42"
                    fontWeight={700}
                  >
                    Location{' '}
                    <Box as="span" color="#C53030">
                      *
                    </Box>
                  </FormLabel>
                  <Box
                    border={
                      !!errors.location &&
                      touched.location &&
                      '1px solid #E53E3E'
                    }
                    borderRadius={
                      !!errors.location && touched.location && '4px'
                    }
                    boxShadow={
                      !!errors.location &&
                      touched.location &&
                      '0 0 0 1px #E53E3E'
                    }
                    onBlur={() => {
                      handleBlur(values);
                      !isEditModal &&
                        setFieldValue('job_ads', handleEditModal(values));
                    }}
                  >
                    <Field
                      name="location"
                      options={locationOption}
                      component={AtsSelectAddress}
                      value={values.location}
                      placeholder="Select Location"
                      styles={{
                        control: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          border: '1px solid #E7E9ED',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          '&:hover': {},
                          '& ::-webkit-scrollbar': {
                            display: 'none',
                          },
                        }),
                        valueContainer: (styles: any) => ({
                          ...styles,
                          maxHeight: '33px',
                          overflowY: 'scroll',
                        }),
                        dropdownIndicator: (styles: any) => ({
                          ...styles,
                          color: '#676767',
                        }),
                        indicatorSeparator: () => ({
                          display: 'none',
                        }),
                        placeholder: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          color: '#9B9B9B',
                        }),
                      }}
                    />
                  </Box>
                  <FormErrorMessage>{String(errors.location)}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={Boolean(!!errors.industry && touched.industry)}
                  mb="24px"
                >
                  <>
                    <FormLabel
                      fontSize="14px"
                      lineHeight="19.1px"
                      color="#2B2D42"
                      fontWeight={700}
                    >
                      Industry{' '}
                      <Box as="span" color="#C53030">
                        *
                      </Box>
                    </FormLabel>
                    <Box
                      border={
                        errors?.industry ===
                          'Please select a valid Industry' &&
                        '1px solid #E53E3E'
                      }
                      borderRadius={
                        errors?.industry ===
                          'Please select a valid Industry' && '4px'
                      }
                      boxShadow={
                        errors?.industry ===
                          'Please select a valid Industry' &&
                        '0 0 0 1px #E53E3E'
                      }
                      onBlur={() => handleBlur(values)}
                    >
                      <Field
                        name="industry"
                        options={jobSpecsOption}
                        component={AtsSelect2}
                        defaultValue={values.industry}
                        onChange={(e: any) => handleJobSpec(e)}
                        placeholder="Select Industry"
                        styles={{
                          control: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            border: '1px solid #E7E9ED',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            boxShadow: 'none',
                            '&:hover': {},
                            '& ::-webkit-scrollbar': {
                              display: 'none',
                            },
                          }),
                          valueContainer: (styles: any) => ({
                            ...styles,
                            maxHeight: '33px',
                            overflowY: 'scroll',
                          }),
                          dropdownIndicator: (styles: any) => ({
                            ...styles,
                            color: '#676767',
                          }),
                          indicatorSeparator: () => ({
                            display: 'none',
                          }),
                          placeholder: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            color: '#9B9B9B',
                          }),
                        }}
                      />
                    </Box>
                    {errors?.industry === 'Please select a valid Industry' && (
                      <Box
                        sx={{ mt: '8px', fontSize: '12px', color: '#e53e3e' }}
                      >
                        {errors?.industry as String}
                      </Box>
                    )}
                  </>
                </FormControl>

                {/* CC and BCC Options */}
                <Flex gap="16px" mb="60px">
                  <FormControl isInvalid={Boolean(!!errors.cc && touched.cc)}>
                    <FormLabel
                      fontSize="14px"
                      lineHeight="19.1px"
                      color="#2B2D42"
                      fontWeight={700}
                    >
                      CC
                    </FormLabel>

                    <Box
                      border={!!errors.cc && touched.cc && '1px solid #E53E3E'}
                      borderRadius={!!errors.cc && touched.cc && '4px'}
                      boxShadow={
                        !!errors.cc && touched.cc && '0 0 0 1px #E53E3E'
                      }
                      onBlur={() =>
                        localStorage.setItem(
                          'jobFormData',
                          JSON.stringify({
                            ...jobFormData,
                            ...values,
                          })
                        )
                      }
                    >
                      <Field
                        id="cc"
                        name="cc"
                        component={AtsSelectContact}
                        value={values.cc}
                        client={values.company}
                        onBlur={() => handleBlur(values)}
                        options={ccOption}
                        isMulti={true}
                        placeholder="Select CC"
                        defaultValue={values.cc}
                        onInputChange={onCcInputChange}
                        isSaveInput={true}
                        styles={{
                          control: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            border: '1px solid #E7E9ED',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            boxShadow: 'none',
                            '&:hover': {},
                            '& ::-webkit-scrollbar': {
                              display: 'none',
                            },
                          }),
                          valueContainer: (styles: any) => ({
                            ...styles,
                            maxHeight: '33px',
                            overflowY: 'scroll',
                          }),
                          dropdownIndicator: (styles: any) => ({
                            ...styles,
                            color: '#676767',
                          }),
                          indicatorSeparator: () => ({
                            display: 'none',
                          }),
                          placeholder: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            color: '#9B9B9B',
                          }),
                        }}
                      />
                    </Box>
                    <FormErrorMessage>{String(errors.cc)}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={Boolean(!!errors.bcc && touched.bcc)}>
                    <FormLabel
                      fontSize="14px"
                      lineHeight="19.1px"
                      color="#2B2D42"
                      fontWeight={700}
                    >
                      BCC
                    </FormLabel>

                    <Box
                      border={
                        !!errors.bcc && touched.bcc && '1px solid #E53E3E'
                      }
                      borderRadius={!!errors.bcc && touched.bcc && '4px'}
                      boxShadow={
                        !!errors.bcc && touched.bcc && '0 0 0 1px #E53E3E'
                      }
                      onBlur={() =>
                        localStorage.setItem(
                          'jobFormData',
                          JSON.stringify({
                            ...jobFormData,
                            ...values,
                          })
                        )
                      }
                    >
                      <Field
                        id="bcc"
                        name="bcc"
                        component={AtsSelectContact}
                        value={values.bcc}
                        client={values.company}
                        onBlur={() => handleBlur(values)}
                        options={bccOption}
                        isMulti={true}
                        placeholder="Select BCC"
                        defaultValue={values.bcc}
                        onInputChange={onBccInputchange}
                        isSaveInput={true}
                        styles={{
                          control: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            border: '1px solid #E7E9ED',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            boxShadow: 'none',
                            '&:hover': {},
                            '& ::-webkit-scrollbar': {
                              display: 'none',
                            },
                          }),
                          valueContainer: (styles: any) => ({
                            ...styles,
                            maxHeight: '33px',
                            overflowY: 'scroll',
                          }),
                          dropdownIndicator: (styles: any) => ({
                            ...styles,
                            color: '#676767',
                          }),
                          indicatorSeparator: () => ({
                            display: 'none',
                          }),
                          placeholder: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            color: '#9B9B9B',
                          }),
                        }}
                      />
                    </Box>
                    <FormErrorMessage>{String(errors.bcc)}</FormErrorMessage>
                  </FormControl>
                </Flex>
              </>
            </Box>

            {/* Footer */}
            <ModalFooter
              position="sticky"
              bottom="0"
              background="#F9F9FB"
              p="8px 0"
            >
              <Flex gap="10px">
                <ButtonChakra
                  onClick={() => {
                    onClose();
                    localStorage.setItem('jobFormData', JSON.stringify({}));
                  }}
                >
                  Cancel
                </ButtonChakra>

                {((String(values.job_type_id) === '1' && tabIndex.id < 4) ||
                  (String(values.job_type_id) === '2' && tabIndex.id < 2)) && (
                  <Button
                    variant="solid"
                    sx={{
                      height: '40px',
                    }}
                    disabled={isOpenAlert}
                    onClick={(e: any) => {
                      setIsDraft(false);
                      if (values.industry && values.industry.value !== 52) {
                        handleSubmit(e);
                      } else {
                        setFieldError(
                          'industry',
                          'Please select a valid Industry'
                        );
                      }

                      console.log(errors, 'xx');
                    }}
                  >
                    Next
                  </Button>
                )}
              </Flex>
            </ModalFooter>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default ClientForm;
