import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Flex, Stack } from '@chakra-ui/react';

import { SkeletonLoading } from 'modules/Leads/Page/SkeletonLoading';

import { listLeads } from 'store/leads.slice';

import { LeadsTable } from '../Table';

import '../../../theme/fonts.css';
import { useLeadsPageServices } from 'modules/Leads/Page/usePageServices';
import LeadSideBar from '../../../components/app/Leads/Sidebar';

export const LeadsPage = () => {
  const dispatch = useDispatch();

  const { resLeads, initFilter, reqStatus, reqTypes, reqLeads }: any =
    useLeadsPageServices();

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchLeads = async (param: object) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    reqLeads({ data: param, signal: abortControllerRef.current.signal });
  };

  useEffect(() => {
    dispatch(
      listLeads({
        leadPgBtn: initFilter,
      })
    );
    fetchLeads(initFilter);
  }, []);

  useEffect(() => {
    reqStatus({});
    reqTypes({});
  }, []);

  return (
    <Flex
      gap="8px"
      sx={{
        mt: '4px',
        p: '8px',
        fontFamily: 'NunitoSans Regular',
        overflow: 'hidden',
        height: '90vh',
      }}
    >
      <LeadSideBar />

      {resLeads.isLoading ? <SkeletonLoading /> : <LeadsTable />}
    </Flex>
  );
};
