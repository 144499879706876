import {
  Box,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import TrLoading from 'components/app/TrLoading';
import FAIcon from 'components/lib/FAIcon';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import {
  getCandidate,
  listCandidates,
  setloadingCandidate,
  useBulkRejectCandidateMutation,
  useDeleteCandidatesMutation,
  useGetCandidatesMutation,
  useGroupCandidatesMutation,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import { getJob, useGetJobsMutation } from 'store/jobs.slice';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import {
  CandidateData,
  candidateDataGroup,
  candidateGroups,
  candidateJobs,
  rowData,
} from 'types';
import CandidatesDrawer from '../../../Drawer';
import CandidatesModal from '../../../Modals';
import CandidatesAssociateJobModal from '../../../Modals/AssociateJobs';
import CandidatesTagModal from '../../../Modals/Tag';
import BulkAction from '../../Bulk.Action';
import GroupRow from './Row';
import { useLocation } from 'react-router-dom';
import EmailsModal from 'components/app/Global/Email/Modal';
import ChangeStatusModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/ChangeStatus';

interface countInt {
  job_id: number;
  count: number;
  grouplist: candidateGroups[];
  update: boolean;
  list?: any;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TableGroup = ({ isCollapse, isGroupByJob }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  // const { fetchGroup, resGroups } = useGroupServices();
  const [reqGroups, resGroups] = useGroupCandidatesMutation();

  const {
    reloadGroupings,
    candidateGroups,
    candidatePgBtn,
    candidatePage,
    candidateOthers,
    candidateData,
  } = useSelector((state: any) => state.candidates);
  const { candidate_filters, filter } = useSelector(
    (state: any) => state.candidateFilters
  );
  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();
  const [reqGetJob] = useGetJobsMutation();

  const [isIndeterminate, setisIndeterminate] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [grouplist, setGroupList] = useState<candidateGroups[]>([]);
  const grouplistRef = useRef(grouplist);
  const [grouplistComparison, setGroupListComparison] = useState<
    candidateGroups[]
  >([]);
  const [openAccordionItems, setOpenAccordionItems] = useState(
    grouplistRef.current.map((val) => val.job_id)
  );

  const urlQuery = useQuery();

  const [isCheck, setIsCheck] = useState([]);
  const [isCheckedJob, setIsCheckedJob] = useState([]);
  const [rowId, setRowId] = useState(Number(urlQuery?.get('rowId')) || 1);
  const [isCheckWithJob, setIsCheckWithJob] = useState([] as any);
  const [totalCandidate, setTotalCandidate] = useState<number>(0);
  const [pushedItems, setPushedItems] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const [assocJobs, setAssocJobs] = useState([]);
  const [candidateId, setCandidateId] = useState(0);
  const [isBulkAction, setIsBulkAction] = useState(false);
  const [assocId, setAssocId] = useState(0);
  const [assocIdList, setAssocIdList] = useState([0]);
  const [mainStatus, setMainStatus] = useState(0);
  const [subStatus, setSubStatus] = useState(0);
  const [candidateIdList, setCandidateIdList] = useState([0]);
  const [emailList, setEmailList] = useState([]);
  const [reload, setReload] = useState(false);
  const stateTab: any = params.candidatesTab;
  const [firstLoad, setFirstLoad] = useState(true);
  const [jobId, setJobId] = useState<number>(
    Number(urlQuery?.get('jobId')) || null
  );
  const [grouplistLoading, setGrouplistLoading] = useState(false);
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqBulkReject, resBulkReject] = useBulkRejectCandidateMutation();
  const [totalCandidates, setTotalCandidates] = useState(
    Number(urlQuery?.get('total')) || 1
  );

  const [activeGroup, setActiveGroup] = useState<candidateGroups | null>(null);
  const [nextPage, setNextPage] = useState<number>(1);
  const [newListLoading, setNewListLoading] = useState(false);

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (!isCollapse) {
      setOpenAccordionItems(grouplistRef.current.map((val) => val.job_id));
    } else {
      setOpenAccordionItems([]);
    }
  }, [isCollapse, grouplist]);

  useEffect(() => {
    if (resGroups.isError) {
      setGrouplistLoading(false);
      setGroupList([]);
      setGroupListComparison([]);
    }
  }, [resGroups.isError]);

  const loadPushed = async (candidates: any) => {
    await setPushedItems(candidates);
  };

  useEffect(() => {
    if (grouplist.length != grouplistComparison.length) {
      // setGroupList(grouplistComparison);
    }
    let candidates = [] as any;
    for (const group of grouplist) {
      let rowNumber = 0;
      const list = group?.list?.data;

      if (list?.length > 0) {
        for (const item of list) {
          rowNumber++;
          candidates.push({
            assoc_id: item.associate_id,
            email: item.emails[0]?.email || null,
            job_id: item.job_id,
            status_id: item.status_id,
            sub_id: item.sub_id,
            id: Number(item.candidate_id),
            rowId: rowNumber,
          });
        }
      }
    }

    loadPushed(candidates);
  }, [grouplist, grouplistComparison]);

  const handleCheck = async (e: any, job_id: number) => {
    const { id, checked } = e.target;
    console.log({ checked });
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    setIsCheckWithJob([...isCheckWithJob, { id: ID, job_id: job_id }]);
    if (!checked) {
      console.log({ checked, isCheck, ID });
      setIsCheck(isCheck.filter((item) => item !== ID));
      setIsCheckWithJob(
        isCheckWithJob.filter(
          (item: any) => item.id !== ID || item.job_id !== job_id
        )
      );
    }
  };

  const abortFilterControllerRef = useRef<AbortController | null>(null);
  const fetchPin = async () => {
    if (params.candidatesTabState) {
      if (abortFilterControllerRef.current) {
        abortFilterControllerRef.current.abort();
      }

      abortFilterControllerRef.current = new AbortController();
      await reqPinList({
        signal: abortFilterControllerRef.current.signal,
      });
    }
  };

  const handleCheckJob = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheckedJob([...isCheckedJob, ID]);
    const jobs = grouplistRef.current.find(
      (item: any) => item.job_id === ID
    ) as any;

    const selectedCandidateIdList =
      jobs?.list?.data.map((item: any) => item.candidate_id) || [];

    if (!checked) {
      setIsCheckedJob((prevIsCheckedJob) =>
        prevIsCheckedJob.filter((item) => item !== ID)
      );
      setIsCheck(
        isCheck.filter((item) => !selectedCandidateIdList.includes(item))
      );
      setIsCheckWithJob(
        isCheckWithJob.filter(
          (item: any) =>
            !selectedCandidateIdList.includes(item.id) || item.job_id !== ID
        )
      );
    } else {
      let selected = [] as number[];
      if (jobs?.list?.data?.length > 0) {
        selected = jobs?.list?.data.map((item: any) => {
          return Number(item.candidate_id);
        });
      }

      setIsCheck([...isCheck, ...selected]);
      let candidateWithJob = [] as any;
      selected.map((id: number) => {
        candidateWithJob.push({ id, job_id: ID });
      });

      setIsCheckWithJob([...isCheckWithJob, ...candidateWithJob]);
    }
  };

  useEffect(() => {
    const groups = candidateGroups?.data || candidateGroups;
    populateDataGroup(groups);
  }, [candidateGroups, firstLoad, resGroups.isSuccess]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAssociate,
    onOpen: onOpenAssociate,
    onClose: onCloseAssociate,
  } = useDisclosure();

  const {
    isOpen: isOpenTag,
    onOpen: onOpenTag,
    onClose: onCloseTag,
  } = useDisclosure();

  const {
    isOpen: isOpenCandidate,
    onOpen: onOpenCandiate,
    onClose: onCloseCandidate,
  } = useDisclosure();

  const {
    isOpen: isOpenChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure();

  const {
    isOpen: isOpenSendEmail,
    onOpen: onOpenSendEmail,
    onClose: onCloseSendEmail,
  } = useDisclosure();

  const sortData = (data: candidateGroups[]) => {
    setFirstLoad(true);
    setGrouplistLoading(true);
    let total: number = 0;

    let candidates: rowData[] = [];
    let groups: candidateGroups[] = [];

    if (!Array.isArray(data)) {
      console.error('sortData was called with non-iterable data:', data);
      // Optionally, handle the case where data is not an array, e.g., by returning or setting a state
      return;
    }

    const sorted = [...data];

    let job_ids: number[] = [];
    Promise.all(
      sorted.map((item: candidateGroups) => {
        total += Number(item.total);
        job_ids.push(item.job_id);
        let rowNumber = 0;
        const candidateData: candidateDataGroup[] = item.candidateData;
        let candidateDataItem: candidateDataGroup[] = [];
        item.candidates?.split(',').map((candidate: string) => {
          rowNumber++;
          const info = candidateData.find(
            (cand: candidateDataGroup) => cand.id === Number(candidate)
          );
          candidates.push({
            ...info,
            id: Number(candidate),
            rowId: rowNumber,
          });
          candidateDataItem.push({
            ...info,
            id: Number(candidate),
            rowId: rowNumber,
          });
        });
        if (item.total > 0) {
          groups.push({
            ...item,
            list: [] as any,
            loading: true,
            candidateData: candidateDataItem,
          });
        }
      })
    );

    // console.log({ data, groups });

    const populate = async () => {
      await setGroupList(groups);
      await dispatch(listCandidates({ candidateGroups: groups }));
      // await setPushedItems(candidates);
      await setTotalCandidate(total);
      setGrouplistLoading(false);
      await setGroupListComparison(groups);
      await fetchData(groups);
    };
    populate();
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchData = debounce(async (groups: candidateGroups[]) => {
    groups.map(async (group: candidateGroups, key: number) => {
      const params = {
        ...candidatePgBtn,
        filter: filter || candidatePgBtn?.filter,
        job_status: null,
        status: null,
        take: 25,
        job_id: group.job_id,
        page: 1,
      };

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      const res: any = await reqCandidates({
        data: params,
        signal: null,
      });

      // Using a functional update to ensure we always have the latest state
      setGroupList((currentList) => {
        return currentList.map((item) => {
          if (item.job_id === group.job_id) {
            return {
              ...item,
              loading: false,
              list: res?.data?.data || [],
            };
          }
          return item;
        });
      });

      delay(500 * key);
    });
    dispatch(setloadingCandidate({ candidateLoading: false }));
  }, 1000);

  const abortGroupControllerRef = useRef<AbortController | null>(null);

  const fetchGroup = async () => {
    if (params.candidatesTabState) {
      // ADDED THIS TO OVERRIDE JOB_STATUS, STATUS, AND JOB_ID PARAMS FROM JOBS
      const newParam = {
        ...candidatePgBtn,
        filter: filter || candidatePgBtn?.filter,
        job_status: null,
        status: null,
        job_id: null,
      };

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      if (abortGroupControllerRef.current) {
        abortGroupControllerRef.current.abort();
      }

      abortGroupControllerRef.current = new AbortController();
      await reqGroups({
        data: newParam,
        signal: abortGroupControllerRef.current.signal,
      }).then((res: any) => {
        const data: candidateGroups[] = res.data?.data?.data;
        sortData(data);
      });
    }
  };
  const resetBulkActions = () => {
    setIsBulkAction(false);
    setIsCheck([]);
    setIsCheckedJob([]);
    setIsCheckAll(false);
    setIsCheckWithJob([]);
  };

  const activeFilters = useMemo(() => {
    const activeFilter = candidate_filters.find(
      (item: any) => item.id === (candidatePgBtn?.filter || filter)
    );
    return activeFilter;
  }, [candidate_filters, candidatePgBtn?.filter, filter, reloadGroupings]);

  useEffect(() => {
    console.log({ activeFilters, candidatePgBtn, reloadGroupings });
  }, []);

  useEffect(() => {
    const fetch = debounce(async () => {
      await fetchGroup();
    }, 1000);

    if (params['*'].includes('candidates')) {
      //group seems always be true. making the fe calls the api when even you close a modal
      if (params?.candidatesTabState !== 'search') {
        if (activeFilters?.group_by_job) {
          fetch();
        }
      }
    }
  }, [activeFilters?.group_by_job, candidatePgBtn, reloadGroupings]);

  const handleSelectAll = async (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    if (!checked) {
      setIsCheck([]);
      setIsCheckWithJob([]);
      setIsCheckedJob([]);
    } else {
      setIsCheckWithJob(pushedItems);
      setIsCheck(pushedItems.map((item: rowData) => item.id));
      setIsCheckedJob(
        Array.from(new Set(pushedItems.map((item: rowData) => item.job_id)))
      );
      setisIndeterminate(false);
    }
  };

  useEffect(() => {
    if (isCheck.length < pushedItems.length && isCheck.length > 0) {
      setisIndeterminate(true);
    } else {
      setisIndeterminate(false);
    }
  }, [isCheck]);

  const [isSorted, setIsSorted] = useState(false);
  const [sortOrder, setSortOrder] = useState(true);
  const [orderBy, setOrderBy] = useState(null);

  const handleSort = async (column_name: string) => {
    const newSortOrder = sortOrder ? 'ASC' : 'DESC';
    setSortOrder(!sortOrder);
    setIsSorted(true);
    setOrderBy({
      column_name: column_name,
      sort_order: newSortOrder,
    });
  };

  useEffect(() => {
    if (isSorted) {
      const params = {
        ...candidatePgBtn,
        job_id: null as any,
        ...(orderBy !== null && {
          orderBy: {
            column_name: orderBy?.column_name,
            sort_order: orderBy?.sort_order,
          },
        }),
      };
      dispatch(
        listCandidates({
          candidatePgBtn: params,
        })
      );

      setIsSorted(false);
    }
  }, [isSorted]);

  const columnDict = {
    // candidate_details: 'Candidate Details',
    contact: 'Contact',
    location: 'Location',
    resume_update_on: 'Resume Update on',
    work_status: 'Work Status',
    last_activity: 'Last Activity',
    associated_jobs: 'Associated Jobs',
    tags: 'Tags',
    distance: 'Location/Distance',
    submitted_on: 'Submitted on/Age',
    interview_status: 'Interview Status',
    interview_date: 'Interview Date',
    offered_date: 'Offered Date/Age',
    placement_date: 'Placement Date',
    candidate_start_date: 'Candidate Start Date',
    last_messaged_on: 'Last Messaged on',
    last_called_on: 'Last Called on',
  } as any;
  const [tableHeader, setTableHeader] = useState<{ label: any }[]>([
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Contact</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" alignItems="center">
            Location
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
          key={`table-candidate-header-2`}
        >
          <Flex gap="10px" alignItems="center">
            Resume Update On
            <Flex
              onClick={() => handleSort('resume_update_on')}
              cursor="pointer"
            >
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
          key={`table-candidate-header-3`}
        >
          <Box>Work Status</Box>
        </Th>
      ),
    },

    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
          key={`table-candidate-header-5`}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Last Activity
            <Flex cursor="pointer" onClick={() => handleSort('last_activity')}>
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },

    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
          key={`table-candidate-header-6`}
        >
          <Box>Associated Jobs</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
          key={`table-candidate-header-7`}
        >
          <Box>Tags</Box>
        </Th>
      ),
    },
  ]);
  const selectedColumns = useMemo(() => {
    if (candidateOthers?.[1]?.columns) {
      let sortedColumns = Object.entries(candidateOthers?.[1]?.columns)
        .filter(([key, value]) => value !== 0)
        .map(([key, value]) => ({ key, value }));
      sortedColumns.sort((a: any, b: any) => a.value - b.value);

      let newColumns = {} as any;
      sortedColumns.forEach((item: any) => (newColumns[item.key] = item.value));

      const header = [] as { label: any }[];
      const columns = [] as string[];
      Object.keys(newColumns).map((column: string, index: number) => {
        const formattedColumn = columnDict[column];
        columns.push(column);
        if (index === 0) {
          // header.push({
          //   label: (
          //     <Th
          //       sx={{
          //         position: 'sticky',
          //         left: 0,
          //         bg: 'default.white.600',
          //         color: 'default.gray.600',
          //         boxShadow: 'inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)',
          //       }}
          //       key={`table-candidate-header-1`}
          //     >
          //       <Flex gap="30px">
          //         <Checkbox
          //           onChange={handleSelectAll}
          //           isIndeterminate={isIndeterminate}
          //           checked={isCheckAll}
          //           defaultChecked={false}
          //           colorScheme="purple"
          //         />
          //         <Flex gap="10px" alignItems="center">
          //           {formattedColumn}
          //           {(column === 'resume_update_on' ||
          //             column === 'last_activity' ||
          //             column === 'submitted_on' ||
          //             column === 'interview_date' ||
          //             column === 'offered_date' ||
          //             column === 'placement_date' ||
          //             column === 'candidate_start_date' ||
          //             column === 'last_messaged_on' ||
          //             column === 'last_called_on') && (
          //             <Flex
          //               onClick={() =>
          //                 handleSort(
          //                   column == 'placement_date'
          //                     ? 'placement_date'
          //                     : column == 'candidate_start_date'
          //                     ? 'candidate_start_date'
          //                     : column
          //                 )
          //               }
          //               cursor="pointer"
          //             >
          //               <FAIcon iconName="sort" />
          //             </Flex>
          //           )}
          //         </Flex>
          //       </Flex>
          //     </Th>
          //   ),
          // });
        } else {
          let sx = {
            bg: 'default.white.600',
            color: 'default.gray.600',
          } as object;
          let flx = {} as object;
          if (column === 'interview_date') {
            sx = {
              ...sx,
              textAlign: 'center',
            };
            flx = {
              justifyContent: 'center',
            };
          }
          header.push({
            label: (
              <Th sx={sx}>
                <Flex gap="10px" alignItems="center" sx={flx}>
                  {formattedColumn}
                  {(column === 'resume_update_on' ||
                    column === 'last_activity' ||
                    column === 'submitted_on' ||
                    column === 'interview_date' ||
                    column === 'offered_date' ||
                    column === 'placement_date' ||
                    column === 'candidate_start_date' ||
                    column === 'last_messaged_on' ||
                    column === 'last_called_on') && (
                    <Flex
                      onClick={() =>
                        handleSort(
                          column == 'placement_date'
                            ? 'placement_date'
                            : column == 'candidate_start_date'
                            ? 'candidate_start_date'
                            : column
                        )
                      }
                      cursor="pointer"
                    >
                      <FAIcon iconName="sort" />
                    </Flex>
                  )}
                </Flex>
              </Th>
            ),
          });
        }
      });
      setTableHeader(header);
      return columns;
    } else {
      return [];
    }
  }, [candidateOthers?.[1]?.columns]);

  const populateDataGroup = (data: candidateGroups[]) => {
    let total: number = 0;

    let candidates: rowData[] = [];
    let groups: candidateGroups[] = [];

    const sorted = [...data];

    let job_ids: number[] = [];
    Promise.all(
      sorted.map((item: candidateGroups) => {
        total += Number(item.total);
        job_ids.push(item.job_id);
        let rowNumber = 0;
        const candidateData: candidateDataGroup[] = item.candidateData;
        let candidateDataItem: candidateDataGroup[] = [];
        item.candidates?.split(',').map((candidate: string) => {
          rowNumber++;
          const info = candidateData.find(
            (cand: candidateDataGroup) => cand.id === Number(candidate)
          );
          candidates.push({
            ...info,
            id: Number(candidate),
            rowId: rowNumber,
          });
          candidateDataItem.push({
            ...info,
            id: Number(candidate),
            rowId: rowNumber,
          });
        });
        groups.push({
          ...item,
          candidateData: candidateDataItem,
        });
      })
    );

    // setPushedItems(candidates);
    setTotalCandidate(total);

    // setGroupList(groups);
  };

  useEffect(() => {
    if (resGroups.isSuccess) {
      setFirstLoad(true);
      setGrouplistLoading(true);
      let total: number = 0;
      const data: candidateGroups[] = resGroups.data?.data?.data;
      // let candidates: rowData[] = [];
      let candidates: candidateDataGroup[] = [];
      let groups: candidateGroups[] = [];

      let job_ids: number[] = [];
      Promise.all(
        data.map((item: candidateGroups) => {
          total += Number(item.total);
          job_ids.push(item.job_id);
          let rowNumber = 0;
          const candidateData: candidateDataGroup[] = item.candidateData;
          let candidateDataItem: candidateDataGroup[] = [];
          item.candidates?.split(',').map((candidate: string) => {
            // rowNumber++;
            const info = candidateData.find(
              (cand: candidateDataGroup) => cand.id === Number(candidate)
            );
            candidates.push({
              ...info,
              // id: Number(candidate),
              // rowId: rowNumber,
            });
            candidateDataItem.push({
              ...info,
              // id: Number(candidate),
              // rowId: rowNumber,
            });
          });
          groups.push({
            ...item,
            candidateData: candidateDataItem,
          });
        })
      );

      const loadData = async () => {
        await dispatch(listCandidates({ candidateGroups: groups }));
        // await setPushedItems(candidates);
        await setTotalCandidate(total);

        // await setGroupList(groups);
        setGrouplistLoading(false);
      };
      loadData();
    }
  }, [resGroups.isSuccess]);

  useEffect(() => {
    if (resBulkReject.isSuccess) {
      fetchGroup();
      resetBulkActions();
      fetchPin();
    }
  }, [resBulkReject.isSuccess]);

  // store totalCandidates when refreshing
  useEffect(() => {
    if (resGroups.isSuccess && isOpen && activeList.length == 0) {
      const index = candidateGroups.findIndex(
        (item: candidateGroups) =>
          item?.job_id === candidateData?.jobs?.[0]?.job_id
      );

      if (candidateGroups?.[index]?.candidateData) {
        const find = grouplistRef.current.find(
          (item: any) => item.job_id === candidateGroups?.[index].job_id
        );

        const candidates =
          find?.list?.data?.map((item: any, key: number) => {
            return {
              assoc_id: item.associate_id,
              email: item.emails[0]?.email || null,
              first_name: item.first_name,
              id: item.candidate_id,
              job_id: item.job_id,
              last_name: item.last_name,
              rowId: key + 1,
              status_id: item.status_id,
              sub_id: item.sub_id,
            };
          }) || [];
        console.log({ candidates });
        setActiveList(candidates);
        // setActiveList(candidateGroups?.[index]?.candidateData);
      }
    }
  }, [isOpen, resGroups]);

  const candidateOpen = async (data: candidateDataGroup) => {
    if (data?.rowId) {
      const active = grouplistRef.current.find(
        (item: candidateGroups) => item.job_id === data.job_id
      );
      const find = grouplistRef.current.find((item) => item.job_id === jobId);
      // console.log({ find });
      if (find) {
        await activeListPopulate(find?.list?.data || []);
      }
      onOpen();
      setRowId(data?.rowId);

      setTotalCandidates(active?.total || 0);
      setActiveGroup(active);
      setNextPage(1);
    }
  };

  const [isRefresh, setIsRefresh] = useState(false);
  useEffect(() => {
    if (urlQuery?.get('jobId')) {
      const active = grouplist.find(
        (item: candidateGroups) =>
          item.job_id === Number(urlQuery?.get('jobId'))
      );

      if (active) {
        setActiveGroup(active);
      }
    }
  }, [grouplist, urlQuery?.get('jobId')]);

  useEffect(() => {
    if (urlQuery?.get('jobId')) {
      const loadData = async () => {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        const param = {
          ...candidatePgBtn,
          filter: filter || candidatePgBtn?.filter,
          job_id: Number(urlQuery?.get('jobId')),
          page: 1,
          take: 25,
        };

        abortControllerRef.current = new AbortController();
        await reqCandidates({
          data: param,
          // signal: abortControllerRef.current.signal,
        }).then((res: any) => {
          const active = {
            job_id: Number(urlQuery?.get('jobId')),
            job_title: '',
            total: res?.data?.data?.count || 0,
            client_name: '',
            client_id: 1,
            city: '',
            state: '',
            candidates: '',
            candidateData: [
              {
                id: 0,
                rowId: 1,
              },
            ],
            list: res?.data?.data || ([] as any),
            loading: false,
          } as any;
          const list =
            res?.data?.data?.data?.map((item: any, key: number) => {
              return {
                first_name: item.first_name,
                id: item.candidate_id,
                job_id: Number(urlQuery?.get('jobId')),
                last_name: item.last_name,
                rowId: key + 1,
              };
            }) || [];

          setActiveList(list);

          setActiveGroup(active);
        });
      };
      loadData();
    }
  }, []);

  // fetches groupList to get activeList and store totalCandidates when refreshing
  useMemo(() => {
    if (params.candidatesId && !isOpen) {
      if (candidateData?.id !== params.candidatesId) {
        const loadapi = async () => {
          await delay(200);
          fetchGroup();
        };
        loadapi();
        setIsRefresh(true);
        onOpen();
      }
      // dispatch(getJob({ jobData: {} }));
    }
  }, [params.candidatesId, isOpen, candidateGroups, activeList]);

  const handlePrevData = async () => {
    setIsRefresh(false);
    const current = activeList.find((item: rowData) => item.rowId === rowId);

    const index =
      candidateGroups.findIndex(
        (item: candidateGroups) => item?.job_id === current?.job_id
      ) - 1;

    let prevRow = rowId - 1;

    let candidate: rowData | null | undefined = activeList.find(
      (item: rowData) => item.rowId === prevRow
    );

    let PrevIndexRow = activeList.findIndex(
      (item: rowData) => item.rowId === prevRow
    );

    let totalCan = candidateGroups[index]?.total || totalCandidates;

    if (PrevIndexRow < 0) {
      if (candidateGroups[index]) {
        const prevJob = candidateGroups[index]?.job_id;
        const find = grouplist.find((item: any) => item.job_id === prevJob);
        totalCan = find?.total || 0;

        const candidateData =
          find?.list?.data.map((item: any, key: number) => {
            return {
              assoc_id: item.associate_id,
              email: item.emails[0]?.email || null,
              first_name: item.first_name,
              id: item.candidate_id,
              job_id: item.job_id,
              last_name: item.last_name,
              rowId: key + 1,
              status_id: item.status_id,
              sub_id: item.sub_id,
            };
          }) || [];

        const last: rowData | null | undefined =
          candidateData[candidateData.length - 1];
        prevRow = last?.rowId;

        setActiveList(candidateData);
        candidate = candidateData.find(
          (item: rowData) => item.rowId === prevRow
        );
        setRowId(prevRow);
      }
    }

    await setTotalCandidates(totalCan);

    if (candidate) {
      const candidateID = candidate.id;
      setRowId(prevRow);
      const prevData = candidate;

      navigate(
        `/candidates/views/${candidatePage.currentPage}/${candidateID}/${stateTab}?jobId=${prevData?.job_id}&total=${totalCan}&IsGroup=1&rowId=${prevRow}`
      );
      dispatch(
        getCandidate({
          // candidateData: prevData,
          candidateDataJobId: prevData?.job_id,
        })
      );
      reqGetCandidate({ id: candidateID });
    }
  };

  const fetchNextPage = async (job_id: number) => {
    const param = {
      ...candidatePgBtn,
      job_id,
      page: nextPage + 1,
      take: 25,
    };

    const res = (await reqCandidates({ data: param })) as any;
    // console.log({ res });
    return res?.data?.data?.data || [];
    // await setNextPage((prevCount) => prevCount + 1);
  };

  const activeListPopulate = async (candidates: any) => {
    let nextRow = 1;
    const newActive = candidates.map((item: any, key: number) => {
      return {
        assoc_id: item.associate_id,
        email: item.emails[0]?.email || null,
        first_name: item.first_name,
        id: item.candidate_id,
        job_id: item.job_id,
        last_name: item.last_name,
        rowId: key + nextRow,
        status_id: item.status_id,
        sub_id: item.sub_id,
      };
    });

    await setActiveList(newActive);
  };

  useEffect(() => {
    const find = grouplistRef.current.find((item) => item.job_id === jobId);
    // console.log({ find });
    if (find) {
      activeListPopulate(find?.list?.data || []);
    }
  }, [jobId, grouplistRef]);

  const handleNextData = async () => {
    setIsRefresh(false);
    let current: rowData = activeList.find(
      (item: rowData) => item.rowId === rowId
    );

    const reference = grouplistRef.current.find(
      (item) => item.job_id === current?.job_id
    );

    const index =
      candidateGroups.findIndex(
        (item: candidateGroups) => item?.job_id === current?.job_id
      ) + 1;

    let nextRow = rowId + 1;

    let currentList = [...activeList];

    let NextIndexRow = currentList.findIndex(
      (item: rowData) => item.rowId === nextRow
    );

    if (reference?.total < nextRow) {
      if (activeList.length < reference?.list?.total) {
        setNewListLoading(true);
        const list = await fetchNextPage(activeGroup.job_id);

        const newActive = list.map((item: any, key: number) => {
          return {
            assoc_id: item.associate_id,
            email: item.emails[0]?.email || null,
            first_name: item.first_name,
            id: item.candidate_id,
            job_id: item.job_id,
            last_name: item.last_name,
            rowId: key + nextRow,
            status_id: item.status_id,
            sub_id: item.sub_id,
          };
        });
        currentList = [...activeList, ...newActive];
        console.log({ currentList });
        setActiveList(currentList);
        setNewListLoading(false);
      } else {
        NextIndexRow = -1;
      }
    } else {
      // NextIndexRow = -1;
    }

    let candidate: rowData | null | undefined = currentList.find(
      (item: rowData) => item.rowId === nextRow
    );

    if (NextIndexRow < 0) {
      if (candidateGroups[index]) {
        nextRow = 1;
        const nextJob = candidateGroups[index]?.job_id;
        const find = grouplist.find((item: any) => item.job_id === nextJob);

        const candidates =
          find?.list?.data.map((item: any, key: number) => {
            return {
              assoc_id: item.associate_id,
              email: item.emails[0]?.email || null,
              first_name: item.first_name,
              id: item.candidate_id,
              job_id: item.job_id,
              last_name: item.last_name,
              rowId: key + 1,
              status_id: item.status_id,
              sub_id: item.sub_id,
            };
          }) || [];

        setActiveList(candidates);
        setTotalCandidate(find?.total || 0);
        candidate = candidates.find((item: rowData) => item.rowId === nextRow);

        // console.log({ index, candidateGroups, candidate });
        setRowId(1);
        // setTotalCandidate;
        setTotalCandidates(find?.total || 0);
      } else {
        candidate = undefined;
        onClose();
        navigate(`/candidates/${params?.candidatesTabState || 'search'}`);
      }
    }

    if (candidate) {
      const candidateID = candidate.id;
      setRowId(nextRow);
      const nextData = candidate;
      navigate(
        `/candidates/views/${candidatePage.currentPage}/${candidateID}/${stateTab}?jobId=${nextData?.job_id}&total=${totalCandidates}&IsGroup=1&rowId=${nextRow}`
      );
      dispatch(
        getCandidate({
          // candidateData: nextData,
          candidateDataJobId: nextData?.job_id,
        })
      );
      // reqGetCandidate({ id: candidateID });
      candidate = undefined;
    } else {
      onClose();
      navigate(`/candidates/${params?.candidatesTabState || 'search'}`);
      candidate = undefined;
    }
  };

  const associateToJob = async (candidate: CandidateData) => {
    let list: any = [];

    if (candidate.jobs.length > 0) {
      await Promise.all(
        candidate.jobs.map((job: candidateJobs) => {
          list.push(job.job_id);
        })
      );
    }

    setAssocJobs(list);
    setCandidateId(candidate?.id);
    onOpenAssociate();
  };

  const editCandidate = async (candidate: CandidateData) => {
    reqGetCandidate({ id: candidate.id });
    onOpenCandiate();
    setCandidateId(candidate.id);
    setReload(false);
  };

  const statusChangeSuccess = async (res: any) => {
    setIsCheck([]);
    setIsCheckedJob([]);
    setIsCheckWithJob([]);
    setIsCheckAll(false);
    setIsBulkAction(false);
    // setReload(true);
    fetchGroup();
  };

  const associateJobSuccess = async () => {
    setIsBulkAction(false);
    reqFilterList({});
    fetchPin();

    reqGetJob({ id: params.jobsId });
    fetchGroup();
  };

  useEffect(() => {
    grouplistRef.current = grouplist;
  }, [grouplist]);

  const updateCount = async (data: countInt) => {
    // const groups = candidateGroups?.data || candidateGroups;
    const groups = grouplist;

    setGrouplistLoading(false);
    if (data.update) {
      setGroupList((prevItems) =>
        prevItems.map((item) =>
          item.job_id === data.job_id
            ? { ...item, total: data.count, list: data.list || item.list }
            : item
        )
      );
    }
  };

  const bulkAssociateToJob = async () => {
    setIsBulkAction(true);
    const candidateList = pushedItems.filter((item: rowData) =>
      isCheck.includes(item.id)
    );
    let list: any = [];
    candidateList.map(async (candidate: rowData) => {
      list.push(candidate.job_id);
    });

    const uniqueCandidateIdList = isCheckWithJob.reduce(
      (unique: any, item: any) => {
        if (!unique[item.id]) {
          unique[item.id] = item;
        }
        return unique;
      },
      {}
    );

    setAssocJobs(list);
    setCandidateIdList(
      Object.values(uniqueCandidateIdList).map((li: any) => li.id)
    );
    onOpenAssociate();
  };

  const bulkEmail = async () => {
    setIsBulkAction(true);
    const candidateList = pushedItems.filter((item: rowData) =>
      isCheck.includes(item.id)
    );

    // //making sure getting only one candidate
    const uniqueCandidate: rowData[] = Object.values(
      candidateList.reduce((unique: any, item: any) => {
        if (!unique[item.id]) {
          unique[item.id] = item;
        }
        return unique;
      }, {})
    );

    const emails = uniqueCandidate?.map((candidate: rowData) => {
      return candidate.email;
    });
    setEmailList(emails);
    setCandidateId(candidateList[0].id);

    const data = {
      candidate_id: uniqueCandidate?.[0]?.job_id,
      job_id: uniqueCandidate?.[0]?.job_id || null,
    } as any;
    // console.log({ data });
    // setJobId(data.job_id);
    Promise.all([reqPair(data), onOpenSendEmail()]);
    reqGetCandidate({ id: candidateList[0].id });
  };

  const bulkReject = async () => {
    setIsBulkAction(true);
    setReload(false);
    const candidateList = pushedItems.filter((item: rowData) =>
      // isCheck.includes(item.id)
      isCheckWithJob.some(
        (check: any) => check?.job_id === item.job_id && check?.id === item?.id
      )
    );
    let associateIdList: any = [];
    let mainStatusSet = false;
    candidateList?.map((candidate: rowData) => {
      associateIdList.push(Number(candidate?.assoc_id));
      if (!mainStatusSet) {
        setMainStatus(Number(candidate?.status_id));
        setSubStatus(Number(candidate?.sub_id));
        mainStatusSet = true;
      }
    });
    if (associateIdList.length > 0) {
      const data = {
        reason: 14,
        idList: associateIdList,
      };
      reqBulkReject({ data });
    }
  };

  const bulkChangeStatus = async () => {
    setIsBulkAction(true);
    setReload(false);
    const candidateList = pushedItems.filter((item: rowData) =>
      // isCheck.includes(item.id)
      isCheckWithJob.some(
        (check: any) => check?.job_id === item.job_id && check?.id === item?.id
      )
    );
    let associateIdList: any = [];
    let mainStatusSet = false;
    candidateList?.map((candidate: rowData) => {
      associateIdList.push(Number(candidate?.assoc_id));
      if (!mainStatusSet) {
        setMainStatus(Number(candidate?.status_id));
        setSubStatus(Number(candidate?.sub_id));
        mainStatusSet = true;
      }
    });
    setAssocIdList(associateIdList);
    onOpenChangeStatus();
  };

  const bulkTags = () => {
    setIsBulkAction(true);
    setReload(false);
    onOpenTag();
  };

  const successModal = () => {
    fetchPin();

    fetchGroup();
  };

  return (
    <Box px={6} pt={7} justifyContent="space-between">
      {isCheckWithJob.length > 0 && (
        <BulkAction
          count={isCheckWithJob.length}
          filter={candidatePgBtn?.filter}
          bulkAssociateToJob={bulkAssociateToJob}
          bulkEmail={bulkEmail}
          bulkChangeStatus={bulkChangeStatus}
          bulkTags={bulkTags}
          bulkReject={bulkReject}
          isRejectLoading={resBulkReject.isLoading}
          all={false}
          mergeCandidates={() => {}}
        />
      )}

      <TableContainer
        boxSizing="border-box"
        border="1px solid #EEEEEE"
        borderRadius="4px"
        height="70vh"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <Tr
              bg="default.white.600"
              key={'header-group'}
              sx={{ position: 'sticky' }}
            >
              <Th
                sx={{
                  position: 'sticky',
                  left: 0,
                  bg: 'default.white.600',
                  color: 'default.gray.600',
                  boxShadow: 'inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)',
                }}
                key={`table-candidate-header-1`}
              >
                <Flex gap="30px">
                  <Checkbox
                    onChange={handleSelectAll}
                    isIndeterminate={isIndeterminate}
                    checked={isCheckAll}
                    defaultChecked={false}
                    colorScheme="purple"
                  />
                  <Box>Candidate Details</Box>
                </Flex>
              </Th>
              {tableHeader.map((title) => title.label)}
            </Tr>
          </Thead>
          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {resGroups.isLoading ? (
              <TrLoading rows={10} columns={tableHeader.length} />
            ) : (
              grouplist.map((item: candidateGroups, key: number) => {
                return (
                  <GroupRow
                    item={item}
                    isCheckedJob={isCheckedJob}
                    isCheck={isCheck}
                    openAccordionItems={openAccordionItems}
                    setOpenAccordionItems={(e) => setOpenAccordionItems(e)}
                    setReload={(e) => setReload(e)}
                    handleCheck={(e, job_id) => handleCheck(e, job_id)}
                    handleCheckJob={(e) => handleCheckJob(e)}
                    isCheckWithJob={isCheckWithJob}
                    candidateOpen={(e) => candidateOpen(e)}
                    associateToJob={(e) => associateToJob(e)}
                    editCandidate={(e) => editCandidate(e)}
                    updateCount={(data) => updateCount(data)}
                    reload={reload}
                    setTotalCandidate={(e) => setTotalCandidate(e)}
                    setActiveList={(e) => setActiveList(e)}
                    setFirstLoad={(e) => setFirstLoad(e)}
                    fetchGroup={fetchGroup}
                    groupList={grouplist}
                    colCount={tableHeader.length}
                    columns={selectedColumns}
                    setJobId={(job_id) => setJobId(job_id)}
                  />
                );
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {isOpen && (
        <CandidatesDrawer
          // key={"0"}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setNextPage(1);
          }}
          rowId={rowId}
          setRowId={setRowId}
          totalCandidates={totalCandidates}
          handlePrevData={handlePrevData}
          handleNextData={handleNextData}
          isLoading={resGetCandidate.isLoading || newListLoading}
          candidates={activeList}
          isRefresh={isRefresh}
        />
      )}
      {isOpenTag && (
        <CandidatesTagModal
          isOpen={isOpenTag}
          onClose={onCloseTag}
          id={candidateId}
          idList={isCheck}
          isBulkTag={isBulkAction}
          onSuccess={() => setReload(true)}
        />
      )}
      {isOpenChangeStatus && (
        <ChangeStatusModal
          isOpen={isOpenChangeStatus}
          onClose={onCloseChangeStatus}
          id={assocId}
          idList={assocIdList}
          main_status={mainStatus}
          sub_status={subStatus}
          onSuccess={(e: any) => statusChangeSuccess(e)}
          isBulkChangeStatus={isBulkAction}
        />
      )}
      {isOpenAssociate && (
        <CandidatesAssociateJobModal
          isOpen={isOpenAssociate}
          onClose={onCloseAssociate}
          id={candidateId}
          idList={candidateIdList}
          assoc_jobs={assocJobs}
          isBulkAssociateJob={isBulkAction}
          onSuccess={() => associateJobSuccess()}
        />
      )}
      {isOpenCandidate && (
        <CandidatesModal
          isOpen={isOpenCandidate}
          onClose={onCloseCandidate}
          edit={true}
          id={Number(candidateId)}
          onSuccess={() => successModal()}
          //   candidateLoading={(resGetCandidate.isLoading)}
        />
      )}
      {isOpenSendEmail && (
        <EmailsModal
          isOpen={isOpenSendEmail}
          onClose={onCloseSendEmail}
          email={''}
          id={candidateId}
          candidate_id={candidateId}
          job_id={jobId}
          allowedCategory={['Candidates']}
          idList={isCheckWithJob}
          emailList={isBulkAction ? emailList : []}
          isBulkEmail={isBulkAction}
        />
      )}
    </Box>
  );
};

export default TableGroup;
