import { debounce } from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useListLeadsMutation,
  listLeads,
  useLeadCountsMutation,
  resetLead,
} from 'store/leads.slice';

export const useSideBarServices = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState('my-leads');
  const [searchLeads, setSearchLeads] = useState('');
  const [prospectSubTab, setProspectSubTab] = useState('new-prospects');
  const [prospectDateSort, setProspectDateSort] = useState('Last Week');

  const { leadPgBtn, counts } = useSelector((state: any) => state.leads);

  const [reqLeads] = useListLeadsMutation();
  const [reqLeadCounts] = useLeadCountsMutation();

  const handleSearchLeads = debounce((e: any) => {
    let query = e.target.value;

    if (searchLeads === '') {
      query = '';
    }

    let params = {
      ...leadPgBtn,
      query: query,
    };

    dispatch(listLeads({ leadPgBtn: params }));

    if (query.length >= 3 || searchLeads.length >= 3) {
      reqLeads({ data: params });
    }
  }, 300);

  const handleTab = (e: any) => {
    setTabValue(e);
    setSearchLeads('');
    let params = {
      ...leadPgBtn,
      unclaimed: e === 'unclaimed' ? true : false,
      is_prospect: e === 'prospects' ? 1 : 0,
      date_filter: e === 'prospects' ? 'Last Week' : null,
      query: '',
    };

    if (e === 'prospects') {
      params.status = [6, 7, 8, 9, 10, 11];
    }

    dispatch(listLeads({ leadPgBtn: params }));
  };

  function handleDateFilter(date_filter: string) {
    setProspectDateSort(date_filter);
  }

  const [statusMyLeadsPayload, setStatusMyLeadsPayload] = useState([]);
  const [statusUnclaimedPayload, setStatusUnclaimedPayload] = useState([]);
  const [statusNewProspectsPayload, setNewProspectsPayload] = useState([]);

  useEffect(() => {
    if (tabValue === 'my-leads' && statusMyLeadsPayload) {
      const flattenedStatus = statusMyLeadsPayload.flat();
      const uniqueStatus = [...new Set(flattenedStatus)];

      let params = {
        ...leadPgBtn,
        status:
          statusMyLeadsPayload.length > 1 && statusMyLeadsPayload.length !== 6
            ? uniqueStatus
            : [1, 2, 3, 4, 5],
      };

      dispatch(listLeads({ leadPgBtn: params }));
      reqLeads({ data: params });
    }
  }, [statusMyLeadsPayload]);

  useEffect(() => {
    if (tabValue === 'unclaimed' && statusUnclaimedPayload) {
      const flattenedStatus = statusUnclaimedPayload.flat();
      const uniqueStatus = [...new Set(flattenedStatus)];

      let params = {
        ...leadPgBtn,
        status:
          statusUnclaimedPayload.length > 1 &&
          statusUnclaimedPayload.length !== 3
            ? uniqueStatus
            : [2, 5],
      };

      dispatch(listLeads({ leadPgBtn: params }));
      reqLeads({ data: params });
    }
  }, [statusUnclaimedPayload]);

  useEffect(() => {
    if (tabValue === 'prospects') {
      const flattenedStatus = statusNewProspectsPayload.flat();
      const uniqueStatus = [...new Set(flattenedStatus)];
      let params = {
        ...leadPgBtn,
        status: uniqueStatus,
        date_filter: prospectDateSort,
      };

      dispatch(listLeads({ leadPgBtn: params }));
      reqLeads({ data: params });
    }
  }, [statusNewProspectsPayload, prospectDateSort]);

  useEffect(() => {
    reqLeadCounts({});

    return () => {
      dispatch(resetLead());
    };
  }, []);

  return {
    counts,
    tabValue,
    handleTab,
    searchLeads,
    prospectSubTab,
    prospectDateSort,
    setSearchLeads,
    setProspectSubTab,
    handleSearchLeads,
    setStatusMyLeadsPayload,
    setStatusUnclaimedPayload,
    setNewProspectsPayload,
    handleDateFilter,
  };
};
