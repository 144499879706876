import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router';
import Loader from '../Library/Loader';

import 'bootstrap/dist/css/bootstrap.min.css';
import pageRoute from './pageRoute';

import { PageRouteProps } from 'types';
import { useDispatch } from 'react-redux';
import { loginAuth } from 'store/auth.slice';
import { useLocation } from 'react-router';

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const authData: string | null = localStorage.getItem('auth');
    const initAuth = authData == null ? null : JSON.parse(authData);

    if (!initAuth && !location.pathname.includes('/job/careers')) {
      navigate('/login');
    } else {
      dispatch(loginAuth(initAuth));
    }
  }, [dispatch, location.pathname, navigate]);

  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        {pageRoute.map((page: PageRouteProps) => (
          <Route key={page.name} path={page.path} element={page.component} />
        ))}
      </Routes>
    </React.Suspense>
  );
};

export default App;
