import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Checkbox, Flex, useDisclosure } from '@chakra-ui/react';

import { listCandidates } from 'store/candidates.slice';
import {
  useGetCandidateFilterColumnsMutation,
  useGetFilterOperatorsMutation,
} from 'store/constant.slice';

import Button from 'Library/Button';
import CandidatesTable from './Table/Table';
import CandidatesModal from './Modals/modal';
import CandidateUploadResumeModal from './Modals/uploadResume';
import TableGroup from './Table/TR/Group';
import { getFilters } from 'store/candidatefilter.slice';
import AtsButtonDropzone from '../AtsButtonDropzone';
import delay from 'utils/delay';
import { useParams } from 'react-router-dom';

export default function CandidatesAll() {
  document.documentElement.dir = 'ltr';
  const storageName = 'candidatesFilter';

  const dispatch = useDispatch();
  const params = useParams();

  const {
    isOpen: isOpenAddCandidate,
    onOpen: onOpenAddCandidate,
    onClose: onCloseAddCandidate,
  } = useDisclosure();

  const {
    isOpen: isOpenUploadResume,
    onOpen: onOpenUploadResume,
    onClose: onCloseUploadResume,
  } = useDisclosure();

  const { candidatePgBtn } = useSelector((state: any) => state.candidates);
  const { filter, candidate_filters } = useSelector(
    (state: any) => state.candidateFilters
  );

  const param = useParams();

  const [isCollapse, setIsCollapse] = useState(false);

  const [reqCandidateFilterColumn] = useGetCandidateFilterColumnsMutation();
  const [reqFilterOperators] = useGetFilterOperatorsMutation();

  useEffect(() => {
    const load = async () => {
      await reqCandidateFilterColumn({});
      await delay(200);
      await reqFilterOperators({});
    };
    load();
  }, [reqCandidateFilterColumn, reqFilterOperators]);

  useEffect(() => {
    setIsCollapse(false);
  }, [candidatePgBtn?.filter]);

  const [isGroupByJob, setIsGroupByJob] = useState(false);

  const filterLoad = (filter: any) => {
    if (params.candidatesTabState === 'search') {
      setIsGroupByJob(false);
    } else {
      const fil = candidate_filters.find((item: any) => item.id === filter);
      if (fil) {
        setIsGroupByJob(fil?.group_by_job || false);
      }
    }
  };

  useEffect(() => {
    filterLoad(filter);
  }, [params, candidate_filters]);

  useEffect(() => {
    try {
      const local_filters: any = JSON.parse(localStorage.getItem(storageName));
      dispatch(listCandidates({ candidatePgBtn: local_filters }));
      const find = candidate_filters.find(
        (item: any) => item.id === local_filters?.filter
      );
      if (find) {
        setIsGroupByJob(find?.group_by_job);
      }
    } catch (e) {
      console.warn(e);
    }
  }, [candidate_filters]);

  const memoizedGetFilters = useCallback(() => {
    dispatch(getFilters({ group: isGroupByJob }));
  }, [dispatch, isGroupByJob]);

  useEffect(() => {
    memoizedGetFilters();
  }, [memoizedGetFilters, params]);

  useEffect(() => {
    const local_filters: any = JSON.parse(localStorage.getItem(storageName));
    filterLoad(local_filters.filter);
    // console.log({
    //   isGroupByJob,
    //   candidatesTabState: param?.candidatesTabState,
    //   local_filters,
    // });
  }, []);

  useEffect(() => {
    console.log({ isGroupByJob });
  }, [isGroupByJob]);

  return (
    <>
      <Flex justifyContent="flex-end" mt="24px" px={6} >
        <Flex gap="10px">
          {isGroupByJob && (
            <Checkbox
              colorScheme="purple"
              color="default.secondarytext"
              mx={1}
              isChecked={isCollapse}
              onChange={() => setIsCollapse(!isCollapse)}
            >
              Collapse All
            </Checkbox>
          )}
          <Box>
            <AtsButtonDropzone onOpen={onOpenUploadResume} />
          </Box>
          <Button leftIcon="plus" variant="solid" onClick={onOpenAddCandidate}>
            Add Candidate
          </Button>
        </Flex>

        {isOpenAddCandidate && (
          <CandidatesModal
            isOpen={isOpenAddCandidate}
            onClose={onCloseAddCandidate}
          />
        )}
        {isOpenUploadResume && (
          <CandidateUploadResumeModal
            isOpen={isOpenUploadResume}
            onClose={onCloseUploadResume}
          />
        )}
      </Flex>

      <Box>
        {isGroupByJob === true && param?.candidatesTabState === 'views' ? (
          <TableGroup isCollapse={isCollapse} isGroupByJob={true} />
        ) : isGroupByJob === false ? (
          <CandidatesTable isGroupByJob={false} />
        ) : (
          // <LoadingPage />
          <></>
        )}
      </Box>
    </>
  );
}
