import {
  Box,
  Checkbox,
  Button as ButtonChakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalFooter,
  Stack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { Formik, FieldArray, Field, ErrorMessage } from 'formik';
import Button from 'Library/Button';
import AtsSelect from 'components/app/AtsSelect';
import ScrollToFieldError from 'components/app/ScrollError';
import AtsAutoComplete from 'components/app/Leads/components/Autocomplete';
import { useJobAdsServices } from './useJobAdsServices';
import FAIcon from 'components/lib/FAIcon';
import AtsEmailBody from 'components/app/Global/Email/AIBody';
import { useAIGeneratorServices } from 'components/app/AIGenerator/useAIGeneratorServices';

interface JobAdsFormProps {
  initialJobAdsValues: any;
  jobId: number | null;
  jobFormData: any;
  jobData: any;
  setJobStatus: (data: any) => void;
  jobPairTemplate: any;
  onSubmit: (data: any) => void;
  jobPairing: (data: any) => void;
  isDraft: boolean;
  setJobFormData: (data: any) => void;
  initialValues: object;
  changeToDefault: (data: any) => object;
  tabList: any;
  tabIndex: any;
  setTabIndex: (data: any) => void;
  setName: (data: any) => void;
  itemLocation: any;
  geoLocationChange: (data: any, name: string) => void;
  geoloading: boolean;
  countryOption: any;
  isOpenAlert: boolean;
  loading: boolean;
  setIsDraft: (e: boolean) => void;
  onOpen: () => void;
  onClose: () => void;
  placholders: (e: any) => void;
}

const JobAdsForm = ({
  initialJobAdsValues,
  jobId,
  jobFormData,
  jobData,
  setJobStatus,
  onSubmit,
  jobPairTemplate,
  jobPairing,
  isDraft,
  setJobFormData,
  initialValues,
  changeToDefault,
  tabList,
  tabIndex,
  setTabIndex,
  setName,
  itemLocation,
  geoLocationChange,
  geoloading,
  countryOption,
  isOpenAlert,
  loading,
  setIsDraft,
  onOpen,
  onClose,
  placholders,
}: JobAdsFormProps) => {
  // Extracting services and configurations for the job ads form
  const {
    validationJobAdsSchema,
    jobAdsSubmit,
    work_types,
    countries,
    previewOpen,
    setPreviewOpen,
  } = useJobAdsServices({
    setJobStatus,
    jobId,
    jobFormData,
    jobData,
    jobPairTemplate,
    jobPairing,
    setJobFormData,
    isDraft,
    initialValues,
    tabList,
    tabIndex,
    setTabIndex,
    onSubmit,
  });

  const {
    hasAIGenerate,
    setHasAIGenerate,
    setIsGenerate,
    handleValues,
    handleAIGenerator,
    setIsError,
    isMaximize,
  } = useAIGeneratorServices({ hasType: 'Jobs' });

  return (
    <Formik
      initialValues={initialJobAdsValues}
      validationSchema={validationJobAdsSchema}
      onSubmit={(data, { setSubmitting, validateForm }) =>
        jobAdsSubmit(data, { setSubmitting, validateForm })
      }
      enableReinitialize={true}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isValid,
        setFieldValue,
        setValues,
      }) => (
        <form onSubmit={handleSubmit} style={{ height: 'inherit' }}>
          {/* Component to scroll to the first field with an error */}
          <ScrollToFieldError />

          <Stack justifyContent="space-between" h="inherit">
            {/* Main form content container */}
            <Box h="inherit">
              <FieldArray name="job_ads">
                {() => (
                  <div style={{ height: 'inherit' }}>
                    {values.job_ads?.length > 0 &&
                      values.job_ads.map((job_ad: any, index: number) => (
                        <Flex
                          key={`jas-${index}`}
                          h="inherit"
                          gap="10px"
                          justifyContent="space-between"
                        >
                          {/* Job Ad Item */}
                          <Stack
                            h="inherit"
                            spacing="24px"
                            sx={{
                              display: hasAIGenerate !== '' ? 'none' : 'flex',
                              w: '35%',
                              p: '10px',
                              background: '#fff',
                              borderRadius: '8px',
                            }}
                          >
                            {/* Posting Title Field */}
                            <FormControl
                              isInvalid={Boolean(
                                !!(errors.job_ads as any[])?.[index]?.title &&
                                  (touched.job_ads as any[])?.[index]?.title
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="19.1px"
                                color="#2B2D42"
                                fontWeight={700}
                              >
                                Posting Title{' '}
                                <Box as="span" color="#C53030">
                                  *
                                </Box>
                              </FormLabel>

                              <Box
                                border={
                                  !!(errors.job_ads as any[])?.[index]?.title &&
                                  (touched.job_ads as any[])?.[index]?.title &&
                                  '1px solid #E53E3E'
                                }
                                borderRadius={
                                  !!(errors.job_ads as any[])?.[index]?.title &&
                                  (touched.job_ads as any[])?.[index]?.title &&
                                  '4px'
                                }
                                boxShadow={
                                  !!(errors.job_ads as any[])?.[index]?.title &&
                                  (touched.job_ads as any[])?.[index]?.title &&
                                  '0 0 0 1px #E53E3E'
                                }
                              >
                                <Field
                                  autoFocus
                                  as={Input}
                                  id={`job_ads.${index}.title`}
                                  name={`job_ads.${index}.title`}
                                  placeholder="Posting Title"
                                  type="text"
                                  variant="outline"
                                  value={job_ad.title}
                                  onChange={handleChange}
                                  onBlur={() =>
                                    localStorage.setItem(
                                      'jobFormData',
                                      JSON.stringify({
                                        ...jobFormData,
                                        ...values,
                                      })
                                    )
                                  }
                                  sx={{
                                    height: '38px',
                                    border: '1px solid #E7EDF4',
                                    p: '8px 12px',
                                    fontSize: '14px',
                                  }}
                                />
                              </Box>
                              <FormErrorMessage>
                                {String(
                                  (errors.job_ads as any[])?.[index]?.title
                                )}
                              </FormErrorMessage>
                            </FormControl>

                            {/* Work Type Field */}
                            <FormControl>
                              <FormLabel
                                fontSize="14px"
                                lineHeight="19.1px"
                                color="#2B2D42"
                                fontWeight={700}
                              >
                                Work Type{' '}
                                <Box as="span" color="#C53030">
                                  *
                                </Box>
                              </FormLabel>
                              <AtsSelect
                                name={`job_ads.${index}.worktype_id`}
                                id={`job_ads.${index}.worktype_id`}
                                placeholder="Select"
                                variant="outline"
                                defaultValue={
                                  work_types
                                    .map((item: any) => ({
                                      label: item.name,
                                      value: item.id,
                                    }))
                                    .filter(
                                      (val: any) =>
                                        val.value === Number(job_ad.worktype_id)
                                    )[0] || job_ad.worktype_id
                                }
                                onChange={(e: any) =>
                                  setFieldValue(
                                    `job_ads[${index}].worktype_id`,
                                    String(e.value)
                                  )
                                }
                                onBlur={() =>
                                  localStorage.setItem(
                                    'jobFormData',
                                    JSON.stringify({
                                      ...jobFormData,
                                      ...values,
                                    })
                                  )
                                }
                                options={
                                  work_types?.length > 0 &&
                                  work_types.map((item: any) => ({
                                    label: item.name,
                                    value: item.id,
                                  }))
                                }
                                styles={{
                                  control: (styles: any) => ({
                                    ...styles,
                                    fontSize: '14px',
                                    border: '1px solid #E7E9ED',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    boxShadow: 'none',
                                    '&:hover': {},
                                    '& ::-webkit-scrollbar': {
                                      display: 'none',
                                    },
                                  }),
                                  valueContainer: (styles: any) => ({
                                    ...styles,
                                    maxHeight: '33px',
                                    overflowY: 'scroll',
                                  }),
                                  dropdownIndicator: (styles: any) => ({
                                    ...styles,
                                    color: '#676767',
                                  }),
                                  indicatorSeparator: () => ({
                                    display: 'none',
                                  }),
                                  placeholder: (styles: any) => ({
                                    ...styles,
                                    fontSize: '14px',
                                    color: '#9B9B9B',
                                  }),
                                }}
                              />
                              <FormErrorMessage>
                                {String(`job_ads${index}.worktype_id`)}
                              </FormErrorMessage>
                            </FormControl>

                            {/* Location Fields (City, State, Country, Zip Code) */}
                            <Flex gap="8px">
                              {/* City Field */}
                              <FormControl
                                isInvalid={Boolean(
                                  !!(errors.job_ads as any[])?.[index]?.city &&
                                    (touched.job_ads as any[])?.[index]?.city
                                )}
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="19.1px"
                                  color="#2B2D42"
                                  fontWeight={700}
                                >
                                  City{' '}
                                  <Box as="span" color="#C53030">
                                    *
                                  </Box>
                                </FormLabel>
                                <AtsAutoComplete
                                  name={`job_ads.${index}.city`}
                                  placeholder="City"
                                  type="text"
                                  variant="outline"
                                  value={job_ad?.city}
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      `job_ads.${index}.city`,
                                      e.target.value
                                    );
                                  }}
                                  onFocus={() => setName('city')}
                                  options={itemLocation}
                                  onKeyUp={(e: any) =>
                                    geoLocationChange(e, 'city')
                                  }
                                  isLoading={geoloading}
                                  onSelect={(item: any) => {
                                    setFieldValue(
                                      `job_ads.${index}.city`,
                                      item.value
                                    );
                                    setFieldValue(
                                      `job_ads.${index}.state`,
                                      item.state
                                    );
                                    setFieldValue(
                                      `job_ads.${index}.zip_code`,
                                      item.postal_code
                                    );
                                    setFieldValue(
                                      `job_ads.${index}.country`,
                                      (countries?.length > 0 &&
                                        countries
                                          .map((item: any) => ({
                                            label: item.country,
                                            value: item.id,
                                            code: item.code,
                                          }))
                                          .filter(
                                            (val: any) =>
                                              val.label === item.country
                                          ))[0]
                                    );
                                    localStorage.setItem(
                                      'jobFormData',
                                      JSON.stringify({
                                        ...jobFormData,
                                        ...values,
                                      })
                                    );
                                  }}
                                />
                                <FormErrorMessage>
                                  {String(
                                    (errors.job_ads as any[])?.[index]?.city
                                  )}
                                </FormErrorMessage>
                              </FormControl>

                              {/* State/Province Field */}
                              <FormControl
                                isInvalid={Boolean(
                                  !!(errors.job_ads as any[])?.[index]?.state &&
                                    (touched.job_ads as any[])?.[index]?.state
                                )}
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="19.1px"
                                  color="#2B2D42"
                                  fontWeight={700}
                                >
                                  State / Province{' '}
                                  <Box as="span" color="#C53030">
                                    *
                                  </Box>
                                </FormLabel>
                                <Input
                                  id={`job_ads.${index}.state`}
                                  name={`job_ads.${index}.state`}
                                  type="text"
                                  placeholder="State / Province"
                                  variant="outline"
                                  value={job_ad?.state}
                                  onChange={handleChange}
                                  onBlur={() =>
                                    localStorage.setItem(
                                      'jobFormData',
                                      JSON.stringify({
                                        ...jobFormData,
                                        ...values,
                                      })
                                    )
                                  }
                                  sx={{
                                    height: '38px',
                                    border: '1px solid #E7EDF4',
                                    p: '8px 12px',
                                    fontSize: '14px',
                                  }}
                                />
                                <FormErrorMessage>
                                  {String(
                                    (errors.job_ads as any[])?.[index]?.state
                                  )}
                                </FormErrorMessage>
                              </FormControl>
                            </Flex>

                            <Flex gap="8px">
                              <FormControl
                                isInvalid={Boolean(
                                  !!(errors.job_ads as any[])?.[index]
                                    ?.country &&
                                    (touched.job_ads as any[])?.[index]?.country
                                )}
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="19.1px"
                                  color="#2B2D42"
                                  fontWeight={700}
                                >
                                  Country{' '}
                                  <Box as="span" color="#C53030">
                                    *
                                  </Box>
                                </FormLabel>
                                <Field
                                  id={`job_ads.${index}.country`}
                                  name={`job_ads.${index}.country`}
                                  options={countryOption}
                                  component={AtsSelect}
                                  placeholder="Country"
                                  value={
                                    job_ad?.country ||
                                    (countryOption.length > 0 &&
                                      countryOption
                                        .map((item: any) => ({
                                          label: item.label,
                                          value: item.value,
                                        }))
                                        .filter(
                                          (val: any) =>
                                            val.label === job_ad?.country
                                        ))
                                  }
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      `job_ads.${index}.country`,
                                      e
                                    );
                                  }}
                                  styles={{
                                    control: (styles: any) => ({
                                      ...styles,
                                      fontSize: '14px',
                                      border: '1px solid #E7E9ED',
                                      borderRadius: '4px',
                                      cursor: 'pointer',
                                      boxShadow: 'none',
                                      '&:hover': {},
                                      '& ::-webkit-scrollbar': {
                                        display: 'none',
                                      },
                                    }),
                                    valueContainer: (styles: any) => ({
                                      ...styles,
                                      maxHeight: '33px',
                                      overflowY: 'scroll',
                                    }),
                                    dropdownIndicator: (styles: any) => ({
                                      ...styles,
                                      color: '#676767',
                                    }),
                                    indicatorSeparator: () => ({
                                      display: 'none',
                                    }),
                                    placeholder: (styles: any) => ({
                                      ...styles,
                                      fontSize: '14px',
                                      color: '#9B9B9B',
                                    }),
                                  }}
                                />
                                <FormErrorMessage>
                                  {String(
                                    (errors.job_ads as any[])?.[index]?.country
                                  )}
                                </FormErrorMessage>
                              </FormControl>
                              <FormControl
                                isInvalid={Boolean(
                                  !!(errors.job_ads as any[])?.[index]
                                    ?.zip_code &&
                                    (touched.job_ads as any[])?.[index]
                                      ?.zip_code
                                )}
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="19.1px"
                                  color="#2B2D42"
                                  fontWeight={700}
                                >
                                  Zip Code{' '}
                                  <Box as="span" color="#C53030">
                                    *
                                  </Box>
                                </FormLabel>
                                <Input
                                  id={`job_ads.${index}.zip_code`}
                                  name={`job_ads.${index}.zip_code`}
                                  type="text"
                                  placeholder="Zip Code"
                                  variant="outline"
                                  value={job_ad?.zip_code}
                                  onChange={handleChange}
                                  sx={{
                                    height: '38px',
                                    border: '1px solid #E7EDF4',
                                    p: '8px 12px',
                                    fontSize: '14px',
                                  }}
                                />
                                <FormErrorMessage>
                                  {String(
                                    (errors.job_ads as any[])?.[index]?.zip_code
                                  )}
                                </FormErrorMessage>
                              </FormControl>
                            </Flex>
                            <Box>
                              <FormLabel
                                fontSize="14px"
                                lineHeight="19.1px"
                                color="#2B2D42"
                                fontWeight={700}
                                mb="16px"
                              >
                                Publish On
                              </FormLabel>

                              <Flex gap="8px" justifyContent="space-between">
                                <Stack spacing="8px">
                                  <Checkbox
                                    colorScheme="purple"
                                    id={`job_ads.${index}.career_page_publish`}
                                    name={`job_ads.${index}.career_page_publish`}
                                    isChecked={job_ad.career_page_publish}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      localStorage.setItem(
                                        'jobFormData',
                                        JSON.stringify({
                                          ...jobFormData,
                                          ...values,
                                        })
                                      );
                                    }}
                                  >
                                    Careers Page
                                  </Checkbox>

                                  <Checkbox
                                    colorScheme="purple"
                                    id={`job_ads.${index}.paid_job_board_publish`}
                                    name={`job_ads.${index}.paid_job_board_publish`}
                                    isChecked={job_ad.paid_job_board_publish}
                                    onChange={handleChange}
                                    onBlur={() =>
                                      localStorage.setItem(
                                        'jobFormData',
                                        JSON.stringify({
                                          ...jobFormData,
                                          ...values,
                                        })
                                      )
                                    }
                                  >
                                    Paid Job boards
                                  </Checkbox>

                                  <Checkbox
                                    colorScheme="purple"
                                    id={`job_ads.${index}.free_job_board_publish`}
                                    name={`job_ads.${index}.free_job_board_publish`}
                                    isChecked={job_ad.free_job_board_publish}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      localStorage.setItem(
                                        'jobFormData',
                                        JSON.stringify({
                                          ...jobFormData,
                                          ...values,
                                        })
                                      );
                                    }}
                                  >
                                    Free Job boards
                                  </Checkbox>
                                </Stack>
                                <Box>
                                  <Checkbox
                                    colorScheme="purple"
                                    id={`job_ads.${index}.show_profile`}
                                    name={`job_ads.${index}.show_profile`}
                                    isChecked={job_ad.show_profile}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      localStorage.setItem(
                                        'jobFormData',
                                        JSON.stringify({
                                          ...jobFormData,
                                          ...values,
                                        })
                                      );
                                    }}
                                  >
                                    Show Profile on Careers page
                                  </Checkbox>
                                </Box>
                              </Flex>
                            </Box>
                          </Stack>

                          <Flex
                            h="inherit"
                            justifyContent="center"
                            gap="10px"
                            sx={{
                              w: hasAIGenerate !== '' ? '100%' : '65%',
                              '& #ai': {
                                m: 0,
                                overflowY: 'scroll',
                                maxHeight: '450px',
                              },
                              '& ::-webkit-scrollbar': {
                                display: 'none',
                              },
                            }}
                          >
                            <Stack
                              h="inherit"
                              spacing="10px"
                              sx={{
                                display:
                                  hasAIGenerate && isMaximize ? 'none' : 'flex',
                                w: hasAIGenerate !== '' ? '65%' : '100%',
                                position: 'relative',
                                bgColor: '#FFF',
                                borderRadius: '6px',
                                p: '16px',
                              }}
                            >
                              <Flex
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="19.1px"
                                  color="#2B2D42"
                                  fontWeight={700}
                                >
                                  Job Description{' '}
                                  <Box as="span" color="caution.800">
                                    *
                                  </Box>
                                </FormLabel>
                                <ButtonChakra
                                  leftIcon={
                                    <FAIcon
                                      iconName={
                                        !previewOpen ? 'eye' : 'eye-slash'
                                      }
                                    />
                                  }
                                  variant={'borderless'}
                                  onClick={() => {
                                    setPreviewOpen(!previewOpen);
                                  }}
                                >
                                  Preview
                                </ButtonChakra>
                              </Flex>

                              <Box
                                border={
                                  !!(errors.job_ads as any[])?.[index]
                                    ?.description &&
                                  (touched.job_ads as any[])?.[index]
                                    ?.description &&
                                  '1px solid #E53E3E'
                                }
                                borderRadius={
                                  !!(errors.job_ads as any[])?.[index]
                                    ?.description &&
                                  (touched.job_ads as any[])?.[index]
                                    ?.description &&
                                  '4px'
                                }
                                boxShadow={
                                  !!(errors.job_ads as any[])?.[index]
                                    ?.description &&
                                  (touched.job_ads as any[])?.[index]
                                    ?.description &&
                                  '0 0 0 1px #E53E3E'
                                }
                              >
                                <Stack
                                  h="inherit"
                                  sx={{
                                    position: 'relative',
                                  }}
                                >
                                  <AtsEmailBody
                                    value={
                                      !previewOpen
                                        ? job_ad.description
                                        : placholders(job_ad.description)
                                    }
                                    onChange={(e: any) => {
                                      setFieldValue(
                                        `job_ads.${index}.description`,
                                        e
                                      );
                                    }}
                                    height={300}
                                    hasAIGenerate={hasAIGenerate}
                                    setHasAIGenerate={setHasAIGenerate}
                                    setIsGenerate={setIsGenerate}
                                    handleValues={handleValues}
                                    setIsError={setIsError}
                                    subject={job_ad.title}
                                    body={job_ad.description}
                                  />
                                </Stack>
                              </Box>
                            </Stack>

                            {handleAIGenerator(setFieldValue)}
                          </Flex>
                        </Flex>
                      ))}
                  </div>
                )}
              </FieldArray>
            </Box>

            {/* Footer with Save as Draft and Next Buttons */}
            <ModalFooter
              position="sticky"
              bottom="0"
              background="#F9F9FB"
              p="8px 0"
              zIndex={2}
            >
              <Flex
                gap="10px"
                justifyContent="space-between"
                sx={{ w: '100%' }}
              >
                {tabIndex.id > 0 && (
                  <Button
                    disabled={isOpenAlert}
                    onClick={() => {
                      tabIndex.id > 0 && setTabIndex(tabList[tabIndex.id - 1]);
                      setJobFormData((prev: any) => ({
                        ...prev,
                        ...values,
                        job_ads: [
                          {
                            ...values.job_ads[0],
                            // country: values.job_ads[0].country,
                          },
                        ],
                      }));
                    }}
                  >
                    Previous
                  </Button>
                )}

                <Flex gap="10px">
                  <ButtonChakra
                    onClick={() => {
                      onClose();
                      localStorage.setItem('jobFormData', JSON.stringify({}));
                    }}
                  >
                    Cancel
                  </ButtonChakra>
                  <Button
                    disabled={isOpenAlert || loading}
                    loading={loading}
                    mr={3}
                    onClick={() => {
                      setIsDraft(true);
                      handleSubmit();
                      !isValid && onOpen();
                    }}
                  >
                    Save Draft
                  </Button>

                  {((String(jobFormData.job_type_id) === '1' &&
                    tabIndex.id < 4) ||
                    (String(jobFormData.job_type_id) === '2' &&
                      tabIndex.id === 2)) && (
                    <Button
                      variant="solid"
                      disabled={isOpenAlert}
                      onClick={(e: any) => {
                        setIsDraft(false);
                        handleSubmit(e);
                      }}
                    >
                      Next
                    </Button>
                  )}
                </Flex>
              </Flex>
            </ModalFooter>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default JobAdsForm;
