import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import {
  getLead,
  useCreateLeadsContactMutation,
  useGetLeadsMutation,
} from 'store/leads.slice';
import { useUserListMutation } from 'store/user.slice';
import {
  useClientSearchMutation,
  useViewClientMutation,
} from 'store/client.slice';
import { useGetJobSpecsMutation } from 'store/constant.slice';
import '../../../../../assets/css/accordion-collapse.css';
import { useNavigate, useParams } from 'react-router';
import ScrollToFieldError from 'components/app/ScrollError';
import AtsAsyncSelect2 from 'components/app/AtsAsyncSelect2';
import { useContactListMutation } from 'store/contact.slice';
import { debounce } from 'lodash';
import { authStorage } from 'utils/localStorage';
import AtsSelect from 'components/app/AtsSelect';
interface optionInterface {
  label: string;
  value: any;
}

interface ContactsModalProps {
  isOpen: any;
  onClose: any;
  modalTitle: string;
}

const ContactsModal = ({ isOpen, onClose, modalTitle }: ContactsModalProps) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams();
  const leadID = params.leadsId;
  const auth = authStorage();

  const { userList } = useSelector((state: any) => state.user);
  const { leadData } = useSelector((state: any) => state.leads);
  const { contactPageBtn } = useSelector((state: any) => state.contacts);

  const [reqCompnay, resCompany] = useClientSearchMutation();
  const [reqLeads] = useContactListMutation();
  const [reqleadsData] = useGetLeadsMutation();
  const [reqCreateLead, resCreateLead] = useCreateLeadsContactMutation();
  const [reqUser] = useUserListMutation();
  const [reqClientData] = useViewClientMutation();
  const [reqSpecs] = useGetJobSpecsMutation();
  const navigate = useNavigate();

  const [companyList, setCompanyList] = useState([]);

  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  useEffect(() => {
    reqSpecs({});
  }, [reqSpecs]);

  useEffect(() => {
    if (leadID) {
      reqleadsData({ id: leadID });
    }
  }, [leadID]);

  const initialValues = {
    first_name: '',
    last_name: '',
    personal_phone: '',
    work_phone: '',
    extension: '',
    email: '',
    linkedIn_url: '',
    title: '',
    company: {},
    lead_owner: auth?.id,
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      first_name: Yup.string()
        .required('First name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'First name is invalid.'),
      last_name: Yup.string()
        .required('Last name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'Last name is invalid.'),
      personal_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Personal phone is invalid'
      ),
      work_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Work phone is invalid'
      ),
      extension: Yup.string().matches(/^[\d ()+-]+$/gi, 'Extension is invalid'),
      email: Yup.string()
        .email('Invalid email.')
        .required('Email is required.'),
      linkedIn_url: Yup.string(),
      title: Yup.string().required('Title is required.'),
      company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
      lead_owner: Yup.string().required('Lead owner is required.'),
    })
  );

  const isEdit = modalTitle === 'Edit Contact';

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const onSubmit = async (data: any) => {
    data['lead_owner'] = parseInt(data.lead_owner);
    reqCreateLead(data);
  };

  useEffect(() => {
    if (isOpen) {
      if (!isEdit) {
        dispatch(getLead({ leadData: {} }));
        dispatch(getLead({ leadDetails: [] }));
      }
      reqCompnay({ search: '' });
    }
  }, [isOpen, isEdit]);

  useEffect(() => {
    reqUser({});

    if (resCreateLead.isSuccess) {
      onCloseAlert();
      onClose();
      dispatch(getLead({ leadData: {} }));
      dispatch(getLead({ leadDetails: [] }));
      reqLeads(contactPageBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (resCreateLead.isSuccess) {
          title = 'Added Contact';
          description = 'New Contact successfully added';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    } else if (resCreateLead.isError) {
      onOpen();
    }

    if (resCreateLead.isError) {
      let errorMessage: any = resCreateLead.error;
      if (errorMessage['data']['error'] !== undefined) {
        setErrorMsg(errorMessage['data']['error']['message']);
      } else {
        try {
          setErrorMsg(errorMessage['data']['message']);
        } catch (e) {
          setErrorMsg('Server Error');
        }
      }
    }
  }, [resCreateLead.isSuccess, resCreateLead.isError]);

  useEffect(() => {
    if (resCompany.isSuccess) {
      const data: any = resCompany.data.data;

      let option: any = [];
      data.map((item: any) => {
        option.push({
          label: item.name,
          value: item.id,
        });
      });
      setCompanyList(option);
    }
  }, [resCompany.isSuccess]);

  useEffect(() => {
    if (leadData?.client_id) {
      reqClientData({ id: leadData?.client_id });
    }
  }, [leadData?.client_id]);

  const formatOption = async (companies: any) => {
    let options: any = [];
    await Promise.all(
      companies.map(async (company: any) => {
        options.push({
          label: company.name,
          value: company.id,
        });
      })
    );

    return await options;
  };

  const SearchCompany = debounce(
    (inputValue: string, callback: (options: optionInterface[]) => void) => {
      reqCompnay({ search: inputValue }).then((res: any) => {
        const getData = async () => {
          const option = await formatOption(res?.data?.data);
          callback(option);
        };
        getData().catch(console.error);
      });
    },
    300
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          dispatch(getLead({ leadData: {} }));
          onCloseAlert();
          onClose();
          navigate(`/clients/contacts`);
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            {modalTitle}
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box width="100%">
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errMsg}</AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit} onChange={onCloseAlert}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.first_name && touched.first_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          First Name{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="first_name"
                          name="first_name"
                          type="text"
                          placeholder="First Name"
                          variant="outline"
                          value={values.first_name}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.first_name)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.last_name && touched.last_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Last Name{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="last_name"
                          name="last_name"
                          type="text"
                          placeholder="Last Name"
                          variant="outline"
                          value={values.last_name}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.last_name)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.personal_phone && touched.personal_phone
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Personal Phone
                        </FormLabel>
                        <Input
                          id="personal_phone"
                          name="personal_phone"
                          type="text"
                          placeholder="Personal Phone"
                          variant="outline"
                          value={values.personal_phone}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.personal_phone)}
                        </FormErrorMessage>
                      </FormControl>
                      <Flex width="100%" gap="32px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.work_phone && touched.work_phone
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Work Phone
                          </FormLabel>
                          <Input
                            id="work_phone"
                            name="work_phone"
                            type="text"
                            placeholder="Work Phone"
                            variant="outline"
                            value={values.work_phone}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.work_phone)}
                          </FormErrorMessage>
                        </FormControl>
                        <Flex width="30%">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.work_phone && touched.work_phone
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Extension
                            </FormLabel>
                            <Input
                              id="extension"
                              name="extension"
                              type="number"
                              placeholder="Ext"
                              variant="outline"
                              value={values.extension}
                              onChange={(e) => {
                                setFieldValue(
                                  'extension',
                                  String(e.target.value)
                                );
                              }}
                            />
                            <FormErrorMessage>
                              {String(errors.extension)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex gap="32px" mb="40px">
                      <FormControl
                        isInvalid={Boolean(!!errors.email && touched.email)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Email{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Email"
                          variant="outline"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.email)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.linkedIn_url && touched.linkedIn_url
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          LinkedIn Link
                        </FormLabel>
                        <Input
                          id="linkedIn_url"
                          name="linkedIn_url"
                          type="text"
                          placeholder="LinkedIn Link"
                          variant="outline"
                          value={values.linkedIn_url}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.linkedIn_url)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>

                    <Box mb="40px">
                      <Text fontWeight={600} mb="34px">
                        Company Details
                      </Text>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(!!errors.title && touched.title)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Title{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            placeholder="Title"
                            variant="outline"
                            value={values.title}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.title)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.company && touched.company
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Company{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <AtsAsyncSelect2
                            id="company"
                            name="company"
                            placeholder="Company"
                            loadOptions={SearchCompany}
                            defaultOptions={companyList}
                            onChange={(event: any) => {
                              reqClientData({ id: event?.value });
                              setFieldValue('company', event);
                            }}
                            cacheOptions={false}
                          />
                          <FormErrorMessage>
                            {String(errors.company)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                    </Box>
                    <Box>
                      <Text fontWeight={600} mb="34px">
                        Additional Information
                      </Text>
                      <Flex mb="34px" gap="32px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.lead_owner && touched.lead_owner
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Contact Owner{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>

                          <AtsSelect
                            id="lead_owner"
                            name="lead_owner"
                            placeholder="Select"
                            variant="outline"
                            // value={values.lead_owner}
                            value={
                              (Object.values(userList).length > 0 &&
                                Object.values(userList)
                                  .map((item: any) => ({
                                    label: [
                                      item.first_name,
                                      item.last_name,
                                    ].join(' '),
                                    value: item.id,
                                  }))
                                  .filter(
                                    (val: any) =>
                                      val.value === Number(values.lead_owner)
                                  )[0]) ||
                              values.lead_owner
                            }
                            onChange={(e: any) =>
                              setFieldValue('lead_owner', String(e.value))
                            }
                            options={Object.values(userList).map(
                              (item: any) => ({
                                label: [item.first_name, item.last_name].join(
                                  ' '
                                ),
                                value: item.id,
                              })
                            )}
                          />
                          <FormErrorMessage>
                            {String(errors.lead_owner)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl />
                      </Flex>
                    </Box>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      type="submit"
                      disabled={isOpenAlert || resCreateLead.isLoading}
                      isLoading={resCreateLead.isLoading}
                      mr={3}
                    >
                      Add
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContactsModal;
