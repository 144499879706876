import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';
import ClientAddAgreementModal from './add';
import { useParams } from 'react-router';
import { useEffect, useRef } from 'react';
import {
  useDelClientAgreementMutation,
  useGetClientAgreementMutation,
  useListClientAgreementMutation,
  setClientAgreement,
} from 'store/clientagreement.slice';
import { swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';
import { Formik } from 'formik';
import ScrollToFieldError from 'components/app/ScrollError';
import Pagination from 'Library/Pagination';
import ClientViewAgreementModal from './view';
import ClientEditgreementModal from './edit';
import TrLoading from 'components/app/TrLoading';
import delay from 'utils/delay';

const ClientsAgreements = () => {
  const params = useParams();
  const clientsId = params.clientsId;

  const dispatch = useDispatch();
  const { clientAgreements, clientAgreementPage, clientAgreementPgBtn } =
    useSelector((state: any) => state.clientAgreements);

  const [reqget] = useGetClientAgreementMutation();
  const [reqdel, resDel] = useDelClientAgreementMutation();
  const [reqAgreement, resAgreement] = useListClientAgreementMutation();
  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchAgreementsList = async (param: object, id: number) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    await delay(200);
    await reqAgreement({ data: param, id, signal: abortControllerRef.current.signal });
  };
  useEffect(() => {
    if (params.clientsTab === 'agreements') {
      fetchAgreementsList(clientAgreementPgBtn, Number(clientsId))
    }
  }, [clientAgreementPgBtn, clientsId, params.clientsTab, reqAgreement]);

  useEffect(() => {
    if (resDel.isSuccess) {
      fetchAgreementsList(clientAgreementPgBtn, Number(clientsId))
    }
  }, [resDel.isSuccess]);

  const tableHeader: { label: string }[] = [
    { label: 'Name Of Agreement' },
    { label: 'Signed On' },
    { label: 'Valid Until' },
    { label: '' },
  ];

  const initialValue = {
    search: '',
  };

  const {
    isOpen: isAddAgreementOpen,
    onOpen: onAddAgreementOpen,
    onClose: onAddAgreementClose,
  } = useDisclosure();

  const {
    isOpen: isEditAgreementOpen,
    onOpen: onEditAgreementOpen,
    onClose: onEditAgreementClose,
  } = useDisclosure();

  const {
    isOpen: isViewAgreementOpen,
    onOpen: onViewAgreementOpen,
    onClose: onViewAgreementClose,
  } = useDisclosure();

  const content: swalContent = {
    title: 'Are you sure?',
    text: 'Do you really want to delete this agreement?',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const deleteClick = async (id: number) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      reqdel({ id: id });
    }
  };

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: clientAgreementPgBtn.take,
      query: clientAgreementPgBtn.query,
    };
    await dispatch(
      setClientAgreement({
        clientAgreementPgBtn: newParam,
      })
    );
    fetchAgreementsList(newParam, Number(clientsId))
  };

  const editClick = async (id: number) => {
    await reqget({ id: id });
    onEditAgreementOpen();
  };

  const onSubmit = async (data: any) => {
    let newParam = {
      page: clientAgreementPgBtn.page,
      take: clientAgreementPgBtn.take,
      query: data.search,
    };
    await dispatch(
      setClientAgreement({
        leadAgreementPgBtn: newParam,
      })
    );

    fetchAgreementsList(newParam, Number(clientsId))

  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let newParam = {
        page: 1,
        take: clientAgreementPgBtn.take,
        query: '',
        status: clientAgreementPgBtn.status,
        types: clientAgreementPgBtn.types,
      };
      dispatch(
        setClientAgreement({
          leadAgreementPgBtn: newParam,
        })
      );
      fetchAgreementsList(newParam, Number(clientsId))
    }
  };

  const entryChange = async (entries: number) => {
    let newParam = {
      page: 1,
      take: entries,
      query: clientAgreementPgBtn.query,
    };
    await dispatch(
      setClientAgreement({
        leadAgreementPgBtn: newParam,
      })
    );
    fetchAgreementsList(newParam, Number(clientsId))
  };

  const viewAgreement = async (id: number) => {
    await reqget({ id: id });
    onViewAgreementOpen();
  };

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="space-between" py={6}>
        <Box pr={2}>
          <Formik initialValues={initialValue} onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <InputGroup background="#fff">
                  <InputRightElement
                    cursor="pointer"
                    children={<FAIcon iconName="search" />}
                  />
                  <Input
                    type="search"
                    name="search"
                    value={values.search}
                    onChange={(e) => {
                      handleChange(e);
                      clearSearch(e);
                    }}
                    placeholder="Search Agreement"
                  />
                </InputGroup>
              </form>
            )}
          </Formik>
        </Box>

        <Button leftIcon="plus" variant="solid" onClick={onAddAgreementOpen}>
          Add Agreement
        </Button>
      </Flex>

      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          minHeight="45vh"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {resAgreement.isLoading ? (
                <TrLoading rows={3} columns={3} />
              ) : (
                clientAgreements.length > 0 &&
                clientAgreements?.map((data: any) => (
                  <Tr key={data.id} cursor="pointer" onClick={() => { }}>
                    <Td>
                      <Link onClick={() => viewAgreement(data.id)}>
                        {data.title}
                      </Link>
                    </Td>
                    <Td>{moment.utc(data.date_signed).format('MM/DD/YYYY')}</Td>
                    <Td>{(data?.valid_until) ? moment
                      .utc(data?.valid_until)
                      .format('MM/DD/YYYY')
                      : 'No Expiry'}</Td>
                    <Td>
                      <Menu>
                        <MenuButton>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={() => editClick(data.id)}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="pencil" />
                              <Box ml={12} cursor="pointer">
                                Edit
                              </Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem onClick={() => deleteClick(data.id)}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="trash" />
                              <Box ml={12} cursor="pointer">
                                Delete
                              </Box>
                            </Flex>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          totalPages={clientAgreementPage.lastPage}
          currentPage={clientAgreementPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          onEntryChange={(entries) => entryChange(entries)}
          totalEntries={clientAgreementPage.count}
          currentCount={clientAgreements?.length}
          targetCount={clientAgreementPage.take}
        />
      </Box>
      {isAddAgreementOpen && (
        <ClientAddAgreementModal
          isOpen={isAddAgreementOpen}
          onClose={onAddAgreementClose}
        />
      )}
      {isViewAgreementOpen && (
        <ClientViewAgreementModal
          isOpen={isViewAgreementOpen}
          onClose={onViewAgreementClose}
        />
      )}
      {isEditAgreementOpen && (
        <ClientEditgreementModal
          isOpen={isEditAgreementOpen}
          onClose={onEditAgreementClose}
        />
      )}
    </Box>
  );
};

export default ClientsAgreements;
