import { createSlice } from '@reduxjs/toolkit';
import authService from 'services/auth.service';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const initialLeadPgBtn: any = {
  page: 1,
  take: 50,
  query: '',
  hot: false,
  qualified: false,
  status: [1, 2, 3, 4],
  types: [],
  unclaimed: false,
  is_prospect: 0,
  orderBy: {
    column_name: 'created_at',
    sort_order: 'ASC',
  },
  date_filter: null,
};

export const leadsSlice = createSlice({
  name: 'leads',
  initialState: {
    leads: [],
    leadClient: [],
    leadStatus: {},
    leadTypes: {},
    leadData: {},
    leadDetails: [],
    LeadLoading: false,
    leadPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    counts: {
      prospects: 0,
      myleads: 0,
      unclaimed: 0,
      prospect_status_count: {
        All: 0,
        Contacted: 0,
        Not_Interested: 0,
        Opened: 0,
        Out_Of_Office: 0,
        Not_the_right_person: 0,
        Replied: 0,
      },
    },
    leadPgBtn: initialLeadPgBtn,
    selected_contact: {},
  },
  reducers: {
    listLeads: (state, { payload }) => ({ ...state, ...payload }),
    getLead: (state, { payload }) => ({ ...state, ...payload }),
    resetLead: () => ({
      leads: [],
      leadClient: [],
      leadStatus: {},
      leadTypes: {},
      leadData: {},
      leadDetails: [],
      LeadLoading: false,
      leadPage: {
        count: 0,
        currentPage: 1,
        lastPage: 1,
        nextPage: null,
        prevPage: null,
      },
      counts: {
        prospects: 0,
        myleads: 0,
        unclaimed: 0,
        prospect_status_count: {
          All: 0,
          Contacted: 0,
          Not_Interested: 0,
          Opened: 0,
          Out_Of_Office: 0,
          Not_the_right_person: 0,
          Replied: 0,
        },
      },
      leadPgBtn: {
        page: 1,
        take: 50,
        query: '',
        hot: false,
        qualified: false,
        status: [1, 2, 3, 4],
        types: [],
        unclaimed: false,
        is_prospect: 0,
        orderBy: {
          column_name: 'created_at',
          sort_order: 'ASC',
        },
        date_filter: null,
      },
      selected_contact: {},
    }),
  },
});

export const { listLeads, getLead, resetLead } = leadsSlice.actions;
export default leadsSlice.reducer;

/* ----------------------------------- API ---------------------------------- */
export const leadsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    LeadCounts: builder.mutation({
      query: ({ signal }) => ({
        url: '/leads/count',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        signal,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const All =
            (data?.data?.prospect_status_count?.Contacted || 0) +
            (data?.data?.prospect_status_count?.Not_Interested || 0) +
            (data?.data?.prospect_status_count?.Opened || 0) +
            (data?.data?.prospect_status_count?.Out_Of_Office || 0) +
            (data?.data?.prospect_status_count?.Not_the_right_person || 0) +
            (data?.data?.prospect_status_count?.Replied || 0);
          dispatch(
            listLeads({
              counts: {
                ...data.data,
                prospect_status_count: {
                  ...data.data.prospect_status_count,
                  All,
                },
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
          const err: any = error;
          // if (err?.status == 403) {
          //   authService.logout();
          // }
        }
      },
    }),
    ListLeads: builder.mutation({
      query: ({ data, signal }) => ({
        url: '/leads',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
        signal,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listLeads({ leads: data.data.data }));
          dispatch(
            listLeads({
              leadPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
          const err: any = error;
          // if (err?.status == 403) {
          //   authService.logout();
          // }
        }
      },
    }),
    CreateLeads: builder.mutation({
      query: (data) => ({
        url: '/leads/create',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    CreateLeadsContact: builder.mutation({
      query: (data) => ({
        url: '/leads/create/contact',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // dispatch(
          //   getLead({
          //     selected_contact: {
          //       label: [
          //         data.data.first_name,
          //         data.data.last_name,
          //       ]
          //         .filter(Boolean)
          //         .join(' '),
          //       value: data.data.id,
          //     },
          //   })
          // )
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateLeads: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getLead({ leadData: { ...data.data, ...data.data.lead } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetLeads: builder.mutation({
      query: ({ id }) => ({
        url: `/leads/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getLead({ leadData: { ...data.data, ...data.data.lead } }));
          dispatch(getLead({ leadDetails: { ...data.data, ...data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    InactiveLeads: builder.mutation({
      query: ({ id }) => ({
        url: `/leads/inactive/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetStatus: builder.mutation({
      query: () => ({
        url: `/constants/lead-status`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getLead({ leadStatus: { ...data.data, ...data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    GetTypes: builder.mutation({
      query: () => ({
        url: `/constants/lead-types/filtered`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getLead({ leadTypes: { ...data.data, ...data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    QualifiedLeads: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/qualified/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getLead({ leadData: { ...data.data, ...data.data.lead } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ToClient: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/convert-to-client/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getLead({ leadData: { ...data.data, ...data.data.lead } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ByClient: builder.mutation({
      query: ({ id }) => ({
        url: `/leads/client/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getLead({ leadClient: { ...data.data, ...data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    SendForApproval: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/send-for-approval/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getLead({ leadData: { ...data.data, ...data.data.lead } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ReActivate: builder.mutation({
      query: ({ id }) => ({
        url: `/leads/re-activate/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    CancelApproval: builder.mutation({
      query: ({ id }) => ({
        url: `/leads/cancel-approval/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    BulkInactive: builder.mutation({
      query: ({ data }) => ({
        url: `/leads/bulk-inactive`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateContactLead: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/update-contact/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    GetAsContact: builder.mutation({
      query: ({ id }) => ({
        url: `/leads/as-contact/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // dispatch(getLead({ leadStatus: { ...data.data, ...data.data } }));
          console.log({ data });
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ClaimLead: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/claim/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useLeadCountsMutation,
  useListLeadsMutation,
  useCreateLeadsMutation,
  useCreateLeadsContactMutation,
  useUpdateLeadsMutation,
  useGetLeadsMutation,
  useInactiveLeadsMutation,
  useGetStatusMutation,
  useGetTypesMutation,
  useQualifiedLeadsMutation,
  useToClientMutation,
  useByClientMutation,
  useSendForApprovalMutation,
  useReActivateMutation,
  useCancelApprovalMutation,
  useBulkInactiveMutation,
  useUpdateContactLeadMutation,
  useGetAsContactMutation,
  useClaimLeadMutation,
} = leadsApiSlice;
