import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import parse from 'html-react-parser';

import {
    Box,
    Button,
    Flex,
    Grid,
    GridItem,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import 'react-quill/dist/quill.snow.css';

import {
    setCandidateNotes,
    useCreateCandidateNoteMutation,
    useDelCandidateNoteMutation,
    useListCandidateNoteMutation,
    useUpdateCandidateNoteMutation,
} from 'store/candidatenote.slice';

import AtsEmailBody from '../../EmailBody';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';

interface notesModalProp {
    isOpen: boolean,
    onClose: any,
}
export default function NotesModal({
    isOpen,
    onClose,
}: notesModalProp) {
    const params = useParams();
    const dispatch = useDispatch();

    const { candidateData } = useSelector(
        (state: any) => state.candidates
    );
    const { candidateNotes, candidateNotePgBtn } = useSelector(
        (state: any) => state.candidateNotes
    );

    const [reqCreate, resCreate] = useCreateCandidateNoteMutation();
    const [reqList] = useListCandidateNoteMutation();
    const [reqDel, resDel] = useDelCandidateNoteMutation();
    const [reqUpdate, resUpdate] = useUpdateCandidateNoteMutation();

    const [emailBodyKey, setEmailBodyKey] = useState(0);
    const [quilValue, setquilValue] = useState('');
    const [hover, setHover] = useState({ id: null, hover: null });
    const [loadingNoteId, setLoadingNoteId] = useState(null); // Track the note ID being currently deleted
    const [hasEditNote, SetHasEditNote] = useState(false);
    const [noteId, setNoteId] = useState(null);


    const candidateID = candidateData?.id || null;

    useEffect(() => {
        if (candidateID) {
            reqList({ data: candidateNotePgBtn, id: candidateID });
            setLoadingNoteId(null);
        }
    }, [candidateID, resDel.isSuccess]);

    useEffect(() => {
        if (resCreate.isSuccess || resUpdate.isSuccess) {
            setquilValue('');
            if (candidateID) {
                reqList({ data: candidateNotePgBtn, id: candidateID });
            }
        }
        if (resUpdate.isSuccess) {
            SetHasEditNote(false);
            setNoteId(null);
        }
    }, [resCreate.isSuccess, resUpdate.isSuccess]);

    const submitNote = () => {
        if (quilValue !== '' && candidateID) {
            setquilValue('');
            reqCreate({ data: { notes: quilValue }, id: candidateID });
            setEmailBodyKey((prevKey) => prevKey + 1);
        }
    };

    const content: swalContent = {
        title: 'Are you sure?',
        text: 'You are about to delete this notes.',
        buttons: ['Cancel', true],
        icon: 'warning',
    };

    const delNote = async (id: number) => {
        const confirm = await AtsConfirm(content);
        if (confirm) {
            setLoadingNoteId(id); // Set the ID of the note being deleted
            reqDel({ id });
        }
    };

    const editNote = async (data: any) => {
        setquilValue(data?.notes || '');
        SetHasEditNote(true);
        setNoteId(data?.id || null);
    };

    const cancelUpdate = () => {
        setquilValue('');
        SetHasEditNote(false);
        setNoteId(null);
    };
    const UpdateNotes = async () => {
        if (quilValue !== '') {
            reqUpdate({ data: { notes: quilValue }, id: noteId });
        }
    };
    const handleCloseNotesModal = () => {
        onClose()
    }
    return (
        <>
            <Modal
                isOpen={isOpen}
                closeOnOverlayClick={false}
                onClose={handleCloseNotesModal}
                size="3xl"
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent fontFamily="NunitoSans Regular">
                    <ModalHeader
                        background="default.white.800"
                        borderRadius="4px 4px 0"
                        p="18px 24px"
                        fontSize="21px"
                        lineHeight="21.92px"
                        textAlign="left"
                    >
                        Notes
                    </ModalHeader>
                    <ModalCloseButton
                        top="13px"
                        right="20px"
                        color="default.secondarytext"
                    />
                    <ModalBody
                        borderRadius="0 0 4px 4px"
                        p="24px"
                        fontFamily="NunitoSans Regular"
                    >
                        <Box mb="200px">
                            {Object.values(candidateNotes).length > 0 &&
                                Object.values(candidateNotes).map((notes: any) => {
                                    return (
                                        <Box
                                            borderBottom="1px solid #E6E9EE"
                                            py={4}
                                            key={`job-note-${notes.id}`}
                                        >
                                            <Grid
                                                templateColumns="repeat(5, 1fr)"
                                                gap={4}
                                                key={`candidate-note-${notes.id}`}
                                            >
                                                <GridItem colSpan={4}>
                                                    <Flex
                                                        gap={2}
                                                        alignItems="center"
                                                        justifyContent="flex-start"
                                                        fontWeight={500}
                                                        fontSize="md"
                                                        color="#545454"
                                                    >
                                                        <Box>
                                                            {[notes?.user?.first_name, notes?.user?.last_name].join(
                                                                ' '
                                                            )}
                                                        </Box>
                                                        <Box color="#9B9B9B">|</Box>
                                                        <Box
                                                            onMouseEnter={() => {
                                                                setHover({ id: notes.id, hover: true });
                                                            }}
                                                            onMouseLeave={() => {
                                                                setHover({ id: notes.id, hover: false });
                                                            }}
                                                        >
                                                            {hover.id === notes.id && hover.hover
                                                                ? moment
                                                                    .utc(notes.created_at)
                                                                    .format('MM/DD/YYYY hh:mm:ss A')
                                                                : moment.utc(notes.created_at).format('MM/DD/YYYY')}
                                                        </Box>
                                                    </Flex>
                                                </GridItem>
                                                <GridItem colEnd={8}>
                                                    <Flex
                                                        gap={2}
                                                        alignItems="right"
                                                        justifyContent="flex-end"
                                                        fontWeight={700}
                                                        fontSize="14px"
                                                        color="primary.dark"
                                                    >
                                                        <IconButton
                                                            size="xs"
                                                            aria-label="delete"
                                                            icon={<BsTrash />}
                                                            onClick={() => delNote(Number(notes.id))}
                                                            isLoading={
                                                                loadingNoteId === notes.id && resDel.isLoading
                                                            }
                                                        />
                                                        <IconButton
                                                            size="xs"
                                                            aria-label="edit"
                                                            icon={<BsPencil />}
                                                            onClick={() => editNote(notes)}
                                                        />
                                                    </Flex>
                                                </GridItem>
                                            </Grid>
                                            {notes.notes && (
                                                <Box fontSize="lg">{parse(notes.notes || '')}</Box>
                                            )}
                                        </Box>
                                    );
                                })}
                        </Box>
                        <Box
                            // position="fixed"
                            // right="0"
                            // bottom="0"
                            width="100%"
                            background="default.white.100"
                            boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                        // p="16px 32px"
                        >
                            <AtsEmailBody
                                key={emailBodyKey}
                                initialValue={''}
                                value={quilValue}
                                height={50}
                                menubar={false}
                                onChange={setquilValue}
                            />
                            <Box mt="3" textAlign={'right'}>
                                {hasEditNote ? (
                                    <Flex gap={2} justifyContent="flex-end">
                                        <Button variant="ghost" onClick={() => cancelUpdate()}>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="solid"
                                            onClick={() => UpdateNotes()}
                                            isLoading={resUpdate.isLoading}
                                        >
                                            Update Note
                                        </Button>
                                    </Flex>
                                ) : (
                                    <Button
                                        variant="solid"
                                        onClick={() => submitNote()}
                                        isLoading={resCreate.isLoading}
                                        loadingText="Submitting"
                                    >
                                        Add Note
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>

        </>
    );
}