import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { SOCKET_URL } from 'constants/values';

/**
 * Custom hook to manage a WebSocket connection via Socket.IO.
 *
 * @param roomId The ID of the room to join. If undefined, no connection is established.
 * @returns A Socket instance if connected, otherwise null.
 */
const useSocket = (roomId: string | undefined): Socket | null => {
  // State to hold the socket instance
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    // Do not attempt to connect if no roomId is provided
    if (!roomId) {
      console.log('Room ID is undefined, not initializing socket.');
      return;
    }

    // Log connection attempt and create a new socket connection to the server
    console.log(`Attempting to connect to socket with roomId: ${roomId}`);
    const socketIo: Socket = io(SOCKET_URL, {
      path: '/socket.io',
      transports: ['websocket'],
    });

    // On successful connection, join the specified room
    socketIo.on('connect', () => {
      console.log(`Socket connected: ${socketIo.id}, joining room.`);
      socketIo.emit('join-room', roomId);
    });

    // Handle connection errors
    socketIo.on('connect_error', (error) => {
      console.error('Connection Error:', error);
    });

    // Update the socket state
    setSocket(socketIo);

    // Cleanup function to leave the room and disconnect when the component unmounts or roomId changes
    return () => {
      console.log(`Leaving room and disconnecting socket: ${roomId}`);
      socketIo.emit('leave-room', roomId);
      socketIo.disconnect();
    };
  }, [roomId]); // Re-run effect if roomId changes

  return socket;
};

export default useSocket;
