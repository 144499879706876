import { ChevronLeftIcon } from '@chakra-ui/icons';
import { TabList, Tab, Box, Icon } from '@chakra-ui/react';

interface JobsTabListProps {
  tabList: any;
  isOpen: any;
  tabIndex: any;
}
export const JobsTabList = ({
  tabList,
  isOpen,
  tabIndex,
}: JobsTabListProps) => {
  return (
    <Box px="32px">
      <TabList
        sx={{
          gap: '8px',
          justifyContent: 'center',
          fontSize: '14px',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          border: 'none',
          background: '#F9FAFB',
          color: '#BABABA',
          borderColor: '#EBEBEB',
          height: '38px',
        }}
      >
        {tabList.map((tab: any, key: number) => (
          <Tab
            key={`tablist01-${key}`}
            sx={{
              width: '100%',
              borderBottom: '4px solid #EBEBEB',
              fontWeight: 700,
              cursor: 'auto',
              '&:focus': {
                boxShadow: 'none',
                background: 'none',
              },
              color: tabIndex.id > tab.id && '#6930CA',
              borderColor: tabIndex.id > tab.id && '#6930CA',
            }}
            _selected={{
              color: '#2B2D42',
              borderColor: '#6930CA',
            }}
          >
            {tab.title}
          </Tab>
        ))}
        <Box
          sx={{
            p: '10px',
            width: '450px',
            fontSize: '14px',
            textAlign: 'right',
            fontWeight: 700,
            color: '#718096',
          }}
        >
          Step {tabIndex.id + 1} / {tabList.length}
        </Box>
      </TabList>
    </Box>
  );
};
