import { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import JobsViewCandidatesModule from '../Layout/ViewCandidatesModule';
import { JobMainModule } from '../Layout/JobModule';

export const JobsPage = () => {
  const params = useParams();

  const [isViewCandidates, setIsViewCandidates] = useState(false);

  useEffect(() => {
    setIsViewCandidates(params.jobsTab === 'view-candidates');
  });
  return isViewCandidates ? <JobsViewCandidatesModule /> : <JobMainModule />;
};
