import { Node } from '@tiptap/core';

const CustomParagraph = Node.create({
  name: 'paragraph',

  group: 'block',

  content: 'inline*',

  parseHTML() {
    return [
      {
        tag: 'div',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes, 0];
  },

  addCommands() {
    return {
      setParagraph:
        () =>
        ({ commands }) => {
          return commands.setNode(this.name);
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-0': () => this.editor.commands.setParagraph(),
    };
  },
});

export default CustomParagraph;
