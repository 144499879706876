import { debounce } from 'lodash';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSkillListMutation } from 'store/skills.slice';
import * as Yup from 'yup';

// Define the props for the custom hook
interface useJobDetailsServicesProps {
  setJobStatus: any;
  hasJobPostType: any;
  jobPairTemplate: any;
  jobPairing: any;
  isDraft: any;
  setJobFormData: any;
  initialValues: any;
  jobFormData: any;
  tabList: any;
  tabIndex: any;
  onSubmit: any;
  setTabIndex: any;
}

// Custom hook to handle job details services
export const useJobDetailsServices = ({
  setJobStatus,
  hasJobPostType,
  jobPairTemplate,
  jobPairing,
  isDraft,
  setJobFormData,
  initialValues,
  jobFormData,
  tabList,
  tabIndex,
  onSubmit,
  setTabIndex,
}: useJobDetailsServicesProps) => {
  
  // Mutations and state
  const [reqSearchSkills, resSearchSkills] = useSkillListMutation();
  const [skillList, setSkillList] = useState([]);

  // Fetch constants from the Redux store
  const {
    work_types,
    educations,
    compensations,
    employment_types,
    experience_level,
  } = useSelector((state: any) => state.constants);

  // Debounced skill search function
  const searchSKills = debounce((e: any) => {
    if (e !== '') {
      reqSearchSkills({ query: e, take: 10 });
    }
  }, 500);

  // Effect to handle the success of the skill search and set options
  useEffect(() => {
    if (resSearchSkills.isSuccess) {
      const data: any = resSearchSkills?.data?.data?.data;
      const options: any[] = [];
      if (data) {
        try {
          data?.map((item: any) => {
            options.push({
              label: item.skill,
              value: item.id,
            });
          });
        } catch (e) {
          console.warn({ e });
        }
      }

      setSkillList(options);
    }
  }, [resSearchSkills.isSuccess]);

  // Validation schema for the job details form
  const validationJobDetailsSchema = Yup.lazy((values) => {
    const currentJobStatus = values.job_status_id;
    setJobStatus(currentJobStatus);

    // Conditional validation based on job post type
    if (currentJobStatus === 5) return Yup.mixed().notRequired();

    return hasJobPostType === '1'
      ? Yup.object().shape({
          // Validation rules for job type 1
          job_type_id: Yup.number().required('Job type is required.'),
          title: Yup.string().required('Job title is required.'),
          opening: Yup.number()
            .required('No. of openings is required.')
            .positive('Must be a positive number.'),
          experience_level: Yup.number()
            .required('Experience level is required.')
            .min(1, 'Invalid number.'),
          education_id: Yup.number().required('Education is required.'),
          work_type_id: Yup.number().required('Work type is required.'),
          client_job_link: Yup.string(),
          description: Yup.string(),
          skills: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string(),
              })
            )
            .required('At least one skill is required')
            .min(1, 'At least one skill must be entered'),
          notes: Yup.string(),
          employment_type_id: Yup.number().required(
            'Employment type is required.'
          ),
          compensation_id: Yup.number(),
          campaign: Yup.boolean(),
          salary_range_start: Yup.string()
            .matches(/^[\d,]+$/gi, 'Salary is invalid')
            .required('Salary range start is required.'),
          salary_range_end: Yup.string()
            .matches(/^[\d,]+$/gi, 'Salary is invalid')
            .required('Salary range end is required.'),
          job_status_id: Yup.number().required('Job status is required.'),
        })
      : Yup.object().shape({
          // Validation rules for other job types
          job_type_id: Yup.number().required('Job type is required.'),
          client_job_link: Yup.string(),
          primary_recruiter: Yup.number().required(
            'Primary Recruiter is required.'
          ),
          am_id: Yup.number().required('Account Manager is required.'),
          title: Yup.string().required('Job title is required.'),
          experience_level: Yup.number()
            .required('Experience level is required.')
            .min(1, 'Invalid number.'),
          skills: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string(),
              })
            )
            .required('At least one skill is required')
            .min(1, 'At least one skill must be entered'),
          notes: Yup.string(),
        });
  });

  // Function to handle form submission
  const detailsSubmit = (data: any, { setSubmitting, validateForm }: any) => {
    const pairTemplate = {
      ...jobPairTemplate,
      client_job_title: data.title,
    };

    jobPairing(pairTemplate);

    // Update job form data if not a draft
    if (isDraft === false) {
      setJobFormData((prev: any) => ({
        ...prev,
        ...data,
      }));
      setSubmitting(false);
    }

    // Validate the form and proceed based on the result
    validateForm().then((errors: any) => {
      if (Object.keys(errors).length === 0) {
        if (isDraft === true) {
          onSubmit({
            ...initialValues,
            ...jobFormData,
            ...data,
            job_status_id: 5,
          });
        } else if (tabList?.length !== tabIndex.id + 1) {
          setTabIndex(tabList[tabIndex.id + 1]);
        }
      }
    });
  };

  return {
    validationJobDetailsSchema,
    detailsSubmit,
    experience_level,
    educations,
    work_types,
    employment_types,
    compensations,
    searchSKills,
    resSearchSkills,
    skillList,
  };
};
