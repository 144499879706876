// Constants and Configurations
import jobCandidateStatus from 'constants/jobCandidateStatus';
import viewCandidateConfig from '../VerticalFilter/config';

// React and Redux Hooks
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router';

// Candidate-related Redux Mutations and Actions
import {
  useListCandidatesMutation,
  useGetCandidatesMutation,
  useDeleteCandidatesMutation,
  useToggleHideCandidatesMutation,
  useBulkToggleHideCandidatesMutation,
  useUnRejectCandidateMutation,
  useBulkUnRejectCandidateMutation,
  useScreenCandidateMutation,
  useOfferCandidateMutation,
  useHiredCandidateMutation,
  useRemoveFromJobCandidateMutation,
  useBulkRemoveFromJobCandidateMutation,
  listCandidates,
  getCandidate,
} from 'store/candidates.slice';

// Client Agreement-related Redux Mutations and Actions
import {
  useListClientAgreementMutation,
  setClientAgreement,
} from 'store/clientagreement.slice';

// Job-related Redux Mutations
import { useGetJobsMutation } from 'store/jobs.slice';

// Template-related Redux Mutation
import { usePlaceHolderPairMutation } from 'store/template.slice';

// Types and Utility functions
import {
  CandidatesTypes,
  swalContent,
  CandidateData,
  candidateJobs,
} from 'types';
import {
  AtsConfirm,
  AtsSwalLoading,
  AtsSuccess,
  AtsSwalError,
  AtsSwalClose,
} from 'utils/swal';

interface useViewCandidatesTableServicesProps {
  reloadCandidate: (e: boolean) => void;
  isOpenScheduleInterview: boolean;
  onOpenAddTags: () => void;
  onOpenReject: () => void;
  onOpenSubmitToClient: () => void;
  onOpenChangeStatus: () => void;
  isOpenAddTags: boolean;
  isOpenChangeStatus: boolean;
  isOpenReject: boolean;
  isOpenSendEmail: boolean;
  onOpenSendEmail: () => void;
  onCloseSubmitToClient: () => void;
  onCloseScheduleInterview: () => void;
  onCloseSendEmail: () => void;
  onOpenScheduleInterview: () => void;
  onOpen: () => void;
  onOpenHired: () => void;
  onClose: () => void;
}
export const useViewCandidatesTableServices = ({
  reloadCandidate,
  isOpenScheduleInterview,
  onOpenAddTags,
  onOpenReject,
  onOpenSubmitToClient,
  onOpenChangeStatus,
  isOpenAddTags,
  isOpenChangeStatus,
  isOpenReject,
  isOpenSendEmail,
  onOpenSendEmail,
  onCloseSubmitToClient,
  onCloseScheduleInterview,
  onCloseSendEmail,
  onOpenScheduleInterview,
  onOpen,
  onOpenHired,
  onClose,
}: useViewCandidatesTableServicesProps) => {
  // Initialize hooks and state variables
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux selectors
  const { clientAgreementPgBtn } = useSelector(
    (state: any) => state.clientAgreements
  );
  const { candidates, candidateData, candidatePage, candidatePgBtn } =
    useSelector((state: any) => state.candidates);
  const { jobData, jobRoute, fullData } = useSelector(
    (state: any) => state.jobs
  );

  // API mutations
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqjob] = useGetJobsMutation();
  const [reqDeleteCandidate, resDeleteCandidate] =
    useDeleteCandidatesMutation();
  const [reqToggleHide, resToggleHide] = useToggleHideCandidatesMutation();
  const [reqBulkHide] = useBulkToggleHideCandidatesMutation();
  const [reqUnReject, resUnReject] = useUnRejectCandidateMutation();
  const [reqBulkUnReject, resBulkUnReject] = useBulkUnRejectCandidateMutation();
  const [reqScreen, resScreen] = useScreenCandidateMutation();
  const [reqOffer, resOffer] = useOfferCandidateMutation();
  const [reqHired, resHired] = useHiredCandidateMutation();
  const [reqRemJob, resRemJob] = useRemoveFromJobCandidateMutation();
  const [reqBulkRemJob, resBulkRemJob] =
    useBulkRemoveFromJobCandidateMutation();
  const [reqAgreement] = useListClientAgreementMutation();
  const [reqGetJob] = useGetJobsMutation();
  const [reqPair] = usePlaceHolderPairMutation();

  // Local state
  const [assocId, setAssocId] = useState(0);
  const [assocIdList, setAssocIdList] = useState<number[]>([0]);
  const [email, setEmail] = useState<string>('');
  const [emailList, setEmailList] = useState<any>(null);
  const [mainStatus, setMainStatus] = useState<number>(0);
  const [subStatus, setSubStatus] = useState<number>(0);
  const [intSubj, setSubject] = useState<string>('');
  const [isSorted, setIsSorted] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<boolean>(true);
  const [orderBy, setOrderBy] = useState<any>(null);
  const [contactOptionValue, setContactOptionValue] = useState<any[]>([]);
  const [filterCache] = useState<string>(JSON.stringify({}));
  const [tempJobData, setTempJobData] = useState<any>(null);
  const [jobId, setJobId] = useState<number | null>(null);
  const [candidateCount, setCandidateCount] = useState<number>(
    candidates.length
  );
  const [hasInterview, setHasInterview] = useState<boolean>(false);

  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState<number[]>([]);
  const [isIndeterminate, setisIndeterminate] = useState<boolean>(false);
  const [rowId, setRowId] = useState<number>(1);
  const [candidateId, setCandidateId] = useState<number>(0);
  const [candidateIds, setCandidateIds] = useState<number[]>([]);
  const [candidateSingleData, setCandidateData] = useState<any>(null);
  const [isBulkAction, setIsBulkAction] = useState<boolean>(false);

  // Set document direction
  document.documentElement.dir = 'ltr';

  useEffect(() => {
    if (resCandidates.isSuccess) {
      reloadCandidate(true);
    } else {
      reloadCandidate(false);
    }
  }, [resCandidates.isSuccess]);

  // Effect for resetting contact options when schedule interview modal is closed
  useEffect(() => {
    if (!isOpenScheduleInterview) {
      setContactOptionValue([]);
    }
  }, [isOpenScheduleInterview]);

  // Fetch candidates with filters
  const candidatedFilterData: string | null =
    localStorage.getItem('candidatesFilter');

  const filter =
    candidatedFilterData === null
      ? candidatePgBtn
      : JSON.parse(candidatedFilterData);

  const fetchCandidate = async (param: any) => {
    await reqCandidates({ data: param }).then((res: any) => {
      setCandidateCount(Number(res?.data?.data?.count) || 0);
    });
  };

  // Update candidate count when candidates or candidate count changes
  useEffect(() => {
    setCandidateCount(candidates.length);
  }, [candidateCount, candidates]);

  // Effect to handle filter cache changes
  useEffect(() => {
    if (filterCache !== '{}' && filterCache !== JSON.stringify(filter)) {
      fetchCandidate(filter);
    }
  }, []);

  // Abort controller for handling fetch requests
  const abortJobControllerRef = useRef<AbortController | null>(null);

  // Fetch job data based on job ID in URL parameters
  useEffect(() => {
    if (params?.jobsId) {
      if (abortJobControllerRef.current) {
        abortJobControllerRef.current.abort();
      }

      abortJobControllerRef.current = new AbortController();
      reqGetJob({
        id: params?.jobsId,
        signal: abortJobControllerRef.current.signal,
      });
    }
  }, [params?.jobsId]);

  // Effect for handling API responses and updating candidates list
  useEffect(() => {
    if (resDeleteCandidate.isSuccess || resToggleHide.isSuccess) {
      submitPage(candidatePgBtn, 'useEffect');
      reloadCandidate(true);
    } else {
      reloadCandidate(false);
    }
  }, [resDeleteCandidate.isSuccess, resToggleHide.isSuccess]);

  // Effect to refetch candidates based on various API responses and filters
  useEffect(() => {
    if (
      resUnReject.isSuccess ||
      resBulkUnReject.isSuccess ||
      resScreen.isSuccess ||
      resOffer.isSuccess ||
      resHired.isSuccess ||
      resRemJob.isSuccess ||
      resBulkRemJob.isSuccess
    ) {
      fetchCandidate(candidatePgBtn);
    }
  }, [
    params.jobsId,
    params.jobsTabNav,
    resUnReject.isSuccess,
    resBulkUnReject.isSuccess,
    resScreen.isSuccess,
    resOffer.isSuccess,
    resHired.isSuccess,
    resRemJob.isSuccess,
    resBulkRemJob.isSuccess,
    params.jobsTabId,
    orderBy,
  ]);

  // Reset bulk actions based on tab changes
  useEffect(() => {
    resetBulkActions();
  }, [params.jobsTabId, params.jobsTabNav]);

  // Handle candidate status and filter setup
  useEffect(() => {
    let status = jobCandidateStatus[params.jobsTabId];
    let queryParamName = '';

    if (params.jobsTabId === 'in-progress') {
      queryParamName = 'candidatesFilterProgress';
      let subs: any = [];
      subs['submits'] = 1;
      subs['interviews'] = 3;
      subs['offers'] = 4;
      try {
        status = [subs[jobRoute]];
        if (subs[jobRoute] === null || subs[jobRoute] === undefined) {
          status = [1];
        }
      } catch (e) {
        status = [1];
      }
      setHasInterview(true);
    } else {
      setHasInterview(false);
    }

    let job_id = Number(params.jobsId);
    let excluded_job_id = null;

    switch (params.jobsTabId) {
      case 'search':
        queryParamName = 'candidatesFilterPool';
        excluded_job_id = Number(params.jobsId);
        job_id = null;
        break;
      case 'screen':
        queryParamName = 'candidatesFilterScreen';
        break;
      case 'hired':
        queryParamName = 'candidatesFilterHired';
        break;
      case 'rejected':
        queryParamName = 'candidatesFilterRejected';
        break;
      default:
        queryParamName = '';
    }

    let queryParam = localStorage.getItem(queryParamName) as any;

    if (queryParam) {
      try {
        queryParam = JSON.parse(queryParam);
      } catch (e) {}
    }

    const search =
      filter?.search || queryParam?.search || candidatePgBtn.search || '';
    const query =
      filter.query || queryParam?.search || candidatePgBtn.query || '';

    const newParam = {
      ...filter,
      search,
      query,
      excluded_job_id,
      job_id: job_id,
      status: status,
      ...(orderBy !== null && {
        orderBy: {
          column_name: orderBy?.column_name,
          sort_order: orderBy?.sort_order,
        },
      }),
    };
  }, [
    isSorted,
    sortOrder,
    params.jobsTabId,
    params.jobsTabNav,
    candidates.length,
  ]);

  // Sort candidates based on selected column
  useEffect(() => {
    if (isSorted) {
      const newParam = {
        ...candidatePgBtn,
        ...(orderBy !== null && {
          orderBy: {
            column_name: orderBy?.column_name,
            sort_order: orderBy?.sort_order,
          },
        }),
      };

      dispatch(
        listCandidates({
          candidatePgBtn: newParam,
        })
      );
      setIsSorted(false);
    }
  }, [isSorted]);

  // Handle candidate deletion
  const handleDeleteCandidate = async (candidate: CandidatesTypes) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will delete the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      AtsSwalLoading();
      await reqDeleteCandidate({ id: candidate.id })
        .then(() => AtsSuccess())
        .catch(() => AtsSwalError())
        .finally(() => AtsSwalClose());
      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const handleHideCandidate = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will hide the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      AtsSwalLoading();
      await reqToggleHide({ id: candidate.id })
        .then(() => AtsSuccess())
        .catch(() => AtsSwalError())
        .finally(() => AtsSwalClose());
      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const handleSelectAll = async (e: any) => {
    const { checked } = e.target;

    setIsCheckAll(checked);
    if (!checked) {
      setIsCheck([]);
    } else {
      setIsCheck(candidates.map((li: any) => li.id));
    }
  };

  const handleSort = async (column_name: string) => {
    const newSortOrder = sortOrder ? 'ASC' : 'DESC';
    setSortOrder(!sortOrder);
    setIsSorted(true);
    setOrderBy({
      column_name: column_name,
      sort_order: newSortOrder,
    });
  };

  const handleCheck = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
    }
  };

  const submitPage = (page: any, source: string) => {
    let param = {
      ...page,
      filter: null,
    };
    localStorage.setItem('candidatesFilter', JSON.stringify(page));
    dispatch(
      listCandidates({
        candidatePgBtn: param,
      })
    );
    fetchCandidate(param);
  };

  useEffect(() => {
    setisIndeterminate(false);
    if (isCheck.length > 0 && isCheck.length < filter.take) {
      setisIndeterminate(true);
    }
    if (isCheck.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheck.length === filter.take) {
      setIsCheckAll(true);
    }
  }, [filter.take, isCheck, isCheckAll]);

  const addTagClick = (id: any) => {
    setCandidateId(id);
    onOpenAddTags();
    reloadCandidate(false);
  };

  const editCandidate = (candidate: any) => {
    setCandidateId(candidate.id);
  };

  const moveToScreen = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This candidate will be moved to screen.',
      buttons: ['Cancel', true],
      icon: 'info',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      const id = Number(candidate.id);
      const data = {
        job_id: [Number(params.jobsId)],
        candidate_id: [id],
        isBulk: false,
      };
      let eligable = true;
      if (candidate.jobs?.length > 0) {
        await Promise.all(
          candidate.jobs.map((item: any) => {
            if (item.job_id == params.jobsId) {
              eligable = false;
            }
          })
        );
      }
      if (eligable) {
        AtsSwalLoading();
        await reqScreen({ data })
          .then(() => AtsSuccess())
          .catch(() => AtsSwalError())
          .finally(() => AtsSwalClose());
        // await fetchCandidate(candidatePgBtn);
      } else {
        const content2: swalContent = {
          title: 'Duplicate entry',
          text: 'This candidate is already associated to this job.',
          icon: 'warning',
        };
        await AtsConfirm(content2);
      }
    }
  };

  const moveToOffer = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This candidate will be moved to offer.',
      buttons: ['Cancel', true],
      icon: 'info',
    };
    console.log('went to moveoffer');
    const confirm = await AtsConfirm(content);
    if (confirm) {
      if (candidate.jobs?.length > 0) {
        console.log('went here to candidate jobs');
        const id = Number(candidate.id);
        const data = {
          job_id: [Number(params.jobsId)],
          candidate_id: [id],
          isBulk: false,
        };
        AtsSwalLoading();
        await reqOffer({ data })
          .then(() => AtsSuccess())
          .catch(() => AtsSwalError())
          .finally(() => AtsSwalClose());
      }
    }
  };

  const moveToHired = async (candidate: CandidateData) => {
    dispatch(
      getCandidate({
        candidateData: candidate,
        candidateDataJobId: Number(params.jobsId),
      })
    );
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This candidate will be moved to hired.',
      buttons: ['Cancel', true],
      icon: 'info',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      let clientID = null;

      if (candidate?.jobs?.[0]?.client?.id) {
        clientID = candidate?.jobs?.[0]?.client?.id;
      } else if (
        jobData?.client_id &&
        jobData?.id === candidate?.jobs?.[0]?.id
      ) {
        clientID = jobData?.client_id;
      }
      if (
        candidateData?.id !== candidate?.id ||
        !candidateData?.source?.source
      ) {
        await reqGetCandidate({ id: candidate?.id });
      }
      if (
        jobData?.id !== candidate?.jobs?.[0]?.id ||
        !jobData?.lead?.lead_source?.source
      ) {
        await reqGetJob({ id: candidate?.jobs?.[0]?.id });
      }
      if (clientID) {
        let newParam = {
          page: 1,
          take: clientAgreementPgBtn.take,
          query: '',
          status: clientAgreementPgBtn.status,
          types: clientAgreementPgBtn.types,
        };
        dispatch(
          setClientAgreement({
            leadAgreementPgBtn: newParam,
          })
        );
        await reqAgreement({ data: newParam, id: clientID });
      }

      onOpenHired();
    }
  };

  const callBackAction = () => {
    if (candidateData.jobs?.length > 0) {
      const id = Number(candidateData.id);
      const data = {
        job_id: [Number(params.jobsId)],
        candidate_id: [id],
        // CHANGE THIS VALUE IF THERE WOULD BE A BULK HIRE ACTION
        isBulk: false,
      };
      reqHired({ data }).then(() => {
        reloadCandidate(false);
        const intervalId = setInterval(() => {
          reloadCandidate(true);
        }, 50);
        clearInterval(intervalId);
        fetchCandidate(candidatePgBtn);
      });
    }
  };

  const bulkHide = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will hide the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      const data = {
        idList: isCheck,
      };
      AtsSwalLoading();
      await reqBulkHide({ data })
        .then(() => AtsSuccess())
        .catch(() => AtsSwalError())
        .finally(() => AtsSwalClose());
      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const bulkScreen = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This candidate(s) will be moved to screen.',
      buttons: ['Cancel', true],
      icon: 'info',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      AtsSwalLoading();
      const data = {
        job_id: [Number(params.jobsId)],
        candidate_id: isCheck,
        isBulk: true,
      };

      reqScreen({ data })
        .then(() => AtsSuccess())
        .catch(() => AtsSwalError())
        .finally(() => AtsSwalClose());
      setIsCheck([]);
    }
  };

  const rejectClick = async (candidate: any) => {
    if (candidate?.jobs?.length > 0) {
      candidate?.jobs.map((job: candidateJobs) => {
        if (job?.job_id === Number(params.jobsId)) {
          setAssocId(Number(job?.assoc_id));
          onOpenReject();
        }
      });
    }
  };

  const unRejectClick = async (candidate: any) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'You are about to unreject this candidate.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      if (candidate.jobs?.length > 0) {
        const job = candidate.jobs[0] as candidateJobs;
        AtsSwalLoading();
        reqUnReject({ data: [], id: job.assoc_id })
          .then(() => AtsSuccess())
          .catch(() => AtsSwalError())
          .finally(() => AtsSwalClose());
      }
    }
  };

  const removeJobClick = async (candidate: any) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'You are about to remove this candidate from this job.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      if (candidate.jobs?.length > 0) {
        const job = candidate.jobs[0] as candidateJobs;
        const id = job.assoc_id;
        AtsSwalLoading();
        reqRemJob({ data: [], id })
          .then(() => AtsSuccess())
          .catch(() => AtsSwalError())
          .finally(() => AtsSwalClose());
      }
    }
  };

  const bulkRemoveJob = async () => {
    const candidateList = candidates.filter((candidate: any) =>
      isCheck.includes(candidate.id)
    );
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'You are about to remove these candidates from this job.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    let associateIdList: any = [];

    if (confirm) {
      candidateList.map((candidate: any) => {
        if (candidate?.jobs?.length > 0) {
          const job = candidate.jobs[0] as candidateJobs;
          associateIdList.push(job.assoc_id);
        }
      });
      const data = {
        idList: associateIdList,
      };
      AtsSwalLoading();
      reqBulkRemJob({ data })
        .then(() => AtsSuccess())
        .catch(() => AtsSwalError())
        .finally(() => AtsSwalClose());
      setIsCheck([]);
    }
  };

  const submitToClient = async (candidate: any) => {
    const job_id = candidate?.jobs?.[0]?.job_id || params?.jobsId;
    setJobId(job_id);
    await reqGetCandidate({ id: candidate?.id });
    if (job_id) {
      await reqjob({ id: job_id }).then(async (res: any) => {
        onOpenSubmitToClient();
      });
    }
  };

  const changeStatus = (candidate: any) => {
    if (candidate?.jobs?.length > 0) {
      dispatch(getCandidate({ candidateData: candidate }));
      candidate?.jobs.map((job: candidateJobs) => {
        if (job?.job_id === Number(params.jobsId)) {
          setAssocId(Number(job?.assoc_id));
          setMainStatus(Number(job?.status.id));
          setSubStatus(Number(job?.sub.id));
          onOpenChangeStatus();
        }
      });
    }
  };

  const resetBulkActions = () => {
    setIsBulkAction(false);
    setIsCheck([]);
    setIsCheckAll(false);
  };

  const bulkChangeStatus = async () => {
    const candidateList = candidates.filter((candidate: any) =>
      isCheck.includes(candidate.id)
    );
    setIsBulkAction(true);
    let associateIdList: any = [];
    let mainStatusSet = false;
    candidateList.map((candidate: any) => {
      if (candidate?.jobs?.length > 0) {
        setCandidateData(candidate);
        candidate?.jobs.map((job: candidateJobs) => {
          if (job?.job_id === Number(params.jobsId)) {
            associateIdList.push(Number(job?.assoc_id));
          }
          // SET THE MAIN STATUS AND SUB STATUS TO THE CURRENT STATUS OF FIRST CANDIDATE
          if (!mainStatusSet) {
            setMainStatus(Number(job?.status?.id));
            setSubStatus(Number(job?.sub?.id));
            mainStatusSet = true;
          }
        });
      }
    });
    setAssocIdList(associateIdList);
    onOpenChangeStatus();
  };

  useEffect(() => {
    if (candidates) {
      if (
        !(
          isOpenAddTags ||
          isOpenChangeStatus ||
          isOpenReject ||
          isOpenSendEmail
        )
      )
        setIsBulkAction(false);
      setIsCheckAll(false);
    }
  }, [
    candidates,
    isOpenAddTags,
    isOpenChangeStatus,
    isOpenReject,
    isOpenSendEmail,
  ]);

  const bulkReject = async () => {
    const candidateList = candidates.filter((candidate: any) =>
      isCheck.includes(candidate.id)
    );
    setIsBulkAction(true);
    let associateIdList: any = [];

    candidateList.map((candidate: any) => {
      if (candidate?.jobs?.length > 0) {
        candidate?.jobs.map((job: candidateJobs) => {
          if (job?.job_id === Number(params.jobsId)) {
            associateIdList.push(Number(job?.assoc_id));
          }
        });
      }
    });
    setAssocIdList(associateIdList);
    onOpenReject();
  };

  const bulkUnreject = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'You are about to unreject these candidates.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    const candidateList = candidates.filter((candidate: any) =>
      isCheck.includes(candidate.id)
    );
    let associateIdList: any = [];

    if (confirm) {
      candidateList.map((candidate: any) => {
        if (candidate.jobs?.length > 0) {
          try {
            associateIdList.push(candidate.jobs[0]['assoc_id']);
          } catch (e) {}
        }
      });
      let data = {
        idList: associateIdList,
      };
      AtsSwalLoading();
      reqBulkUnReject({ data })
        .then(() => AtsSuccess())
        .catch(() => AtsSwalError())
        .finally(() => AtsSwalClose());
    }
  };

  const bulkOffer = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'These candidates will be moved to offer.',
      buttons: ['Cancel', true],
      icon: 'info',
    };
    const confirm = await AtsConfirm(content);

    if (confirm) {
      const data = {
        job_id: [Number(params.jobsId)],
        candidate_id: isCheck,
        isBulk: true,
      };
      await reqOffer({ data });
    }
  };

  const bulkTags = () => {
    setIsBulkAction(true);
    onOpenAddTags();
  };

  const bulkEmail = async () => {
    setIsBulkAction(true);
    const candidateList = candidates?.filter((candidate: any) =>
      isCheck.includes(candidate.id)
    );
    const emails = candidateList?.map(
      (candidate: any) => candidate?.emails[0]?.email
    );
    setEmailList(emails);
    setCandidateId(candidateList[0].id);
    dispatch(getCandidate({ candidateData: candidateList[0] }));
    onOpenSendEmail();
  };

  const tabOptions = [
    'blasted',
    'screen',
    'submits',
    'interviews',
    'offers',
    'hired',
    'rejected',
  ];

  let {
    blastedCountParam,
    screenCountParam,
    submitCountParam,
    interviewCountParam,
    offersCountParam,
    hiredCountParam,
    rejectedCountParam,
  } = viewCandidateConfig(candidatePgBtn);

  const payloads = [
    blastedCountParam,
    screenCountParam,
    submitCountParam,
    interviewCountParam,
    offersCountParam,
    hiredCountParam,
    rejectedCountParam,
  ];

  const reloadCandidateList = async () => {
    const index = tabOptions.indexOf(params?.jobsTabNav);

    const parameter = {
      ...candidatePgBtn,
      ...payloads[index],
    };

    fetchCandidate(parameter);
  };

  const drawerOnClose = () => {
    onClose();
    reloadCandidateList();
  };

  const submitClientClose = () => {
    onCloseSubmitToClient();
    reloadCandidateList();
  };

  const interviewClose = () => {
    onCloseScheduleInterview();
    reloadCandidateList();
  };

  const sendEmailclose = () => {
    onCloseSendEmail();
    const index = tabOptions.indexOf(params?.jobsTabNav);
    const parameter = {
      ...candidatePgBtn,
      job_status:
        candidatePgBtn.job_status || payloads[index]?.job_status || null,
      status: candidatePgBtn.status || payloads[index]?.status || [],
    };
    fetchCandidate(parameter);
  };

  const tagSuccess = async () => {
    reloadCandidateList();
  };

  useEffect(() => {
    if (jobData?.id) {
      setTempJobData(jobData);
    }
  }, [jobData]);

  const scheduleForInterview = async (candidate: any) => {
    if (tempJobData === null) {
      await reqGetJob({ id: params.jobsId });
    }

    const jobs: candidateJobs[] = candidate?.jobs.filter((job: any) => {
      return job.job_id === Number(params.jobsId);
    });
    const latest: any = jobs[jobs.length - 1];
    const client_name = jobData?.client ? jobData?.client?.name : '';
    const contactOption = [
      {
        label: [jobData?.lead?.first_name, jobData?.lead?.last_name].join(' '),
        value: jobData?.lead?.primary_email,
      },
    ];
    setContactOptionValue(contactOption);
    const subject =
      'Video Interview' +
      ' | ' +
      latest?.title +
      ' | ' +
      candidate?.first_name +
      ' ' +
      candidate?.last_name +
      '/' +
      client_name;
    setAssocId(latest?.assoc_id);
    setSubject(subject);
    onOpenScheduleInterview();
    dispatch(getCandidate({ candidateData: candidate }));
  };

  const statusChangeSuccess = async () => {
    resetBulkActions();
    await reqGetJob({ id: params.jobsId });
  };

  const onStatusChange = (e: any) => {
    console.log({ e });
  };

  const pageChange = async (page: number) => {
    let job_id = Number(params.jobsId);
    let excluded_job_id = null;
    let job_status = candidatePgBtn.job_status;
    let status = candidatePgBtn.status;

    if (params.jobsTabId === 'search') {
      excluded_job_id = Number(params.jobsId);
      job_id = null;
      job_status = null;
      status = null;
    }

    const newParam = {
      ...filter,
      page: page,
      excluded_job_id,
      job_id: job_id,
      job_status,
      status,
      ...(orderBy !== null && {
        orderBy: {
          column_name: orderBy?.column_name,
          sort_order: orderBy?.sort_order,
        },
      }),
    };

    submitPage(newParam, 'pageChange');
  };

  const entryChange = (entries: number) => {
    let job_id = Number(params.jobsId);
    let excluded_job_id = null;
    let job_status = candidatePgBtn.job_status;
    let status = candidatePgBtn.status;
    if (params.jobsTabId === 'search') {
      excluded_job_id = Number(params.jobsId);
      job_id = null;
      job_status = null;
      status = null;
    }

    const newParam = {
      ...filter,
      page: 1,
      take: entries,
      excluded_job_id,
      job_id: job_id,
      job_status,
      status,
      ...(orderBy !== null && {
        orderBy: {
          column_name: orderBy?.column_name,
          sort_order: orderBy?.sort_order,
        },
      }),
    };

    submitPage(newParam, 'entryChange');
  };

  const handleClick = (candidate: CandidatesTypes) => {
    if (candidate) {
      dispatch(
        getCandidate({
          candidateData: candidate,
          candidateDataJobId: Number(params.jobsId),
        })
      );

      const nav = [
        params.jobsId,
        params.jobsTab,
        params.jobsTabId,
        params.jobsTabNav,
        candidate.id,
        'about',
      ];
      onOpen();
      navigate(`${nav.filter(Boolean).join('/')}`);

      const key =
        candidates.findIndex((x: CandidatesTypes) => x.id === candidate.id) + 1;
      setRowId(key);
    }
  };

  const rejectCallBack = async () => {
    console.log('reject callback');
    resetBulkActions();
    fetchCandidate(candidatePgBtn);
  };

  const handlePrevData = () => {
    const candidateID = candidates[rowId - 1 - 1]?.id;
    if (candidateID) {
      const prevData = candidates.filter(
        (data: CandidatesTypes) => data.id === candidateID
      );
      setRowId((prevKey: number) => prevKey - 1);
      dispatch(getCandidate({ candidateData: prevData[0] }));
      const nav = [
        params.jobsId,
        params.jobsTab,
        params.jobsTabId,
        params.jobsTabNav,
        candidateID,
        params.jobsDrawerTab || 'about',
      ];
      navigate(`${nav.filter(Boolean).join('/')}`);
    }
  };

  const handleNextData = () => {
    const newRow = rowId + 1;
    const nextData = candidates[rowId];

    if (nextData) {
      const candidateID = nextData ? nextData?.id : null;
      setRowId(newRow);

      const nav = [
        params.jobsId,
        params.jobsTab,
        params.jobsTabId,
        params.jobsTabNav,
        candidateID,
        params.jobsDrawerTab,
      ];
      navigate(`${nav.filter(Boolean).join('/')}`);
      dispatch(getCandidate({ candidateData: nextData[0] }));
    }
  };

  useEffect(() => {
    if (Number(params.candidateId)) {
      const data = candidates.find(
        (x: CandidatesTypes) => x.id === Number(params.candidateId)
      );
      handleClick(data);
    }
  }, [params.candidateId, candidates]);

  const handleSendEmail = async (candidate: any) => {
    let emails = candidate?.emails?.filter((email: any) => email.primary);
    emails = emails.length === 0 ? candidate?.emails : emails;
    setEmail(emails?.[0]?.email);
    const data = {
      candidate_id: candidate?.id,
      job_id: params.jobsId || null,
    } as any;
    await reqPair(data);
    setCandidateId(candidate?.id);
    dispatch(getCandidate({ candidateData: candidate }));
    onOpenSendEmail();
  };

  return {
    params,
    bulkEmail,
    bulkChangeStatus,
    bulkTags,
    bulkReject,
    bulkRemoveJob,
    bulkOffer,
    setIsBulkAction,
    candidates,
    isCheck,
    setCandidateIds,
    handleSelectAll,
    isIndeterminate,
    isCheckAll,
    isLoading: resCandidates.isLoading || resGetCandidate.isLoading,
    handleCheck,
    handleClick,
    addTagClick,
    editCandidate,
    handleDeleteCandidate,
    handleHideCandidate,
    onStatusChange,
    moveToScreen,
    submitToClient,
    scheduleForInterview,
    handleSendEmail,
    changeStatus,
    rejectClick,
    moveToOffer,
    moveToHired,
    unRejectClick,
    removeJobClick,
    hasInterview,
    candidatePage,
    pageChange,
    entryChange,
    filter,
    assocId,
    assocIdList,
    mainStatus,
    subStatus,
    statusChangeSuccess,
    isBulkAction,
    candidateData,
    candidateSingleData,
    jobId,
    tagSuccess,
    candidateIds,
    sendEmailclose,
    email,
    candidateId,
    emailList,
    interviewClose,
    intSubj,
    contactOptionValue,
    submitClientClose,
    drawerOnClose,
    rowId,
    setRowId,
    candidateCount,
    handlePrevData,
    handleNextData,
    handleSort,
    bulkHide,
    bulkUnreject,
    bulkScreen,
    rejectCallBack,
    callBackAction,
    // Return any other functions or states as needed
  };
};
