import {
  Tabs,
  TabList,
  Button as ButtonChakra,
  Tab,
  TabPanels,
  TabPanel,
  FormControl,
  Flex,
  FormLabel,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  FormErrorMessage,
  Divider,
  ModalFooter,
  Box,
  Menu,
  Text,
  Stack,
} from '@chakra-ui/react';
import Button from 'Library/Button';

import ScrollToFieldError from 'components/app/ScrollError';

import parse from 'html-react-parser';
import { Formik } from 'formik';
import FAIcon from 'components/lib/FAIcon';
import { useAIGeneratorServices } from 'components/app/AIGenerator/useAIGeneratorServices';
import { useState } from 'react';
import AtsEmailBody from 'components/app/Global/Email/AIBody';

interface CustomizePitchQuickProps {
  setJobFormData: (data: any) => void;
  onSubmit: (data: any) => void;
  jobFormData: any;
  initialValues: any;
  tabList: any;
  tabIndex: any;
  setTabIndex: (data: any) => void;
  isOpenAlert: boolean;
  onOpen: () => void;
  validationSchema: any;
  jobStatus: any;
  placeholderOptions: any;
  placeHolderClick: any;
  placholders: any;
  isLoading: any;
  setErrorList: any;
  modalHeader: any;
  onClose: () => void;
}

export const CustomizePitchQuickForm = ({
  setJobFormData,
  onSubmit,
  jobFormData,
  initialValues,
  tabList,
  tabIndex,
  setTabIndex,
  isOpenAlert,
  onOpen,
  validationSchema,
  jobStatus,
  placeholderOptions,
  placeHolderClick,
  placholders,
  isLoading,
  setErrorList,
  modalHeader,
  onClose,
}: CustomizePitchQuickProps) => {
  const {
    hasAIGenerate,
    setHasAIGenerate,
    setIsGenerate,
    handleValues,
    handleAIGenerator,
    setIsError,
    isMaximize,
  } = useAIGeneratorServices({ hasType: 'Jobs' });

  const [previewOpen, setPreviewOpen] = useState(true);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(data: any) => {
        onSubmit({
          ...jobFormData,
          status: jobStatus,
          lead_id: data.lead_id,
          email_subject: data.email_subject,
          email_content: data.email_content,
          job_status_id: 1,
        });
      }}
      enableReinitialize={true}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isValid,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} style={{ height: 'inherit' }}>
          <ScrollToFieldError />

          <Stack justifyContent="space-between" h="inherit">
            <Flex
              justifyContent="center"
              gap="10px"
              sx={{
                '& #ai': {
                  m: 0,
                  overflowY: 'scroll',
                  h: 'inherit',
                },
                '& ::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <Stack
                h="inherit"
                spacing="10px"
                sx={{
                  display: hasAIGenerate && isMaximize ? 'none' : 'flex',
                  w: hasAIGenerate !== '' ? '65%' : '100%',
                  mr: hasAIGenerate && 0,
                }}
              >
                <Stack
                  spacing="16px"
                  sx={{
                    background: '#fff',
                    borderRadius: '6px',
                    p: '16px',
                  }}
                >
                  <FormControl
                    isInvalid={Boolean(
                      !!errors.email_subject && touched.email_subject
                    )}
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <FormLabel fontSize="14px" lineHeight="18px">
                        Subject
                      </FormLabel>
                      <ButtonChakra
                        leftIcon={
                          <FAIcon
                            iconName={!previewOpen ? 'eye' : 'eye-slash'}
                          />
                        }
                        variant={'borderless'}
                        onClick={() => {
                          setPreviewOpen(!previewOpen);
                        }}
                      >
                        Preview
                      </ButtonChakra>
                    </Flex>

                    <Input
                      name="email_subject"
                      type="text"
                      placeholder="Email Subject"
                      variant="outline"
                      value={
                        !previewOpen
                          ? values.email_subject
                          : placholders(values.email_subject)
                      }
                      onChange={(e: any) =>
                        setFieldValue('email_subject', e.target.value)
                      }
                      sx={{
                        height: '38px',
                        border: '1px solid #E7EDF4',
                        p: '8px 12px',
                        fontSize: '14px',
                      }}
                    />
                    <FormErrorMessage>
                      {String(errors.email_subject)}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>

                <Stack
                  sx={{
                    position: 'relative',
                    bgColor: '#FFF',
                    borderRadius: '6px',
                    p: '16px',
                  }}
                >
                  <FormControl
                    isInvalid={Boolean(
                      !!errors.email_content && touched.email_content
                    )}
                  >
                    <AtsEmailBody
                      value={
                        !previewOpen
                          ? values.email_content
                          : placholders(values.email_content)
                      }
                      onChange={(e: any) => {
                        setFieldValue('email_content', e);
                      }}
                      height={200}
                      hasAIGenerate={hasAIGenerate}
                      setHasAIGenerate={setHasAIGenerate}
                      setIsGenerate={setIsGenerate}
                      handleValues={handleValues}
                      setIsError={setIsError}
                      subject={values.subject}
                      body={values.email_content}
                    />
                    <FormErrorMessage>
                      {String(errors.email_content)}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </Stack>

              {handleAIGenerator(setFieldValue)}
            </Flex>

            <ModalFooter
              position="sticky"
              bottom="0"
              background="#F9F9FB"
              p="8px 0"
              zIndex={2}
            >
              <Flex
                gap="10px"
                justifyContent="space-between"
                sx={{ w: '100%' }}
              >
                {tabIndex.id > 0 && (
                  <Button
                    disabled={isOpenAlert}
                    mr={3}
                    onClick={() => {
                      tabIndex.id > 0 && setTabIndex(tabList[tabIndex.id - 1]);
                      setJobFormData((prev: any) => ({
                        ...prev,
                        ...values,
                        email_subject: values.email_subject,
                        email_content: values.email_content,
                      }));
                    }}
                  >
                    Previous
                  </Button>
                )}

                <Flex gap="10px">
                  <ButtonChakra
                    onClick={() => {
                      onClose();
                      localStorage.setItem('jobFormData', JSON.stringify({}));
                    }}
                  >
                    Cancel
                  </ButtonChakra>

                  {String(jobFormData.job_type_id) === '2' &&
                    tabIndex.id === 2 && (
                      <Button
                        type="submit"
                        variant="solid"
                        loading={isLoading}
                        onClick={(e: any) => {
                          console.log(errors);
                          handleSubmit(e);
                          setErrorList(errors);
                          !isValid && onOpen();
                        }}
                        disabled={isOpenAlert || isLoading}
                      >
                        {modalHeader === 'Edit Job' ? 'Update' : 'Save'}
                      </Button>
                    )}
                </Flex>
              </Flex>
            </ModalFooter>
          </Stack>
        </form>
      )}
    </Formik>
  );
};
