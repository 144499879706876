// Import necessary modules and functions from Redux and Yup
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listClients } from 'store/client.slice';
import { getLead } from 'store/leads.slice';
import * as Yup from 'yup';

// Define the interface for the props used in useClientServices
interface useClientServicesProps {
  setJobStatus: any;
  hasJobPostType: any;
  isDraft: any;
  setJobFormData: any;
  jobFormData: any;
  jobData: any;
  aMOption: any;
  auth: any;
  onSubmit: any;
  initialValues: any;
  tabList: any;
  tabIndex: any;
  setTabIndex: any;
  jobAdDescTemplate: any;
}

// Main hook function to handle client services
export const useClientServices = ({
  setJobStatus,
  hasJobPostType,
  isDraft,
  setJobFormData,
  jobFormData,
  jobData,
  aMOption,
  auth,
  onSubmit,
  initialValues,
  tabList,
  tabIndex,
  setTabIndex,
  jobAdDescTemplate,
}: useClientServicesProps) => {
  // Define email validation schema using Yup
  const emailSchema = Yup.string().email('Invalid email format');

  // Define the validation schema for client data based on job status and type
  const validationClientSchema = Yup.lazy((values) => {
    var currentJobStatus = values.job_status_id;
    setJobStatus(currentJobStatus);
    if (currentJobStatus === 5) return Yup.mixed().notRequired();
    return hasJobPostType === '1'
      ? Yup.object().shape({
          // Client fields with validation
          job_type_id: Yup.number().required('Job type is required.'),
          company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
          contact: Yup.array().min(1, 'Pick at least 1 contact').nullable(),
          industry: Yup.array().min(1, 'Pick at least 1 industry').nullable(),
          location: Yup.array().min(1, 'Pick at least 1 location').nullable(),
          cc: Yup.array()
            .nullable()
            .of(
              Yup.object().shape({
                label: Yup.string().when('$isLabelProvided', {
                  is: true,
                  then: emailSchema,
                  otherwise: Yup.string(),
                }),
                id: Yup.string(),
                __isNew__: Yup.boolean(),
              })
            ),
          bcc: Yup.array()
            .nullable()
            .of(
              Yup.object().shape({
                label: Yup.string().when('$isLabelProvided', {
                  is: true,
                  then: emailSchema,
                  otherwise: Yup.string(),
                }),
                id: Yup.string(),
                __isNew__: Yup.boolean(),
              })
            ),
          job_status_id: Yup.number().required('Job status is required.'),
        })
      : Yup.object().shape({
          // Client fields without additional validation
          job_type_id: Yup.number().required('Job type is required.'),
          company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
          contact: Yup.array().min(1, 'Pick at least 1 contact').nullable(),
          industry: Yup.array().min(1, 'Pick at least 1 industry').nullable(),
          location: Yup.array().min(1, 'Pick at least 1 location').nullable(),
        });
  });

  // Extract relevant data from the Redux store
  const { client_location_obj } = useSelector((state: any) => state.clients);
  const { countries } = useSelector((state: any) => state.constants);

  // Initialize dispatch function from Redux
  const dispatch = useDispatch();

  // Function to handle form submission
  const clientSubmit = (data: any, { setSubmitting, validateForm }: any) => {
    console.log({ data });
    if (isDraft === false) {
      setJobFormData((prev: any) => ({
        opening: jobFormData.opening || jobData.opening || 1,
        employment_type_id:
          jobFormData.employment_type_id || jobData.employment_type_id || 1,
        compensation_id:
          jobFormData.compensation_id || jobData.compensation_id || 2,
        am_id: aMOption?.length > 0 ? aMOption[0]?.value : ('' as any),
        ...prev,
        ...data,
        primary_recruiter:
          jobFormData.primary_recruiter ||
          jobData.primary?.recruiter_id ||
          auth?.id,
      }));
    }
    setSubmitting(false);
    validateForm().then((errors: any) => {
      if (Object.keys(errors).length === 0) {
        if (isDraft === true) {
          onSubmit({
            ...initialValues,
            ...data,
            ...jobFormData,
            job_status_id: 5,
          });
        } else if (tabList?.length !== tabIndex.id + 1) {
          setTabIndex(tabList[tabIndex.id + 1]);
        }
      }
    });
  };

  // Function to handle contact selection
  const handleContact = () => {
    dispatch(
      getLead({
        selected_contact: [],
      })
    );
  };

  // Function to handle form blur event and save data to local storage
  const handleBlur = (values: any) => {
    localStorage.setItem(
      'jobFormData',
      JSON.stringify({
        ...jobFormData,
        ...values,
      })
    );
  };

  // Function to handle location selection
  const handleLocation = (values: any) => {
    dispatch(
      listClients({
        selected_client: values,
        selected_client_location: [],
        client_location_obj: {
          city: '',
          state: '',
          zip_code: '',
          country: '',
        },
      })
    );
  };

  // Function to handle the editing of job ad modal
  const handleEditModal = (values: any) => {
    return [
      {
        title: '',
        description: jobAdDescTemplate,
        career_page_publish: true,
        show_profile: false,
        free_job_board_publish: true,
        paid_job_board_publish: false,
        active: 1,
        ...(jobFormData?.job_ads && jobFormData?.job_ads[0]),
        state: values.location?.data?.state || client_location_obj?.state,
        city: values.location?.data?.city || client_location_obj?.city,
        zip_code:
          values.location?.data?.zip_code || client_location_obj?.zip_code,
        country: (countries?.length > 0 &&
          countries
            .map((item: any) => ({
              label: item.country,
              value: item.id,
              code: item.code,
            }))
            .filter(
              (val: any) =>
                val.label ===
                (values.location?.data?.country?.country ||
                  client_location_obj?.country)
            ))[0],
      },
    ];
  };

  // Return the functions and schemas for external use
  return {
    validationClientSchema,
    clientSubmit,
    handleContact,
    handleBlur,
    handleLocation,
    handleEditModal,
  };
};
