const jobAdDescTemplate = `
    <p><b>Job Title</b>: </p>
    <p><b>Location</b>: </p>
    <p><b>Salary</b>: </p>
    <p><b>Skills</b>: </p>
    <br/>
    <p><b>About Company / Opportunity</b>: </p>
    <br/>
    <p><b>Responsibilities</b>: </p>
    <br/>
    <p><b>Must-Have Skills</b>: </p>
    <ol style="list-style-position: inside; padding-left: 0;">
        <li></li>
        <li></li>
        <li></li>
    </ol>
    <br/>
    <p><b>Nice to Have Skills</b>: </p>
    <ol style="list-style-position: inside;  padding-left: 0;">
        <li></li>
        <li></li>
        <li></li>
    </ol>
`

export default jobAdDescTemplate;