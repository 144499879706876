import {
  Box,
  Checkbox,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link,
} from '@chakra-ui/react';
import LeadStatus from 'components/app/LeadStatus';
import moment from 'moment';
import React, { ReactElement } from 'react';

import { LeadsTypes } from 'types';
import toUrl from 'utils/toUrl';
import { useTableServices } from './useTableServices';

export default function NewPropectsTable() {
  const {
    isCheck,
    isIndeterminate,
    isCheckAll,
    leads,
    handleCheck,
    handleClick,
    handleSelectAll,
  } = useTableServices();
  const headers: { label: ReactElement | string }[] = [
    {
      label: (
        <Checkbox
          onChange={handleSelectAll}
          isIndeterminate={isIndeterminate}
          checked={isCheckAll}
          defaultChecked={false}
          colorScheme="purple"
        />
      ),
    },
    { label: 'Prospect Details' },
    { label: 'Client Name' },
    { label: 'Contact Information' },
    { label: 'Client Job Link' },
    { label: 'Status ' },
    { label: 'Created Date ' },
  ];
  return (
    <Table>
      <Thead>
        <Tr bg="default.white.600">
          {headers.map((title, key: number) => (
            <Th key={`lead-th-${key}`}>
              <Box color="default.gray.600">{title.label}</Box>
            </Th>
          ))}
        </Tr>
      </Thead>

      <Tbody
        boxSizing="border-box"
        background="default.white.100"
        borderBottom="1px solid"
        borderColor="default.white.400"
      >
        {leads.map((lead: LeadsTypes) => (
          <Tr
            key={`lead-tr-${lead.id}`}
            _hover={{
              bg: '#f8f9fa',
            }}
          >
            <Td w={50} cursor="pointer">
              <Checkbox
                onChange={handleCheck}
                isChecked={isCheck.includes(lead.id)}
                id={lead.id.toString()}
                key={lead.id}
                colorScheme="purple"
              />
            </Td>
            <Td width={250} onClick={() => handleClick(lead)} cursor="pointer">
              <Box whiteSpace="pre-line" maxWidth="250px">
                <Box
                  fontWeight="bold"
                  textTransform="capitalize"
                  style={{ textWrap: 'nowrap' }}
                >
                  {[lead.first_name, lead.last_name].join(' ')}
                </Box>
                <Box fontSize="sm"> {lead.title}</Box>
                <Box fontSize="sm"> {lead?.client?.name}</Box>
              </Box>
            </Td>
            <Td width="50px" p="5px 24px" fontSize="14px" cursor="pointer">
              <Link
                href={`/clients/my-clients/${lead.client?.id}/overview`}
                target="_blank"
              >
                {lead?.client?.name}
              </Link>
            </Td>

            <Td width={250} onClick={() => handleClick(lead)} cursor="pointer">
              <Box>
                <Box fontWeight="bold" fontSize="sm">
                  {lead.primary_email}
                </Box>
                <Box fontSize="sm"> {lead.personal_phone}</Box>
              </Box>
            </Td>
            <Td p="5px 24px" fontSize="14px" cursor="pointer">
              <Link href={toUrl(lead.client_job_link)} target="_blank">
                {lead?.client_job_title}
              </Link>
            </Td>
            <Td onClick={() => handleClick(lead)} cursor="pointer">
              <LeadStatus status={lead.leadStatus.lead_status} />
            </Td>
            <Td
              width="50px"
              p="5px 24px"
              fontSize="14px"
              onClick={() => handleClick(lead)}
              cursor="pointer"
            >
              {moment.utc(lead?.created_at).format('MM/DD/YYYY')}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
