// React and hooks
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Moment.js for date manipulation
import moment from 'moment';

// Chakra UI components
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

// Assets
import GoTo from 'assets/images/arrowSquare.svg';

// Components
import FAIcon from 'components/lib/FAIcon';
import CandidatesAbout from './About';
import CandidatesActivity from './Activity';
import CandidatesAssociatedJobs from './AssociatedJobs';
import CandidatesAttachments from './Attachments';
import CandidatesInterviews from './Interviews';
import StreamAttachmentComponent from '../streamV2';
import CandidateUpdateResumeModal from '../Modals/updateResume';

// Utility functions and types
import extractBooleanSearchTerms from 'utils/extractBoolean';
import { candidateDataInt } from 'types';
import { color } from './color';

// Redux store hooks
import {
  useListResumeCandidateMutation,
  useUpdateResumePrimaryStatusMutation,
} from 'store/candidateresume.slice';

import delay from 'utils/delay';

interface props {
  candidateData: candidateDataInt;
  isRefresh?: boolean;
}

interface colorInt {
  bg: string;
  color: string;
}

interface highlightInt {
  color: colorInt;
  value: string;
}

const CandidatesTabs = ({ candidateData }: props) => {
  // Import hooks and selectors
  const navigate = useNavigate();
  const params = useParams();
  const { search } = useLocation();

  // Retrieve state from Redux store
  const { candidatePage, candidatePgBtn } = useSelector(
    (state: any) => state.candidates
  );
  const { resumes } = useSelector((state: any) => state.candidateResume);
  const { jobData, fullData } = useSelector((state: any) => state.jobs);

  // Define mutation hooks
  const [reqAttachment] = useListResumeCandidateMutation();
  const [reqUpdate, resUpdate] = useUpdateResumePrimaryStatusMutation();

  // State hooks for managing highlights and resume details
  const [highlights, setHighlight] = useState<Array<highlightInt>>([]);
  const [showHighlights, setShowHighlight] = useState<Array<highlightInt>>([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [isPrimary, setIsPrimary] = useState(false);
  const [resumeUrl, setResumeUrl] = useState('');
  const [resumeId, setResumeId] = useState('');
  const [resumeView, setResumeView] = useState<string>('original');
  const [isEdit, setIsEdit] = useState(false);

  // Modal disclosure hook
  const {
    isOpen: isOpenUploadResume,
    onOpen: onOpenUploadResume,
    onClose: onCloseUploadResume,
  } = useDisclosure();

  // Effect for managing highlights based on job skills
  useEffect(() => {
    const skills = jobData?.skills || fullData.skills || [];

    if (skills.length > 0) {
      setHighlight([]);
      skills.forEach((item: any, key: number) => {
        const newHighlight: highlightInt = {
          color: color[key] as colorInt,
          value: item.skill,
        };

        setHighlight((prevHighlights) => {
          if (
            !prevHighlights.some((highlight) => highlight.value === item.skill)
          ) {
            getHighlightedText(selectedVersion?.html, [
              ...prevHighlights,
              newHighlight,
            ]);
            return [...prevHighlights, newHighlight];
          }
          getHighlightedText(selectedVersion?.html, prevHighlights);
          return prevHighlights;
        });
      });
    }
  }, [jobData?.skills, params.candidatesId, fullData]);

  // Effect for updating resume primary status and requesting attachments
  useEffect(() => {
    if (resUpdate.isSuccess) {
      const loadUpdate = async () => {
        await delay(100); // Small delay for update
        await reqAttachment({ id: candidateID });
      };
      loadUpdate();
    }
  }, [resUpdate.isSuccess]);

  // Effect for managing search highlights
  useEffect(() => {
    const loadSearches = async () => {
      const highlights = await extractBooleanSearchTerms(
        candidatePgBtn?.search
      );

      if (highlights.length > 0) {
        setHighlight([]);
        highlights.forEach((item: string, key) => {
          const newHighlight: highlightInt = {
            color: color[key] as colorInt,
            value: item,
          };

          setHighlight((prevHighlights) => {
            if (!prevHighlights.some((highlight) => highlight.value === item)) {
              return [...prevHighlights, newHighlight];
            }
            return prevHighlights;
          });
        });
      }
    };

    if (candidatePgBtn?.search !== '') {
      loadSearches();
    }
  }, [candidatePgBtn, params.candidatesId]);

  useEffect(() => {
    const tab = params.candidatesTab || params.jobsDrawerTab;
    if (tab) {
      setClientTab(tab);
    }
  }, [params.candidatesTab, params.jobsDrawerTab]);

  // Effect for toggling selected version
  useEffect(() => {
    setSelectedVersion((prev: any) => (prev !== null ? null : prev));
  }, [params.candidatesId]);

  // Define tab indices for navigation
  const tabIndex: Record<string, number> = {
    about: 0,
    activity: 1,
    associated_jobs: 2,
    attachments: 3,
    interviews: 4,
  };

  // Determine the current tab based on URL parameters
  const stateTab: string =
    params.candidatesTab || params.jobsDrawerTab || params.candidateId;
  const candidateID: string = params.candidatesId;
  const [clientTab, setClientTab] = useState(stateTab);

  // Memoize the primary resume or the first resume if no primary is found
  const resumeMemo = useMemo(() => {
    if (resumes.length > 0) {
      // Find the primary resume or default to the first resume
      let primaryResume =
        resumes.find((resume: any) => resume.is_primary === true) || resumes[0];
      setSelectedVersion(primaryResume);
      setIsPrimary(primaryResume?.is_primary);

      return primaryResume;
    } else {
      // Reset state if no resumes are available
      setSelectedVersion(null);
      setResumeUrl(null);
      return null;
    }
  }, [resumes]);

  // Handle navigation based on route and parameters
  const handleRoute = (route: string) => {
    let nav: string[] = [
      candidatePage.currentPage,
      candidateData.id,
      `${route}${search}`,
    ];

    // Handle navigation for in-progress jobs
    if (params['*'].includes('in-progress')) {
      nav = [
        params.jobsId,
        params.jobsTab,
        params.jobsTabId,
        params.jobsTabNav,
        candidateData.id,
        route,
      ];
    }
    // Handle navigation for search tab
    if (!params?.candidatesTabState && params['*'].includes('search')) {
      nav = [params.jobsId, params.jobsTab, params.jobsTabId];
    }
    // Handle navigation for candidates tab state
    if (params?.candidatesTabState) {
      nav = [
        params.candidatesTabState,
        candidatePage.currentPage,
        candidateData.id,
        `${route}${search}`,
      ];
    }

    // Navigate to the constructed URL
    navigate(`${nav.filter(Boolean).join('/')}`);
  };

  // Change primary status of the resume
  const changePrimary = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const primary = e.target.checked;
    setIsPrimary(primary);
    const body = { is_primary: primary };
    await reqUpdate({ data: body, id: selectedVersion.id });
  };

  // Switch between different resume versions
  const changeResumeVersion = (key: number) => {
    setSelectedVersion(resumes[key]);
    setIsPrimary(resumes[key]?.is_primary);
  };

  // Fetch the PDF from URL and create a Blob URL
  const setBlobUrl = async (pdfUrl: string) => {
    const response = await fetch(pdfUrl);
    const pdfData = await response.arrayBuffer();
    const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
    setResumeUrl(URL.createObjectURL(pdfBlob));
  };

  // Decode HTML entities in a string
  const decodeHtml = (html: string) => {
    // Use a DOM element to decode HTML entities
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    return textArea.value;
  };

  // Function to highlight specific terms in a given text
  const getHighlightedText = (text: string, highlights: highlightInt[]) => {
    // Decode HTML entities in the input text
    let highlightedText = decodeHtml(text);

    // Track highlighted words to prevent duplicate highlights
    let highlightedWords = new Set<highlightInt>();

    // Map to associate each term with its color
    const termColorMap = new Map<string, colorInt>();

    // Populate the term-color map from the highlights array
    highlights.forEach((term: highlightInt, index: number) => {
      if (term?.value) {
        termColorMap.set(term.value.toLowerCase(), color[index % color.length]);
      }
    });

    // Sort terms by length in descending order to handle longer terms first
    const sortedTerms = highlights
      .map((term: highlightInt) => ({
        term,
        color: termColorMap.get(term?.value?.toLowerCase())!,
      }))
      .sort(
        (a: { term: highlightInt }, b: { term: highlightInt }) =>
          b.term?.value?.length - a.term?.value?.length
      );

    // Function to apply highlights to a segment of text
    const applyHighlights = (segment: string): string => {
      sortedTerms.forEach(
        ({ term, color }: { term: highlightInt; color: colorInt }) => {
          // Escape special characters in the term to create a regex pattern
          const escapedTerm = term?.value?.replace(
            /[.*+?^${}()|[\]\\]/g,
            '\\$&'
          );
          const regexPattern = new RegExp(`(\\b${escapedTerm}\\b)`, 'gi');

          // Split text by the term and apply highlighting
          const splitText = segment.split(regexPattern);
          segment = splitText
            .map((part) => {
              const wordRegex = new RegExp(`^${escapedTerm}$`, 'i');
              if (wordRegex.test(part.toLowerCase())) {
                highlightedWords.add(term); // Track highlighted term
                return `<span style="background-color: ${color.bg}; color: ${color.color}">${part}</span>`;
              } else {
                return part;
              }
            })
            .join('');
        }
      );
      return segment;
    };

    // Split text into segments to handle HTML tags separately
    const segments = highlightedText.split(/(<[^>]+>)/g);
    let skipNext = false;
    highlightedText = segments
      .map((segment, index) => {
        if (skipNext) {
          skipNext = false;
          return ''; // Skip the next segment
        }

        if (segment.startsWith('<title>')) {
          skipNext = true; // Skip content inside <title> tags
          return segment;
        } else if (segment.startsWith('<')) {
          return segment; // Leave HTML tags unchanged
        } else {
          return applyHighlights(segment); // Apply highlights to text segments
        }
      })
      .join('');

    // Update the list of highlighted words for display
    const words = Array.from(highlightedWords) as highlightInt[];
    const lowerCaseWords = words.map((item: highlightInt) =>
      item?.value?.toLowerCase()
    );
    const updatedWords = highlights.filter((item: highlightInt) =>
      lowerCaseWords.includes(item?.value?.toLowerCase())
    );
    setShowHighlight(updatedWords);

    console.log(highlights, 'xxhightlight', lowerCaseWords);
    return highlightedText;
  };

  const ResumeHtml = ({ selectedVersion }: any) => {
    return (
      <>
        {selectedVersion ? (
          <div
            style={{
              transform: 'scale(1)',
              height: 'auto',
              width: '100%',
              paddingBottom: '15em',
            }}
            dangerouslySetInnerHTML={{
              __html: getHighlightedText(selectedVersion?.html, highlights),
            }}
          />
        ) : (
          <Text>No resume available</Text>
        )}
      </>
    );
  };

  const FileRender = ({ selectedVersion, setResumeUrl }: any) => {
    if (selectedVersion) {
      const extension = selectedVersion?.file_name?.split('.');
      if (extension[1]?.toLowerCase() === 'pdf') {
        setBlobUrl(selectedVersion.signedUrl);
      }
      getHighlightedText(selectedVersion.html, highlights);
    }

    return (
      <Box textAlign="center">
        {selectedVersion ? (
          <StreamAttachmentComponent
            filename={selectedVersion?.file_name}
            fileUrl={selectedVersion?.signedUrl}
            setResumeUrl={setResumeUrl}
            pdfHeight={`calc(100vh - ${310 + 'px'})`}
            docHeight="83vh"
          />
        ) : (
          <Text>No resume available</Text>
        )}
      </Box>
    );
  };

  const parentRef = useRef(null);

  const memoizedResume = useMemo(() => {
    if (selectedVersion) {
      setResumeId((prevId) => {
        if (prevId !== selectedVersion.id) {
          return selectedVersion.id;
        }
        return prevId;
      });
    }

    return (
      <Box
        ref={parentRef}
        textAlign="center"
        overflow="auto"
        height={`calc(100vh - ${310 + 'px'})`}
        width="100%"
      >
        {selectedVersion ? (
          selectedVersion.html === 'null' ||
          selectedVersion.html === null ||
          resumeView === 'original' ? (
            <FileRender
              selectedVersion={selectedVersion}
              setResumeUrl={setResumeUrl}
            />
          ) : (
            <ResumeHtml selectedVersion={selectedVersion} />
          )
        ) : (
          <Text>No resume available</Text>
        )}
      </Box>
    );
  }, [selectedVersion, resumeId, resumeMemo, resumeView]);

  return (
    <>
      <Tabs colorScheme="purple" index={tabIndex[stateTab]}>
        <TabList fontSize="md" fontWeight="500" sx={{ padding: '0 20px' }}>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => handleRoute('about')}
          >
            About
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => handleRoute('activity')}
          >
            Activity
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => handleRoute('associated_jobs')}
          >
            Associated Jobs
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => handleRoute('attachments')}
          >
            Attachments
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => handleRoute('interviews')}
          >
            Interviews
          </Tab>
        </TabList>

        <Flex h="100">
          <Box
            width="60%"
            display={clientTab != 'activity' ? 'block' : 'none'}
            py={2}
            borderRight="1px solid"
            borderColor="default.borderlight"
            px={0}
          >
            <Stack spacing="10px" pl={3} py={2}>
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  px: '12px',
                }}
              >
                <Box>
                  {resumeUrl ? (
                    <Link
                      target="_blank"
                      style={{
                        display: 'flex',
                        gap: '5px',
                        fontSize: '15px',
                        color: 'primary.600',
                      }}
                      onClick={() => {
                        const link = document.createElement('a');
                        link.target = '_blank';
                        const explode = selectedVersion?.file_name.split('.');
                        const extension = explode.splice(-1)[0];
                        let url = resumeUrl;
                        if (extension !== 'pdf') {
                          var encodedUrl = encodeURIComponent(
                            selectedVersion?.signedUrl
                          );
                          url =
                            'https://view.officeapps.live.com/op/embed.aspx?src=' +
                            encodedUrl;
                        } else {
                          url = selectedVersion?.signedUrl;
                        }
                        link.href = `${url}#toolbar=0&navpanes=0&scrollbar=0`;
                        link.click();
                      }}
                      rel="noreferrer"
                    >
                      Resume
                      <span>
                        <Image src={GoTo} alt="logo" height="20px" />
                      </span>
                    </Link>
                  ) : (
                    'Resume'
                  )}
                </Box>

                <Flex
                  sx={{
                    gap: '9px',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Link
                    target="_blank"
                    sx={{
                      display: 'flex',
                      gap: '5px',
                      fontSize: '15px',
                      color: 'primary.600',
                      mr: '15px',
                    }}
                    rel="noreferrer"
                    onClick={() => {
                      const link = document.createElement('a');
                      const explode = selectedVersion?.file_name.split('.');
                      const extension = explode.splice(-1)[0];
                      link.download =
                        [
                          candidateData.first_name,
                          candidateData.last_name,
                        ].join(' ') || selectedVersion?.original_file_name;
                      if (extension === 'docx' || extension === 'doc') {
                        link.download = link.download + '.' + extension;
                      }
                      link.href = resumeUrl;
                      link.target = '_blank';
                      link.click();
                    }}
                  >
                    <span>
                      <FAIcon iconName="download" />
                    </span>
                    Download
                  </Link>

                  {resumes.length > 0 && (
                    <>
                      {resUpdate.isLoading ? (
                        <Flex alignItems="center" h="16px" gap="2">
                          <CircularProgress
                            size="16px"
                            isIndeterminate
                            position="relative"
                          />
                          <Text fontSize="14px">Primary</Text>
                        </Flex>
                      ) : (
                        <Checkbox
                          id="isPrimary"
                          name="isPrimary"
                          isChecked={isPrimary}
                          onChange={(e) => changePrimary(e)}
                        >
                          Primary
                        </Checkbox>
                      )}
                    </>
                  )}

                  <Menu closeOnSelect={true}>
                    <MenuButton>
                      <Box
                        sx={{
                          button: {
                            border: 'none',
                            color: 'primary.500',
                          },
                        }}
                      >
                        {resumes.length > 0 && (
                          <Box
                            sx={{
                              button: {
                                height: '25px',
                                fontSize: '13px',
                              },
                            }}
                          >
                            <Button
                              rightIcon={<FAIcon iconName="chevron-down" />}
                              disabled={resUpdate.isLoading}
                            >
                              {moment
                                .utc(selectedVersion?.created_at)
                                .format('MMM DD, YYYY hh:mm A')}
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </MenuButton>
                    {
                      <MenuList
                        fontSize="sm"
                        maxHeight="120px"
                        overflowY="auto"
                      >
                        {resumes?.map((item: any, key: number) => {
                          return (
                            <MenuItem
                              onClick={() => changeResumeVersion(key)}
                              key={`tab-mi-${key}`}
                            >
                              <Flex
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Box cursor="pointer">
                                  {moment
                                    .utc(item?.created_at)
                                    .format('MMM DD, YYYY hh:mm A')}
                                </Box>
                              </Flex>
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    }
                  </Menu>

                  <Box
                    sx={{
                      button: {
                        radius: '6px',
                        fontSize: '12px',
                        fontWeight: 600,
                        lineHeight: '16px',
                        color: 'primary.600',
                      },
                    }}
                  >
                    <Button onClick={onOpenUploadResume}>Update</Button>
                  </Box>

                  <Flex bg="#FAF6FF" borderRadius="10px" p="1" color="#718096">
                    <Button
                      variant="ghost"
                      sx={{
                        fontSize: '14px',
                        lineHeight: '18px',
                        fontWeight: 700,
                        py: resumeView === 'original' && '14px',
                        borderRadius: resumeView === 'original' && '10px',
                        bg: resumeView === 'original' ? '#FFF' : '#FAF6FF',
                        color: resumeView === 'original' && 'primary.800',
                      }}
                      _focus={{ border: 'none' }}
                      _hover={{
                        bg: resumeView === 'original' ? '#FFF' : '#FAF6FF',
                      }}
                      w="100%"
                      onClick={() => setResumeView('original')}
                    >
                      Original
                    </Button>
                    <Button
                      variant="ghost"
                      sx={{
                        fontSize: '14px',
                        lineHeight: '18px',
                        fontWeight: 700,
                        py: resumeView === 'html' && '14px',
                        borderRadius: resumeView === 'html' && '10px',
                        bg: resumeView === 'html' ? '#FFF' : '#FAF6FF',
                        color: resumeView === 'html' && 'primary.800',
                      }}
                      _focus={{ border: 'none' }}
                      _hover={{
                        bg: resumeView === 'html' ? '#FFF' : '#FAF6FF',
                      }}
                      w="100%"
                      onClick={() => setResumeView('html')}
                    >
                      HTML
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
              <Box>
                {resumes.length > 0 && (
                  <Flex gap="8px" rowGap="8px" flexWrap="wrap" px="12px">
                    {showHighlights?.length > 0 &&
                      showHighlights.map((item: highlightInt, key: number) => {
                        return (
                          <Box
                            sx={{
                              padding: '4px 8px',
                              borderRadius: '2px',
                              fontSize: '14px',
                              fontWeight: 700,
                              color: '#2B2D42',
                              background: item?.color?.bg || '#ffffff',
                            }}
                          >
                            {item.value}
                          </Box>
                        );
                      })}
                  </Flex>
                )}
              </Box>
            </Stack>

            <Stack position="relative" height="70vh">
              <Box
                mt="10px"
                textAlign="center"
                style={{
                  position: 'absolute',
                  bottom: '0.5em',
                  left: 0,
                  right: 0,
                  top: 0,
                }}
                ref={parentRef}
              >
                {memoizedResume}
              </Box>
            </Stack>
          </Box>

          <Box width={clientTab === 'activity' ? '100%' : '40%'}>
            <TabPanels>
              <TabPanel
                sx={{
                  overflowY: 'scroll',
                  height: `calc(100vh - 220px)`,
                  padding: '24px',
                }}
              >
                {clientTab === 'about' && (
                  <>
                    {!isEdit && (
                      <Flex
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          mb: '16px',
                        }}
                      >
                        Personal Information
                        <Box
                          sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '14.62px',
                            textDecor: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => setIsEdit((prev) => !prev)}
                        >
                          Edit
                        </Box>
                      </Flex>
                    )}
                    <CandidatesAbout isEdit={isEdit} setIsEdit={setIsEdit} />
                  </>
                )}
              </TabPanel>
              <TabPanel
                sx={{ overflowY: 'scroll', height: `calc(100vh - 220px)` }}
              >
                {clientTab === 'activity' && <CandidatesActivity />}
              </TabPanel>
              <TabPanel
                sx={{ overflowY: 'scroll', height: `calc(100vh - 220px)` }}
              >
                {clientTab === 'associated_jobs' && (
                  <CandidatesAssociatedJobs />
                )}
              </TabPanel>
              <TabPanel
                sx={{ overflowY: 'scroll', height: `calc(100vh - 220px)` }}
              >
                {clientTab === 'attachments' && <CandidatesAttachments />}
              </TabPanel>
              <TabPanel
                sx={{ overflowY: 'scroll', height: `calc(100vh - 220px)` }}
              >
                {clientTab === 'interviews' && (
                  <CandidatesInterviews candidateData={candidateData} />
                )}
              </TabPanel>
            </TabPanels>
          </Box>
        </Flex>
      </Tabs>

      {isOpenUploadResume && (
        <CandidateUpdateResumeModal
          isOpen={isOpenUploadResume}
          onClose={onCloseUploadResume}
        />
      )}
    </>
  );
};

export default CandidatesTabs;
