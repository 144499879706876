import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const approvalSlice = createSlice({
  name: 'approval',
  initialState: {
    approvals: [],
    appovalStatus: [],
    approvalData: [],
    approvalDetails: [],
    approvalPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    approvalPgBtn: {
      page: 1,
      take: 50,
      query: '',
      hot: false,
      qualified: false,
      status: [1],
      types: [1, 3, 4],
    },
  },
  reducers: {
    listApprovals: (state, { payload }) => ({ ...state, ...payload }),
    getApproval: (state, { payload }) => ({ ...state, ...payload }),
  },
  extraReducers: () => {},
});

export const { listApprovals, getApproval } = approvalSlice.actions;
export default approvalSlice.reducer;

export const approvalApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    ListAppprovals: builder.mutation({
      query: ({ data, signal }) => ({
        url: '/lead-approvals/list',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
        signal,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(listApprovals({ approvals: data.data.data }));

          dispatch(
            listApprovals({
              approvalPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetApproval: builder.mutation({
      query: ({ data, id }) => ({
        url: `/lead-approvals/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            listApprovals({
              approvalData: { ...data.data, lead: data.data.lead },
            })
          );
          dispatch(
            listApprovals({
              approvalDetails: { ...data.data, lead: data.data },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    RejectApproval: builder.mutation({
      query: ({ data, id }) => ({
        url: `/lead-approvals/reject/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // console.log('data', data.data);
          dispatch(listApprovals({ approvalData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ApproveApproval: builder.mutation({
      query: ({ data, id }) => ({
        url: `/lead-approvals/approve/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // console.log('data', data.data);
          dispatch(listApprovals({ approvalData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ApprovalStatus: builder.mutation({
      query: (data) => ({
        url: '/constants/approval-status',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(listApprovals({ appovalStatus: data.data }));

          dispatch(
            listApprovals({
              approvalPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useListAppprovalsMutation,
  useGetApprovalMutation,
  useRejectApprovalMutation,
  useApproveApprovalMutation,
  useApprovalStatusMutation,
} = approvalApiSlice;
