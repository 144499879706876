import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listCandidates } from 'store/candidates.slice';
import { listJobs, useListJobsMutation } from 'store/jobs.slice';

interface useSideBarServicesProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const useSideBarServices = ({
  setIsLoading,
}: useSideBarServicesProps) => {
  const dispatch = useDispatch();

  const storageName = 'jobFilter';

  const { jobPgBtn } = useSelector((state: any) => state.jobs);

  const [reqList, resList] = useListJobsMutation();

  const jobFilerData: string | null = localStorage.getItem(storageName);
  const initFilter = jobFilerData == null ? jobPgBtn : JSON.parse(jobFilerData);

  const [searchJobs, setSearchJobs] = useState(initFilter.query);
  const [statusJobTypePayload, setStatusJobTypePayload] = useState([]);
  const [statusJobStatusPayload, setStatusJobStatusPayload] = useState([]);

  // Debounced Search Handler
  const handleSearchJobs = debounce((e: any) => {
    let query = e.target.value;

    if (searchJobs === '') {
      query = '';
    }

    const payload = {
      ...jobPgBtn,
      page: 1,
      query: query,
    };

    if (query.length >= 3 || searchJobs.length >= 3) {
      submitForm(payload);
    }
  }, 300);

  const submitForm = (params: any) => {
    dispatch(
      listJobs({
        jobPgBtn: params,
      })
    );
    fetchJobs(params);
    localStorage.setItem(storageName, JSON.stringify(params));
  };

  // AbortController for fetchJobs
  const abortControllerJobRef = useRef<AbortController | null>(null);

  // Function to Fetch Jobs
  const fetchJobs = async (params: object) => {
    if (abortControllerJobRef.current) {
      abortControllerJobRef.current.abort();
    }

    abortControllerJobRef.current = new AbortController();
    reqList({ data: params, signal: abortControllerJobRef.current.signal });
    dispatch(listCandidates({ candidatesCount: [], candidatesAllCount: 0 }));
  };

  useEffect(() => {
    let payload = {
      ...jobPgBtn,
    };

    const combinedSort = Object.assign(
      {},
      {
        closed: false,
        open: false,
        quickJobs: false,
        job_assigned: false,
        draft: false,
        full: false,
      },
      ...statusJobTypePayload,
      ...statusJobStatusPayload
    );
    const updatedParam = { ...payload, ...combinedSort };

    submitForm(updatedParam);
  }, [statusJobTypePayload, statusJobStatusPayload]);

  useEffect(() => {
    setIsLoading(resList.isLoading);
  }, [resList.isLoading]);

  return {
    searchJobs,
    setSearchJobs,
    handleSearchJobs,
    statusJobTypePayload,
    setStatusJobTypePayload,
    statusJobStatusPayload,
    setStatusJobStatusPayload,
  };
};
