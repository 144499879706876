import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Formik } from 'formik';

import {
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link,
  Switch,
  InputLeftElement,
  Checkbox,
} from '@chakra-ui/react';
import { BsEnvelope, BsLinkedin, BsStar, BsTelephone } from 'react-icons/bs';

import FAIcon from 'components/lib/FAIcon';
import Pagination from 'Library/Pagination';

import { navClient } from 'store/client.slice';
import { listContact, useContactListMutation } from 'store/contact.slice';
import ScrollToFieldError from 'components/app/ScrollError';
import toUrl from 'utils/toUrl';
import { useViewContactMutation } from 'store/contact.slice';
import TrLoading from '../../TrLoading';

// THIS IS FOR CONTACTS TAB IN ALL CLIENTS AND MY CLIENTS
const ClientsContacts = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const { clientData } = useSelector((state: any) => state.clients);

  const { contactList, contactTabPageBtn, contactPage } = useSelector(
    (state: any) => state.contacts
  );

  const { clientNav } = useSelector((state: any) => state.clients);
  // const [reqContacts, resContacts] = useContactListMutation();
  const [reqContacts, resContacts] = useViewContactMutation();
  const [activeSwitch, setActiveSwitch] = useState(1);

  const tableHeader: { label: string }[] = [
    { label: 'Contact Name' },
    { label: 'Contact Details' },
    { label: 'Actions' },
  ];
  const stateTab: any = params.clientsTab;
  const clientID = params.clientsId;

  const initialValues = {
    search: '',
  };

  const [selectedContact, setSelectedContact] = useState(null);
  const onSubmit = (data: any) => {
    const newParam = {
      ...contactTabPageBtn,
      query: data.search,
    };

    reqContacts({ data: newParam, id: clientID });
    dispatch(listContact({ contactTabPageBtn: newParam }));
  };

  // ========================= FOR PAGINATION =========================
  const pageChange = async (page: number) => {
    const newParam = {
      ...contactTabPageBtn,
      page: page,
    };
    dispatch(
      listContact({
        contactTabPageBtn: newParam,
        contactPage: { ...contactPage, currentPage: page },
      })
    );
    reqContacts({ data: newParam, id: clientID });
  };

  const entryChange = (entries: number) => {
    let newParam = {
      ...contactTabPageBtn,
      page: 1,
      take: entries,
    };
    dispatch(
      listContact({
        contactTabPageBtn: newParam,
        contactPage: { ...contactPage, take: entries },
      })
    );
    reqContacts({ data: newParam, id: clientID });
  };
  // ==================================================================

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      const newParam = {
        ...contactTabPageBtn,
        query: '',
      };
      dispatch(listContact({ contactTabPageBtn: newParam }));
      reqContacts({ data: newParam, id: clientID });
    }
  };

  const handleClick = async (contact: any) => {
    if (contact.leadType.id === 4) {
      setSelectedContact(contact);
      dispatch(navClient({ clientNav: '' }));
    } else if (contact.leadType.id === 1) {
      navigate(`/leads/${contact?.id}/details`);
      dispatch(navClient({ clientNav: 'leads' }));
    }
  };

  useEffect(() => {
    if (stateTab === 'contacts' && clientID) {
      const newParam = {
        ...contactTabPageBtn,
        active: activeSwitch,
      };
      dispatch(listContact({ contactTabPageBtn: newParam }));
      reqContacts({ data: newParam, id: clientID });
    }
  }, [stateTab, clientID, activeSwitch]);

  useEffect(() => {
    if (clientNav === '') {
      dispatch(navClient({ clientNav: 'contacts' }));
    }
  }, [clientNav]);

  useEffect(() => {
    if (clientNav === 'contacts') {
      navigate(`/clients/${selectedContact?.id}/overview`);
    }
  }, [clientNav]);

  const [typeIsChecked, setTypeChecked] = useState([]);
  const [statusIsChecked, setStatusChecked] = useState([]);

  useEffect(() => {
    setTypeChecked(contactTabPageBtn.types);
    setStatusChecked(contactTabPageBtn.status);
  }, [contactTabPageBtn.status, contactTabPageBtn.types]);

  // const submitForm = (params: any) => {
  //   dispatch(listContact({ contactTabPageBtn: params }));
  //   reqContact({ data: params, id: clientID });
  // };

  // const handleCheckTypes = useCallback(
  //   (e: any) => {
  //     let params = {
  //       page: contactTabPageBtn.page,
  //       take: contactTabPageBtn.take,
  //       query: contactTabPageBtn.query,
  //       types: typeIsChecked,
  //       status: statusIsChecked,
  //       contacts: contactTabPageBtn.contacts,
  //     };

  //     const { id, checked } = e.target;
  //     let ID = parseInt(id);
  //     setTypeChecked([...typeIsChecked, ID]);
  //     params.types = [...typeIsChecked, ID];
  //     if (!checked) {
  //       const isChecked = typeIsChecked.filter((item: number) => item !== ID);
  //       setTypeChecked(isChecked);
  //       params.types = isChecked;
  //     }
  //     submitForm(params);
  //   },
  //   [
  //     contactPageBtn.query,
  //     contactPageBtn.take,
  //     statusIsChecked,
  //     submitForm,
  //     typeIsChecked,
  //   ]
  // );

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="space-between" pb="19px" mt="20px">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <Box pr={2}>
                <InputGroup background="#fff">
                  <InputLeftElement children={<FAIcon iconName="search" />} />
                  <Input
                    type="search"
                    value={values.search}
                    name="search"
                    placeholder="Search Contacts"
                    onChange={(e) => {
                      handleChange(e);
                      clearSearch(e);
                    }}
                  />
                </InputGroup>
              </Box>
            </form>
          )}
        </Formik>
        <Flex gap="10px">
          <Switch
            colorScheme="purple"
            isChecked={Boolean(activeSwitch)}
            onChange={(e) => setActiveSwitch(e.target.checked ? 1 : 0)}
          />
          Active
        </Flex>
      </Flex>
      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          minHeight="45vh"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {resContacts.isLoading ? (
                <TrLoading rows={3} columns={3} />
              ) : (
                contactList?.length > 0 &&
                contactList.map((data: any) => {
                  return (
                    <Tr
                      key={data?.id}
                      _hover={{
                        bg: '#f8f9fa',
                      }}
                      cursor="pointer"
                    >
                      <Td
                        onClick={() => {
                          handleClick(data);
                        }}
                      >
                        <Box>
                          <Box fontWeight="bold">
                            {[data?.first_name, data?.last_name].join(' ')}
                          </Box>
                          <Box fontSize="sm"> {data?.title}</Box>
                          <Box fontSize="sm"> {data?.company}</Box>
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <Box fontWeight="bold" fontSize="sm">
                            {data?.primary_email}
                          </Box>
                          <Box fontSize="sm"> {data?.personal_phone}</Box>
                        </Box>
                      </Td>
                      <Td>
                        <HStack spacing="25px">
                          <Icon as={BsStar} />
                          <Link href={toUrl(data?.linked_in_url)} isExternal>
                            <Icon as={BsLinkedin} />
                          </Link>
                          <Link href={`mailto:${data?.primary_email}`}>
                            <Icon as={BsEnvelope} />
                          </Link>
                          <Link href={`tel:${data?.personal_phone}`}>
                            <Icon as={BsTelephone} />
                          </Link>
                        </HStack>
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={contactPage.lastPage}
          currentPage={contactPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          onEntryChange={(entries) => entryChange(entries)}
          totalEntries={contactTabPageBtn.count}
          targetCount={contactTabPageBtn.take}
        />
      </Box>
    </Box>
  );
};

export default ClientsContacts;
