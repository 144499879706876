import {
  Box,
  Button as ButtonChakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalFooter,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import AtsSelect from 'components/app/AtsSelect';
import ScrollToFieldError from 'components/app/ScrollError';
import { Formik, Field, ErrorMessage } from 'formik';
import Button from 'Library/Button';
import { BsCurrencyDollar } from 'react-icons/bs';
import AtsCraetableSelDyn from 'components/app/AtsCreatabeSelect/Dyanmic';
import AtsEmailBody from 'components/app/EmailBody';
import { useJobDetailsServices } from './useJobDetailServices';
import FAIcon from 'components/lib/FAIcon';

interface JobDetailsFormProps {
  initialJobDetailsValues: any;
  jobFormData: any;
  setJobStatus: (data: any) => void;
  jobPairTemplate: any;
  onSubmit: (data: any) => void;
  jobPairing: (data: any) => void;
  isDraft: boolean;
  setJobFormData: (data: any) => void;
  initialValues: object;
  tabList: any;
  tabIndex: any;
  setTabIndex: (data: any) => void;
  isOpenAlert: boolean;
  loading: boolean;
  setIsDraft: (e: boolean) => void;
  onOpen: () => void;
  hasJobPostType: any;
  aMOption: any;
  recOptions: any;
  isEditModal: boolean;
  setProtocol: (data: any) => void;
  protocol: any;
  handleProtocol: (data: any) => void;
  onClose: () => void;
}

const JobDetailsForm = ({
  initialJobDetailsValues,
  jobFormData,
  onSubmit,
  jobPairTemplate,
  jobPairing,
  isDraft,
  setJobFormData,
  initialValues,
  tabList,
  tabIndex,
  setTabIndex,
  isOpenAlert,
  loading,
  setIsDraft,
  onOpen,
  hasJobPostType,
  setJobStatus,
  aMOption,
  recOptions,
  isEditModal,
  setProtocol,
  protocol,
  handleProtocol,
  onClose,
}: JobDetailsFormProps) => {
  const {
    validationJobDetailsSchema,
    detailsSubmit,
    experience_level,
    educations,
    work_types,
    employment_types,
    compensations,
    searchSKills,
    resSearchSkills,
    skillList,
  } = useJobDetailsServices({
    setJobStatus,
    hasJobPostType,
    jobPairTemplate,
    jobPairing,
    isDraft,
    setJobFormData,
    initialValues,
    jobFormData,
    tabList,
    tabIndex,
    onSubmit,
    setTabIndex,
  });

  return (
    <Formik
      initialValues={initialJobDetailsValues}
      validationSchema={validationJobDetailsSchema}
      onSubmit={(data, { setSubmitting, validateForm }) =>
        detailsSubmit(data, { setSubmitting, validateForm })
      }
      enableReinitialize={true}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isValid,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} style={{ height: 'inherit' }}>
          <ScrollToFieldError />
          {/* Job Details */}

          <Stack justifyContent="space-between" h="inherit">
            <Flex gap="10px">
              <Box
                h="inherit"
                sx={{
                  w: '50%',
                  background: '#fff',
                  borderRadius: '6px',
                  p: '16px',
                }}
              >
                {hasJobPostType === '2' && (
                  <Flex gap="16px" mb="24px">
                    <FormControl
                      isInvalid={Boolean(!!errors.am_id && touched.am_id)}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="19.1px"
                        color="#2B2D42"
                        fontWeight={700}
                      >
                        Account Manager{' '}
                        <Box as="span" color="#C53030">
                          *
                        </Box>
                      </FormLabel>
                      <AtsSelect
                        name="am_id"
                        id="am_id"
                        placeholder="Select"
                        variant="outline"
                        onChange={(e: any) =>
                          setFieldValue('am_id', String(e.value))
                        }
                        onBlur={() =>
                          localStorage.setItem(
                            'jobFormData',
                            JSON.stringify({
                              ...jobFormData,
                              ...values,
                            })
                          )
                        }
                        defaultValue={
                          (aMOption?.length > 0 &&
                            aMOption
                              .map((item: any) => ({
                                label: item.label,
                                value: item.value,
                              }))
                              .filter(
                                (val: any) => val.value === values.am_id
                              )[0]) ||
                          values.am_id
                        }
                        options={
                          aMOption?.length > 0 &&
                          aMOption.map((item: any) => ({
                            label: item.label,
                            value: item.value,
                          }))
                        }
                        styles={{
                          control: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            border: '1px solid #E7E9ED',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            boxShadow: 'none',
                            '&:hover': {},
                            '& ::-webkit-scrollbar': {
                              display: 'none',
                            },
                          }),
                          valueContainer: (styles: any) => ({
                            ...styles,
                            maxHeight: '33px',
                            overflowY: 'scroll',
                          }),
                          dropdownIndicator: (styles: any) => ({
                            ...styles,
                            color: '#676767',
                          }),
                          indicatorSeparator: () => ({
                            display: 'none',
                          }),
                          placeholder: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            color: '#9B9B9B',
                          }),
                        }}
                      />
                      <FormErrorMessage>
                        {String(errors.am_id)}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={Boolean(
                        !!errors.primary_recruiter && touched.primary_recruiter
                      )}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="19.1px"
                        color="#2B2D42"
                        fontWeight={700}
                      >
                        Primary Recruiter{' '}
                        <Box as="span" color="#C53030">
                          *
                        </Box>
                      </FormLabel>

                      <AtsSelect
                        name="primary_recruiter"
                        id="primary_recruiter"
                        placeholder="Select"
                        variant="outline"
                        onChange={(e: any) => {
                          console.log({ e });
                          setFieldValue('primary_recruiter', String(e.value));
                        }}
                        onBlur={() =>
                          localStorage.setItem(
                            'jobFormData',
                            JSON.stringify({
                              ...jobFormData,
                              ...values,
                            })
                          )
                        }
                        value={
                          (recOptions?.length > 0 &&
                            recOptions
                              .map((item: any) => ({
                                label: item.label,
                                value: item.value,
                              }))
                              .filter(
                                (val: any) =>
                                  val.value === Number(values.primary_recruiter)
                              )[0]) ||
                          values.primary_recruiter
                        }
                        options={
                          recOptions?.length > 0 &&
                          recOptions.map((item: any) => ({
                            label: item.label,
                            value: item.value,
                          }))
                        }
                        styles={{
                          control: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            border: '1px solid #E7E9ED',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            boxShadow: 'none',
                            '&:hover': {},
                            '& ::-webkit-scrollbar': {
                              display: 'none',
                            },
                          }),
                          valueContainer: (styles: any) => ({
                            ...styles,
                            maxHeight: '33px',
                            overflowY: 'scroll',
                          }),
                          dropdownIndicator: (styles: any) => ({
                            ...styles,
                            color: '#676767',
                          }),
                          indicatorSeparator: () => ({
                            display: 'none',
                          }),
                          placeholder: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            color: '#9B9B9B',
                          }),
                        }}
                      />
                      <FormErrorMessage>
                        {String(errors.primary_recruiter)}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                )}
                <Flex gap="16px" mb="24px">
                  <FormControl
                    isInvalid={Boolean(!!errors.title && touched.title)}
                  >
                    <FormLabel
                      fontSize="14px"
                      lineHeight="19.1px"
                      color="#2B2D42"
                      fontWeight={700}
                    >
                      Client Job Opening Title{' '}
                      <Box as="span" color="#C53030">
                        *
                      </Box>
                    </FormLabel>

                    <Box
                      border={
                        !!errors.title && touched.title && '1px solid #E53E3E'
                      }
                      borderRadius={!!errors.title && touched.title && '4px'}
                      boxShadow={
                        !!errors.title && touched.title && '0 0 0 1px #E53E3E'
                      }
                    >
                      <Input
                        autoFocus
                        id="title"
                        name="title"
                        type="text"
                        placeholder="Job Title"
                        variant="outline"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={() =>
                          localStorage.setItem(
                            'jobFormData',
                            JSON.stringify({
                              ...jobFormData,
                              ...values,
                            })
                          )
                        }
                        sx={{
                          height: '38px',
                          border: '1px solid #E7EDF4',
                          p: '8px 12px',
                          fontSize: '14px',
                        }}
                      />
                    </Box>

                    <FormErrorMessage>{String(errors.title)}</FormErrorMessage>
                  </FormControl>
                  {hasJobPostType !== '2' && (
                    <FormControl
                      isInvalid={Boolean(!!errors.opening && touched.opening)}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="19.1px"
                        color="#2B2D42"
                        fontWeight={700}
                      >
                        No. of Openings{' '}
                        <Box as="span" color="#C53030">
                          *
                        </Box>
                      </FormLabel>

                      <Box
                        border={
                          !!errors.opening &&
                          touched.opening &&
                          '1px solid #E53E3E'
                        }
                        borderRadius={
                          !!errors.opening && touched.opening && '4px'
                        }
                        boxShadow={
                          !!errors.opening &&
                          touched.opening &&
                          '0 0 0 1px #E53E3E'
                        }
                      >
                        <Input
                          id="opening"
                          name="opening"
                          type="number"
                          min={1}
                          placeholder="0"
                          variant="outline"
                          value={values.opening}
                          onChange={handleChange}
                          onBlur={() =>
                            localStorage.setItem(
                              'jobFormData',
                              JSON.stringify({
                                ...jobFormData,
                                ...values,
                              })
                            )
                          }
                        />
                      </Box>
                      <FormErrorMessage>
                        {String(errors.opening)}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Flex>

                <Flex gap="16px" mb="24px">
                  <FormControl
                    isInvalid={Boolean(
                      !!errors.experience_level && touched.experience_level
                    )}
                  >
                    <FormLabel
                      fontSize="14px"
                      lineHeight="19.1px"
                      color="#2B2D42"
                      fontWeight={700}
                    >
                      Experience Level{' '}
                      <Box as="span" color="#C53030">
                        *
                      </Box>
                    </FormLabel>

                    <Box
                      border={
                        !!errors.experience_level &&
                        touched.experience_level &&
                        '1px solid #E53E3E'
                      }
                      borderRadius={
                        !!errors.experience_level &&
                        touched.experience_level &&
                        '4px'
                      }
                      boxShadow={
                        !!errors.experience_level &&
                        touched.experience_level &&
                        '0 0 0 1px #E53E3E'
                      }
                    >
                      <AtsSelect
                        id="experience_level"
                        name="experience_level"
                        placeholder="Select Level"
                        onChange={(e: any) =>
                          setFieldValue('experience_level', String(e.value))
                        }
                        onBlur={() =>
                          localStorage.setItem(
                            'jobFormData',
                            JSON.stringify({
                              ...jobFormData,
                              ...values,
                            })
                          )
                        }
                        value={
                          (experience_level?.length > 0 &&
                            experience_level
                              .map((item: any) => ({
                                label: item.label,
                                value: item.id,
                              }))
                              .filter(
                                (val: any) =>
                                  val.value === Number(values.experience_level)
                              )[0]) ||
                          values.experience_level
                        }
                        options={
                          experience_level?.length > 0 &&
                          experience_level.map((item: any) => ({
                            label: item.label,
                            value: item.id,
                          }))
                        }
                        styles={{
                          control: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            border: '1px solid #E7E9ED',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            boxShadow: 'none',
                            '&:hover': {},
                            '& ::-webkit-scrollbar': {
                              display: 'none',
                            },
                          }),
                          valueContainer: (styles: any) => ({
                            ...styles,
                            maxHeight: '33px',
                            overflowY: 'scroll',
                          }),
                          dropdownIndicator: (styles: any) => ({
                            ...styles,
                            color: '#676767',
                          }),
                          indicatorSeparator: () => ({
                            display: 'none',
                          }),
                          placeholder: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            color: '#9B9B9B',
                          }),
                        }}
                      />
                    </Box>
                    <FormErrorMessage>
                      {String(errors.experience_level)}
                    </FormErrorMessage>
                  </FormControl>
                  {hasJobPostType !== '2' ? (
                    <FormControl
                      isInvalid={Boolean(
                        !!errors.education_id && touched.education_id
                      )}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="19.1px"
                        color="#2B2D42"
                        fontWeight={700}
                      >
                        Education{' '}
                        <Box as="span" color="#C53030">
                          *
                        </Box>
                      </FormLabel>

                      <Box
                        border={
                          !!errors.education_id &&
                          touched.education_id &&
                          '1px solid #E53E3E'
                        }
                        borderRadius={
                          !!errors.education_id && touched.education_id && '4px'
                        }
                        boxShadow={
                          !!errors.education_id &&
                          touched.education_id &&
                          '0 0 0 1px #E53E3E'
                        }
                      >
                        <AtsSelect
                          id="education_id"
                          name="education_id"
                          placeholder="Select Education"
                          onChange={(e: any) =>
                            setFieldValue('education_id', String(e.value))
                          }
                          onBlur={() =>
                            localStorage.setItem(
                              'jobFormData',
                              JSON.stringify({
                                ...jobFormData,
                                ...values,
                              })
                            )
                          }
                          value={
                            (educations?.length > 0 &&
                              educations
                                .map((item: any) => ({
                                  label: item.name,
                                  value: item.id,
                                }))
                                .filter(
                                  (val: any) =>
                                    val.value === Number(values.education_id)
                                )[0]) ||
                            values.education_id
                          }
                          options={
                            educations?.length > 0 &&
                            educations.map((item: any) => ({
                              label: item.name,
                              value: item.id,
                            }))
                          }
                          styles={{
                            control: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              border: '1px solid #E7E9ED',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              boxShadow: 'none',
                              '&:hover': {},
                              '& ::-webkit-scrollbar': {
                                display: 'none',
                              },
                            }),
                            valueContainer: (styles: any) => ({
                              ...styles,
                              maxHeight: '33px',
                              overflowY: 'scroll',
                            }),
                            dropdownIndicator: (styles: any) => ({
                              ...styles,
                              color: '#676767',
                            }),
                            indicatorSeparator: () => ({
                              display: 'none',
                            }),
                            placeholder: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              color: '#9B9B9B',
                            }),
                          }}
                        />
                      </Box>
                      <FormErrorMessage>
                        {String(errors.education_id)}
                      </FormErrorMessage>
                    </FormControl>
                  ) : (
                    <FormControl></FormControl>
                  )}
                </Flex>

                {hasJobPostType !== '2' && (
                  <>
                    <Flex gap="16px" mb="24px" width="100%">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.work_type_id && touched.work_type_id
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="19.1px"
                          color="#2B2D42"
                          fontWeight={700}
                        >
                          Work Type{' '}
                          <Box as="span" color="#C53030">
                            *
                          </Box>
                        </FormLabel>

                        <Box
                          border={
                            !!errors.work_type_id &&
                            touched.work_type_id &&
                            '1px solid #E53E3E'
                          }
                          borderRadius={
                            !!errors.work_type_id &&
                            touched.work_type_id &&
                            '4px'
                          }
                          boxShadow={
                            !!errors.work_type_id &&
                            touched.work_type_id &&
                            '0 0 0 1px #E53E3E'
                          }
                        >
                          <AtsSelect
                            id="work_type_id"
                            name="work_type_id"
                            placeholder="Select Type"
                            variant="outline"
                            value={
                              work_types
                                .map((item: any) => ({
                                  label: item.name,
                                  value: item.id,
                                }))
                                .filter(
                                  (val: any) =>
                                    val.value === Number(values.work_type_id)
                                )[0] || values.work_type_id
                            }
                            onChange={(e: any) => {
                              setFieldValue('work_type_id', String(e.value));
                              !isEditModal &&
                                setFieldValue('job_ads', [
                                  {
                                    ...(jobFormData?.job_ads &&
                                      jobFormData?.job_ads[0]),
                                    worktype_id: String(e.value),
                                  },
                                ]);
                            }}
                            onBlur={(e: any) =>
                              localStorage.setItem(
                                'jobFormData',
                                JSON.stringify({
                                  ...jobFormData,
                                  ...values,
                                })
                              )
                            }
                            options={
                              work_types?.length > 0 &&
                              work_types.map((item: any) => ({
                                label: item.name,
                                value: item.id,
                              }))
                            }
                            styles={{
                              control: (styles: any) => ({
                                ...styles,
                                fontSize: '14px',
                                border: '1px solid #E7E9ED',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                boxShadow: 'none',
                                '&:hover': {},
                                '& ::-webkit-scrollbar': {
                                  display: 'none',
                                },
                              }),
                              valueContainer: (styles: any) => ({
                                ...styles,
                                maxHeight: '33px',
                                overflowY: 'scroll',
                              }),
                              dropdownIndicator: (styles: any) => ({
                                ...styles,
                                color: '#676767',
                              }),
                              indicatorSeparator: () => ({
                                display: 'none',
                              }),
                              placeholder: (styles: any) => ({
                                ...styles,
                                fontSize: '14px',
                                color: '#9B9B9B',
                              }),
                            }}
                          />
                        </Box>
                        <FormErrorMessage>
                          {String(errors.work_type_id)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.client_job_link && touched.client_job_link
                        )}
                        width="100%"
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="19.1px"
                          color="#2B2D42"
                          fontWeight={700}
                        >
                          Client Job Link{' '}
                        </FormLabel>
                        <Flex
                          width="100%"
                          direction="row"
                          alignItems="center"
                          alignSelf="stretch"
                        >
                          <Flex alignSelf="stretch">
                            <Flex alignItems="center">
                              <AtsSelect
                                variant="outline"
                                menuPosition="fixed"
                                value={protocol}
                                onChange={(e: any) => {
                                  setProtocol(e);
                                }}
                                styles={{
                                  control: (styles: any) => ({
                                    ...styles,
                                    width: '110px',
                                    fontSize: '14px',
                                    border: '1px solid #E7E9ED',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    boxShadow: 'none',
                                    '&:hover': {},
                                    '& ::-webkit-scrollbar': {
                                      display: 'none',
                                    },
                                  }),
                                  valueContainer: (styles: any) => ({
                                    ...styles,
                                    maxHeight: '33px',
                                    overflowY: 'scroll',
                                  }),
                                  dropdownIndicator: (styles: any) => ({
                                    ...styles,
                                    color: '#676767',
                                  }),
                                  indicatorSeparator: () => ({
                                    display: 'none',
                                  }),
                                  placeholder: (styles: any) => ({
                                    ...styles,
                                    fontSize: '14px',
                                    color: '#9B9B9B',
                                  }),
                                }}
                                options={[
                                  {
                                    value: 'https://',
                                    label: 'https://',
                                  },
                                  {
                                    value: 'http://',
                                    label: 'http://',
                                  },
                                ]}
                                isSearchable={false}
                              />
                            </Flex>
                          </Flex>
                          <Flex width="100%">
                            <Flex width="100%">
                              <Field
                                as={Input}
                                value={values.client_job_link}
                                name="client_job_link"
                                margin="0px"
                                padding="0px"
                                type="text"
                                onChange={handleChange}
                                onBlur={(e: any) => {
                                  setFieldValue(
                                    'client_job_link',
                                    e.target.value.replace(/(^\w+:|^)\/\//, '')
                                  );
                                  const { value } = e.target;
                                  handleProtocol(value);
                                }}
                                sx={{
                                  height: '38px',
                                  border: '1px solid #E7EDF4',
                                  p: '8px 12px',
                                  fontSize: '14px',
                                }}
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                        <FormErrorMessage mb="12px">
                          {String(errors.client_job_link)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Box>
                      <Flex gap="16px" mb="24px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.employment_type_id &&
                              touched.employment_type_id
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="19.1px"
                            color="#2B2D42"
                            fontWeight={700}
                          >
                            Employment Type{' '}
                            <Box as="span" color="#C53030">
                              *
                            </Box>
                          </FormLabel>
                          <Box
                            border={
                              !!errors.employment_type_id &&
                              touched.employment_type_id &&
                              '1px solid #E53E3E'
                            }
                            borderRadius={
                              !!errors.employment_type_id &&
                              touched.employment_type_id &&
                              '4px'
                            }
                            boxShadow={
                              !!errors.employment_type_id &&
                              touched.employment_type_id &&
                              '0 0 0 1px #E53E3E'
                            }
                          >
                            <AtsSelect
                              id="employment_type_id"
                              name="employment_type_id"
                              placeholder="Select"
                              variant="outline"
                              value={
                                (employment_types?.length > 0 &&
                                  employment_types
                                    .map((item: any) => ({
                                      label: item.name,
                                      value: item.id,
                                    }))
                                    .filter(
                                      (val: any) =>
                                        val.value ===
                                        Number(values.employment_type_id)
                                    )[0]) ||
                                values.employment_type_id
                              }
                              onChange={(e: any) =>
                                setFieldValue(
                                  'employment_type_id',
                                  String(e.value)
                                )
                              }
                              onBlur={() =>
                                localStorage.setItem(
                                  'jobFormData',
                                  JSON.stringify({
                                    ...jobFormData,
                                    ...values,
                                  })
                                )
                              }
                              options={
                                employment_types?.length > 0 &&
                                employment_types.map((item: any) => ({
                                  label: item.name,
                                  value: item.id,
                                }))
                              }
                              styles={{
                                control: (styles: any) => ({
                                  ...styles,
                                  fontSize: '14px',
                                  border: '1px solid #E7E9ED',
                                  borderRadius: '4px',
                                  cursor: 'pointer',
                                  boxShadow: 'none',
                                  '&:hover': {},
                                  '& ::-webkit-scrollbar': {
                                    display: 'none',
                                  },
                                }),
                                menu: (styles: any) => ({
                                  ...styles,
                                  zIndex: 9999, // Set the zIndex for the dropdown menu
                                }),
                                valueContainer: (styles: any) => ({
                                  ...styles,
                                  maxHeight: '33px',
                                  overflowY: 'scroll',
                                }),
                                dropdownIndicator: (styles: any) => ({
                                  ...styles,
                                  color: '#676767',
                                }),
                                indicatorSeparator: () => ({
                                  display: 'none',
                                }),
                                placeholder: (styles: any) => ({
                                  ...styles,
                                  fontSize: '14px',
                                  color: '#9B9B9B',
                                }),
                              }}
                            />
                          </Box>
                          <FormErrorMessage>
                            {String(errors.employment_type_id)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.compensation_id && touched.compensation_id
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="19.1px"
                            color="#2B2D42"
                            fontWeight={700}
                          >
                            Compensation Type
                          </FormLabel>

                          <AtsSelect
                            id="compensation_id"
                            name="compensation_id"
                            placeholder="Select"
                            variant="outline"
                            // value={values.compensation_id}
                            value={
                              (compensations?.length > 0 &&
                                compensations
                                  .map((item: any) => ({
                                    label: item.name,
                                    value: item.id,
                                  }))
                                  .filter(
                                    (val: any) =>
                                      val.value ===
                                      Number(values.compensation_id)
                                  )[0]) ||
                              values.compensation_id
                            }
                            onChange={(e: any) => {
                              setFieldValue('compensation_id', String(e.value));
                            }}
                            onBlur={() =>
                              localStorage.setItem(
                                'jobFormData',
                                JSON.stringify({
                                  ...jobFormData,
                                  ...values,
                                })
                              )
                            }
                            options={
                              compensations?.length > 0 &&
                              compensations.map((item: any) => ({
                                label: item.name,
                                value: item.id,
                              }))
                            }
                            styles={{
                              control: (styles: any) => ({
                                ...styles,
                                fontSize: '14px',
                                border: '1px solid #E7E9ED',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                boxShadow: 'none',
                                '&:hover': {},
                                '& ::-webkit-scrollbar': {
                                  display: 'none',
                                },
                              }),
                              valueContainer: (styles: any) => ({
                                ...styles,
                                maxHeight: '33px',
                                overflowY: 'scroll',
                              }),
                              dropdownIndicator: (styles: any) => ({
                                ...styles,
                                color: '#676767',
                              }),
                              indicatorSeparator: () => ({
                                display: 'none',
                              }),
                              placeholder: (styles: any) => ({
                                ...styles,
                                fontSize: '14px',
                                color: '#9B9B9B',
                              }),
                            }}
                          />
                          <FormErrorMessage>
                            {String(errors.compensation_id)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>

                      <Flex gap="16px" mb="24px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.salary_range_start &&
                              touched.salary_range_start
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="19.1px"
                            color="#2B2D42"
                            fontWeight={700}
                          >
                            Salary Range Start{' '}
                            <Box as="span" color="#C53030">
                              *
                            </Box>
                          </FormLabel>

                          <Box
                            border={
                              !!errors.salary_range_start &&
                              touched.salary_range_start &&
                              '1px solid #E53E3E'
                            }
                            borderRadius={
                              !!errors.salary_range_start &&
                              touched.salary_range_start &&
                              '4px'
                            }
                            boxShadow={
                              !!errors.salary_range_start &&
                              touched.salary_range_start &&
                              '0 0 0 1px #E53E3E'
                            }
                          >
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                zIndex={0}
                                children={
                                  <Icon
                                    as={BsCurrencyDollar}
                                    color="gray.500"
                                  />
                                }
                                sx={{
                                  height: '38px',
                                }}
                              />
                              <Field
                                as={Input}
                                id="salary_range_start"
                                name="salary_range_start"
                                placeholder="0.00"
                                type="text"
                                variant="outline"
                                value={values.salary_range_start}
                                onChange={(event: any) => {
                                  let { value } = event.target;

                                  // Allow only numeric characters and a dot
                                  value = value.replace(/[^0-9.]/g, '');

                                  // Remove leading zeros
                                  value = value.replace(/^0+/g, '');

                                  // Format the number with commas
                                  const formattedValue =
                                    Number(value)?.toLocaleString();

                                  setFieldValue(
                                    'salary_range_start',
                                    formattedValue
                                  );
                                }}
                                onBlur={() =>
                                  localStorage.setItem(
                                    'jobFormData',
                                    JSON.stringify({
                                      ...jobFormData,
                                      ...values,
                                    })
                                  )
                                }
                                sx={{
                                  height: '38px',
                                  border: '1px solid #E7EDF4',
                                  p: '8px 12px',
                                  pl: '35px',
                                  fontSize: '14px',
                                }}
                              />
                            </InputGroup>
                          </Box>

                          <FormErrorMessage>
                            {String(errors.salary_range_start)}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.salary_range_end &&
                              touched.salary_range_end
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="19.1px"
                            color="#2B2D42"
                            fontWeight={700}
                          >
                            Salary Range End{' '}
                            <Box as="span" color="#C53030">
                              *
                            </Box>
                          </FormLabel>

                          <Box
                            border={
                              !!errors.salary_range_end &&
                              touched.salary_range_end &&
                              '1px solid #E53E3E'
                            }
                            borderRadius={
                              !!errors.salary_range_end &&
                              touched.salary_range_end &&
                              '4px'
                            }
                            boxShadow={
                              !!errors.salary_range_end &&
                              touched.salary_range_end &&
                              '0 0 0 1px #E53E3E'
                            }
                          >
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                zIndex={0}
                                children={
                                  <Icon
                                    as={BsCurrencyDollar}
                                    color="gray.500"
                                  />
                                }
                                sx={{
                                  height: '38px',
                                }}
                              />
                              <Field
                                as={Input}
                                id="salary_range_end"
                                name="salary_range_end"
                                placeholder="0.00"
                                type="string"
                                variant="outline"
                                value={values.salary_range_end}
                                onChange={(event: any) => {
                                  let { value } = event.target;

                                  // Allow only numeric characters and a dot
                                  value = value.replace(/[^0-9.]/g, '');

                                  // Remove leading zeros
                                  value = value.replace(/^0+/g, '');

                                  // Format the number with commas
                                  const formattedValue =
                                    Number(value)?.toLocaleString();

                                  setFieldValue(
                                    'salary_range_end',
                                    formattedValue
                                  );
                                }}
                                onBlur={() =>
                                  localStorage.setItem(
                                    'jobFormData',
                                    JSON.stringify({
                                      ...jobFormData,
                                      ...values,
                                    })
                                  )
                                }
                                sx={{
                                  height: '38px',
                                  border: '1px solid #E7EDF4',
                                  p: '8px 12px',
                                  pl: '35px',
                                  fontSize: '14px',
                                }}
                              />
                            </InputGroup>
                          </Box>

                          <FormErrorMessage>
                            {String(errors.salary_range_end)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                    </Box>
                  </>
                )}
                <Box mb="24px">
                  <FormControl
                    isInvalid={Boolean(!!errors.skills && touched.skills)}
                  >
                    <FormLabel
                      fontSize="14px"
                      lineHeight="19.1px"
                      color="#2B2D42"
                      fontWeight={700}
                    >
                      Skills{' '}
                      <Box as="span" color="#C53030">
                        *
                      </Box>
                    </FormLabel>
                    <Box
                      border={
                        !!errors.skills && touched.skills && '1px solid #E53E3E'
                      }
                      borderRadius={!!errors.skills && touched.skills && '4px'}
                      boxShadow={
                        !!errors.skills && touched.skills && '0 0 0 1px #E53E3E'
                      }
                    >
                      <Field
                        id="skills"
                        name="skills"
                        component={AtsCraetableSelDyn}
                        defaultValue={values.skills}
                        placeholder="Search Skills"
                        onChange={(event: any) => {
                          setFieldValue('skills', event);
                        }}
                        onInputChange={searchSKills}
                        isLoading={resSearchSkills.isLoading}
                        options={skillList}
                        isClearable
                        isMulti={true}
                        styles={{
                          control: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            border: '1px solid #E7E9ED',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            boxShadow: 'none',
                            '&:hover': {},
                            '& ::-webkit-scrollbar': {
                              display: 'none',
                            },
                          }),
                          menu: (styles: any) => ({
                            ...styles,
                            zIndex: 9999, // Set the zIndex for the dropdown menu
                          }),
                          valueContainer: (styles: any) => ({
                            ...styles,
                            maxHeight: '33px',
                            overflowY: 'scroll',
                          }),
                          dropdownIndicator: (styles: any) => ({
                            ...styles,
                            color: '#676767',
                          }),
                          indicatorSeparator: () => ({
                            display: 'none',
                          }),
                          placeholder: (styles: any) => ({
                            ...styles,
                            fontSize: '14px',
                            color: '#9B9B9B',
                          }),
                        }}
                      />
                    </Box>
                    <FormErrorMessage>{String(errors.skills)}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                h="inherit"
                sx={{
                  w: '50%',
                  background: '#fff',
                  borderRadius: '6px',
                  p: '16px',

                  '& ::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                <Stack gap="16px">
                  {hasJobPostType !== '2' && (
                    <Box>
                      <FormLabel
                        fontSize="14px"
                        lineHeight="19.1px"
                        color="#2B2D42"
                        fontWeight={700}
                      >
                        Client Job Opening Description
                      </FormLabel>

                      <Box
                        border={
                          !!errors.description &&
                          touched.description &&
                          '1px solid #E53E3E'
                        }
                        borderRadius={
                          !!errors.description && touched.description && '4px'
                        }
                        boxShadow={
                          !!errors.description &&
                          touched.description &&
                          '0 0 0 1px #E53E3E'
                        }
                      >
                        <AtsEmailBody
                          id="description"
                          value={values.description}
                          onChange={(e: any) => {
                            setFieldValue('description', e);
                          }}
                          refocusTo="title"
                          height={300}
                        />
                      </Box>

                      <Box color="#E53E3E" fontSize="12px" mt="10px">
                        <ErrorMessage name="description" />
                      </Box>
                    </Box>
                  )}

                  <Box>
                    <FormLabel
                      fontSize="14px"
                      lineHeight="19.1px"
                      color="#2B2D42"
                      fontWeight={700}
                    >
                      Notes
                    </FormLabel>

                    <Field
                      as={Textarea}
                      id="notes"
                      name="notes"
                      type="text"
                      placeholder="Type what ever you need here..."
                      variant="outline"
                      value={values.notes}
                    />
                  </Box>
                </Stack>
              </Box>
            </Flex>

            <ModalFooter
              position="sticky"
              bottom="0"
              background="#F9F9FB"
              p="8px 0"
              zIndex={1}
            >
              <Flex
                gap="10px"
                justifyContent="space-between"
                sx={{ w: '100%' }}
              >
                {tabIndex.id > 0 && (
                  <Button
                    disabled={isOpenAlert}
                    sx={{
                      height: '40px',
                    }}
                    onClick={() => {
                      tabIndex.id > 0 && setTabIndex(tabList[tabIndex.id - 1]);
                      setJobFormData((prev: any) => ({
                        ...prev,
                        ...values,
                      }));
                    }}
                  >
                    Previous
                  </Button>
                )}

                <Flex gap="10px">
                  <ButtonChakra
                    onClick={() => {
                      onClose();
                      localStorage.setItem('jobFormData', JSON.stringify({}));
                    }}
                  >
                    Cancel
                  </ButtonChakra>

                  <Button
                    disabled={isOpenAlert || loading}
                    loading={loading}
                    mr={3}
                    onClick={() => {
                      setIsDraft(true);
                      handleSubmit();
                      !isValid && onOpen();
                    }}
                  >
                    Save Draft
                  </Button>

                  {((String(jobFormData.job_type_id) === '1' &&
                    tabIndex.id < 4) ||
                    (String(jobFormData.job_type_id) === '2' &&
                      tabIndex.id < 2)) && (
                    <Button
                      variant="solid"
                      disabled={isOpenAlert}
                      onClick={(e: any) => {
                        setIsDraft(false);
                        handleSubmit(e);
                      }}
                      sx={{
                        height: '40px',
                      }}
                    >
                      Next
                    </Button>
                  )}
                </Flex>
              </Flex>
            </ModalFooter>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default JobDetailsForm;
