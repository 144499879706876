import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import FAIcon from '../lib/FAIcon';
import Logo from 'assets/images/logo.svg';
import authService from '../../services/auth.service';
import { useEffect, useState } from 'react';
import { useGetParseListMutation } from 'store/resumerparse.slice';
import { SOCKET_URL } from 'constants/values';
import { authStorage } from 'utils/localStorage';
import { getUser, useUserListPaginateMutation } from 'store/user.slice';
import { navClient } from 'store/client.slice';
import { initialCandidatePgBtn } from 'store/candidates.slice';
import BiSetting from 'assets/images/navbar/setting.svg';
import BiAI from 'assets/images/navbar/ai-parser.svg';
import BiLogOut from 'assets/images/navbar/logout.svg';
import BiChevronRight from 'assets/images/navbar/chevron-right.svg';
import User from 'assets/images/user.png';
import { FaRegBell } from 'react-icons/fa6';
import delay from 'utils/delay';
import { TalieButton } from './TalieAI/Button';
import { updateApp } from 'store/app.slice';
import io from 'socket.io-client';

interface NavItem {
  id: number;
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  to?: string;
}

interface teamMember {
  first_name: string;
  last_name: string;
  user_id: number;
  signedUrl: string;
  user: any;
  thumbnailSignedUrl: string;
  role: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    id: 1,
    label: 'Inbox',
    to: '/inbox/emails',
  },
  {
    id: 2,
    label: 'Candidates',
    to: '/candidates/views',
  },
  {
    id: 3,
    label: 'Jobs',
    to: '/jobs',
  },

  {
    id: 4,
    label: 'Clients',
    to: '/clients',
  },
  {
    id: 5,
    label: 'Leads',
    to: '/leads',
  },
  {
    id: 6,
    label: 'Approvals',
    to: '/approvals',
  },
  {
    id: 7,
    label: 'Engage',
    to: '/engage/sequences/recruitment',
  },
];

interface AppNavBarProps {
  handleOpenParseQueue: () => void;
  handleOpenFooterNotif: () => void;
  handleSwitchAccount: (member: teamMember) => void;
  handleNotification: () => void;
  constants: () => void;
  callViewProfile: () => void;
  profile: any;
  // teamMembers: teamMember[];
}

export default function LoginAppNavBar({
  handleOpenParseQueue,
  handleOpenFooterNotif,
  handleSwitchAccount,
  handleNotification,
  constants,
  callViewProfile,
  // teamMembers,
  profile,
}: AppNavBarProps) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isOpen: isOpenProfile,
    onToggle: onToggleProfile,
    onClose: onCloseProfile,
  } = useDisclosure();

  const default_pic = User;

  const { UserPgBtn, currentUser } = useSelector((state: any) => state.user);
  const { auth, app } = useSelector((state: any) => state);
  const { teamMembers } = useSelector((state: any) => state.teams);

  // const auth = authStorage()
  const isActiveLink = location.pathname.split('/')[1];

  const handleLogout = async () => {
    authService.logout();
    localStorage.setItem(
      'candidatesFilter',
      JSON.stringify(initialCandidatePgBtn)
    );
  };

  const handleClickProfile = () => {
    // console.log("user_id", auth?.id)
    // console.log("went to handleClickProfile")
    dispatch(
      getUser({
        currentUser: {
          id: auth?.id,
          name: [auth?.first_name, auth?.last_name].join(' '),
          email: auth?.email,
          profile: auth?.signedUrl,
        },
      })
    );
  };

  const [reqBatch, resBatch] = useGetParseListMutation();
  const [reqUser] = useUserListPaginateMutation();

  const [totalProcessing, setTotalProcessing] = useState(0);
  const authSt = authStorage();
  const { batches } = useSelector((state: any) => state.resumeParse);

  useEffect(() => {
    constants();
  }, []);
  useEffect(() => {
    const load = async () => {
      await delay(200);
      await callViewProfile();
    };
    if (auth?.id) {
      load();
    }
  }, [auth?.id]);

  useEffect(() => {
    if (batches.length > 0) {
      let total = 0;
      Promise.all(
        batches.map(async (item: any) => {
          if (!item.is_parse) {
            total++;
          }
        })
      );
      setTotalProcessing(total);
    } else {
      setTotalProcessing(0);
    }
  }, [resBatch.isSuccess, batches]);

  // useEffect(() => {
  //   const socket = io(SOCKET_URL);
  //   socket.on('message', (data) => {
  //     // setMessages((prevMessages) => [...prevMessages, data]);
  //     console.log({ data });
  //   });

  //   socket.emit('message', {}, (response: string) => {
  //     console.log('Server acknowledgment:', response);
  //   });

  //   return () => {
  //     socket.off('message');
  //     socket.close(); // Ensure the socket is closed on component unmount
  //   };
  // }, []);

  return (
    <Box position="absolute" zIndex="999" width="100%">
      {app.isBackDrop && (
        <Box
          sx={{
            zIndex: 99999999,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bgColor: 'black',
            opacity: '30%',
            height: '100%',
          }}
        />
      )}
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH="60px"
        py={0}
        px={{ base: 8 }}
        borderBottom={1}
        borderStyle="solid"
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align="center"
      >
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          {/* <Flex alignItems="center" justifyContent="center" h="100%" > */}
          {/* <Image src={Logo} alt="logo" /> */}
          <Image
            src={Logo}
            alt="logo"
            w="40px"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/dashboard');
            }}
          />
          {/* </Flex> */}

          <Flex
            display={{ base: 'none', md: 'flex' }}
            my="10px"
            ml={10}
            alignItems="center"
            py="0px"
          >
            <Stack direction="row">
              {NAV_ITEMS.map((navItem) => {
                const activeLink = isActiveLink === navItem.to.split('/')[1];
                return (
                  <Box
                    key={navItem.label}
                    onClick={() =>
                      navItem.to.split('/')[1] === 'clients' &&
                      dispatch(navClient({ clientNav: 'my-clients' }))
                    }
                  >
                    <Link to={navItem.to ?? '#'}>
                      <Box
                        px="18px"
                        py="13px"
                        fontSize="14px"
                        fontWeight="bold"
                        _hover={{
                          backgroundColor: 'primary.100',
                          borderRadius: '3px',
                          textDecoration: 'none',
                          color: 'primary.800',
                          fontWeight: 'bold',
                        }}
                        backgroundColor={activeLink && 'primary.100'}
                        borderRadius={activeLink && '3px'}
                        textDecoration={activeLink && 'none'}
                        color={
                          activeLink ? 'primary.800' : 'default.secondarytext'
                        }
                      >
                        {navItem.label}
                      </Box>
                    </Link>
                  </Box>
                );
              })}
            </Stack>
          </Flex>
        </Flex>

        <Flex
          gap="3"
          justify="flex-end"
          alignItems="center"
          w="100%"
          h="fit-content"
          sx={{
            '&& .chakra-menu__menu-list': {
              mt: '8px',
            },
          }}
        >
          <TalieButton />

          <Box
            cursor="pointer"
            position="relative"
            alignItems="center"
            onClick={handleNotification}
            w="fit-content"
            h="fit-content"
          >
            <Flex
              position="relative"
              alignItems="flex-start"
              w="fit-content"
              h="fit-content"
            >
              <Flex
                w={'35px'}
                h={'35px'}
                bg="#AE8FE2"
                alignItems="center"
                fontSize="21px"
                justifyContent="center"
                borderRadius="full"
                color="white"
              >
                <Icon color="white" fontSize="21px" as={FaRegBell} />
              </Flex>
            </Flex>
          </Box>

          <Flex cursor="pointer" alignItems="center" onClick={onToggleProfile}>
            <Menu closeOnSelect={false} onClose={onCloseProfile}>
              <MenuButton>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  w="fit-content"
                >
                  <Flex
                    w={'35px'}
                    h={'35px'}
                    position="relative"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      w="35px"
                      h="35px"
                      rounded="full"
                      src={profile?.thumbnail}
                      fallbackSrc={default_pic}
                    ></Image>
                  </Flex>
                  <Flex
                    w={'21px'}
                    h={'21px'}
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    ml="-7px"
                    border="0.5px solid"
                    borderColor="#AE8FE2"
                    borderRadius="full"
                    bg="white"
                  >
                    <Icon
                      as={ChevronDownIcon}
                      fontSize="20px"
                      transition="all .25s ease-in-out"
                      transform={isOpenProfile ? 'rotate(180deg)' : ''}
                      color="#AE8FE2"
                    />
                  </Flex>
                </Flex>
              </MenuButton>

              <MenuList>
                {app.isBackDrop && (
                  <Box
                    sx={{
                      zIndex: 1,
                      position: 'absolute',
                      top: '8px',
                      left: 0,
                      right: 0,
                      bgColor: 'black',
                      opacity: '30%',
                      height: '-webkit-fill-available',
                    }}
                  />
                )}
                <Stack spacing="8px" sx={{ p: '8px 16px' }}>
                  <Stack spacing="10px">
                    <Flex
                      gap="10px"
                      alignItems="center"
                      onClick={() => {
                        handleClickProfile();
                        navigate(`/profile/${profile?.user_id || profile?.id}`);
                      }}
                    >
                      <Image
                        w="50px"
                        h="50px"
                        rounded="full"
                        src={profile?.thumbnail}
                        fallbackSrc={default_pic}
                      />

                      <Text
                        fontSize="lg"
                        textAlign="left"
                        color="default.primarytext"
                      >
                        {profile?.name}
                      </Text>
                    </Flex>
                    <Box
                      sx={{
                        borderBottom: '1px',
                        borderColor: '#EEEEEE',
                        pb: '8px',
                        cursor: 'pointer',
                      }}
                    >
                      <Menu
                        onClose={() =>
                          dispatch(updateApp({ isBackDrop: false }))
                        }
                        onOpen={() => dispatch(updateApp({ isBackDrop: true }))}
                      >
                        <MenuButton w="100%">
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text
                              sx={{
                                fontSize: '12px',
                                lineHeight: '16px',
                                fontWeight: 300,
                                color: '#2B2D42',
                              }}
                            >
                              Switch profile
                            </Text>
                            <Image src={BiChevronRight} />
                          </Flex>
                        </MenuButton>
                        <MenuList>
                          <Box sx={{ p: '8px 16px' }}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="center"
                              w="100%"
                              gap="8px"
                              direction="column"
                            >
                              {/* TEAM MEMBERS' ACCOUNT */}
                              {teamMembers?.length > 0 &&
                                teamMembers.map(
                                  (member: teamMember, key: number) => {
                                    return (
                                      <Flex
                                        key={`teamMembers-nav-${key}`}
                                        gap="8px"
                                        alignItems="center"
                                        justifyContent="center"
                                        w="fit-content"
                                        onClick={() =>
                                          handleSwitchAccount(
                                            member.role === 'manager'
                                              ? null
                                              : member
                                          )
                                        }
                                      >
                                        <Flex
                                          w={'35px'}
                                          h={'35px'}
                                          alignItems="center"
                                          justifyContent="center"
                                        >
                                          <Image
                                            w="35px"
                                            h="35px"
                                            rounded="full"
                                            src={member?.thumbnailSignedUrl}
                                            fallbackSrc={default_pic}
                                          />
                                        </Flex>
                                        <Text
                                          sx={{
                                            fontSize: '14px',
                                            lineHeight: '16px',
                                            color: '#6B6C7B',
                                          }}
                                        >
                                          {member?.user?.first_name +
                                            ' ' +
                                            member?.user?.last_name}
                                        </Text>
                                      </Flex>
                                    );
                                  }
                                )}
                            </Flex>
                          </Box>
                        </MenuList>
                      </Menu>
                    </Box>
                  </Stack>
                  <Stack spacing="4px">
                    {/* FOR USER'S ACCOUNT */}
                    <Flex
                      gap="8px"
                      py="8px"
                      alignItems="center"
                      onClick={() => navigate('/settings')}
                    >
                      <Image src={BiSetting} />
                      <Text
                        sx={{
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#6B6C7B',
                        }}
                      >
                        Settings
                      </Text>
                    </Flex>
                    <Flex
                      gap="8px"
                      py="8px"
                      alignItems="center"
                      onClick={handleOpenParseQueue}
                    >
                      <Image src={BiAI} />
                      <Text
                        sx={{
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#6B6C7B',
                        }}
                      >
                        AI Parser Logs
                      </Text>
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          bgColor: '#6930CA',
                          height: '16px',
                          width: '16px',
                          borderRadius: '100%',
                        }}
                      >
                        <Text
                          sx={{
                            fontSize: '12px',
                            fontWeight: 400,
                            color: '#fff',
                          }}
                        >
                          {totalProcessing}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex
                      gap="8px"
                      py="8px"
                      alignItems="center"
                      onClick={() => {
                        handleLogout();
                        navigate('/login');
                      }}
                    >
                      <Image src={BiLogOut} />
                      <Text
                        sx={{
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#6B6C7B',
                        }}
                      >
                        Logout
                      </Text>
                    </Flex>
                  </Stack>
                </Stack>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
