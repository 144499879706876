import { useState } from 'react';
import * as Yup from 'yup';

interface useRecruitingTeamServicesProps {
  initialValues: any;
  setJobStatus: any;
  setJobFormData: any;
  onSubmit: any;
  jobFormData: any;
  tabList: any;
  tabIndex: any;
  setTabIndex: any;
  isDraft: boolean;
}

export const useRecruitingTeamServices = ({
  initialValues,
  setJobStatus,
  setJobFormData,
  onSubmit,
  jobFormData,
  tabList,
  tabIndex,
  setTabIndex,
  isDraft,
}: useRecruitingTeamServicesProps) => {
  const validationRecruitersSchema = Yup.lazy((values) => {
    var currentJobStatus = values.job_status_id;
    setJobStatus(currentJobStatus);
    if (currentJobStatus === 5) return Yup.mixed().notRequired();
    return Yup.object().shape({
      // recruiters
      primary_recruiter: Yup.number().required(
        'Primary Recruiter is required.'
      ),
      am_id: Yup.number().required('Account Manager is required.'),
      job_type_id: Yup.number().required('Job type is required.'),
      recruiters: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Recruiter is required'),
          split_percentage: Yup.number().required(
            'Split percentage is required'
          ),
          post_new_job_ads: Yup.boolean(),
          no_submittal_review: Yup.boolean(),
          review_send_as_client_owner: Yup.boolean(),
          review_send_as_client_recruiter: Yup.boolean(),
          interview_send_as_client_owner: Yup.boolean(),
          interview_send_as_recruiter: Yup.boolean(),
        })
      ),
      // not include
      job_status_id: Yup.number().required('Job status is required.'),
    });
  });

  const [recruiter, setRecruiter] = useState(null);

  const recruiterSubmit = (data: any, { setSubmitting, validateForm }: any) => {
    if (isDraft == false) {
      setJobFormData((prev: any) => ({
        ...prev,
        ...data,
      }));
    }

    setSubmitting(false);
    validateForm().then((errors: any) => {
      if (Object.keys(errors).length === 0) {
        if (isDraft === true) {
          onSubmit({
            ...initialValues,
            ...jobFormData,
            ...data,
            job_status_id: 5,
          });
        } else if (tabList?.length !== tabIndex.id + 1) {
          setTabIndex(tabList[tabIndex.id + 1]);
        }
      }
    });
  };

  return {
    validationRecruitersSchema,
    recruiterSubmit,
    setRecruiter,
  };
};
