import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

export function useTabListServices() {
  const navigate = useNavigate();
  const params = useParams();

  const { leadData } = useSelector((state: any) => state.leads);

  const [activeTab, setActiveTab] = useState(params.leadsTab);

  const stateTab: any = params.leadsTab;

  const handleRoute = (route: string) => {
    navigate(`/leads/${leadData.id}/${route}`);
    setActiveTab(route);
  };

  return { stateTab, handleRoute, activeTab };
}
