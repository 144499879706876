import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  SkeletonCircle,
  Stack,
  Td,
  Tr,
} from '@chakra-ui/react';

const LoadingEdit = () => {
  return (
    <>
      <FormControl mb="24px">
        <Box width="100px" mb="12px">
          <Skeleton height="15px" />
        </Box>
        <Box>
          <Flex alignItems="center" gap="10px">
            <SkeletonCircle size="4" />
            <Box width="100px" mb="12px">
              <Skeleton height="15px" />
            </Box>
          </Flex>
        </Box>
      </FormControl>
      <Flex gap="32px" mb="34px">
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
      <Flex gap="32px" mb="34px">
        <Box w="100%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
      <Flex gap="32px" mb="34px">
        <Box w="100%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
      <Flex gap="32px" mb="34px">
        <Box w="100%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
    </>
  );
};
export default LoadingEdit;
