import { Formik, FieldArray, Field, ErrorMessage } from 'formik';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  ModalBody,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Textarea,
  ModalFooter,
  Button,
  Switch,
  Text,
} from '@chakra-ui/react';

import Buttonv2 from 'Library/Button';

import AtsCraetableSelDyn from 'components/app/AtsCreatabeSelect/Dyanmic';
import AtsSelect from 'components/app/AtsSelect';
import AtsDropZone from 'components/app/Dropzone';
import ScrollToFieldError from 'components/app/ScrollError';

import { LeadsModalProps } from './types';
import { useSendForApprovalServices } from './useSendForApprovalServices';

export const LeadsSendForApprovalModal = ({
  isOpen,
  onClose,
  isSendApproval,
  setIsSendApproval,
}: LeadsModalProps) => {
  const {
    isOpenAlert,
    errorMessage,
    onCloseAlert,
    initialValues,
    validationSchema,
    onSubmit,
    setIsDisabledWebsite,
    SearchCompany,
    resCompany,
    companyList,
    handleAddressSelection,
    countryOption,
    leadData,
    leadClientData,
    isDisabledWebsite,
    userList,
    leadSrouceList,
    uploading,
  } = useSendForApprovalServices({
    isOpen,
    onClose,
    isSendApproval,
    setIsSendApproval,
  });
  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        isSendApproval && setIsSendApproval(false);
        onClose();
      }}
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          {isSendApproval && 'Send to Approval'}
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        {isOpenAlert && (
          <Box>
            <Alert status="error" justifyContent="space-around">
              <AlertIcon />
              <Box>
                <AlertTitle>Oh snap!</AlertTitle>
                <AlertDescription>{errorMessage}</AlertDescription>
              </Box>
              <CloseButton
                position="relative"
                right={-1}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit} onChange={onCloseAlert}>
                <ScrollToFieldError />
                <Box p="32px">
                  <Flex gap="32px" mb="34px">
                    <FormControl
                      isInvalid={Boolean(
                        !!errors.first_name && touched.first_name
                      )}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        First Name{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </FormLabel>
                      <Input
                        id="first_name"
                        name="first_name"
                        type="text"
                        placeholder="First Name"
                        variant="outline"
                        value={values.first_name}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        {String(errors.first_name)}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={Boolean(
                        !!errors.last_name && touched.last_name
                      )}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Last Name{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </FormLabel>
                      <Input
                        id="last_name"
                        name="last_name"
                        type="text"
                        placeholder="Last Name"
                        variant="outline"
                        value={values.last_name}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        {String(errors.last_name)}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Flex gap="32px" mb="34px">
                    <FormControl
                      isInvalid={Boolean(
                        !!errors.personal_phone && touched.personal_phone
                      )}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Personal Phone
                      </FormLabel>
                      <Input
                        id="personal_phone"
                        name="personal_phone"
                        type="text"
                        placeholder="Personal Phone"
                        variant="outline"
                        value={values.personal_phone}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        {String(errors.personal_phone)}
                      </FormErrorMessage>
                    </FormControl>
                    <Flex width="100%" gap="32px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.work_phone && touched.work_phone
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Work Phone
                        </FormLabel>
                        <Input
                          id="work_phone"
                          name="work_phone"
                          type="text"
                          placeholder="Work Phone"
                          variant="outline"
                          value={values.work_phone}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.work_phone)}
                        </FormErrorMessage>
                      </FormControl>
                      <Flex width="30%">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.work_phone && touched.work_phone
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Extension
                          </FormLabel>
                          <Input
                            id="extension"
                            name="extension"
                            type="number"
                            placeholder="Ext"
                            variant="outline"
                            value={values.extension}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.extension)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex gap="32px" mb="40px">
                    <FormControl
                      isInvalid={Boolean(!!errors.email && touched.email)}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Email{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </FormLabel>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email"
                        variant="outline"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        {String(errors.email)}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={Boolean(
                        !!errors.linkedIn_url && touched.linkedIn_url
                      )}
                    >
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        LinkedIn Link
                      </FormLabel>
                      <Input
                        id="linkedIn_url"
                        name="linkedIn_url"
                        type="text"
                        placeholder="LinkedIn Link"
                        variant="outline"
                        value={values.linkedIn_url}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        {String(errors.linkedIn_url)}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>

                  <Box mb="40px">
                    <Text fontWeight={600} mb="34px">
                      Company Details
                    </Text>
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.title && touched.title)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Title{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          placeholder="Title"
                          variant="outline"
                          value={values.title}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.title)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(!!errors.company && touched.company)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Company{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <AtsCraetableSelDyn
                          id="company"
                          name="company"
                          defaultValue={values.company}
                          placeholder="Company"
                          onChange={(event: any) => {
                            setFieldValue('company', event);
                            if (event?.address) {
                              setFieldValue('address', event.address);
                            }

                            if (event?.website) {
                              setFieldValue('website', event.website);
                              setIsDisabledWebsite(true);
                            }

                            if (!event) {
                              setFieldValue('address', []);
                              setFieldValue('website', '');
                              setIsDisabledWebsite(false);
                            }
                          }}
                          onInputChange={SearchCompany}
                          isLoading={resCompany.isLoading}
                          options={companyList}
                          isClearable
                        />
                        <FormErrorMessage>
                          {String(errors.company)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>

                    <Box>
                      <Text fontWeight={600} mb="10px">
                        Address Information{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </Text>
                    </Box>

                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.address && touched.address)}
                      >
                        <FieldArray name="address">
                          {({ remove, push }) => (
                            <div>
                              {values.address &&
                                values.address?.length > 0 &&
                                values.address.map(
                                  (job: any, index: number) => (
                                    <Accordion
                                      defaultIndex={[index]}
                                      allowMultiple
                                      key={`jobAdd-${index}`}
                                    >
                                      <AccordionItem>
                                        <Flex position="relative">
                                          <AccordionButton>
                                            <Box
                                              as="span"
                                              flex="1"
                                              textAlign="left"
                                            >
                                              <Box
                                                as="span"
                                                fontSize="14px"
                                                lineHeight="18px"
                                                fontWeight={600}
                                                color="default.primarytext"
                                                mb="8px"
                                              >
                                                {job.location
                                                  ? `${job.location}${
                                                      job.primary
                                                        ? ` (Primary Address)`
                                                        : ''
                                                    }`
                                                  : `Address ${index + 1}`}
                                              </Box>
                                            </Box>

                                            <Box>
                                              <AccordionIcon color="#6930CA" />
                                            </Box>
                                          </AccordionButton>
                                          <Flex
                                            gap="15px"
                                            alignItems="center"
                                            position="absolute"
                                            right="55px"
                                            top="0"
                                            bottom="0"
                                          >
                                            <Field
                                              as={Switch}
                                              colorScheme="purple"
                                              name={`address.${index}.primary`}
                                              isChecked={job.primary}
                                              onChange={() =>
                                                handleAddressSelection(
                                                  index,
                                                  setFieldValue,
                                                  values
                                                )
                                              }
                                            />
                                            <Box>
                                              <Buttonv2
                                                leftIcon="close"
                                                onClick={() => remove(index)}
                                                size="sm"
                                              >
                                                Remove
                                              </Buttonv2>
                                            </Box>
                                          </Flex>
                                        </Flex>
                                        <AccordionPanel pb={4}>
                                          <div
                                            className="row"
                                            key={`jobAdd-${index}`}
                                          >
                                            <FormControl
                                              mb="34px"
                                              isInvalid={Boolean(
                                                (errors.address as any[])?.[
                                                  index
                                                ]?.location &&
                                                  (touched.address as any[])?.[
                                                    index
                                                  ]?.location
                                              )}
                                            >
                                              <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                              >
                                                Location Name{' '}
                                                <Box
                                                  as="span"
                                                  color="caution.800"
                                                >
                                                  *
                                                </Box>
                                              </FormLabel>
                                              <Field
                                                as={Input}
                                                name={`address.${index}.location`}
                                                placeholder="Location Name"
                                                type="text"
                                                variant="outline"
                                                value={job.location}
                                                onChange={handleChange}
                                              />
                                              <FormErrorMessage>
                                                {String(
                                                  (errors.address as any[])?.[
                                                    index
                                                  ]?.location
                                                )}
                                              </FormErrorMessage>
                                            </FormControl>
                                            <FormControl
                                              mb="34px"
                                              isInvalid={Boolean(
                                                (errors.address as any[])?.[
                                                  index
                                                ]?.address &&
                                                  (touched.address as any[])?.[
                                                    index
                                                  ]?.address
                                              )}
                                            >
                                              <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                              >
                                                Address
                                              </FormLabel>
                                              <Field
                                                as={Input}
                                                name={`address.${index}.address`}
                                                placeholder="Address"
                                                type="text"
                                                variant="outline"
                                                value={job.address}
                                                onChange={handleChange}
                                              />
                                              <FormErrorMessage>
                                                {String(
                                                  (errors.address as any[])?.[
                                                    index
                                                  ]?.address
                                                )}
                                              </FormErrorMessage>
                                            </FormControl>

                                            <Flex gap="32px" mb="34px">
                                              <FormControl
                                                isInvalid={Boolean(
                                                  (errors.address as any[])?.[
                                                    index
                                                  ]?.city &&
                                                    (
                                                      touched.address as any[]
                                                    )?.[index]?.city
                                                )}
                                              >
                                                <FormLabel
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  color="default.secondarytext"
                                                >
                                                  City{' '}
                                                  <Box
                                                    as="span"
                                                    color="caution.800"
                                                  >
                                                    *
                                                  </Box>
                                                </FormLabel>
                                                <Field
                                                  as={Input}
                                                  name={`address.${index}.city`}
                                                  placeholder="City"
                                                  type="text"
                                                  variant="outline"
                                                  value={job.city}
                                                  onChange={handleChange}
                                                />
                                                <FormErrorMessage>
                                                  {String(
                                                    (errors.address as any[])?.[
                                                      index
                                                    ]?.city
                                                  )}
                                                </FormErrorMessage>
                                              </FormControl>
                                              <FormControl
                                                isInvalid={Boolean(
                                                  (errors.address as any[])?.[
                                                    index
                                                  ]?.state &&
                                                    (
                                                      touched.address as any[]
                                                    )?.[index]?.state
                                                )}
                                              >
                                                <FormLabel
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  color="default.secondarytext"
                                                >
                                                  State / Province{' '}
                                                  <Box
                                                    as="span"
                                                    color="caution.800"
                                                  >
                                                    *
                                                  </Box>
                                                </FormLabel>
                                                <Field
                                                  as={Input}
                                                  name={`address.${index}.state`}
                                                  placeholder="State / Province"
                                                  type="text"
                                                  variant="outline"
                                                  value={job.state}
                                                  onChange={handleChange}
                                                />
                                                <FormErrorMessage>
                                                  {String(
                                                    (errors.address as any[])?.[
                                                      index
                                                    ]?.state
                                                  )}
                                                </FormErrorMessage>
                                              </FormControl>
                                            </Flex>
                                            <Flex gap="32px" mb="34px">
                                              <FormControl
                                                isInvalid={Boolean(
                                                  (errors.address as any[])?.[
                                                    index
                                                  ]?.country &&
                                                    (
                                                      touched.address as any[]
                                                    )?.[index]?.country
                                                )}
                                              >
                                                <FormLabel
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  color="default.secondarytext"
                                                >
                                                  Country{' '}
                                                  <Box
                                                    as="span"
                                                    color="caution.800"
                                                  >
                                                    *
                                                  </Box>
                                                </FormLabel>
                                                <Field
                                                  name={`address.${index}.country`}
                                                  options={countryOption}
                                                  component={AtsSelect}
                                                  defaultValue={job.country}
                                                  onChange={(e: any) => {
                                                    setFieldValue(
                                                      `address.${index}.country`,
                                                      e
                                                    );
                                                  }}
                                                />
                                                <FormErrorMessage>
                                                  {String(
                                                    (errors.address as any[])?.[
                                                      index
                                                    ]?.country
                                                  )}
                                                </FormErrorMessage>
                                              </FormControl>
                                              <FormControl
                                                isInvalid={Boolean(
                                                  (errors.address as any[])?.[
                                                    index
                                                  ]?.zip_code &&
                                                    (
                                                      touched.address as any[]
                                                    )?.[index]?.zip_code
                                                )}
                                              >
                                                <FormLabel
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  color="default.secondarytext"
                                                >
                                                  Zip Code
                                                </FormLabel>
                                                <Field
                                                  as={Input}
                                                  name={`address.${index}.zip_code`}
                                                  placeholder="Zip Code"
                                                  type="text"
                                                  variant="outline"
                                                  value={job.zip_code}
                                                  onChange={handleChange}
                                                />
                                                <FormErrorMessage>
                                                  {String(
                                                    (errors.address as any[])?.[
                                                      index
                                                    ]?.zip_code
                                                  )}
                                                </FormErrorMessage>
                                              </FormControl>
                                            </Flex>
                                          </div>
                                        </AccordionPanel>
                                      </AccordionItem>
                                    </Accordion>
                                  )
                                )}
                              {!leadData.address && (
                                <Box mt="50px">
                                  <Buttonv2
                                    leftIcon="plus"
                                    onClick={() => {
                                      push({
                                        location: '',
                                        address: '',
                                        city: '',
                                        state: '',
                                        country: '',
                                        zip_code: '',
                                        primary:
                                          leadClientData[0]?.client.address
                                            ?.length === 0 ||
                                          values.address?.length === 0
                                            ? true
                                            : false,
                                      });
                                    }}
                                  >
                                    {values.address &&
                                    values.address?.length > 0
                                      ? 'Add Another Address'
                                      : 'Add Address'}
                                  </Buttonv2>
                                </Box>
                              )}
                            </div>
                          )}
                        </FieldArray>
                        {values.address?.length === 0 && (
                          <FormErrorMessage mt="20px">
                            {String(errors.address)}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Flex>

                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.website && touched.website)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Company Website{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="website"
                          name="website"
                          type="text"
                          placeholder="Company Website"
                          variant="outline"
                          value={values.website}
                          onChange={handleChange}
                          disabled={isDisabledWebsite}
                        />
                        <FormErrorMessage>
                          {String(errors.website)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Box>

                  <Box>
                    <Text fontWeight={600} mb="34px">
                      Additional Information
                    </Text>
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.created_by && touched.created_by
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Created By:
                        </FormLabel>
                        <AtsSelect
                          id="created_by"
                          name="created_by"
                          placeholder="Select"
                          variant="outline"
                          value={
                            (Object.values(userList).length > 0 &&
                              Object.values(userList)
                                .map((item: any) => ({
                                  label: [item.first_name, item.last_name].join(
                                    ' '
                                  ),
                                  value: item.id,
                                }))
                                .filter(
                                  (val: any) =>
                                    val.value === Number(values.created_by)
                                )[0]) ||
                            values.created_by
                          }
                          onChange={(e: any) =>
                            setFieldValue('created_by', String(e.value))
                          }
                          options={Object.values(userList).map((item: any) => ({
                            label: [item.first_name, item.last_name].join(' '),
                            value: item.id,
                          }))}
                        />
                        <FormErrorMessage>
                          {String(errors.created_by)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.lead_source && touched.lead_source
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Source{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          id="lead_source"
                          name="lead_source"
                          options={leadSrouceList}
                          component={AtsSelect}
                          value={values.lead_source}
                          onChange={(e: any) => {
                            setFieldValue(`lead_source`, e);
                          }}
                        />
                        <FormErrorMessage>
                          {errors.lead_source &&
                            touched.lead_source &&
                            String((errors.lead_source as any).label)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex mb="34px" gap="32px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.lead_owner && touched.lead_owner
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Lead Owner{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <AtsSelect
                          id="lead_owner"
                          name="lead_owner"
                          placeholder="Select"
                          variant="outline"
                          value={
                            (Object.values(userList).length > 0 &&
                              Object.values(userList)
                                .map((item: any) => ({
                                  label: [item.first_name, item.last_name].join(
                                    ' '
                                  ),
                                  value: item.id,
                                }))
                                .filter(
                                  (val: any) =>
                                    val.value === Number(values.lead_owner)
                                )[0]) ||
                            values.lead_owner
                          }
                          onChange={(e: any) =>
                            setFieldValue('lead_owner', String(e.value))
                          }
                          options={Object.values(userList).map((item: any) => ({
                            label: [item.first_name, item.last_name].join(' '),
                            value: item.id,
                          }))}
                        />
                        <FormErrorMessage>
                          {String(errors.lead_owner)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl />
                    </Flex>
                  </Box>
                  <Box mb="34px">
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Attachments
                      </FormLabel>
                    </FormControl>
                    <AtsDropZone
                      multipleFile={true}
                      uploadedEnd={(e: any) => {}}
                      deletedFile={(key: string) => {}}
                    />
                  </Box>
                  <Box>
                    <FormLabel
                      mb="8px"
                      fontSize="14px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      Notes
                    </FormLabel>
                    <Textarea
                      id="notes"
                      name="notes"
                      placeholder="Type here"
                      variant="outline"
                      value={values.notes}
                      onChange={handleChange}
                    />
                    <Text
                      mt="8px"
                      fontSize="12px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      Maximum 200 characters allowed
                    </Text>
                    <Box color="red.700">
                      <ErrorMessage
                        name="notes"
                        className="alert alert-danger"
                      />
                    </Box>
                  </Box>
                </Box>
                <ModalFooter
                  position="sticky"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                >
                  <Button
                    variant="solid"
                    disabled={isOpenAlert || uploading}
                    type="submit"
                    mr={3}
                  >
                    Send
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
