import { useDisclosure, useToast } from '@chakra-ui/react';

import * as Yup from 'yup';

import { debounce } from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  useClientSearchMutation,
  useViewClientMutation,
} from 'store/client.slice';
import {
  useGetCountriesMutation,
  useGetJobSpecsMutation,
  useGetLeadSourceMutation,
} from 'store/constant.slice';
import {
  getLead,
  useCreateLeadsMutation,
  useGetLeadsMutation,
  useListLeadsMutation,
  useUpdateLeadsMutation,
} from 'store/leads.slice';
import { cleanUpload, uploadList } from 'store/uploads.slice';
import { useUserListMutation } from 'store/user.slice';
import { swalContent } from 'types';
import { authStorage } from 'utils/localStorage';
import { changeUploadName } from 'utils/newFilename';
import { AtsConfirm } from 'utils/swal';
import { addressFormat, leadSourceFormat } from 'utils/utilFormat';
import { LeadsModalProps } from './types';

export function useModalServices({
  isOpen,
  onClose,
  modalTitle,
}: LeadsModalProps) {
  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams();
  const leadID = params.leadsId;

  // const { auth } = useSelector((state: any) => state.auth);
  const { leadData, leadPgBtn, leadDetails } = useSelector(
    (state: any) => state.leads
  );
  const { countries, lead_source, job_specs } = useSelector(
    (state: any) => state.constants
  );
  const { clientData } = useSelector((state: any) => state.clients);
  const { userList } = useSelector((state: any) => state.user);
  const { uploaded, prefix, withPrefix, uploading } = useSelector(
    (state: any) => state.uploads
  );

  const [reqCountry] = useGetCountriesMutation();
  const [reqCompnay, resCompany] = useClientSearchMutation();
  const [reqLeads] = useListLeadsMutation();
  const [reqleadsData] = useGetLeadsMutation();
  const [reqCreateLead, resCreateLead] = useCreateLeadsMutation();
  const [reqUpdateLead, resUpdateLead] = useUpdateLeadsMutation();
  const [reqUser, resUser] = useUserListMutation();
  const [reqClientData, resClientData] = useViewClientMutation();
  const [reqLeadSource] = useGetLeadSourceMutation();
  const [reqSpecs] = useGetJobSpecsMutation();

  const [jobSpecsDefOption, setJobSpecsDefOption] = useState({});
  const [jobSpecsOption, setJobSpecsOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [countryDefOption, setCountryDefOption] = useState([]);
  const [selectOption, setSelectOption] = useState([]);
  const auth = authStorage();
  const [companyList, setCompanyList] = useState([]);
  const [leadSrouceList, setLeadSrouceList] = useState([]);
  let [managers, setManager] = useState([]);

  const [protocol, setProtocol] = useState({
    value: 'https://',
    label: 'https://',
  });

  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  // INITIALIZE OPTIONS ON LOAD IF EMPTY
  useEffect(() => {
    // FOR LEAD OWNER OPTIONS
    if (userList.length === 0) {
      reqUser({});
    }
    // FOR ACCOUNT MANAGER OPTIONS
    if (managers.length === 0 && leadData?.client_id) {
      reqClientData({ id: leadData?.client_id }).then((res: any) => {
        const data = res?.data?.data;
        if (data) {
          if (data?.manager?.length > 0) {
            let options: any = [];
            data?.manager.map((data: any) => {
              options.push({
                value: data?.user?.id,
                label: [data?.user?.first_name, data?.user?.last_name].join(
                  ' '
                ),
              });
            });
            setManager(options);
          }
        }
      });
    }

    // FOR SPECIFICATION OPTIONS
    if (job_specs.length === 0) {
      reqSpecs({}).then((res: any) => {
        const data = res?.data?.data;
        if (data && data.length > 0) {
          let options: any = [];
          data.map((item: any) => {
            options.push({
              label: item.specification,
              value: item.id,
            });
            if (item.id === leadData.spec_id) {
              setJobSpecsDefOption({
                label: item.specification,
                value: item.id,
              });
            }
          });
          setJobSpecsOption(options);
        }
      });
    }
  }, [leadData, userList, job_specs]);
  const defManager = () => {
    let manager: any = [];
    try {
      manager.push({
        value: auth?.id,
        label: [auth?.first_name, auth?.last_name].join(' '),
      });
    } catch (e) {
      console.warn(e);
    }

    return manager;
  };

  useEffect(() => {
    let userOption: any = [];
    if (resUser.isSuccess) {
      if (resUser.data.data.length > 0) {
        resUser.data.data.map(async (data: any) => {
          await userOption.push({
            value: data.id,
            label: [data.first_name, data.last_name].join(' '),
          });
        });
        setSelectOption(userOption);
      }
    }
  }, [resUser.isSuccess]);

  useEffect(() => {
    // reqCountry({});
    if (clientData?.manager?.length > 0) {
      let options: any = [];
      clientData?.manager.map((data: any) => {
        options.push({
          value: data?.user?.id,
          label: [data?.user?.first_name, data?.user?.last_name].join(' '),
        });
      });
      setManager(options);
    }
  }, [clientData]);

  useEffect(() => {
    if (leadID) {
      reqleadsData({ id: leadID });
    }
  }, [leadID]);

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });

        if (item.code === 'us') {
          setCountryDefOption([
            {
              label: item.country,
              value: item.id,
              code: item.code,
            },
          ]);
        }
      });
    }
    setCountryOption(options);
  }, [countries]);

  useEffect(() => {
    // leadSrouceList, setLeadSrouceList;
    let options: any = [];
    if (lead_source?.length > 0) {
      lead_source.map((item: any) => {
        options.push({
          label: item.source,
          value: item.id,
        });
      });
    }
    setLeadSrouceList(options);
  }, [lead_source]);

  const initialValues =
    leadData && Object.keys(leadData).length !== 0
      ? {
          first_name: leadData.first_name,
          last_name: leadData.last_name,
          title: leadData.title,
          company: {
            value: leadDetails?.lead?.client?.id,
            label: leadDetails?.lead?.client?.name,
          },

          specification_id: leadData.spec_id, // check not populating issue
          email: leadData.primary_email,
          personal_phone: leadData.personal_phone,
          work_phone: leadData.work_phone,
          extension: leadData.extension,
          // company_location: leadData.company_location,
          address: addressFormat(leadDetails?.address, countries),
          linkedIn_url: leadData.linked_in_url,
          website: leadData.website,
          lead_owner: leadDetails?.lead?.owner_id, // check not populating issue
          lead_source: leadSourceFormat(leadData.lead_source),
          created_by: String(leadData?.user_id),
          notes: '',
          status: leadData.leadStatus?.id,
          client_job_link:
            leadDetails?.lead?.client_job_link &&
            leadDetails?.lead?.client_job_link?.replace(/(^\w+:|^)\/\//, ''),
          isMultipleAccountManager: leadData?.multipleAm ? 'true' : 'false',
          manager: managers, // check not populating issue
        }
      : {
          first_name: '',
          last_name: '',
          title: '',
          company: {},
          specification_id: '',
          email: '',
          personal_phone: '',
          work_phone: '',
          extension: '',
          address: [
            {
              location: '',
              address: '',
              city: '',
              state: '',
              country: countryDefOption,
              zip_code: '',
              primary: true,
              id: null as any,
            },
          ],
          linkedIn_url: '',
          website: '',
          lead_owner: 0,
          lead_source: '',
          created_by: String(auth?.id),
          notes: '',
          status: 1,
          client_job_link: '',
          isMultipleAccountManager: 'false',
          manager: defManager(),
        };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      first_name: Yup.string()
        .required('First name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'First name is invalid.'),
      last_name: Yup.string()
        .required('Last name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'Last name is invalid.'),
      title: Yup.string().required('Title is required.'),
      company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
      specification_id: Yup.number()
        .required('Industry is required.')
        .min(1, 'Invalid level.'),
      email: Yup.string()
        .email('Invalid email.')
        .required('Email is required.'),
      personal_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Personal phone is invalid'
      ),
      work_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Work phone is invalid'
      ),
      extension: Yup.string().matches(/^[\d ()+-]+$/gi, 'Extension is invalid'),
      address: Yup.array()
        .of(
          Yup.object().shape({
            location: Yup.string().required('Location is required.'),
            address: Yup.string(),
            city: Yup.string().required('City is required.'),
            state: Yup.string().required('State is required.'),
            country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
            zip_code: Yup.string(),
            primary: Yup.boolean(),
          })
        )
        .min(1, 'Address is required.'),
      linkedIn_url: Yup.string(),
      website: Yup.string().required('Website is required.'),
      client_job_link: Yup.string(),
      isMultipleAccountManager: Yup.string(),
      manager: Yup.array().min(1, 'Pick at least 1 manager').nullable(),
      lead_owner: Yup.string().required('Lead owner is required.'),
      // lead_source: Yup.array().min(1, 'Pick at least 1 source').nullable(),
      lead_source: Yup.object().shape({
        label: Yup.string().required('Pick at least 1 source'),
        value: Yup.number(),
      }),
      created_by: Yup.string().required('Created by is required.'),
      notes: Yup.string(),
      status: Yup.number(),
    })
  );

  const [isSend, setIsSend] = useState<boolean>(false);

  const isEdit = modalTitle === 'Edit Lead';

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const protocolRegex = /^(.*?:\/\/)/;

  const handleProtocol = (value: string) => {
    const match = value.match(protocolRegex);

    if (match) {
      const protocol = match[1];
      setProtocol({
        value: protocol,
        label: protocol,
      });
    }
  };

  const handleSubmit = async (data: any) => {
    if (isEdit) {
      data['attachments'] = withPrefix
        ? await changeUploadName(uploaded, prefix)
        : uploaded;
      reqUpdateLead({ data, id: leadData.id });
    } else {
      data['created_by'] = parseInt(data.created_by);
      data['lead_owner'] = parseInt(data.lead_owner);
      data['attachments'] = withPrefix
        ? await changeUploadName(uploaded, prefix)
        : uploaded;
      reqCreateLead(data);
    }
  };

  const onSubmit = async (data: any) => {
    if (data.manager[0] === undefined) {
      let manager: any = [];
      manager.push(data.manager);
      data['manager'] = manager;
    }

    if (isSend) {
      data.status = 3;
    } else {
      data.status = leadData.leadStatus?.id || 1;
    }
    try {
      delete data['company']['address'];
      delete data['company']['location'];
      delete data['company']['website'];
      delete data['company']['specification'];
    } catch (e) {}

    const isSame = data.status === 3 && data.lead_owner !== data.created_by;

    if (isEdit || isSame) {
      const content: swalContent = {
        title: 'Do you still want to proceed?',
        text: 'The lead owner and account manager are different.',
        buttons: ['Cancel', true],
        icon: 'warning',
      };
      const confirm = await AtsConfirm(content);
      if (confirm) {
        handleSubmit(data);
      }
    } else {
      handleSubmit(data);
    }
  };

  useEffect(() => {
    let options: any = [];
    if (job_specs?.length > 0) {
      job_specs.map((item: any) => {
        options.push({
          label: item.specification,
          value: item.id,
        });
        if (item.id === leadData.spec_id) {
          setJobSpecsDefOption({
            label: item.specification,
            value: item.id,
          });
        }
      });
    }
    setJobSpecsOption(options);
  }, [job_specs]);

  useEffect(() => {
    if (isOpen) {
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
      reqCountry({});
      reqLeadSource({});
      if (!isEdit) {
        dispatch(getLead({ leadData: {} }));
        dispatch(getLead({ leadDetails: [] }));
      }
      reqCompnay({ search: '' });
    }
  }, [isOpen, isEdit]);

  useEffect(() => {
    reqUser({});
    dispatch(uploadList({ prefix: 'Attachment' }));

    if (resUpdateLead.isSuccess) {
      reqleadsData({ id: leadID });
    }

    if (resCreateLead.isSuccess || resUpdateLead.isSuccess) {
      onCloseAlert();
      onClose();
      setIsSend(false);
      dispatch(getLead({ leadData: {} }));
      dispatch(getLead({ leadDetails: [] }));
      reqLeads({ data: leadPgBtn });
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (resCreateLead.isSuccess) {
          title = 'Added Lead';
          description = 'New Lead successfully added';
        } else if (resUpdateLead.isSuccess) {
          title = 'Updated Lead';
          description = 'Lead has been updated';
          status = 'info';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    } else if (resCreateLead.isError || resUpdateLead.isError) {
      onOpen();
    }

    if (resCreateLead.isError) {
      let errorMessage: any = resCreateLead.error;
      if (errorMessage['data']['error'] !== undefined) {
        setErrorMsg(errorMessage['data']['error']['message']);
      } else {
        try {
          setErrorMsg(errorMessage['data']['message']);
        } catch (e) {
          console.log('e', e);
          setErrorMsg('Server Error');
        }
      }
    }
  }, [
    resCreateLead.isSuccess,
    resCreateLead.isError,
    resUpdateLead.isSuccess,
    resUpdateLead.isError,
  ]);

  useEffect(() => {
    if (resCompany.isSuccess) {
      const data: any = resCompany.data.data;
      let option: any = [];
      data.map((item: any) => {
        option.push({
          label: item.name,
          value: item.id,
          website: item.website,
          address: addressFormat(item.address, countries),
          specification: {
            label: job_specs.filter((spec: any) => spec.id === item.spec_id)[0]
              ?.specification,
            value: item.spec_id,
          },
        });
      });
      setCompanyList(option);
    }
  }, [resCompany.isSuccess]);

  useEffect(() => {
    if (leadData?.client_id) {
      reqClientData({ id: leadData?.client_id });
    }
  }, [leadData?.client_id]);

  useEffect(() => {
    let userOption: any = [];

    if (resClientData.isSuccess) {
      const managersId: any = resClientData?.data?.data?.manager?.map(
        (a: any) => a.user_id
      );
      if (resUser.isSuccess) {
        if (resUser.data.data.length > 0) {
          resUser.data.data.map(async (data: any) => {
            if (!managersId?.includes(data.id)) {
              await userOption.push({
                value: data.id,
                label: [data.first_name, data.last_name].join(' '),
              });
            }
          });
          setSelectOption(userOption);
        }
      }
    }
  }, [
    resUser?.data?.data,
    resUser.isSuccess,
    resClientData?.isSuccess,
    resClientData?.data?.data,
  ]);

  const SearchCompany = debounce((e: any) => {
    reqCompnay({ search: e });
  }, 300);

  const [isDisabledWebsite, setIsDisabledWebsite] = useState(false);
  const [isDisabledSpecification, setIsDisabledSpecification] = useState(false);

  const handleAddressSelection = (
    index: number,
    setFieldValue: any,
    values: any
  ) => {
    setFieldValue(
      'address',
      values.address.map((address: any, idx: any) => ({
        ...address,
        primary: idx === index,
      }))
    );
  };
  return {
    errMsg,
    initialValues,
    validationSchema,
    SearchCompany,
    companyList,
    countryOption,
    jobSpecsOption,
    jobSpecsDefOption,
    isDisabledSpecification,
    isDisabledWebsite,
    leadData,
    selectOption,
    countryDefOption,
    userList,
    leadSrouceList,
    isEdit,
    isOpenAlert,
    uploading,
    protocol,
    setProtocol,
    handleProtocol,
    onSubmit,
    setJobSpecsDefOption,
    setIsDisabledWebsite,
    setIsDisabledSpecification,
    reqClientData,
    resCompany,
    resUpdateLead,
    resCreateLead,
    handleAddressSelection,
    setIsSend,
    onCloseAlert,
  };
}
