import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaRegCopy } from 'react-icons/fa';

import {
  Box,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Button as ChakraButton,
} from '@chakra-ui/react';

import CandidatesDrawer from '../Drawer';
import Pagination from 'Library/Pagination';
import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';

import {
  getCandidate,
  listCandidates,
  useDeleteCandidatesMutation,
  useToggleHideCandidatesMutation,
  useListCandidatesMutation,
  useGetCandidatesMutation,
  useBulkRejectCandidateMutation,
  setloadingCandidate,
} from 'store/candidates.slice';

import {
  CandidateData,
  candidateJobs,
  isCheckWithRowType,
  swalContent,
} from 'types';
import { AtsConfirm } from 'utils/swal';
import CandidatesTagModal from '../Modals/Tag';
import CandidatesMergeModal from '../Modals/CandidatesMergeModal';
import CandidatesAssociateJobModal from '../Modals/AssociateJobs';
// import CandidatesModal from './Modals';
import CandidateTR from './TR/CandidateTR';
import TrLoading from '../../TrLoading';
import { getJob, useGetJobsMutation } from 'store/jobs.slice';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
  usePinCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import CandidatesModal from '../Modals/modal';
import { debounce } from 'lodash';
import BulkAction from './Bulk.Action';
import DynamicCandidateTR from './TR/DynamicCandidateTR';
import delay from 'utils/delay';
import { candidateList } from 'services/candidates.service';
import EmailsModal from 'components/app/Global/Email/Modal';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CandidatesTable = ({ isGroupByJob }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();
  const [reqBulkReject, resBulkReject] = useBulkRejectCandidateMutation();

  const abortFilterControllerRef = useRef<AbortController | null>(null);

  const {
    candidates,
    candidatePage,
    candidateData,
    candidatePgBtn,
    candidateLoading,
    candidateOthers,
  } = useSelector((state: any) => state.candidates);

  const { filter_count } = useSelector((state: any) => state.candidateFilters);
  const [candidateSort, setCandidateSort] = useState(candidates);
  useEffect(() => {
    if (
      filter_count === 0 &&
      (params?.candidatesTabState === 'search' ||
        candidatePgBtn?.filter == null) &&
      candidatePgBtn?.query == ''
    ) {
      setCandidateSort([]);
      dispatch(
        getCandidate({
          candidatePage: {
            count: 0,
            currentPage: 1,
            lastPage: 1,
            nextPage: null,
            prevPage: null,
          },
        })
      );
    } else {
      const sort = candidates?.map((candidate: any, index: number) => {
        return { ...candidate, rowId: index + 1 };
      });

      setCandidateSort(sort);
    }
  }, [
    candidates,
    params?.candidatesTabState,
    filter_count,
    candidatePgBtn?.filter,
    candidatePgBtn?.query,
    params?.candidatesId,
  ]);

  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const urlQuery = useQuery();
  const debounceReqCandidates = debounce(reqCandidates, 300);
  const [reqGetJob] = useGetJobsMutation();
  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqGetCandidate2, resGetCandidate2] = useGetCandidatesMutation();

  const [reqDeleteCandidate, resDeleteCandidate] =
    useDeleteCandidatesMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqToggleHide, resToggleHide] = useToggleHideCandidatesMutation();

  const [rowId, setRowId] = useState(Number(urlQuery?.get('rowId')) || 1);
  const [candidateId, setCandidateId] = useState(0);
  const [candidateIdList, setCandidateIdList] = useState([0]);
  const [assocJobs, setAssocJobs] = useState([]);

  document.documentElement.dir = 'ltr';

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isSorted, setIsSorted] = useState(false);
  const [sortOrder, setSortOrder] = useState(true);
  const [orderBy, setOrderBy] = useState(null);

  const [isBulkAction, setIsBulkAction] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [filterCache, setFilterCache] = useState(JSON.stringify({}));

  const {
    isOpen: isOpenCandidate,
    onOpen: onOpenCandiate,
    onClose: onCloseCandidate,
  } = useDisclosure();

  const {
    isOpen: isOpenTag,
    onOpen: onOpenTag,
    onClose: onCloseTag,
  } = useDisclosure();

  const {
    isOpen: isOpenAssociate,
    onOpen: onOpenAssociate,
    onClose: onCloseAssociate,
  } = useDisclosure();

  const {
    isOpen: isOpenMergeCandidates,
    onOpen: onOpenMergeCandidates,
    onClose: onCloseMergeCandidates,
  } = useDisclosure();

  const {
    isOpen: isOpenSendEmail,
    onOpen: onOpenSendEmail,
    onClose: onCloseSendEmail,
  } = useDisclosure();

  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();

  const { filter, group, candidate_filters } = useSelector(
    (state: any) => state.candidateFilters
  );

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchCandidate = async () => {
    // ADDED THIS TO OVERRIDE JOB_STATUS, STATUS, AND JOB_ID PARAMS FROM JOBS
    const newParam = {
      ...candidatePgBtn,
      job_status: null,
      status: null,
      job_id: null,
    };
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    await reqCandidates({
      data: newParam,
      signal: abortControllerRef.current.signal,
    });
  };

  const resetBulkActions = () => {
    setIsBulkAction(false);
    setIsCheck([]);
    setIsCheckAll(false);
    setIsCheckWithRow([]);
  };

  const associateJobSuccess = async () => {
    setIsBulkAction(false);
    fetchCandidate();

    await reqFilterList({});
    if (abortFilterControllerRef.current) {
      abortFilterControllerRef.current.abort();
    }

    abortFilterControllerRef.current = new AbortController();
    await reqPinList({
      signal: abortFilterControllerRef.current.signal,
    });
    // await reqGetJob({ id: params.jobsId });
  };
  useEffect(() => {
    // const candidateID =
    //   candidateSort.findIndex(
    //     (x: CandidatesTypes) => x.id === Number(params.candidatesId)
    //   ) + 1;
    // setRowId(candidateID);
    setIsBulkAction(false);
  }, [candidateSort, params.candidatesId]);

  const activeFilters = useMemo(() => {
    const activeFilter = candidate_filters.find(
      (item: any) => item.id === candidatePgBtn?.filter
    );
    return activeFilter;
  }, [candidate_filters, candidatePgBtn?.filter, candidatePgBtn]);

  useEffect(() => {
    if (filterCache != '{}' && filterCache != JSON.stringify(candidatePgBtn)) {
      // if (!group) fetchCandidate();
    }

    if (!activeFilters?.group_by_job) {
      const loadapi = async () => {
        fetchCandidate();
      };

      if (urlQuery?.get('IsGroup') && urlQuery?.get('IsGroup') === '1') {
      } else {
        console.log('opening...');
        loadapi();
      }
    }

    setFilterCache(JSON.stringify(candidatePgBtn));
  }, [activeFilters?.group_by_job, candidatePgBtn]);

  useEffect(() => {
    if (resDeleteCandidate.isSuccess || resToggleHide.isSuccess) {
      fetchCandidate();
      if (abortFilterControllerRef.current) {
        abortFilterControllerRef.current.abort();
      }

      abortFilterControllerRef.current = new AbortController();
      reqPinList({
        signal: abortFilterControllerRef.current.signal,
      });
    }
  }, [resDeleteCandidate.isSuccess, resToggleHide.isSuccess]);

  useEffect(() => {
    if (resBulkReject.isSuccess) {
      fetchCandidate();
      resetBulkActions();
      if (abortFilterControllerRef.current) {
        abortFilterControllerRef.current.abort();
      }

      abortFilterControllerRef.current = new AbortController();
      reqPinList({
        signal: abortFilterControllerRef.current.signal,
      });
    }
  }, [resBulkReject.isSuccess]);

  useEffect(() => {
    if (isSorted) {
      const params = {
        ...candidatePgBtn,
        job_id: null as any,
        ...(orderBy !== null && {
          orderBy: {
            column_name: orderBy?.column_name,
            sort_order: orderBy?.sort_order,
          },
        }),
      };

      dispatch(
        listCandidates({
          candidatePgBtn: params,
        })
      );

      setIsSorted(false);
    }
  }, [isSorted]);

  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    if (params.candidatesId && !isOpen) {
      if (candidateData?.id !== params.candidatesId) {
        pageChange(Number(params?.candidatesPageNum)).then(() => {
          setIsRefresh(true);

          if (urlQuery?.get('IsGroup') && urlQuery?.get('IsGroup') === '1') {
          } else {
            console.log('opening...');
            onOpen();
          }
        });
      }
      dispatch(getJob({ jobData: {} }));
    }
  }, [params.candidatesId, isOpen]);

  // function to update to rowId when refreshing
  // useEffect(() => {
  //   if (candidateSort.length > 0 && rowId == 1 && params?.candidatesId && candidateData) {

  //     const index = candidateSort.filter(
  //       (item: any) => item?.id == Number(params?.candidatesId) && item?.jobs?.[0]?.job_id == candidateData?.jobs?.[0]?.job_id
  //     )[0];
  //     console.log("indexing sa row Id", index)
  //     setRowId(index)
  //   }
  // }, [candidateSort, params?.candidatesId, candidateData])

  // const [isGroupByJob, setIsGroupByJob] = useState(false);

  useEffect(() => {
    if (filter) {
      // setIsGroupByJob(true);
    }
  }, [filter]);

  const handleClick = (candidate: any) => {
    dispatch(
      getCandidate({
        candidateData: candidate,
        candidateDataJobId:
          isGroupByJob || filter !== null ? candidate?.jobs?.[0]?.job_id : null,
      })
    );
    dispatch(
      listCandidates({
        candidatePgBtn: { ...candidatePgBtn, filter },
      })
    );
    onOpen();

    // if (isGroupByJob && candidate?.jobs?.[0]) {
    if (candidatePgBtn?.filter) {
      navigate(
        `/candidates/views/${candidatePage.currentPage}/${candidate.id}/about?jobId=${candidate?.jobs?.[0]?.job_id}`
      );
    } else if (filter !== null && candidate?.jobs?.[0]) {
      navigate(
        `/candidates/views/${candidatePage.currentPage}/${candidate.id}/about?jobId=${candidate?.jobs?.[0]?.job_id}`
      );
    } else {
      navigate(
        `/candidates/${params?.candidatesTabState}/${candidatePage.currentPage}/${candidate.id}/about`
      );
    }

    if (isGroupByJob) {
      reqGetCandidate({ id: candidate.id });
    }
  };

  const handleDeleteCandidate = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will delete the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDeleteCandidate({ id: candidate.id });
      await reqFilterList({});
      if (abortFilterControllerRef.current) {
        abortFilterControllerRef.current.abort();
      }

      abortFilterControllerRef.current = new AbortController();
      await reqPinList({
        signal: abortFilterControllerRef.current.signal,
      });
      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const handleHideCandidate = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will hide the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      // console.log('hidden Table');
      await reqToggleHide({ id: candidate.id });
      await reqFilterList({});
      if (abortFilterControllerRef.current) {
        abortFilterControllerRef.current.abort();
      }

      abortFilterControllerRef.current = new AbortController();
      await reqPinList({
        signal: abortFilterControllerRef.current.signal,
      });
      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const stateTab: any = params.candidatesTab;

  const handlePrevData = () => {
    setIsRefresh(false);
    const prevData = candidateSort.filter(
      (item: any) => item.rowId === rowId - 1
    )[0];
    if (prevData) {
      const candidateID = candidateSort.filter(
        (item: any) => item.rowId === rowId - 1
      )[0]?.id;
      setRowId((prevKey: number) => prevKey - 1);
      if (isGroupByJob && prevData?.jobs?.[0]) {
        navigate(
          `/candidates/views/${candidatePage.currentPage}/${candidateID}/${stateTab}?jobId=${prevData?.jobs[0]?.job_id}`
        );
      } else if (filter !== null && prevData?.jobs?.[0]) {
        navigate(
          `/candidates/views/${candidatePage.currentPage}/${candidateID}/${stateTab}?jobId=${prevData?.jobs[0]?.job_id}`
        );
      } else {
        navigate(
          `/candidates/${params?.candidatesTabState}/${candidatePage.currentPage}/${candidateID}/${stateTab}`
        );
      }
      dispatch(
        getCandidate({
          candidateData: prevData,
          candidateDataJobId:
            isGroupByJob || filter !== null ? prevData?.jobs[0]?.job_id : null,
        })
      );
      if (isGroupByJob) {
        reqGetCandidate({ id: candidateID });
      }
    }
  };

  const handleNextData = () => {
    setIsRefresh(false);
    const nextData = candidateSort.filter(
      (item: any) => item.rowId === rowId + 1
    )[0];
    if (nextData) {
      const candidateID = candidateSort.filter(
        (item: any) => item.rowId === rowId + 1
      )[0].id;
      setRowId((prevKey: number) => prevKey + 1);
      if (isGroupByJob && nextData?.jobs?.[0]) {
        navigate(
          `/candidates/views/${candidatePage.currentPage}/${candidateID}/${stateTab}?jobId=${nextData?.jobs[0]?.job_id}`
        );
      } else if (filter !== null && nextData?.jobs?.[0]) {
        navigate(
          `/candidates/views/${candidatePage.currentPage}/${candidateID}/${stateTab}?jobId=${nextData?.jobs[0]?.job_id}`
        );
      } else {
        navigate(
          `/candidates/${params?.candidatesTabState}/${candidatePage.currentPage}/${candidateID}/${stateTab}`
        );
      }
      dispatch(
        getCandidate({
          candidateData: nextData,
          candidateDataJobId:
            isGroupByJob || filter !== null ? nextData?.jobs[0]?.job_id : null,
        })
      );
      if (isGroupByJob) {
        reqGetCandidate({ id: candidateID });
      }
    }
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckWithRow, setIsCheckWithRow] = useState<isCheckWithRowType[]>(
    []
  );
  const [idList, setIdList] = useState([]);
  const [isIndeterminate, setisIndeterminate] = useState(false);

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    // setIsCheck(candidateSort.map((li: any) => li.id));

    if (!checked) {
      // setIsCheck([]);
      setIsCheckWithRow([]);
    } else {
      setIsCheckWithRow(
        candidateSort.map((li: any) => {
          return { id: li.id, rowId: li.rowId };
        })
      );
    }
  };

  //
  useEffect(() => {
    setIsCheckWithRow([]);
  }, [filter]);

  const handleCheck = async (e: any, rowId?: number) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheckWithRow([...isCheckWithRow, { id: ID, rowId: rowId }]);
    // setIsCheck([...isCheck, ID]);
    // console.log("setischeck", isCheck)
    if (!checked) {
      // setIsCheck(isCheck.filter((item) => item !== ID));
      setIsCheckWithRow(
        isCheckWithRow.filter(
          (item: any) => item.id !== ID || item.rowId !== rowId
        )
      );
    }
  };

  const handleSort = async (column_name: string) => {
    const newSortOrder = sortOrder ? 'ASC' : 'DESC';
    setSortOrder(!sortOrder);
    setIsSorted(true);
    setOrderBy({
      column_name: column_name,
      sort_order: newSortOrder,
    });
  };

  useEffect(() => {
    setisIndeterminate(false);
    // if (isCheck.length > 0 && isCheck.length < candidatePgBtn?.take) {
    //   setisIndeterminate(true);
    // }
    // if (isCheck.length <= 0) {
    //   setIsCheckAll(false);
    // }
    // if (isCheck.length === candidatePgBtn?.take) {
    //   setIsCheckAll(true);
    // }
    if (
      isCheckWithRow.length > 0 &&
      isCheckWithRow.length < candidatePgBtn?.take
    ) {
      setisIndeterminate(true);
    }
    if (isCheckWithRow.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheckWithRow.length === candidatePgBtn?.take) {
      setIsCheckAll(true);
    }
  }, [
    candidatePgBtn?.take,
    // isCheck,
    isCheckWithRow,
    isCheckAll,
  ]);

  const columnDict = {
    candidate_details: 'Candidate Details',
    contact: 'Contact',
    location: 'Location',
    resume_update_on: 'Resume Update on',
    work_status: 'Work Status',
    last_activity: 'Last Activity',
    associated_jobs: 'Associated Jobs',
    tags: 'Tags',
    distance: 'Location/Distance',
    submitted_on: 'Submitted on/Age',
    interview_status: 'Interview Status',
    interview_date: 'Interview Date',
    offered_date: 'Offered Date/Age',
    placement_date: 'Placement Date',
    candidate_start_date: 'Candidate Start Date',
    last_messaged_on: 'Last Messaged on',
    last_called_on: 'Last Called on',
  } as any;
  const [tableHeader, setTableHeader] = useState<{ label: any }[]>([
    {
      label: (
        <Th
          sx={{
            position: 'sticky',
            left: 0,
            bg: 'default.white.600',
            color: 'default.gray.600',
            boxShadow: 'inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)',
          }}
          key={`table-candidate-header-1`}
        >
          <Flex gap="30px">
            <Checkbox
              onChange={handleSelectAll}
              isIndeterminate={isIndeterminate}
              checked={isCheckAll}
              defaultChecked={false}
              colorScheme="purple"
            />
            <Box>Candidate Details</Box>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Contact</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" alignItems="center">
            Location
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
          key={`table-candidate-header-2`}
        >
          <Flex gap="10px" alignItems="center">
            Resume Update On
            <Flex
              onClick={() => handleSort('resume_update_on')}
              cursor="pointer"
            >
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
          key={`table-candidate-header-3`}
        >
          <Box>Work Status</Box>
        </Th>
      ),
    },
    // {
    //   label: (
    //     <Th
    //       sx={{
    //         bg: 'default.white.600',
    //         color: 'default.gray.600',
    //       }}
    //       key={`table-candidate-header-4`}
    //     >
    //       <Flex gap="10px" justifyContent="center" alignItems="center">
    //         Created on
    //         <Flex onClick={() => handleSort('created_at')} cursor="pointer">
    //           <FAIcon iconName="sort" />
    //         </Flex>
    //       </Flex>
    //     </Th>
    //   ),
    // },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
          key={`table-candidate-header-5`}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Last Activity
            <Flex cursor="pointer" onClick={() => handleSort('last_activity')}>
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },

    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
          key={`table-candidate-header-6`}
        >
          <Box>Associated Jobs</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
          key={`table-candidate-header-7`}
        >
          <Box>Tags</Box>
        </Th>
      ),
    },
  ]);
  const selectedColumns = useMemo(() => {
    if (candidateOthers?.[1]?.columns) {
      let sortedColumns = Object.entries(candidateOthers?.[1]?.columns)
        .filter(([key, value]) => value !== 0)
        .map(([key, value]) => ({ key, value }));
      sortedColumns.sort((a: any, b: any) => a.value - b.value);
      let newColumns = {} as any;
      sortedColumns.forEach((item: any) => (newColumns[item.key] = item.value));
      const header = [] as { label: any }[];
      const columns = [] as string[];
      Object.keys(newColumns).map((column: string, index: number) => {
        const formattedColumn = columnDict[column];
        columns.push(column);
        if (index === 0) {
          header.push({
            label: (
              <Th
                sx={{
                  position: 'sticky',
                  left: 0,
                  bg: 'default.white.600',
                  color: 'default.gray.600',
                  boxShadow: 'inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)',
                }}
                key={`table-candidate-header-1`}
              >
                <Flex gap="30px">
                  <Checkbox
                    onChange={handleSelectAll}
                    isIndeterminate={isIndeterminate}
                    checked={isCheckAll}
                    defaultChecked={false}
                    colorScheme="purple"
                  />
                  <Flex gap="10px" alignItems="center">
                    {formattedColumn}
                    {(column === 'resume_update_on' ||
                      column === 'last_activity' ||
                      column === 'submitted_on' ||
                      column === 'interview_date' ||
                      column === 'offered_date' ||
                      column === 'placement_date' ||
                      column === 'candidate_start_date' ||
                      column === 'last_messaged_on' ||
                      column === 'last_called_on') && (
                      <Flex
                        onClick={() =>
                          handleSort(
                            column == 'placement_date'
                              ? 'placement_date'
                              : column == 'candidate_start_date'
                              ? 'candidate_start_date'
                              : column
                          )
                        }
                        cursor="pointer"
                      >
                        <FAIcon iconName="sort" />
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Th>
            ),
          });
        } else {
          header.push({
            label: (
              <Th
                sx={{
                  bg: 'default.white.600',
                  color: 'default.gray.600',
                }}
              >
                <Flex gap="10px" alignItems="center">
                  {formattedColumn}
                  {(column === 'resume_update_on' ||
                    column === 'last_activity' ||
                    column === 'submitted_on' ||
                    column === 'interview_date' ||
                    column === 'offered_date' ||
                    column === 'placement_date' ||
                    column === 'candidate_start_date' ||
                    column === 'last_messaged_on' ||
                    column === 'last_called_on') && (
                    <Flex
                      onClick={() =>
                        handleSort(
                          column == 'placement_date'
                            ? 'placement_date'
                            : column == 'candidate_start_date'
                            ? 'candidate_start_date'
                            : column
                        )
                      }
                      cursor="pointer"
                    >
                      <FAIcon iconName="sort" />
                    </Flex>
                  )}
                </Flex>
              </Th>
            ),
          });
        }
      });
      setTableHeader(header);
      return columns;
    } else {
      return [];
    }
  }, [candidateOthers?.[1]?.columns]);

  const pageChange = async (page: number) => {
    let newParam = {
      ...candidatePgBtn,
      page: page,
    };

    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    );
  };

  const entryChange = (entries: number) => {
    let newParam = {
      ...candidatePgBtn,
      take: entries,
    };
    console.log({ newParam });

    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    );
  };

  const addTagClick = (id: any) => {
    setIsBulkAction(false);
    setCandidateId(id);
    onOpenTag();
  };

  const associateToJob = async (candidate: CandidateData) => {
    let list: any = [];
    // console.log('candidate', candidate);
    if (candidate.jobs.length > 0) {
      await Promise.all(
        candidate.jobs.map((job: candidateJobs) => {
          list.push(job.job_id);
        })
      );
    }
    // console.log('list', list);
    setAssocJobs(list);
    setCandidateId(candidate?.id);
    onOpenAssociate();
  };

  const editCandidate = async (candidate: any) => {
    // console.log("candidate ", candidate)
    await reqGetCandidate({ id: candidate.id });
    onOpenCandiate();
    setCandidateId(candidate.id);
  };

  const bulkReject = async () => {
    const candidateList = candidateSort.filter((candidate: any) =>
      isCheckWithRow.some(
        (obj: any) => obj.id === candidate?.id && obj.rowId === candidate?.rowId
      )
    );
    setIsBulkAction(true);
    let associateIdList: any = [];

    candidateList.map((candidate: any) => {
      if (candidate?.jobs?.length > 0) {
        associateIdList.push(Number(candidate?.jobs?.[0]?.assoc_id));
      }
    });
    if (associateIdList.length > 0) {
      const data = {
        reason: 14,
        idList: associateIdList,
      };
      reqBulkReject({ data });
    }
  };

  const bulkAssociateToJob = async () => {
    // console.log('went to bulk associate to job');
    setIsBulkAction(true);
    const candidateList = candidateSort.filter((candidate: CandidateData) =>
      // isCheck.includes(candidate.id)
      isCheckWithRow.some(
        (obj: any) => obj.id === candidate?.id && obj.rowId === candidate?.rowId
      )
    );
    candidateList.map(async (candidate: CandidateData) => {
      let list: any = [];
      if (candidate?.jobs?.length > 0) {
        await Promise.all(
          candidate.jobs.map((job: candidateJobs) => {
            list.push(job.job_id);
          })
        );
      }
      setAssocJobs(list);
      const uniqueIds = isCheckWithRow
        .map((li) => li.id) // Extracting all ids
        .filter((id, index, array) => array.indexOf(id) === index);
      setCandidateIdList(uniqueIds);
    });
    onOpenAssociate();
  };

  const bulkTags = () => {
    setIsBulkAction(true);
    onOpenTag();
  };

  const bulkEmail = async () => {
    setIsBulkAction(true);
    const candidateList = candidateSort?.filter((candidate: any) =>
      // isCheck.includes(candidate.id)
      isCheckWithRow.some(
        (obj: any) => obj.id === candidate?.id && obj.rowId === candidate?.rowId
      )
    );

    const emails = candidateList?.map((candidate: any) => {
      const candidateEmail = [...candidate?.emails]?.sort(
        (emailA: any, emailB: any) => {
          return emailB.primary - emailA.primary;
        }
      );
      return candidateEmail[0].email;
    });
    setEmailList(emails);
    setCandidateId(candidateList[0].id);
    // dispatch(getCandidate({ candidateData: candidateList[0] }));
    const data = {
      // candidate_id: isCheck[0],
      candidate_id: isCheckWithRow[0].id,
      job_id: params?.jobsId || candidateList[0].jobs?.[0]?.job_id || null,
    } as any;

    let listEmail = [] as any;

    await Promise.all(
      candidateList.map((list: any) => {
        if (list?.jobs[0]) {
          listEmail.push({
            id: list.id,
            job_id: list?.jobs[0]?.job_id,
          });
        } else {
          listEmail.push(list.id);
        }
      })
    );
    setIdList(listEmail);

    // console.log('data', data);

    await reqPair(data);
    onOpenSendEmail();
    await reqGetCandidate({ id: candidateList[0].id });
    // if (candidateList[0]?.jobs.length > 0) {
    //   await reqGetJob({ id: candidateList[0]?.jobs?.[0]?.id });
    // }
    // setIdList;
    // console.log('ischeck', candidateList);
  };

  const mergeCandidates = async () => {
    onOpenMergeCandidates();
    // await reqGetCandidate({ id: isCheck[0] });
    // await reqGetCandidate2({ id: isCheck[1] });
    await reqGetCandidate({ id: isCheckWithRow[0].id });
    await reqGetCandidate2({ id: isCheckWithRow[1].id });
    // setIsCheck([]);
    setIsCheckWithRow([]);
  };
  return (
    <Box px={6} pt={7} justifyContent="space-between">
      {
        // isCheck.length > 0
        isCheckWithRow.length > 0 && (
          <BulkAction
            count={isCheckWithRow.length}
            filter={candidatePgBtn?.filter}
            bulkAssociateToJob={bulkAssociateToJob}
            bulkEmail={bulkEmail}
            bulkChangeStatus={() => {}}
            bulkTags={bulkTags}
            bulkReject={bulkReject}
            isRejectLoading={resBulkReject.isLoading}
            all={true}
            mergeCandidates={mergeCandidates}
          />
        )
      }

      <TableContainer
        boxSizing="border-box"
        border="1px solid #EEEEEE"
        borderRadius="4px"
        height="70vh"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead
            sx={{ position: 'sticky', top: 0, zIndex: 1 }}
            key={`thead-candidate-0`}
          >
            <Tr bg="default.white.600">
              {tableHeader.map((title) => title.label)}
            </Tr>
          </Thead>
          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {resCandidates.isLoading ||
            resToggleHide.isLoading ||
            candidateLoading ? (
              <TrLoading rows={10} columns={tableHeader.length} />
            ) : (
              <>
                {candidateSort.map((candidate: any, key: number) => {
                  return (
                    <DynamicCandidateTR
                      candidate={candidate}
                      handleCheck={handleCheck}
                      isCheck={isCheck}
                      handleClick={() => {
                        handleClick(candidate);
                        setRowId(candidate?.rowId);
                      }}
                      associateToJob={associateToJob}
                      addTagClick={addTagClick}
                      editCandidate={editCandidate}
                      handleDeleteCandidate={(candidate: any) =>
                        handleDeleteCandidate(candidate)
                      }
                      handleHideCandidate={(candidate: any) =>
                        handleHideCandidate(candidate)
                      }
                      columns={selectedColumns}
                      statusChangeSuccessCb={(e) => {}}
                      isCheckWithRow={isCheckWithRow}
                      fromGroupByJobs={false}
                      fromJob={false}
                      key={`${key}-${candidate?.id}`}
                    />
                  );
                })}
              </>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {isOpen && (
        <CandidatesDrawer
          // key={"0"}
          isOpen={isOpen}
          onClose={onClose}
          rowId={rowId}
          setRowId={setRowId}
          totalCandidates={candidateSort?.length}
          handlePrevData={handlePrevData}
          handleNextData={handleNextData}
          isLoading={resGetCandidate.isLoading}
          candidates={candidateSort}
          isRefresh={isRefresh}
        />
      )}
      {isOpenMergeCandidates && (
        // Add merge candidates component
        <CandidatesMergeModal
          isOpen={isOpenMergeCandidates}
          onClose={onCloseMergeCandidates}
          candidate1={resGetCandidate}
          candidate2={resGetCandidate2}
        />
      )}
      {isOpenTag && (
        <CandidatesTagModal
          isOpen={isOpenTag}
          onClose={onCloseTag}
          id={candidateId}
          // idList={isCheck}
          idList={isCheckWithRow.map((li: isCheckWithRowType) => li.id)}
          isBulkTag={isBulkAction}
        />
      )}
      {isOpenAssociate && (
        <CandidatesAssociateJobModal
          isOpen={isOpenAssociate}
          onClose={onCloseAssociate}
          id={candidateId}
          idList={candidateIdList}
          assoc_jobs={assocJobs}
          isBulkAssociateJob={isBulkAction}
          onSuccess={() => associateJobSuccess()}
        />
      )}
      {isOpenCandidate && (
        // <CandidatesModal
        //   isOpen={isOpenCandidate}
        //   onClose={onCloseCandidate}
        //   edit={true}
        //   id={candidateId}
        // />
        <CandidatesModal
          isOpen={isOpenCandidate}
          onClose={onCloseCandidate}
          edit={true}
          id={Number(candidateId)}
          candidateLoading={resGetCandidate.isLoading}
        />
      )}
      {isOpenSendEmail && (
        <EmailsModal
          isOpen={isOpenSendEmail}
          onClose={onCloseSendEmail}
          email={''}
          id={candidateId}
          candidate_id={candidateId}
          job_id={candidateData?.jobs?.[0]?.job_id}
          allowedCategory={['Candidates']}
          idList={idList}
          emailList={isBulkAction ? emailList : []}
          isBulkEmail={isBulkAction}
        />
      )}
      <Pagination
        totalPages={candidatePage.lastPage}
        currentPage={candidatePage.currentPage}
        onPageChange={(page) => pageChange(page)}
        onEntryChange={(entries) => entryChange(entries)}
        totalEntries={candidatePage.count}
        currentCount={candidateSort?.length}
        targetCount={candidatePgBtn?.take}
      />
    </Box>
  );
};

export default CandidatesTable;
