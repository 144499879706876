import CreatableSelect from 'react-select/creatable';

export default function AtsCraetableSelDyn(FieldProps: any) {
  return (
    <CreatableSelect
      {...FieldProps.field}
      onChange={(option) => {
        // FieldProps.form.setFieldValue(FieldProps.field.name, option);
        if (typeof FieldProps.onChange === 'function') {
          FieldProps.onChange(option);
        }
      }}
      className={FieldProps.className}
      options={FieldProps.options}
      placeholder={FieldProps.placeholder}
      defaultValue={FieldProps.defaultValue}
      value={FieldProps.value}
      isMulti={FieldProps.isMulti}
      onInputChange={FieldProps.onInputChange}
      isClearable={FieldProps.isClearable}
      //   isDisabled={FieldProps.isLoading}
      isLoading={FieldProps.isLoading}
      loadOptions={FieldProps.loadOptions}
      noOptionsMessage={() =>
        FieldProps.isLoading ? 'Loading...' : 'No options found'
      }
      formatCreateLabel={FieldProps.formatCreateLabel}
      styles={FieldProps.styles}
      components={FieldProps.components}
    />
  );
}
