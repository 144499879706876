import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import JobsAddressModal from '../Jobs/Modal/JobAddress';
import {
  listClients,
  useDeleteClientLocationMutation,
} from 'store/client.slice';
import { useDispatch, useSelector } from 'react-redux';

const CustomOption = ({
  label,
  value,
  onEdit,
  innerProps,
}: {
  label: string;
  value: number;
  onEdit: () => void;
  innerProps: any;
}) => {
  const dispatch = useDispatch();

  const [reqDeleteLocation] = useDeleteClientLocationMutation();

  const handleRemoveAddress = () => {
    reqDeleteLocation({ id: value });
    dispatch(listClients({ selected_client_location: [] }));
  };

  return (
    <Flex
      sx={{ justifyContent: 'space-between', p: '16px', cursor: 'pointer' }}
      _active={{
        backgroundColor: '#4481F7',
        color: 'white',
      }}
      _hover={{
        backgroundColor: '#E0EBFD',
        color: '#1B202B',
      }}
    >
      <Box sx={{ fontWeight: label === 'Create New Address' && 600 }}>
        <Text
          onClick={(e) => {
            innerProps.onClick(e);
          }}
        >
          {label}
        </Text>
      </Box>
      {label !== 'Create New Address' && (
        <Flex ml="20px" gap="15px" alignItems="center">
          <Box
            sx={{ cursor: 'pointer', color: 'primary.800' }}
            onClick={onEdit}
          >
            <FAIcon iconName="pencil" />
          </Box>
          <Box
            sx={{ cursor: 'pointer', color: 'caution.800' }}
            onClick={() => {
              handleRemoveAddress();
            }}
          >
            <FAIcon iconName="trash" />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default function AtsSelectAddress(FieldProps: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selected_client_location } = useSelector(
    (state: any) => state.clients
  );
  const [addressData, setAddressData] = useState({});

  const handleEditOption = (data: any) => {
    setAddressData(data.data || {});
    onOpen();
  };

  // Sets the created location to formik values
  useEffect(() => {
    if (Object.keys(selected_client_location).length > 0) {
      FieldProps.form.setFieldValue(FieldProps.field.name, selected_client_location)
    }
  }, [selected_client_location?.value])
  return (
    <>
      <Select
        {...FieldProps.field}
        options={FieldProps.options}
        onChange={(option: any) => {
          if (option?.label != "Create New Address") {
            FieldProps.form.setFieldValue(FieldProps.field.name, option);
          }
        }}
        components={{
          Option: ({ label, data, innerProps }) => (
            <div
              {...innerProps}
              onClick={(e) => {
                label === 'Create New Address' && handleEditOption(data);
              }}
            >
              <CustomOption
                label={label}
                value={(data as any)?.value}
                onEdit={() => handleEditOption(data)}
                innerProps={innerProps}
              />
            </div>
          ),
        }}
        placeholder={FieldProps.placeholder}
        value={
          Object.keys(selected_client_location).length > 0
            ? selected_client_location
            : FieldProps.value
        }
        isClearable
        styles={FieldProps.styles}
      />
      {isOpen && (
        <JobsAddressModal
          isOpen={isOpen}
          onClose={onClose}
          addressData={addressData}
        />
      )}
    </>
  );
}
