import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import ClientsOverview from '../Tabs/Overview';
import ClientsContacts from '../Tabs/Contacts';
import ClientsJobs from '../Tabs/Jobs';
import ClientsAgreements from '../Agreement';
import ClientsSettings from '../Tabs/Settings';
import ClientsActivityLog from '../Tabs/ActivityLog';

import { getClient } from 'store/client.slice';

const MyClientsTabs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { MyClients, clientData } = useSelector((state: any) => state.clients);

  const tabIndex: any = {
    overview: 0,
    contacts: 1,
    jobs: 2,
    agreements: 3,
    settings: 4,
    'activity-log': 5,
  };

  const stateTab: any = params.clientsTab;
  const clientID = params.clientsId;
  const [clientTab, setClientTab] = useState(params.clientsTab);

  // useEffect(() => {
  //   if (clientID) {
  //     const getClientData = MyClients.filter(
  //       (client: any) => client.id === Number(clientID)
  //     )[0];
  //     console.log("MyClients", MyClients)
  //     console.log("getClientData", getClientData)
  //     dispatch(getClient({ clientData: getClientData }));
  //     console.log("went to my clients tabs")
  //   }
  // }, [clientID]);

  const handleRoute = (route: string) => {
    navigate(`/clients/${params.tab}/${clientData?.id}/${route}`);
    setClientTab(route);
  };
  return (
    <Tabs colorScheme="purple" defaultIndex={tabIndex[stateTab]}>
      <TabList fontSize="md" fontWeight="bold">
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('overview')}
        >
          Overview
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('contacts')}
        >
          Contacts
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('jobs')}
        >
          Jobs
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('agreements')}
        >
          Agreements
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('settings')}
        >
          Settings
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('activity-log')}
        >
          Activity Logs
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>{clientTab === 'overview' && <ClientsOverview />}</TabPanel>
        <TabPanel>{clientTab === 'contacts' && <ClientsContacts />}</TabPanel>
        <TabPanel>{clientTab === 'jobs' && <ClientsJobs />}</TabPanel>
        <TabPanel>
          {clientTab === 'agreements' && <ClientsAgreements />}
        </TabPanel>
        <TabPanel>{clientTab === 'settings' && <ClientsSettings />}</TabPanel>
        <TabPanel>
          {clientTab === 'activity-log' && <ClientsActivityLog />}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default MyClientsTabs;
