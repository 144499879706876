import { useToast, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useCreateNotesMutation } from 'store/leadnotes.slice';
import {
  useListLeadsMutation,
  useGetLeadsMutation,
  useInactiveLeadsMutation,
  useReActivateMutation,
  useCancelApprovalMutation,
  getLead,
  useClaimLeadMutation,
} from 'store/leads.slice';
import { cleanUpload } from 'store/uploads.slice';
import { swalContent, LeadsTypes } from 'types';
import { authStorage } from 'utils/localStorage';
import { AtsConfirm } from 'utils/swal';
import { LeadProps } from './types';

export function useDrawerServices({ isOpen, onClose }: LeadProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();
  const auth = authStorage();
  const { leads, leadData, leadPgBtn } = useSelector(
    (state: any) => state.leads
  );

  const [reqLeadList] = useListLeadsMutation();
  const [reqleads, resleads] = useGetLeadsMutation();
  const [reqNotes, resNotes] = useCreateNotesMutation();
  const [reqInactiveLead, resInactiveLead] = useInactiveLeadsMutation();
  const [reqActivate, resActivate] = useReActivateMutation();
  const [reqCancelApproval, resCancelApproval] = useCancelApprovalMutation();
  const [reqClaim, resClaim] = useClaimLeadMutation();
  const { clientNav, clientTab } = useSelector((state: any) => state.clients);
  const [claimLoading, setClaimLoading] = useState(false);

  useEffect(() => {
    setClaimLoading(resClaim.isLoading);
  }, [resClaim.isLoading]);

  const {
    isOpen: isEditLeadOpen,
    onOpen: onEditLeadOpen,
    onClose: onEditLeadClose,
  } = useDisclosure();

  const {
    isOpen: isConvertClientOpen,
    onOpen: onConvertClientOpen,
    onClose: onConvertClientClose,
  } = useDisclosure();

  const {
    isOpen: isConvertLeadOpen,
    onOpen: onConvertLeadOpen,
    onClose: onConvertLeadClose,
  } = useDisclosure();

  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure();

  const stateTab: any = params.leadsTab;
  const leadID = params.leadsId || leadData?.id;

  // INITIALIZE VALUES
  useEffect(() => {
    // TO RESET WITH PREFIX FOR ATTACHMENTS (e.g. LEADS MODAL, CONVERT TO CONTACT, etc.)
    // DO NOT INCLUDE GET LEAD AS IT IS CALLED IN TABLE
    dispatch(cleanUpload({ withPrefix: false }));
    reqleads({ id: leadID });
  }, [leadID]);

  const handleOpenDrawer = () => {
    if (leadID) {
      return true;
    }
    return isOpen;
  };

  let content: swalContent = {
    title: 'Are you sure?',
    text: 'You are about to mark this lead as inactive, continue?',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const handleInactiveLead = async (lead: LeadsTypes) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqInactiveLead({ id: lead.id });
      dispatch(getLead({ leadData: {} }));
      onClose();
      navigate('/leads');
    }
  };

  const converClientClick = (lead: any) => {
    dispatch(getLead({ leadData: lead }));
    onConvertClientOpen();
  };

  const [quilValue, setquilValue] = useState('');
  const [isNewButton, setIsNewButton] = useState(false);
  const [isSendApproval, setIsSendApproval] = useState(false);
  const [hover, setHover] = useState(false);

  const type = leadData?.leadType?.type;
  const status = leadData?.leadStatus?.lead_status;

  const createNotes = async () => {
    let data = {
      note: quilValue,
    };
    await reqNotes({ data, id: leadID });
  };

  useEffect(() => {
    if (
      resInactiveLead.isSuccess ||
      resCancelApproval.isSuccess ||
      resActivate.isSuccess
    ) {
      reqLeadList({ data: leadPgBtn });
    }
  }, [
    resInactiveLead.isSuccess,
    resCancelApproval.isSuccess,
    resActivate.isSuccess,
  ]);

  useEffect(() => {
    if (resNotes.isSuccess) {
      reqleads({ id: leadID });
      setquilValue('');
    }
  }, [resNotes.isSuccess]);

  useEffect(() => {
    if (resActivate.isSuccess) {
      reqLeadList(leadPgBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (
          resActivate.isSuccess &&
          resActivate.data.data.message.includes('Lead has been reactivated')
        ) {
          title = 'Mark as Active';
          description = 'Lead status was changed to Active';
        }

        if (
          resActivate.isSuccess &&
          resActivate.data.data.message.includes('Failed to reactivate')
        ) {
          title = 'Failed to reactivate';
          description = 'No logs found to retrace it. Status still Inactive';
          status = 'warning';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [resActivate.isSuccess]);

  useEffect(() => {
    if (resInactiveLead.isSuccess) {
      reqLeadList(leadPgBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (
          resInactiveLead.isSuccess &&
          resInactiveLead.data.data.message.includes(
            'Lead has been deactivated'
          )
        ) {
          title = 'Mark as Inactive';
          description = 'Lead status was changed to Inactive';
          status = 'info';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [resInactiveLead.isSuccess]);

  useEffect(() => {
    if (resCancelApproval.isSuccess) {
      reqLeadList(leadPgBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (
          resCancelApproval.isSuccess &&
          resCancelApproval.data.data.message.includes(
            'Approval has been canceld'
          )
        ) {
          title = 'Cancel Approval';
          description = 'Lead approval was cancelled';
          status = 'info';
        }

        if (
          resCancelApproval.isSuccess &&
          resCancelApproval.data.data.message.includes(
            'Failed to cancel Approval'
          )
        ) {
          title = 'Failed to cancel Approval';
          description = 'There is no logs to retrace';
          status = 'warning';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [resCancelApproval.isSuccess]);

  const sendForApproval = (lead: any) => {
    dispatch(getLead({ leadData: lead }));
    setIsSendApproval(true);
    onConvertLeadOpen();
  };

  const cancelApprovalClick = async (lead: any) => {
    content.text = 'You are about to cancel this approval, continue?';
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqCancelApproval({ id: lead.id });
    }
  };

  const marAsActiveClick = async (lead: any) => {
    content.text = 'You are about to mark this lead as active, continue?';
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqActivate({ id: lead.id });
    }
  };

  useEffect(() => {
    if (
      (type === 'Contact' && status === 'Approval Pending') ||
      (type === 'Contact' && status === 'Active') ||
      (type === 'Lead' && status === 'Approval Pending') ||
      (type === 'Qualified' && status === 'Approval Pending') ||
      status === 'Inactive'
    ) {
      setIsNewButton(true);
    } else {
      setIsNewButton(false);
    }
  }, [status, type]);

  const showLeadDrawer = (): boolean => {
    // leadData should not be an empty object {}
    // leadData should have a value not undefined
    // leadData should have owner_id or user_id same as user
    // lead should not be deleted
    // lead should not be a contact => called only in clients
    return (
      Object.keys(leadData).length !== 0 &&
      leadData.constructor === Object &&
      leadData &&
      leadData?.lead_type_id !== 4 &&
      leadData?.lead?.deleted_at === null
    );
  };

  const ClaimLead = async (id: number) => {
    reqClaim({ id });
  };

  return {
    type,
    leadData,
    onEditLeadOpen,
    handleInactiveLead,
    converClientClick,
    sendForApproval,
    cancelApprovalClick,
    onConvertLeadOpen,
    marAsActiveClick,
    showLeadDrawer,
    clientNav,
    clientTab,
    handleOpenDrawer,
    setHover,
    hover,
    status,
    onOpenEmail,
    isNewButton,
    isEditLeadOpen,
    onEditLeadClose,
    isConvertClientOpen,
    onConvertClientClose,
    isConvertLeadOpen,
    onConvertLeadClose,
    isSendApproval,
    setIsSendApproval,
    stateTab,
    quilValue,
    setquilValue,
    createNotes,
    isOpenEmail,
    onCloseEmail,
    resleads,
    ClaimLead,
    resClaim,
  };
}
