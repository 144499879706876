import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';

import LeadsDetails from './TabPanel/Details';
import { LeadsJobs } from './TabPanel/Jobs';
import { LeadsAgreements } from './TabPanel/Agreements';
import { LeadsAttachments } from './TabPanel/Attachments';
import { LeadsActivityLog } from './TabPanel/ActivityLog';

import { tabListIndex } from './constants';
import { useTabListServices } from './useTabListServices';
import { LeadsNotes } from './TabPanel/Notes';

export const LeadsTabList = () => {
  const { stateTab, handleRoute, activeTab } = useTabListServices();
  return (
    <Tabs colorScheme="purple" defaultIndex={tabListIndex[stateTab]}>
      <TabList fontSize="md" fontWeight="bold">
        <Tab
          onClick={() => handleRoute('details')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Details
        </Tab>
        <Tab
          onClick={() => handleRoute('jobs')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Jobs
        </Tab>
        <Tab
          onClick={() => handleRoute('agreements')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Agreements
        </Tab>
        <Tab
          onClick={() => handleRoute('attachments')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Attachments
        </Tab>
        <Tab
          onClick={() => handleRoute('activity-log')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Activity Log
        </Tab>
        <Tab
          onClick={() => handleRoute('notes')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Notes
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>{activeTab === 'details' && <LeadsDetails />}</TabPanel>
        <TabPanel>{activeTab === 'jobs' && <LeadsJobs />}</TabPanel>
        <TabPanel>{activeTab === 'agreements' && <LeadsAgreements />}</TabPanel>
        <TabPanel>
          {activeTab === 'attachments' && <LeadsAttachments />}
        </TabPanel>
        <TabPanel>
          {activeTab === 'activity-log' && <LeadsActivityLog />}
        </TabPanel>
        <TabPanel>{activeTab === 'notes' && <LeadsNotes />}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};
