import { Box, InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';

interface SearchBarProps {
  search: string;
  setSearch: any;
  placeholder: string;
  handleOnChange: (e: any) => void;
}

export const SearchBar = ({
  search,
  setSearch,
  placeholder,
  handleOnChange,
}: SearchBarProps) => {
  return (
    <Box w="100%">
      <InputGroup
        sx={{
          input: {
            border: `1px solid ${!search ? '#E7EDF4' : '#6930CA'}`,
            borderRadius: '6px',
            bgColor: '#FFFFFF',
            fontSize: '14px',
            fontWeight: 700,
            color: '#6930CA',
          },
        }}
      >
        <InputLeftElement
          cursor="pointer"
          children={<FAIcon iconName="search" />}
          color="#718096"
        />
        <Input
          type="search"
          placeholder={placeholder}
          id="search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            if (e.target.value === '') {
              handleOnChange(e);
            }
          }}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOnChange(e);
            }
          }}
          _placeholder={{ fontWeight: 400, fontSize: '14px', color: '#718096' }}
          _hover={{
            border: '1px solid #6930CA',
          }}
          _focus={{
            border: '1px solid #6930CA',
            boxShadow: 'none',
            color: '#6930CA',
          }}
        />
      </InputGroup>
    </Box>
  );
};
