import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import * as Yup from 'yup';

import { useToast, useDisclosure } from '@chakra-ui/react';

import { useViewClientMutation } from 'store/client.slice';
import {
  useGetLeadSourceMutation,
  useGetCountriesMutation,
  useGetJobSpecsMutation,
} from 'store/constant.slice';
import {
  useListLeadsMutation,
  useGetLeadsMutation,
  useToClientMutation,
  useByClientMutation,
} from 'store/leads.slice';
import { uploadList, cleanUpload } from 'store/uploads.slice';
import { useUserListMutation } from 'store/user.slice';

import { changeUploadName } from 'utils/newFilename';
import { addressFormat, leadSourceFormat } from 'utils/utilFormat';
import { LeadsConvertClientModalProps } from './types';

export function useConvertClientServices({
  isOpen,
  onClose,
}: LeadsConvertClientModalProps) {
  const dispatch = useDispatch();
  const params = useParams();
  const toast = useToast();

  const { leadData, leadClient, leadPgBtn } = useSelector(
    (state: any) => state.leads
  );
  const { clientData } = useSelector((state: any) => state.clients);
  const { uploaded, prefix, withPrefix, uploading } = useSelector(
    (state: any) => state.uploads
  );

  const { countries, lead_source, job_specs } = useSelector(
    (state: any) => state.constants
  );

  const { userList } = useSelector((state: any) => state.user);

  const [reqLeads] = useListLeadsMutation();
  const [reqGetLeads] = useGetLeadsMutation();
  const [reqClient, resClient] = useToClientMutation();
  const [reqLeadClient] = useByClientMutation();
  const [reqUser, resUser] = useUserListMutation();
  const [reqClientData, resClientData] = useViewClientMutation();
  const [reqLeadSource] = useGetLeadSourceMutation();
  const [reqCountry] = useGetCountriesMutation();
  const [reqSpecs] = useGetJobSpecsMutation();

  let [managers, setManager] = useState([]);

  const [jobSpecsDefOption, setJobSpecsDefOption] = useState({});
  const [jobSpecsOption, setJobSpecsOption] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [selectOption, setSelectOption] = useState([]);
  const [leadSrouceList, setLeadSrouceList] = useState([]);
  const [countryDefOption, setCountryDefOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);

  const leadID = params.leadsId;

  useEffect(() => {
    reqSpecs({});
  }, [reqSpecs]);

  useEffect(() => {
    reqLeadSource({});
    reqCountry({});
  }, [leadID, isOpen]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const leadClientData: any = Object.values(leadClient).filter(
    (lead: any) => lead.id === leadData?.id
  );

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });

        if (item.code === 'us') {
          setCountryDefOption([
            {
              label: item.country,
              value: item.id,
              code: item.code,
            },
          ]);
        }
      });
    }
    setCountryOption(options);
  }, [countries]);

  useEffect(() => {
    // leadSrouceList, setLeadSrouceList;
    let options: any = [];
    if (lead_source?.length > 0) {
      lead_source.map((item: any) => {
        options.push({
          label: item.source,
          value: item.id,
        });
      });
    }
    setLeadSrouceList(options);
  }, [lead_source]);

  useEffect(() => {
    reqUser({});
    dispatch(uploadList({ prefix: 'Attachment' }));
    dispatch(cleanUpload({ withPrefix: false }));
    dispatch(cleanUpload({ attachments: [] }));
    dispatch(cleanUpload({ uploaded: [] }));
    reqLeadClient({ id: leadData?.client_id });

    if (resClient.isSuccess) {
      onClose();
      reqLeads({ data: leadPgBtn });

      try {
        const description = `Lead was successfully converted to a contact`;

        toast({
          title: 'Request to Convert',
          description: description,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
    if (resClient.isError) {
      let errorMessage: any = resClient.error;
      if (errorMessage['data']['error'] !== undefined) {
        setErrorMessage(errorMessage['data']['error']['message']);
      } else {
        try {
          setErrorMessage(errorMessage['data']['message']);
        } catch (e) {
          console.log('e', e);
          setErrorMessage('Server Error');
        }
      }
      onOpen();
    }
  }, [
    leadID,
    resClient.isError,
    resClient.isSuccess,
    leadData?.client_id,
    resClient.error,
  ]);

  useEffect(() => {
    reqClientData({ id: leadData?.client_id });
    reqGetLeads({ id: leadData?.id });
  }, []);

  useEffect(() => {
    let userOption: any = [];
    if (resUser.isSuccess) {
      if (resUser.data.data.length > 0) {
        resUser.data.data.map(async (data: any) => {
          await userOption.push({
            value: data.id,
            label: [data.first_name, data.last_name].join(' '),
          });
        });
        setSelectOption(userOption);
      }
    }
  }, [resUser.isSuccess]);

  useEffect(() => {
    let options: any = [];
    if (job_specs?.length > 0) {
      job_specs.map((item: any) => {
        options.push({
          label: item.specification,
          value: item.id,
        });
        if (item.id === leadData.spec_id) {
          setJobSpecsDefOption({
            label: item.specification,
            value: item.id,
          });
        }
      });
    }
    setJobSpecsOption(options);
  }, [job_specs]);
  useEffect(() => {
    if (clientData?.manager?.length > 0) {
      let options: any = [];
      clientData?.manager.map((data: any) => {
        options.push({
          value: data?.user?.id,
          label: [data?.user?.first_name, data?.user?.last_name].join(' '),
        });
      });
      setManager(options);
    }
  }, [clientData]);

  useEffect(() => {
    let userOption: any = [];

    if (resClientData.isSuccess) {
      const managersId: any = resClientData?.data?.data?.manager?.map(
        (a: any) => a.user_id
      );
      if (resUser.isSuccess) {
        if (resUser.data.data.length > 0) {
          resUser.data.data.map(async (data: any) => {
            if (!managersId?.includes(data.id)) {
              await userOption.push({
                value: data.id,
                label: [data.first_name, data.last_name].join(' '),
              });
            }
          });
          setSelectOption(userOption);
        }
      }
    }
  }, [
    resUser?.data?.data,
    resUser.isSuccess,
    resClientData?.isSuccess,
    resClientData?.data?.data,
  ]);

  const formValues = {
    client_name: leadData !== undefined ? leadData.company : '',
    first_name: leadData !== undefined ? leadData.first_name : '',
    last_name: leadData !== undefined ? leadData.last_name : '',
    address: addressFormat(leadClientData[0]?.client.address, countries) || [],
    website: leadData !== undefined ? leadData.website : '',
    email: leadData !== undefined ? leadData.primary_email : '',
    personal_phone: leadData !== undefined ? leadData.personal_phone : '',
    work_phone: leadData !== undefined ? leadData.work_phone : '',
    extension: leadData !== undefined ? leadData.extension : '',
    linkedIn_url: leadData !== undefined ? leadData.linked_in_url : '',
    title: leadData !== undefined ? leadData.title : '',
    owner_id: leadData !== undefined ? leadData.owner_id : 0,
    isMultipleAccountManager: 'false',
    manager: managers,
    notes: '',
    lead_source: leadSourceFormat(leadData?.lead_source),
    specification_id:
      leadData !== undefined ? leadData.spec_id : (undefined as any),
  };
  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      client_name: Yup.string().required('Client name is required.'),
      first_name: Yup.string()
        .required('First name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'First name is invalid.'),
      last_name: Yup.string()
        .required('Last name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'Last name is invalid.'),
      address: Yup.array()
        .of(
          Yup.object().shape({
            location: Yup.string().required('Location is required.'),
            address: Yup.string(),
            city: Yup.string().required('City is required.'),
            state: Yup.string().required('State is required.'),
            country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
            zip_code: Yup.string(),
            primary: Yup.boolean(),
          })
        )
        .min(1, 'Address is required.'),
      website: Yup.string().required('Website is required.'),
      email: Yup.string()
        .email('Invalid email.')
        .required('Email is required.'),
      personal_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Personal phone is invalid'
      ),
      work_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Work phone is invalid'
      ),
      extension: Yup.string().matches(/^[\d ()+-]+$/gi, 'Extension is invalid'),
      linkedIn_url: Yup.string(),
      title: Yup.string().required('Title is required.'),
      owner_id: Yup.string().required('Lead owner is required.'),
      isMultipleAccountManager: Yup.string(),
      manager: Yup.array().min(1, 'Pick at least 1 manager').nullable(),
      notes: Yup.string(),
      // lead_source: Yup.array().min(1, 'Pick at least 1 lead').nullable(),
      lead_source: Yup.object().shape({
        label: Yup.string().required('Pick at least 1 source'),
        value: Yup.number(),
      }),
      status: Yup.number(),
      specification_id: Yup.number()
        .required('Industry is required.')
        .min(1, 'Invalid level.'),
    })
  );

  const onSubmit = async (data: any) => {
    data['attachments'] = withPrefix
      ? await changeUploadName(uploaded, prefix)
      : uploaded;
    if (data.manager[0] === undefined) {
      let manager: any = [];
      manager.push(data.manager);
      data['manager'] = manager;
    }
    data.address?.sort((a: any, b: any) =>
      a.primary && !b.primary ? -1 : !a.primary && b.primary ? 1 : 0
    );
    reqClient({ data, id: leadData.id });
  };

  const handleAddressSelection = (
    index: number,
    setFieldValue: any,
    values: any
  ) => {
    setFieldValue(
      'address',
      values.address.map((address: any, idx: any) => ({
        ...address,
        primary: idx === index,
      }))
    );
  };
  return {
    isOpenAlert,
    errorMessage,
    onCloseAlert,
    formValues,
    validationSchema,
    onSubmit,
    jobSpecsOption,
    jobSpecsDefOption,
    setJobSpecsDefOption,
    handleAddressSelection,
    countryOption,
    leadData,
    userList,
    leadSrouceList,
    selectOption,
    uploading,
  };
}
