import {
  Box,
  Button as ChakraButton,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalFooter,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import * as Yup from 'yup';
import Select from 'react-select';
import ScrollToFieldError from 'components/app/ScrollError';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  listTemplate,
  useRawListTemplateConfirmationMutation,
} from 'store/template.slice';
import { clientSelectType, fileUploadedInt, InterviewPreviewInt } from 'types';
import { BsEyeFill } from 'react-icons/bs';
import PreviewDetails from './previews/Details';
import NotesModal from '../../../Modals/Notes';
import moment from 'moment';
import replacePlaceholders from 'utils/textPlaceholders';
import { useAIGeneratorServices } from 'components/app/AIGenerator/useAIGeneratorServices';
import AtsEmailBody from 'components/app/Global/Email/AIBody';
import TemplateAddModal from 'components/app/Global/TemplateModal';
import FAIcon from 'components/lib/FAIcon';
import { uploadList } from 'store/uploads.slice';

interface initialvaluesInt {
  template: any;
  subject: string;
  body: string;
  attachment: any;
  primary: boolean;
}

interface InviteMessageProps {
  initialvalues: initialvaluesInt;
  onSubmit: (e: any) => void;
  onPrev: (data: any) => void;
  placeholders: (body: any) => string | null;
  loading: boolean;
  userOptions: any;
  jobData: any;
  candidateData: any;
  schedInterview: string;
  candidatePairing: (e: any) => void;
  submitDetails: any;
  onClose: any;
}

const InviteMessage = ({
  initialvalues,
  onSubmit,
  onPrev,
  placeholders,
  loading,
  userOptions,
  jobData,
  candidateData,
  schedInterview,
  candidatePairing,
  submitDetails,
  onClose,
}: InviteMessageProps) => {
  const dispatch = useDispatch();

  const { placehoderPair, templateRawList } = useSelector(
    (state: any) => state.template
  );
  const {
    attachments: attachment,
    attachmentInvite,
    uploading,
  } = useSelector((state: any) => state.uploads);

  const [reqList, resList] = useRawListTemplateConfirmationMutation();

  const [inivitieInitial, setInitialValue] = useState(initialvalues);
  const [template, setTemplate] = useState([]);
  const [attachments, setAttachments] = useState(attachmentInvite);
  const [company, setCompany] = useState<clientSelectType | object>({});
  const [forceRender, setForceRender] = useState(0); // Force re-render

  useEffect(() => {
    setInitialValue(initialvalues);
  }, [initialvalues]);

  useEffect(() => {
    setAttachments(attachmentInvite || attachment);
  }, [attachment, attachmentInvite]);

  useEffect(() => {
    dispatch(
      uploadList({
        attachments: attachmentInvite,
      })
    );
  }, [attachmentInvite]);

  const [previewOpen, setPreviewOpen] = useState(true);

  const { resumes } = useSelector((state: any) => state.candidateResume);

  useEffect(() => {
    // calling all template fro confirmation
    const getData = async () => {
      await reqList({ id: 4 });
    };
    getData();
  }, []);

  useEffect(() => {
    const popluateTemplates = async () => {
      let option: any = [];
      if (templateRawList.length > 0) {
        await Promise.all(
          templateRawList.map((item: any) => {
            let pushed = false;
            if (submitDetails.mode_id === item.mode_id) {
              pushed = true;
            }

            // for On-site type
            if (submitDetails.type_id === 3 && item.type_id === 3) {
              pushed = true;
            }

            if (pushed) {
              option.push({
                value: item.id,
                label: item.title,
                subject: item.subject,
                body: item.body,
                attachments: item.attachments,
              });
            }
          })
        );
      }
      if (option.length >= 1) {
        setInitialValue((prevState) => ({
          ...prevState,
          subject: inivitieInitial.subject || option[0]?.subject,
          body: inivitieInitial.body || option[0]?.body,
          attachment: inivitieInitial.attachment || option[0]?.attachments,
          template: option[0],
        }));

        setAttachments(option[0]?.attachments);
        setForceRender((prev) => prev + 1); // Force re-render
      }
      setTemplate([
        ...option,
        {
          value: null,
          label: 'Create New Template',
          subject: '',
          body: '',
          attachments: null,
        },
      ]);
      dispatch(
        listTemplate({
          aiTemplate: [
            ...option,
            {
              value: null,
              label: 'Create New Template',
              subject: '',
              body: '',
              attachments: null,
            },
          ],
        })
      );
    };
    popluateTemplates();
  }, [templateRawList]);

  useEffect(() => {
    if (jobData) {
      // console.log("jobData?.client_id", jobData?.client_id)
      setCompany({
        label: jobData ? jobData?.client?.name : '',
        value: jobData ? jobData?.client_id : '',
      });
    }
  }, [jobData]);

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      body: Yup.string().when('auto_interview', {
        is: false,
        then: Yup.string(),
        otherwise: Yup.string().required('Body is required'),
      }),
      subject: Yup.string().when('auto_interview', {
        is: false,
        then: Yup.string(),
        otherwise: Yup.string().required('Subject is required'),
      }),
      primary: Yup.boolean(),
    })
  );

  const ConfirmSubmit = (data: any) => {
    const param = {
      ...data,
      attachment: attachment || attachmentInvite,
    };

    onSubmit(param);
    if (attachment && attachment.length > 0) {
      dispatch(
        uploadList({
          attachmentInvite: attachment,
        })
      );
    }

    dispatch(
      uploadList({
        uploaded: [],
        attachments: [],
      })
    );
  };

  const ClickBack = (values: any) => {
    const data = {
      ...values,
      attachment: attachments,
    };
    onPrev(data);
    // console.log('values', values);
  };

  const {
    isOpen: isOpenPreview,
    onOpen: onOpenPreview,
    onClose: onClosePreview,
  } = useDisclosure();
  const {
    isOpen: isOpenNotes,
    onClose: onCloseNotes,
    onOpen: onOpenNotes,
  } = useDisclosure();

  const PanelMembers = (members: any) => {
    let panels = [] as string[];
    if (members?.length > 0) {
      members.map((item: any) => item);
    }

    return panels;
  };

  const primaryResumes = () => {
    const res = resumes.find((resume: any) => resume.is_primary === true);
    return res?.original_file_name || null;
  };

  const prevAttachments = (primary: boolean) => {
    const primaryResume = candidateData?.primary_resume || primaryResumes();

    let att = [] as string[];
    if (primary) att.push(primaryResume);

    if (attachments?.length > 0) {
      attachments.map((item: any) => {
        att.push(item.original_file_name);
      });
    }
    return att;
  };

  const [PrevParam, setPrevParam] = useState<InterviewPreviewInt>();

  const getPrimary = () => {
    let email = '';
    try {
      const firstemail =
        candidateData?.emails?.length > 0 ? candidateData?.emails[0] : null;
      const data =
        candidateData?.emails?.find((item: any) => item.primary === 1) ||
        firstemail;
      email = data?.email || '';
    } catch (e) {}
    return email;
  };

  const placeholdersDetails = (text: string, placeholderPair: any) => {
    let data = replacePlaceholders(text, placeholderPair);
    if (
      submitDetails.meeting_type === 'Video Interview' &&
      submitDetails.meeting_mode.label === 'Microsoft Teams' &&
      submitDetails.meeting_location === ''
    ) {
      data = data.replace(
        /\{\{interview_location\}\}/i,
        'https://teams.microsoft.com/l/meetup-join/'
      );
    }

    return data;
  };

  const locationPlaceholderPopulate = () => {
    if (submitDetails.meeting_type === 'Phone Interview') {
      return submitDetails?.phones || '';
    }
    if (
      submitDetails.meeting_type === 'Video Interview' &&
      submitDetails.meeting_mode.label === 'Microsoft Teams' &&
      submitDetails.meeting_location === ''
    ) {
      return '{{interview_location}}';
    } else {
      return submitDetails.meeting_location;
    }
  };

  const locationPopulate = () => {
    if (
      submitDetails.meeting_type === 'Video Interview' &&
      submitDetails.meeting_mode.label === 'Microsoft Teams' &&
      submitDetails.meeting_location === ''
    ) {
      return 'https://teams.microsoft.com/l/meetup-join/';
    } else {
      return submitDetails.meeting_location || submitDetails.phones;
    }
  };

  const PreviewClick = (values: any) => {
    let meetingDate = moment(submitDetails.meeting_date).format('YYYY-MM-DD');
    let startTime = moment(`${meetingDate} ${submitDetails.meeting_start}`);
    let endTime = moment(`${meetingDate} ${submitDetails.meeting_end}`);

    let formattedDate = startTime.format('dddd, MMMM D'); // "Monday, February 12"
    let formattedStartTime = startTime.format('h:mm A'); // "11:30 AM"
    let formattedEndTime = endTime.format('h:mm A'); // "12:00 PM"
    let timeZone = startTime.tz(submitDetails.meeting_timezone).format('z'); // "PST" - Note: This might vary based on DST
    const dateTime = `${formattedDate} at ${formattedStartTime} to ${formattedEndTime} ${timeZone}`;

    const interviewParam = {
      interview_type: submitDetails.meeting_type || '',
      interview_location: locationPlaceholderPopulate() || '',
      // interview_location: submitDetails.meeting_location || '',
      interview_date: dateTime || '',
    };
    const placeholderPair = {
      ...placehoderPair,
      ...interviewParam,
    };
    candidatePairing(placeholderPair);
    const param = {
      body: placeholdersDetails(values.body, placeholderPair),
      to: getPrimary(),
      panels: submitDetails.panel_members,
      mode: submitDetails.meeting_type || '',
      location: locationPopulate() || values.phones?.value || '',
      subject: placeholdersDetails(values.subject, placeholderPair),
      timezone: submitDetails.meeting_timezone?.value || '',
      date_time: dateTime.toString(),
      attachments: prevAttachments(values.primary),
    } as InterviewPreviewInt;

    setPrevParam(param);
    onOpenPreview();
  };

  const {
    hasAIGenerate,
    setHasAIGenerate,
    setIsGenerate,
    handleValues,
    handleAIGenerator,
    setIsError,
    isMaximize,
  } = useAIGeneratorServices({ hasType: 'Candidate' });

  const {
    isOpen: addOpen,
    onOpen: addOnOpen,
    onClose: addOnClose,
  } = useDisclosure();

  const [placehoder, setPlaceHoders] = useState(placehoderPair);

  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);

  const placholders = (text: string) => {
    return replacePlaceholders(text, placehoder);
  };

  return (
    <>
      <Formik
        initialValues={inivitieInitial}
        validationSchema={validationSchema}
        onSubmit={ConfirmSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <ScrollToFieldError />

            <Flex
              justifyContent="center"
              gap="10px"
              sx={{
                '& #ai': {
                  overflowY: 'scroll',
                  height: '80vh',

                  '& ::-webkit-scrollbar': {
                    display: 'none',
                  },
                },
              }}
            >
              <Stack
                spacing="10px"
                sx={{
                  display: hasAIGenerate && isMaximize ? 'none' : 'flex',
                  position: 'relative',
                  borderRadius: '6px',
                  w: hasAIGenerate !== '' ? '65%' : '100%',
                  m: '8px 16px',
                  mt: hasAIGenerate && '-2px',
                  mr: hasAIGenerate && 0,
                  mb: '50px',
                }}
              >
                <Stack
                  sx={{
                    bgColor: '#FFF',
                    borderRadius: '6px',
                    p: '16px',
                  }}
                >
                  <FormControl
                    isInvalid={Boolean(!!errors.subject && touched.subject)}
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <FormLabel fontSize="14px" lineHeight="18px">
                        Interview Invite Subject{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </FormLabel>
                      <ChakraButton
                        leftIcon={
                          <FAIcon
                            iconName={!previewOpen ? 'eye' : 'eye-slash'}
                          />
                        }
                        variant={'borderless'}
                        onClick={() => {
                          setPreviewOpen(!previewOpen);
                        }}
                      >
                        Preview
                      </ChakraButton>
                    </Flex>
                    <Input
                      name="subject"
                      type="text"
                      placeholder="Subject"
                      variant="outline"
                      value={
                        !previewOpen
                          ? values.subject
                          : placholders(values.subject)
                      }
                      onChange={(e: any) => {
                        setFieldValue('subject', e.target.value);
                      }}
                      sx={{
                        height: '38px',
                        border: 'none',
                        borderBottom: '1px solid',
                        borderColor: '#E7E9ED',
                        p: '8px 12px',
                        fontSize: '14px',
                      }}
                    />
                    <FormErrorMessage>
                      {String(errors.subject)}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>

                <Stack
                  sx={{
                    bgColor: '#FFF',
                    borderRadius: '6px',
                    p: '16px',
                  }}
                >
                  <FormControl
                    isInvalid={Boolean(!!errors.body && touched.body)}
                  >
                    <AtsEmailBody
                      value={
                        !previewOpen ? values.body : placholders(values.body)
                      }
                      onChange={(e: any) => {
                        setFieldValue('body', e);
                      }}
                      height={300}
                      hasAIGenerate={hasAIGenerate}
                      setHasAIGenerate={setHasAIGenerate}
                      setIsGenerate={setIsGenerate}
                      handleValues={handleValues}
                      setIsError={setIsError}
                      subject={values.subject}
                      body={values.body}
                      attachments={attachments}
                      setAttachments={setAttachments}
                      isHideAttach
                    />
                    <FormErrorMessage>{String(errors.body)}</FormErrorMessage>
                  </FormControl>
                </Stack>

                <Stack
                  sx={{
                    bgColor: '#FFF',
                    borderRadius: '6px',
                    p: '16px',
                  }}
                >
                  <Checkbox
                    colorScheme="purple"
                    defaultChecked={values.primary}
                    name="primary"
                    id="primary"
                    onChange={handleChange}
                  >
                    Attach Primary Resume
                  </Checkbox>
                </Stack>
              </Stack>

              {handleAIGenerator(setFieldValue)}
            </Flex>

            <ModalFooter
              position="sticky"
              bottom="0"
              background="default.white.100"
              boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
              gap={5}
              p="10px"
              zIndex={2}
            >
              <Flex width="100%" justifyContent="space-between">
                <Flex gap="10px">
                  <Button
                    variant="ghost"
                    type="button"
                    onClick={() => ClickBack(values)}
                    disabled={loading}
                    sx={{
                      borderRadius: '8px',
                      color: '#2B2D42',
                      boxShadow: '0px 2px 8px 0px #0000000A',
                    }}
                  >
                    Back
                  </Button>
                  <ChakraButton
                    variant="outline"
                    onClick={onOpenNotes}
                    left="0px"
                    color="primary.800"
                  >
                    Notes
                  </ChakraButton>
                </Flex>
                <Flex gap="10px">
                  <ChakraButton
                    variant="borderless"
                    onClick={() => {
                      onClose();
                      dispatch(
                        uploadList({
                          uploaded: [],
                          attachments: [],
                          attachmentInvite: [],
                          attachmentConfirmation: [],
                        })
                      );
                      dispatch(listTemplate({ aiTemplate: [] }));
                    }}
                  >
                    Cancel
                  </ChakraButton>
                  <Menu closeOnSelect={true}>
                    <Flex>
                      <Box
                        sx={{
                          button: {
                            p: '8px 4px',
                            pl: '8px',
                            height: '40px',
                            fontSize: '12px',
                            borderRight: 'none',
                            borderRadius: '6px 0 0 6px',
                          },
                        }}
                      >
                        <ChakraButton type="button">Save As</ChakraButton>
                      </Box>
                      <MenuButton
                        as={ChakraButton}
                        type="button"
                        sx={{
                          p: '8px',
                          height: '40px',
                          fontSize: '12px',
                          borderLeft: 'none',
                          borderRadius: '0 6px 6px 0',
                        }}
                      >
                        |{' '}
                        <Box as="span" ml="10px">
                          <FAIcon iconName="chevron-down" />
                        </Box>
                      </MenuButton>
                    </Flex>
                    {
                      <MenuList
                        fontSize="sm"
                        maxHeight="120px"
                        overflowY="auto"
                      >
                        <MenuItem onClick={() => {}}>
                          Save as a template
                        </MenuItem>
                      </MenuList>
                    }
                  </Menu>
                  {/* <Button
                    variant="outline"
                    type="button"
                    leftIcon={<BsEyeFill />}
                    onClick={() => PreviewClick(values)}
                  >
                    Preview
                  </Button> */}
                  <ChakraButton
                    variant="solid"
                    type="submit"
                    isLoading={loading}
                    isDisabled={uploading || loading}
                  >
                    {schedInterview === '0' ? 'Next' : 'Submit'}
                  </ChakraButton>
                </Flex>
              </Flex>
            </ModalFooter>
          </form>
        )}
      </Formik>
      {isOpenPreview && (
        <PreviewDetails
          isOpen={isOpenPreview}
          onClose={onClosePreview}
          param={PrevParam}
        />
      )}
      {isOpenNotes && (
        <NotesModal isOpen={isOpenNotes} onClose={onCloseNotes} />
      )}
      {addOpen && <TemplateAddModal isOpen={addOpen} onClose={addOnClose} />}
    </>
  );
};

export default InviteMessage;
