import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  Box,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import Button from 'Library/Button';

import { AtsConfirm } from 'utils/swal';
import { downloadLink } from 'utils/downloadlink';

import { LeadAgreementAttachmentTypes, swalContent } from 'types';

import GoTo from 'assets/images/goto.svg';
import { useEffect, useState } from 'react';
import {
  removeAttachmentTemplate,
  useDelTemplateAttachmentMutation,
  useListTemplateAttachmentMutation,
} from 'store/templateattachment.slice';
import TrLoading from 'components/app/TrLoading';
import FAIcon from 'components/lib/FAIcon';
import { StreamAttachmentModal } from 'modules/Leads/TabList/TabPanel/Attachments/Stream';

interface templateAttachmentInt {
  template_id: number;
}

const TemplateAttachments = ({ template_id }: templateAttachmentInt) => {
  const tableHeader: { label: string }[] = [
    { label: 'File Name' },
    { label: 'Uploaded By' },
    { label: 'Uploaded On' },
    { label: '' },
  ];

  const dispatch = useDispatch();
  const [reqList, resList] = useListTemplateAttachmentMutation();

  const [fileData, setFileData] = useState(null);

  const [reqdel] = useDelTemplateAttachmentMutation();

  const content: swalContent = {
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this file.',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const { attachments } = useSelector((state: any) => state.templateAttachment);

  useEffect(() => {
    reqList({ id: template_id, data: [] });
  }, [template_id]);

  const {
    isOpen: isStreamOpen,
    onOpen: onStreamOpen,
    onClose: onStreamClose,
  } = useDisclosure();

  const delAttachment = async (id: number, key: number) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqdel({ id });
      dispatch(removeAttachmentTemplate(key));
    }
  };

  return (
    <Box>
      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {resList.isLoading ? (
                <TrLoading rows={5} columns={4} />
              ) : (
                attachments?.map(
                  (item: LeadAgreementAttachmentTypes, key: number) => (
                    <Tr
                      key={item.original_file_name}
                      cursor="pointer"
                      onClick={() => {}}
                    >
                      <Td>
                        <Flex alignItems="center" color="primary.800">
                          <Link
                            style={{ display: 'flex', gap: '7px' }}
                            rel="noreferrer"
                            onClick={() => {
                              onStreamOpen();
                              setFileData(item);
                            }}
                          >
                            {item.original_file_name}
                            <span>
                              <Image src={GoTo} alt="logo" />
                            </span>
                          </Link>
                        </Flex>
                      </Td>
                      <Td></Td>
                      <Td>
                        {moment
                          .utc(item.created_at)
                          .format('MM/DD/YYYY hh:mm A')}
                      </Td>
                      <Td>
                        <Button
                          htmlType="button"
                          variant="ghost"
                          iconName="trash"
                          iconSize="xl"
                          onClick={() => delAttachment(item.id, key)}
                        />
                        {/* <Menu>
                          <MenuButton
                            onClick={() => delAttachment(item.id, key)}
                          >
                            <Button
                              htmlType="button"
                              variant="ghost"
                              iconName="trash"
                              iconSize="xl"
                              onClick={() => delAttachment(item.id, key)}
                            />
                          </MenuButton>
                        </Menu> */}
                      </Td>
                    </Tr>
                  )
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {isStreamOpen && (
        <StreamAttachmentModal
          isOpen={isStreamOpen}
          onClose={onStreamClose}
          file={fileData}
          route={'/email-template/attachments/download/'}
        />
      )}
    </Box>
  );
};

export default TemplateAttachments;
