import {
  Flex,
  Image,
  Link,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import LinkedIn from 'assets/images/linkedin.svg';
import { useEffect, useState } from 'react';
import Button from 'Library/Button';
import EmailsModal from 'components/app/Global/Email/Modal';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import toUrl from 'utils/toUrl';
import {
  updateInbox,
  usePostTaskListMutation,
  usePostUpdateStatusMutation,
} from 'store/inbox.slice';
import { translateAngleBrackets } from 'utils/translateAngleBrackets';

interface EmailsThreadProps {
  active: number;
}

export const EmailThread = ({ active }: EmailsThreadProps) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { inboxData, inboxPgBtn } = useSelector((state: any) => state.inbox);
  const [activeTask, setActiveTask] = useState({ id: null, isActive: false });

  const [reqPostTaskList, resPostTaskList] = usePostTaskListMutation();
  const [reqPostUpdateStatus, resPostUpdateStatus] =
    usePostUpdateStatusMutation();

  const {
    isOpen: isOpenReply,
    onOpen: onOpenReply,
    onClose: onCloseReply,
  } = useDisclosure();

  const handleUpdateStatus = (id: number) => {
    const payload = {
      status: active === 0 ? '2' : '1',
      ids: [String(id)],
    };
    reqPostUpdateStatus(payload);
  };

  useEffect(() => {
    if (resPostUpdateStatus.isSuccess) {
      dispatch(updateInbox({ inboxData: {} }));
      reqPostTaskList(inboxPgBtn);

      toast({
        title: 'Task status updated.',
        description: "We've updated your task status.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostUpdateStatus.isSuccess]);

  return (
    <Stack sx={{ mt: '16px', p: '10px' }}>
      <Flex
        justifyContent="space-between"
        sx={{ borderRadius: '4px', bgColor: '#F9F9FB', p: '10px 16px' }}
      >
        <Stack spacing="4px">
          <Flex gap="4px" alignItems="center">
            <Link
              href={`/candidates/search/1/${inboxData?.data?.candidate?.id}/about`}
              target="_blank"
              rel="noreferrer"
              sx={{ fontSize: '14px', fontWeight: 700 }}
            >
              {[
                inboxData?.data?.candidate?.first_name,
                inboxData?.data?.candidate?.last_name,
              ].join(' ')}
            </Link>
            <Link
              target="_blank"
              href={toUrl(inboxData?.data?.candidate?.linkedin_url)}
            >
              <Image src={LinkedIn} height="16px" width="16px" />
            </Link>
          </Flex>
          <Text sx={{ fontSize: '14px', color: '#2B2D42' }}>
            {inboxData?.data?.email}
          </Text>
        </Stack>
        <Stack spacing="4px">
          <Text sx={{ fontSize: '14px', fontWeight: 700, color: '#718096' }}>
            <Link
              href={`/jobs/${inboxData?.data?.suquence?.job?.id}/details`}
              target="_blank"
              rel="noreferrer"
            >
              {inboxData?.data?.suquence?.title}
            </Link>{' '}
            -{' '}
            <Link
              href={`/clients/${inboxData?.data?.suquence?.job?.client?.id}/overview`}
              target="_blank"
              rel="noreferrer"
            >
              {inboxData?.data?.suquence?.job?.client?.name}
            </Link>
          </Text>
        </Stack>
      </Flex>
      <Text
        sx={{
          p: '12px 24px',
          borderBottom: '1px solid #EEEEEE',
          fontSize: '14px',
          fontWeight: 600,
          color: '#2B2D42',
        }}
      >
        {inboxData?.email_thread
          ? inboxData?.email_thread[inboxData?.email_thread?.length - 1]
              ?.Subject
          : ''}
      </Text>
      <Stack
        spacing="16px"
        pt="8px"
        sx={{ height: '70vh', overflowY: 'scroll' }}
      >
        {inboxData?.email_thread?.length > 0 &&
          inboxData?.email_thread?.map((val: any) => {
            const isActive = val.Id === activeTask.id;
            return (
              <Stack
                sx={{
                  borderRadius: '4px',
                  border: isActive ? '1px solid #6930CA' : '1px solid #EEEEEE',
                  p: '8px 24px',
                  color: '#2B2D42',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setActiveTask({ id: val.Id, isActive: !activeTask.isActive });
                }}
              >
                <Flex justifyContent="space-between">
                  <Text sx={{ fontWeight: 700 }}>
                    {val?.Sender?.emailAddress?.name}
                  </Text>
                  <Text sx={{ fontSize: '12px', color: '#9B9B9B' }}>
                    {moment(val?.CreatedDateTime).format(
                      'ddd, MMM DD, YYYY HH:MMA'
                    )}{' '}
                    PST
                  </Text>
                </Flex>
                <Text
                  sx={{
                    whiteSpace: !isActive && 'nowrap',
                    overflow: !isActive && 'hidden',
                    textOverflow: !isActive && 'ellipsis',
                    width: !isActive && 'initial',
                  }}
                >
                  {!isActive
                    ? parse(translateAngleBrackets(val?.BodyPreview))
                    : parse(val?.body)}
                </Text>
              </Stack>
            );
          })}
      </Stack>
      {active === 0 && (
        <Flex
          gap="10px"
          sx={{ position: 'absolute', top: '10px', right: '14px' }}
        >
          <Button leftIcon="chevron-right" onClick={() => onOpenReply()}>
            Reply
          </Button>
          <Button
            leftIcon="check"
            variant="solid"
            onClick={() => handleUpdateStatus(inboxData?.data?.id)}
          >
            Done
          </Button>
        </Flex>
      )}

      {isOpenReply && (
        <EmailsModal
          modalTitle="Reply Email"
          isOpen={isOpenReply}
          onClose={onCloseReply}
          email={inboxData?.data?.email}
          id={inboxData?.data?.id}
          isSubjectDisabled
          candidate_id={inboxData?.job_assoc?.candidate_id}
          job_id={inboxData?.job_assoc?.job_id}
          allowedCategory={['Candidates', 'Jobs']}
          isReply
        />
      )}
    </Stack>
  );
};
